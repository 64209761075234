import {
  setSubmitFailed,
  setSubmitSucceeded,
  startSubmit,
  stopSubmit,
} from 'redux-form';
import { all, put, takeLatest } from 'redux-saga/effects';

import { API_ACTION_TYPES } from 'api/actions';
import { RequestActionMeta } from 'api/apiReqSagaCreator';

interface XhrAction {
  type: string;
  meta: RequestActionMeta<{}>;
}

const STATE = {
  START: 'START',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const getFormsArray = (meta: RequestActionMeta<{}>) =>
  [
    ...(Array.isArray(meta.submittedForm)
      ? meta.submittedForm
      : [meta.submittedForm]),
  ] as string[];

export default function* watchersSaga() {
  yield takeLatest(
    [
      API_ACTION_TYPES.XHR.START,
      API_ACTION_TYPES.XHR.SUCCESS,
      API_ACTION_TYPES.XHR.ERROR,
    ],
    function* workerSaga({ type, meta }: XhrAction) {
      const state = type.split('.').reverse()[0];

      if (meta?.submittedForm) {
        yield all(
          getFormsArray(meta).map((e) =>
            put(state === STATE.START ? startSubmit(e) : stopSubmit(e))
          )
        );
        yield all(
          getFormsArray(meta).map((e) => {
            if (state === STATE.SUCCESS) return put(setSubmitSucceeded(e));
            if (state === STATE.ERROR) return put(setSubmitFailed(e));
            return null;
          })
        );
      }
    }
  );
}
