import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import {
  errorSaveTemplate,
  reFetchTemplates,
  removeGridTemplateSuccess,
  successSaveTemplate,
} from './sagas';

function* watcherSaga() {
  yield takeLatest(
    API_REQ_ACTION_TYPES.GRID.SAVE_TEMPLATE.ERROR,
    errorSaveTemplate
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.GRID.SAVE_TEMPLATE.SUCCESS,
    successSaveTemplate
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.GRID.REMOVE_TEMPLATE.SUCCESS,
    removeGridTemplateSuccess
  );

  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.GRID.SAVE_TEMPLATE.SUCCESS,
      API_REQ_ACTION_TYPES.GRID.ACTIVATE_TEMPLATE.SUCCESS,
      API_REQ_ACTION_TYPES.GRID.REMOVE_TEMPLATE.SUCCESS,
    ],
    reFetchTemplates
  );
}

export default watcherSaga;
