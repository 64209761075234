import ROUTES, { OcrTestPreviewUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { fork, select } from 'redux-saga/effects';

import Ocr from 'api/endpoints/ocr';

import ApiUrlParams from 'constants/apiUrlParams';

function* reFetchTestResults() {
  const { pathname } = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId },
  }: match<OcrTestPreviewUiParams> = yield matchPath(pathname, {
    path: [
      ROUTES.process.definition.services.ocr.test._(),
      ROUTES.process.definition.services.ocr.test.preview(),
    ],
  });

  yield fork(Ocr.getTestResults, {
    [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
  });
}

export default reFetchTestResults;
