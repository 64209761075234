import { combineReducers } from 'redux';

import { GLOBAL_ACTION_TYPES, ToggleModalCloseCallbacks } from './actions';

export interface GlobalState {
  hidePageWrap: boolean;
  openedModals: string[];
}

const global = combineReducers<GlobalState>({
  hidePageWrap(state = false, { type }) {
    switch (type) {
      case GLOBAL_ACTION_TYPES.TOGGLE_PAGE_WRAP_VISIBILITY:
        return !state;
      default:
        return state;
    }
  },
  openedModals(state = [], { type, payload }: ToggleModalCloseCallbacks) {
    switch (type) {
      case GLOBAL_ACTION_TYPES.TOGGLE_OPENED_MODAL:
        if (!payload) {
          return state.slice(0, state.length - 1);
        }
        if (payload.remove) {
          return state.filter((e) => e !== payload.id);
        }
        return state.concat(payload.id);

      default:
        return state;
    }
  },
});

export default global;
