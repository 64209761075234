import ROUTES from 'app/routes/paths';
import { push } from 'connected-react-router';
import { fork, put } from 'redux-saga/effects';

import { Users } from 'api/endpoints';
import {
  ImportProcessParams,
  ImportProcessResult,
} from 'api/endpoints/processes';

import { SKIP_REDIRECT_TITLE } from 'store/users/sagas/moveFromRestrictedRoute';

import ApiUrlParams from 'constants/apiUrlParams';
import UiUrlParams from 'constants/uiUrlParams';

import createATA from 'utils/createAddToasterAction';

const MESSAGE = 'The process successfully imported.';

function* importProcessSuccess({
  payload: { id },
  meta: {
    input: { tenantId },
  },
}: ReduxAction<ImportProcessResult, { input: ImportProcessParams }>) {
  yield put(
    createATA({
      message: MESSAGE,
    })
  );

  yield put(
    push(
      ROUTES.process.definition.details.versions({
        [UiUrlParams.releaseOrDraftProcessId]: id,
      })
    )
  );

  yield fork(
    Users.updateUserActiveTenant,
    { [ApiUrlParams.tenantId]: tenantId },
    { title: SKIP_REDIRECT_TITLE }
  );
}

export default importProcessSuccess;
