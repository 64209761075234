import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import { updateFunctionSuccess as updateStageSuccess } from 'store/function/sagas';

import {
  handleStageCreate,
  reFetchList,
  updateCreateStageSuccess,
} from './sagas';

function* watchersSaga() {
  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.STAGES.CREATE.SUCCESS,
      API_REQ_ACTION_TYPES.STAGES.UPDATE.SUCCESS,
      API_REQ_ACTION_TYPES.STAGES.DELETE.SUCCESS,
    ],
    reFetchList
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.STAGES.UPDATE.SUCCESS],
    updateStageSuccess
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.STAGES.CREATE.SUCCESS],
    handleStageCreate
  );

  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.STAGES.CREATE.SUCCESS,
      API_REQ_ACTION_TYPES.STAGES.UPDATE.SUCCESS,
    ],
    updateCreateStageSuccess
  );
}
export default watchersSaga;
