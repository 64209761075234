import ROUTES, { ProcessInstanceUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { call, fork, select } from 'redux-saga/effects';

import { Variables } from 'components/organisms/taskSelector';

import ProcessInstance, { Task } from 'api/endpoints/processInstance';

import ApiUrlParams from 'constants/apiUrlParams';
import { TaskType } from 'constants/global';

import normalizedFormFiles from './normalizedFormFiles';

export type SubmitInstanceTaskArgs = [Task, Variables];

function* submitInstanceTask(task: Task, values: KeyValuePairs) {
  const { pathname } = yield select(getLocation);

  const {
    params: { processInstanceId, releaseOrDraftProcessId },
  }: match<ProcessInstanceUiParams> = yield matchPath(pathname, {
    path: [ROUTES.process.instance._()],
  });

  switch (task.type) {
    case TaskType.SendFormViaEmail:
    case TaskType.SendFormViaSms:
    case TaskType.SendFormWithEmailTemplate:
    case TaskType.SendEmailWithVars:
      yield fork(
        ProcessInstance.skipProcessInstanceTask,
        {
          [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
          [ApiUrlParams.processInstanceId]: processInstanceId,
        },
        { submittedForm: task.id }
      );
      break;

    default: {
      const transformResult: KeyValuePairs<string | null | number> = yield call(
        normalizedFormFiles,
        {
          form: task.id,
          fields: Object.fromEntries(
            Object.entries(values).filter(([key]) => key.length === 36)
          ),
          releaseOrDraftProcessId,
          processInstanceId,
        }
      );

      if (transformResult) {
        yield fork(
          ProcessInstance.nextProcessInstanceTask,
          {
            [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
            [ApiUrlParams.processInstanceId]: processInstanceId,
            fields: { ...transformResult },
          },
          { submittedForm: task.id }
        );
      }
      break;
    }
  }
}

export default submitInstanceTask;
