import { call } from 'redux-saga/effects';

import {
  Fields as AuthFields,
  credentialsAreEmpty,
  EmailProviders,
  ImapFields,
  MSGraphFields,
} from 'components/organisms/form/checkGSuitCreds/types';
import { Fields } from 'components/organisms/form/emailPollingScenario/types';

import { ApiSagaResult } from 'api/apiReqSagaCreator';
import EmailPolling, {
  ApiLabelPair,
  CreateScenarioParams,
  CreateScenarioResult,
  EmailProviderAuth,
  ImapAuth,
  MSGraphAuth,
  UpdateScenarioResult,
} from 'api/endpoints/emailPolling';

import ApiUrlParams from 'constants/apiUrlParams';

function buildRequestAuth(
  provider: EmailProviders,
  credentialsData: AuthFields
): EmailProviderAuth | undefined {
  const addCredentials = !credentialsAreEmpty(provider, credentialsData);

  if (!addCredentials) return undefined;

  if (provider === 'gmail' || provider === 'office365') {
    const creds = credentialsData as ImapFields;
    return {
      email: creds.email,
      password: creds.password,
    } as ImapAuth;
  }
  if (provider === 'msgraph') {
    const creds = credentialsData as MSGraphFields;
    return {
      email: creds.email,
      tenantId: creds.tenantId,
      applicationId: creds.applicationId,
      userId: creds.userId,
      secret: creds.secret,
    } as MSGraphAuth;
  }
  return undefined;
}

function* createUpdateScenario(
  scenarioData: Fields,
  releaseOrDraftProcessId: string,
  inboundEmailScenarioId?: string
) {
  const credentialsData: AuthFields = scenarioData.serviceAuth!;
  const {
    name,
    attachment,
    attachmentFileName,
    body,
    sender,
    subject,
    labels,
    emailLabel,
    emailProvider,
  } = scenarioData;
  const typedProvider = ((emailProvider.value as string) ??
    '') as EmailProviders; // scenarioData['emailProvider']?.value ?? ''
  const auth = buildRequestAuth(typedProvider, credentialsData);

  const labelsAsApiLabels: ApiLabelPair[] = labels.map(
    ({ inLabel, processedLabel, indexSourceId, individualAttachments }) => ({
      input: inLabel.value,
      processed: processedLabel.value,
      indexingSourceId:
        indexSourceId?.value === '' || indexSourceId?.value === undefined
          ? null
          : indexSourceId?.value,
      individualAttachments,
    })
  );

  const normalizedData: CreateScenarioParams['data'] = {
    name: name as string,
    emailSenderFieldId: sender.value as string,
    emailSubjectFieldId: subject.value as string,
    emailBodyFieldId: body.value as string,
    emailAttachmentFieldId: attachment.value as string,
    emailAttachmentFileNameFieldId: attachmentFileName.value as string,
    emailLabelFieldId: emailLabel.value as string,
    labels: labelsAsApiLabels,
    isPolicyAccepted: true,
    provider: typedProvider,
    imapAuth: auth,
  };

  if (!inboundEmailScenarioId) {
    const result: ApiSagaResult<CreateScenarioResult> = yield call(
      EmailPolling.createScenario,
      {
        [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
        data: normalizedData,
      }
    );
    return result;
  } else {
    const result: ApiSagaResult<UpdateScenarioResult> = yield call(
      EmailPolling.updateScenario,
      {
        [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
        [ApiUrlParams.inboundEmailScenarioId]: inboundEmailScenarioId,
        data: normalizedData,
      }
    );
    return result;
  }
}

export type CreateUpdateScenarioArgs = Parameters<typeof createUpdateScenario>;

export default createUpdateScenario;
