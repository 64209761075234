import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import fileUploadSuccess from 'store/printRender/sagas/fileUploadSuccess';

import { addScanConfigFailure, reFetchScanList } from './sagas';

function* watchersSaga() {
  yield takeLatest(
    [API_REQ_ACTION_TYPES.SCANNING.CREATE.SUCCESS],
    reFetchScanList
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.SCANNING.CREATE.ERROR,
    addScanConfigFailure
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.SCANNING.PAGES.UPDATE_PAGE.SUCCESS,
    fileUploadSuccess
  );
}

export default watchersSaga;
