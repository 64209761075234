import { ReusableFunctionDetails } from 'api/endpoints/function';

import createAction from 'utils/createAction';

const FUNCTION = {
  CLEAR_LIST: 'FUNCTION.CLEAR_LIST',
  UPDATE_ACTIVE: 'FUNCTION.UPDATE_ACTIVE',
};

const FunctionActions = {
  clearList: createAction(FUNCTION.CLEAR_LIST),
  updateActive: createAction<ReusableFunctionDetails>(FUNCTION.UPDATE_ACTIVE),
};

export { FUNCTION as FUNCTION_ACTION_TYPES, FunctionActions };
