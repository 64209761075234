export type OpenHamburgerHandler = EmptyArgsVoidFunc;

export enum WrapperVisible {
  none = 'none',
  flex = 'flex',
}

export interface UseHamburgerMenu {
  isOpen: boolean;
  openHamburgerHandler: OpenHamburgerHandler;
  wrapperVisible: WrapperVisible;
}
