import { combineReducers } from 'redux';

import { Fields } from 'components/organisms/form/createPrintRenderScenario/types';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import {
  GetAllScenariosResult,
  PrintRenderScenarioDetails,
  PrintRenderScenarioGeneral,
} from 'api/endpoints/printRender';
import { InstanceCountData } from 'api/endpoints/processInstance';

import { PRINT_RENDER_ACTION_TYPES } from './actions';

export interface PrintRenderState {
  activePrintRenderItem: Nullable<PrintRenderScenarioDetails>;
  list: Nullable<PrintRenderScenarioGeneral[]>;
  total: Nullable<number>;
  instanceCount: Nullable<InstanceCountData>;
  initialPrintRenderItemData: Nullable<Fields>;
}

interface ActionMeta {
  title: string;
  input: {
    isActive?: boolean;
  };
}

const printRender = combineReducers({
  list(
    state = null,
    { type, payload, meta }: ReduxAction<GetAllScenariosResult, ActionMeta>
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.PRINT_RENDER.GET_ALL.SUCCESS:
      case PRINT_RENDER_ACTION_TYPES.SET_PRINT_RENDER_DATA:
        return !meta?.input?.isActive ? (payload?.data ?? null) : state;
      default:
        return state;
    }
  },
  activePrintRenderItem(
    state = null,
    { type, payload, meta }: ReduxAction<GetAllScenariosResult, ActionMeta>
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.PRINT_RENDER.GET_ALL.SUCCESS:
      case PRINT_RENDER_ACTION_TYPES.SET_PRINT_RENDER_DATA:
        return meta?.input?.isActive ? (payload?.data ?? null) : state;
      default:
        return state;
    }
  },
  total(
    state = null,
    { type, payload, meta }: ReduxAction<GetAllScenariosResult, ActionMeta>
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.PRINT_RENDER.GET_ALL.SUCCESS:
      case PRINT_RENDER_ACTION_TYPES.SET_PRINT_RENDER_DATA:
        return !meta?.input?.isActive ? (payload?.total ?? null) : state;
      default:
        return state;
    }
  },
  instanceCount(
    state = null,
    { type, payload }: { type: string; payload: InstanceCountData }
  ) {
    switch (type) {
      case PRINT_RENDER_ACTION_TYPES.SET_PROCESS_INSTANCES:
        return payload || null;

      default:
        return state;
    }
  },
  initialPrintRenderItemData(state = null, { type, payload }) {
    switch (type) {
      case PRINT_RENDER_ACTION_TYPES.SET_INITIAL_PRINT_RENDER_ITEM_DATA:
        return payload ?? null;
      default:
        return state;
    }
  },
});

export default printRender;
