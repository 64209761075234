import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Text from 'components/atoms/text';
import Button from 'components/molecules/button';

import { ToasterPayload } from 'utils/createAddToasterAction';

import sm from './styles.module.scss';

export type HandleActionClick = EmptyArgsVoidFunc;

export interface ToasterProps {
  payload: ToasterPayload;
}

const Toaster = ({ payload: { message, action } }: ToasterProps) => {
  const { push } = useHistory();

  const handleActionClick: HandleActionClick = useCallback(() => {
    if (action?.onClick) {
      action.onClick();
    } else if (action?.moveTo) {
      push(action.moveTo);
    }
  }, [action, push]);

  return (
    <div className={sm.Toaster}>
      <div className={sm.Toaster_Body}>
        <div className={action ? sm.Toaster_Body_Message : undefined}>
          {!!message && <Text content={message} withWhiteColor />}
        </div>
        {action && (
          <Button
            label={action.label}
            onClick={handleActionClick}
            type="button"
            isOutlined
            withFullWidth
            view="Default"
          />
        )}
      </div>
    </div>
  );
};

export default memo(Toaster);
