import { startAsyncValidation, stopAsyncValidation } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';

import { Fields } from 'components/organisms/form/createFtpConnection';

import { FtpConnections } from 'api/endpoints';

import { FORM_NAMES } from 'constants/form';

import {
  FormValuesSelector,
  getFormValuesWrapper,
} from 'utils/getFormValuesWrapper';

import { FtpConnectionsActions } from '../actions';

function* validateThirdPartyServicesCredentials() {
  const prSelector: FormValuesSelector<Fields> = yield getFormValuesWrapper(
    FORM_NAMES.CREATE_FTP_CONNECTION
  );

  const values: Fields = yield select(prSelector);

  const { result } = yield call(FtpConnections.validateCredentials, values);

  yield put(startAsyncValidation(FORM_NAMES.CREATE_FTP_CONNECTION));

  yield put(FtpConnectionsActions.setUserCredentialsStatus(result?.isValid));

  yield put(stopAsyncValidation(FORM_NAMES.CREATE_FTP_CONNECTION));
}

export default validateThirdPartyServicesCredentials;
