import { call, put, select } from 'redux-saga/effects';
import { ScanDocumentContainerWithPages, SelectedPage } from 'types/scanning';

import { ProcessScan } from 'api/endpoints';
import { UpdatePage } from 'api/endpoints/processScan';
import {
  WithName,
  WithReleaseOrDraftProcessId,
  WithScanInstanceId,
} from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { DocumentViewActions } from '../actions';
import ScanDocumentViewSelector from '../selectors';

function* updateDocumentPages({
  meta: {
    input: { updateDocMeta, ...input },
  },
}: ReduxAction<
  unknown,
  {
    input: UpdatePage &
      WithReleaseOrDraftProcessId &
      WithScanInstanceId &
      WithName;
  }
>) {
  const { result } = yield call(ProcessScan.getPage, {
    [ApiUrlParams.releaseOrDraftProcessId]:
      input[ApiUrlParams.releaseOrDraftProcessId],
    [ApiUrlParams.scanInstanceId]: input[ApiUrlParams.scanInstanceId],
    [ApiUrlParams.name]: input[ApiUrlParams.name],
  });

  const docsList: ScanDocumentContainerWithPages[] = yield select(
    ScanDocumentViewSelector.getList
  );

  const docsTotal: number = yield select(ScanDocumentViewSelector.getTotal);

  const neededToUpdateDoc = docsList.find((e) => e.id === updateDocMeta?.docId);

  const updatedPages = neededToUpdateDoc?.pages?.map((e) => {
    if (e.name !== updateDocMeta?.localPageName) return e;
    return {
      ...e,
      url: URL.createObjectURL(result),
    };
  });

  const updateDocumentsList = docsList.map((e) => {
    if (e.id !== updateDocMeta?.docId) return e;
    return {
      ...e,
      pages: updatedPages as SelectedPage[],
    };
  });

  yield put(
    DocumentViewActions.setProcessInstances({
      data: updateDocumentsList ?? null,
      total: docsTotal,
    })
  );
}

export default updateDocumentPages;
