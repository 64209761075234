import { fork } from 'redux-saga/effects';

import Stage, { CreateStageParams } from 'api/endpoints/stage';
import { WithReleaseOrDraftProcessId } from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

function* reFetchStagesList({
  meta: { input },
}: {
  type: string;
  meta: { input: CreateStageParams & WithReleaseOrDraftProcessId };
}) {
  if (input.reFetchStages) {
    yield fork(Stage.getAll, {
      [ApiUrlParams.releaseOrDraftProcessId]:
        input[ApiUrlParams.releaseOrDraftProcessId],
    });
  }
}

export default reFetchStagesList;
