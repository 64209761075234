import { combineReducers } from 'redux';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import { Licenses } from 'api/endpoints/licenses';

export interface LicensesState {
  userLimit: number | null;
  activeUsersCount: number | null;
}

const licenses = combineReducers<LicensesState>({
  userLimit(state = null, { type, payload }: ReduxAction<Licenses>) {
    switch (type) {
      case API_REQ_ACTION_TYPES.LICENSES.GET_LICENSES.SUCCESS:
        return payload ? payload.userLimit : null;
      default:
        return state;
    }
  },
  activeUsersCount(state = null, { type, payload }: ReduxAction<Licenses>) {
    switch (type) {
      case API_REQ_ACTION_TYPES.LICENSES.GET_LICENSES.SUCCESS:
        return payload ? payload.activeUsersCount : null;
      default:
        return state;
    }
  },
});

export default licenses;
