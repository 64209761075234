import { put } from 'redux-saga/effects';

import createATA from 'utils/createAddToasterAction';

const MESSAGE = 'The Search Template was successfully removed';

function* removeSearchTemplateSuccess() {
  yield put(
    createATA({
      message: MESSAGE,
    })
  );
}

export default removeSearchTemplateSuccess;
