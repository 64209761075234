import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { call, Effect, put, race, select, take } from 'redux-saga/effects';

import * as TOASTER_MESSAGES from 'constants/toasterMessages';

import createAsyncActions, { AsyncActionTypes } from 'utils/createAsyncActions';

import { HTTP_METHODS } from '../constants';
import {
  AUTH_TOKEN_EXCHANGE,
  AUTH_TOKEN_EXPIRED,
  AuthTokenExpired,
  isExchangePendingSelector,
  isRetryPossibleSelector,
} from './config';
import xhrSaga from './xhrSaga';

type ApiAxiosPositiveResult<S = {}> = AxiosResponse<S>;

type ApiAxiosNegativeResult = AxiosResponse<{ errors: BackendError[] }>;

type ApiResult<S = {}> = ApiAxiosPositiveResult<S> | ApiAxiosNegativeResult;

function ApiResultIsNegative<S>(r: ApiResult<S>): r is ApiAxiosNegativeResult {
  return r.status >= 400;
}

type PositiveActionPayload<S> = S | ApiAxiosPositiveResult<S>;

type NegativeActionPayload = BackendError[] | ApiAxiosNegativeResult;

const fallbackReqMeta = {
  muteError: false,
  maxRetries: 1,
  rawResult: false,
  authRequired: true,
  ignorePendingExchange: false,
  withPrevPageOnLessTotal: false,
  getErrorMessageByCode: (code: number) =>
    TOASTER_MESSAGES.API_ERRORS_BY_CODES[code],
};

export type RequestActionMeta<I> = Partial<typeof fallbackReqMeta> & {
  input?: I;
  title?: string;
  submittedForm?: string | string[];
};

export type ApiSagaResult<O> = APISagaResultSuccess<O> | APISagaResultError;
type APISagaResultSuccess<O> = { ok: true; result: O };
type APISagaResultError = { ok: false; result: BackendError[] };

export default function apiReqSagaCreator<I, O = unknown>(
  actionTypes: AsyncActionTypes,
  reqConfigCreator: (input: I) => AxiosRequestConfig = () => ({
    method: HTTP_METHODS.GET,
    url: '/api',
  }),
  baseReqMeta?: RequestActionMeta<I>
) {
  const actions = createAsyncActions<
    I,
    PositiveActionPayload<O>,
    NegativeActionPayload,
    RequestActionMeta<I>
  >(actionTypes);

  return function* apiRequestSaga(
    input: I,
    customReqMeta?: Partial<RequestActionMeta<I>>
  ): IterableIterator<ApiSagaResult<O> | Effect> {
    const meta = {
      input,
      title: actionTypes.START,
      ...fallbackReqMeta,
      ...(baseReqMeta || {}),
      ...(customReqMeta || {}),
    };

    yield put(actions.start(input, meta));

    const isExchangePending = yield select(isExchangePendingSelector);

    if (isExchangePending && !meta.ignorePendingExchange) {
      // @ts-ignore
      const { exchError } = yield race({
        exchSuccess: take(AUTH_TOKEN_EXCHANGE.SUCCESS),
        exchError: take(AUTH_TOKEN_EXCHANGE.ERROR),
      });

      if (exchError) {
        yield put(actions.error(exchError.payload, meta));
        return exchError.payload;
      }
    }

    const reqConfig = reqConfigCreator(input);

    // @ts-ignore
    const { response, parallelAuthFailed } = yield race({
      response: call(() => xhrSaga<O>(reqConfig, meta)),
      parallelAuthFailed: take(AUTH_TOKEN_EXPIRED),
    });

    const reqAuthFailed = response && (response as ApiResult).status === 401;
    if (reqAuthFailed || parallelAuthFailed) {
      // renew authentication token
      const suppressTokenExpired = yield select(isExchangePendingSelector);

      if (reqAuthFailed && !suppressTokenExpired) {
        yield put(AuthTokenExpired());
      }

      const retryPossible = yield select(isRetryPossibleSelector);

      if (meta.maxRetries > 0 && retryPossible) {
        return yield call(apiRequestSaga, input, {
          ...meta,
          maxRetries: meta.maxRetries - 1,
        });
      }
      const result: NegativeActionPayload =
        (response as ApiAxiosNegativeResult) ??
        ([
          {
            code: 0,
            description: 'failed to get token',
          },
        ] as BackendError[]);
      yield put(actions.error(result, meta));
      return {
        ok: false,
        result,
      };
    }

    const typedResponse = response as ApiResult<O>;
    if (ApiResultIsNegative(typedResponse)) {
      const actionPayload: NegativeActionPayload = meta.rawResult
        ? typedResponse
        : typedResponse.data.errors;
      yield put(actions.error(actionPayload, meta));

      return {
        ok: false,
        result: actionPayload,
      };
    }

    const actionPayload: PositiveActionPayload<O> = meta.rawResult
      ? typedResponse
      : typedResponse.data;
    yield put(actions.success(actionPayload, meta));

    return {
      ok: true,
      result: actionPayload,
    };
  };
}
