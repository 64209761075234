import ROUTES from 'app/routes/paths';
import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';

import { string } from 'utils/formatters';

function* redirectToProcessDefinition() {
  yield put(push(string.withDefaultPagination(ROUTES.process.definition._())));
}

export default redirectToProcessDefinition;
