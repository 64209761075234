import { stopAsyncValidation } from 'redux-form';
import { put } from 'redux-saga/effects';

import * as ERROR_CODES from 'constants/errorCodes';
import { ERROR_KEYS } from 'constants/fieldErrors';
import { FORM_NAMES } from 'constants/form';

function* defineKeysFailure({
  payload,
}: {
  type: string;
  payload: BackendError[];
}) {
  if (payload.some((e) => e.code === ERROR_CODES.ALREADY_USED_FIELD_FOR_OCR)) {
    yield put(
      stopAsyncValidation(FORM_NAMES.DEFINE_OCR_KEYS, {
        keysField: {
          key: ERROR_KEYS.ALREADY_USED_FIELD_FOR_DEFINE_OCR_KEYS,
        },
      })
    );
  }

  if (
    payload.some(
      (e) => e.code === ERROR_CODES.ALREADY_USED_CONFIDENCE_FIELD_FOR_OCR
    )
  ) {
    yield put(
      stopAsyncValidation(FORM_NAMES.DEFINE_OCR_KEYS, {
        confidenceField: {
          key: ERROR_KEYS.ALREADY_USED_FIELD_FOR_DEFINE_OCR_KEYS,
        },
      })
    );
  }
}

export default defineKeysFailure;
