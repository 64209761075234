import { takeLatest } from 'redux-saga/effects';

import { REGISTRATION_REQ_ACTION_TYPES } from './actions';
import registrationFailure from './sagas/registrationFailure';
import registrationRedirect from './sagas/registrationRedirect';

export default function* watchersSaga() {
  yield takeLatest(
    REGISTRATION_REQ_ACTION_TYPES.REGISTER.ERROR,
    registrationFailure
  );

  yield takeLatest(
    [
      REGISTRATION_REQ_ACTION_TYPES.REGISTER.SUCCESS,
      REGISTRATION_REQ_ACTION_TYPES.VALIDATE_INVITATION_OTT.ERROR,
    ],
    registrationRedirect
  );
}
