import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import {
  createSearchTemplateFailure,
  reFetchList,
  removeSearchTemplateSuccess,
} from './sagas';

function* watchersSaga() {
  yield takeLatest(
    API_REQ_ACTION_TYPES.SEARCH_TEMPLATE.CREATE.ERROR,
    createSearchTemplateFailure
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.SEARCH_TEMPLATE.REMOVE.SUCCESS,
    removeSearchTemplateSuccess
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.SEARCH_TEMPLATE.REMOVE.SUCCESS,
    reFetchList
  );
}

export default watchersSaga;
