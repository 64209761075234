const CELL_TYPE = {
  ICONS: 'icons',
};

const BASE_TYPE = {
  ACTION: 'action',
  DISPLAY: 'display',
};

const CELL_WIDTH = {
  ICON: 40,
};

const TABLE_MENU_RENDER_TYPE = 'TABLE_MENU_RENDER_TYPE';

const TABLE_ACTION = {
  EXPAND: `${BASE_TYPE.ACTION}-expand`,
  MENU: `${BASE_TYPE.ACTION}-menu`,
  BTN_CLICK: `${BASE_TYPE.ACTION}-btn-click`,
  INITIAL_FORM: `${BASE_TYPE.DISPLAY}-initial-form`,
  HIDE_FROM_USER: `${BASE_TYPE.DISPLAY}-hide-from-user`,
};

export {
  BASE_TYPE,
  TABLE_ACTION,
  TABLE_MENU_RENDER_TYPE,
  CELL_TYPE,
  CELL_WIDTH,
};
