import createAction from 'utils/createAction';

const FTP_CONNECTIONS = {
  SET_USER_CREDENTIALS_STATUS: 'CONNECTIONS.SET_USER_CREDENTIALS_STATUS',
  CLEAR_CONNECTION_DETAILS: 'CONNECTIONS.CLEAR_CONNECTION_DETAILS',
};

const FtpConnectionsActions = {
  setUserCredentialsStatus: createAction<Nullable<boolean>>(
    FTP_CONNECTIONS.SET_USER_CREDENTIALS_STATUS
  ),
  clearConnectionDetails: createAction(
    FTP_CONNECTIONS.CLEAR_CONNECTION_DETAILS
  ),
};

export {
  FTP_CONNECTIONS as FTP_CONNECTIONS_ACTION_TYPES,
  FtpConnectionsActions,
};
