import createAction from 'utils/createAction';

const PROCESS_VERSION = {
  CLEAR_DETAILS: 'PROCESS_VERSION.CLEAR_DETAILS',
};

const ProcessVersionActions = {
  clearDetails: createAction(PROCESS_VERSION.CLEAR_DETAILS),
};

export {
  PROCESS_VERSION as PROCESS_VERSION_ACTION_TYPES,
  ProcessVersionActions,
};
