import React, { memo, useMemo } from 'react';

import Text from 'components/atoms/text';
import Modal from 'components/molecules/modal';

import { TEXTS } from './config';
import sm from './styles.module.scss';

interface Props {
  isVisible: boolean;
  onAccept: EmptyArgsVoidFunc;
  onReject: EmptyArgsVoidFunc;
  disableButtons?: boolean;
}

const LogOutModal = ({
  isVisible,
  onAccept,
  disableButtons,
  onReject,
}: Props) => (
  <Modal
    withBiggestZIndex
    label={TEXTS.LOG_OUT.LABEL}
    isVisible={isVisible}
    rejectConfig={useMemo(
      () => ({
        label: TEXTS.LOG_OUT.CANCEL,
        onClick: onReject,
        isDisabled: disableButtons,
      }),
      [disableButtons, onReject]
    )}
    submitConfig={useMemo(
      () => ({
        label: TEXTS.LOG_OUT.LABEL,
        onClick: onAccept,
        isDisabled: disableButtons,
      }),
      [disableButtons, onAccept]
    )}
  >
    <div className={sm.Modal}>
      <div className={sm.Modal_Description}>
        <Text with18Font content={TEXTS.LOG_OUT.DESCRIPTION} />
      </div>
    </div>
  </Modal>
);

export default memo(LogOutModal);
