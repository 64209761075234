import { fork } from 'redux-saga/effects';

import { SplitDocumentGeneral } from 'api/endpoints/splitDocument';

import * as ERROR_CODES from 'constants/errorCodes';

import createDocumentSuccess, {
  Params as CreateDocumentParams,
} from './createDocumentSuccess';

function* splitDocumentError({
  payload,
  meta,
}: {
  type: string;
  payload: SplitDocumentGeneral | BackendError[];
  meta: { input: CreateDocumentParams };
}) {
  if (
    !Array.isArray(payload) ||
    (Array.isArray(payload) &&
      payload.some((e) => e.code === ERROR_CODES.BASE_CLIENT_ERROR))
  ) {
    yield fork(createDocumentSuccess, {
      payload: meta.input,
    } as unknown as CreateDocumentParams);
  }
}

export default splitDocumentError;
