import { SecurityGroupDetailsWithMembers } from 'api/endpoints/securityGroups';

import createAction from 'utils/createAction';

const SECURITY_GROUPS = {
  MEMBERS_WAS_UPDATED_AND_SAVED: 'SECURITY_GROUPS.UPDATED_MEMBERS_AND_SAVED',
  SET_DETAILS: 'SECURITY_GROUPS.SET_DETAILS',
};

const SecurityGroupsActions = {
  membersWasUpdatedAndSaved: createAction<string>(
    SECURITY_GROUPS.MEMBERS_WAS_UPDATED_AND_SAVED
  ),
  setDetails: createAction<SecurityGroupDetailsWithMembers>(
    SECURITY_GROUPS.SET_DETAILS
  ),
};

export {
  SECURITY_GROUPS as SECURITY_GROUPS_ACTION_TYPES,
  SecurityGroupsActions,
};
