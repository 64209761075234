import React from 'react';

import Text from 'components/atoms/text';
import BlankLink from 'components/molecules/blankLink';

import { LINKS, TEXTS } from './config';

interface Props {
  className?: string;
}

export const PrivacyPolicy = ({ className }: Props) => (
  <div className={className}>
    <BlankLink {...LINKS.TERMS} />
    <Text content={TEXTS.AND} />
    <BlankLink {...LINKS.POLICY} />
  </div>
);

export default PrivacyPolicy;
