import { stopAsyncValidation } from 'redux-form';
import { put } from 'redux-saga/effects';

import * as ERROR_CODES from 'constants/errorCodes';
import { ERROR_KEYS } from 'constants/fieldErrors';
import { FORM_NAMES } from 'constants/form';

function* createTemplateFailure({
  payload,
}: {
  type: string;
  payload: BackendError[];
}) {
  if (payload.some((e) => e.code === ERROR_CODES.ALREADY_USED_INDEX)) {
    yield put(
      stopAsyncValidation(FORM_NAMES.CREATE_TEMPLATE, {
        index: {
          key: ERROR_KEYS.ALREADY_USED_INDEX,
        },
      })
    );
  }
}

export default createTemplateFailure;
