import { stopSubmit } from 'redux-form';
import { call, fork, put } from 'redux-saga/effects';

import External from 'api/endpoints/external';

import normalizedFormFiles from 'store/process/sagas/normalizedFormFiles';

import { StartSubmitFormAction } from '../actions';

function* submitForm({ payload }: StartSubmitFormAction) {
  const transformResult: DynamicFormNormalizedParams = yield call(
    normalizedFormFiles,
    payload
  );

  if (transformResult) {
    yield fork(
      External.form.submit,
      {
        params: transformResult,
        token: String(payload?.ott),
      },
      { submittedForm: payload?.form }
    );
  } else {
    yield put(stopSubmit(String(payload?.form)));
  }
}

export default submitForm;
