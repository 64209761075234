import {
  ApiResponseAsync,
  translateAxiosErrorToApiError,
} from 'container/models';

import { authHeaderName } from 'api/apiReqSagaCreator/config';
import { handleExpiredTokens } from 'api/apiReqSagaCreator/httpRequest';

import { JsonPatch } from '../../../api/types/jsonPatch';
import { OdataURLBuilder } from '../../../api/types/OdataURLBuilder';
import {
  AbstractIndexingSourcesRepo,
  IndexingSourceWithHypermedia,
} from './abstractIndexingSourcesRepo';

export class IndexingSourcesRepo extends AbstractIndexingSourcesRepo {
  searchIndexingSources = (
    request: OdataURLBuilder
  ): ApiResponseAsync<Array<IndexingSourceWithHypermedia>> =>
    handleExpiredTokens(() =>
      this.client.get<Array<IndexingSourceWithHypermedia>>(request.toString(), {
        headers: {
          [authHeaderName]: this.tokenAccessor(),
        },
      })
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);

  createIndexingSource = (
    processId: string,
    name: string,
    preserveSource: boolean
  ): ApiResponseAsync<IndexingSourceWithHypermedia> =>
    handleExpiredTokens(() =>
      this.client.post<IndexingSourceWithHypermedia>(
        `processes/${processId}/indexing-sources`,
        { name, preserveSource },
        {
          headers: {
            [authHeaderName]: this.tokenAccessor(),
          },
        }
      )
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);

  updateIndexingSource = (
    requestURL: string,
    patches: JsonPatch[]
  ): ApiResponseAsync<IndexingSourceWithHypermedia> =>
    handleExpiredTokens(() =>
      this.client.patch<IndexingSourceWithHypermedia>(requestURL, patches, {
        headers: {
          [authHeaderName]: this.tokenAccessor(),
        },
      })
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);
}
