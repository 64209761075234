import { put } from 'redux-saga/effects';

import createAddToasterAction from 'utils/createAddToasterAction';

const SUCCESS_INVITATION_MESSAGE = 'Invitation was successfully sent';

function* inviteUserSuccess() {
  yield put(
    createAddToasterAction({
      message: SUCCESS_INVITATION_MESSAGE,
    })
  );
}

export default inviteUserSuccess;
