import { fork } from 'redux-saga/effects';

import { SearchTypes } from 'constants/global';

import { fetchUniqueField } from '.';

function* reFetchFields() {
  yield fork(fetchUniqueField, SearchTypes.quick);
  yield fork(fetchUniqueField, SearchTypes.view);
}

export default reFetchFields;
