import { stopAsyncValidation } from 'redux-form';
import { put } from 'redux-saga/effects';

import { ERROR_KEYS } from 'constants/fieldErrors';
import { FORM_NAMES } from 'constants/form';

function* errorSaveTemplate() {
  yield put(
    stopAsyncValidation(FORM_NAMES.SAVE_GRID_TEMPLATE, {
      name: { key: ERROR_KEYS.DUPLICATES_GRID_TEMPLATE_NAME },
    })
  );
}

export default errorSaveTemplate;
