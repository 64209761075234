import cn from 'classnames';
import React, { memo } from 'react';

import SpinnerIcon from 'assets/img/spinner.svg';

import sm from './styles.module.scss';

interface Props {
  isInBlock?: boolean;
  withWhiteBackground?: boolean;
}

export const Spinner = ({ isInBlock, withWhiteBackground }: Props) => (
  <div
    className={cn([sm.Container], {
      [sm.Container_InBlock]: isInBlock,
      [sm.Container_WithWhiteBackground]: withWhiteBackground,
    })}
  >
    <div className={sm.Container_Center}>
      <img src={SpinnerIcon} alt="spinner" />
    </div>
  </div>
);

export default memo(Spinner);
