import { put } from 'redux-saga/effects';

import * as ERROR_CODES from 'constants/errorCodes';
import { TOASTER_TYPE } from 'constants/toasterMessages';

import createATA from 'utils/createAddToasterAction';

const MESSAGE = 'The instance name field must be required';

function* editInitialFormError({
  payload,
}: {
  type: string;
  payload: BackendError[];
}) {
  if (
    payload.some((e) => e.code === ERROR_CODES.REQUIRED_TEXT_INSTANCE_FIELD)
  ) {
    yield put(
      createATA({
        type: TOASTER_TYPE.ERROR,
        message: MESSAGE,
      })
    );
  }
}

export default editInitialFormError;
