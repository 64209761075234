const TEXTS = {
  MOVE_LEFT: 'Move left',
  MOVE_RIGHT: 'Move right',
  ROTATE_LEFT: 'Rotate left',
  ROTATE_RIGHT: 'Rotate right',
  DELETE: 'Delete',
  MOVE_DOCUMENT: 'Move to document',
};

const SUB_MENU_ACTIONS = {
  MOVE_LEFT: 'MOVE_LEFT',
  MOVE_RIGHT: 'MOVE_RIGHT',
  ROTATE_LEFT: 'ROTATE_LEFT',
  ROTATE_RIGHT: 'ROTATE_RIGHT',
  DELETE: 'DELETE',
};

const SUB_MENU_ITEMS = {
  MOVE_LEFT: {
    label: TEXTS.MOVE_LEFT,
    value: SUB_MENU_ACTIONS.MOVE_LEFT,
  },
  MOVE_RIGHT: {
    label: TEXTS.MOVE_RIGHT,
    value: SUB_MENU_ACTIONS.MOVE_RIGHT,
  },
  ROTATE_LEFT: {
    label: TEXTS.ROTATE_LEFT,
    value: SUB_MENU_ACTIONS.ROTATE_LEFT,
  },
  ROTATE_RIGHT: {
    label: TEXTS.ROTATE_RIGHT,
    value: SUB_MENU_ACTIONS.ROTATE_RIGHT,
  },
  DELETE: {
    label: TEXTS.DELETE,
    value: SUB_MENU_ACTIONS.DELETE,
  },
  MOVE_DOCUMENT: {
    label: TEXTS.MOVE_DOCUMENT,
  },
};

const displaySelectedPages = (count: number) => `Selected ${count} page(s)`;

export { TEXTS, SUB_MENU_ITEMS, displaySelectedPages, SUB_MENU_ACTIONS };
