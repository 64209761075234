import { WithReleaseOrDraftProcessId, WithGroupId } from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { excludeObjectProperty } from 'utils/normalizers/object';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export interface ManageMembers {
  users: string[];
}

export interface SecurityGroupGeneral extends BaseEntity {
  isRoot: boolean;
}

export type ASWithStaticFieldsQuery = SearchParams['advancedSearch'] & {
  defaultFields?: SearchStaticVariable[];
};

export interface SecurityGroupDetails extends SecurityGroupGeneral {
  securityQuery: ASWithStaticFieldsQuery;
}

export interface SecurityGroupUserGeneral extends ById {
  email: string;
  firstName: string;
  isAssigned: boolean;
  lastName: string;
  role: string;
  status: 'active' | 'inactive';
}

export interface SecurityGroupDetailsWithMembers extends SecurityGroupDetails {
  users: SecurityGroupUserGeneral[];
}

export type GetAvailableUsersParams = WithReleaseOrDraftProcessId & Pagination;
export type GetAvailableUsersResult = DataWithTotal<SecurityGroupUserGeneral>;
export type AddMembersParams = ManageMembers &
  WithReleaseOrDraftProcessId &
  WithGroupId;
export type AddMembersResult = unknown;
export type RemoveMembersParams = ManageMembers &
  WithReleaseOrDraftProcessId &
  WithGroupId;
export type RemoveMembersResult = unknown;
export type GetGroupsParams = WithReleaseOrDraftProcessId & Pagination;
export type GetGroupsResult = DataWithTotal<SecurityGroupGeneral>;
export type GetGroupDetailsParams = WithReleaseOrDraftProcessId & WithGroupId;
export type GetGroupDetailsResult = SecurityGroupDetails;
export type GetGroupMembersParams = WithReleaseOrDraftProcessId & WithGroupId;
export type GetGroupMembersResult = DataWithTotal<SecurityGroupUserGeneral>;
export type CreateParams = WithReleaseOrDraftProcessId;
export type CreateResult = SecurityGroupDetails;
export type UpdateParams = WithReleaseOrDraftProcessId & WithGroupId;
export type UpdateResult = SecurityGroupDetails;
export type RemoveParams = WithReleaseOrDraftProcessId & WithGroupId;
export type RemoveResult = unknown;

const SecurityGroups = {
  getAvailableUsers: apiReqSagaCreator<
    GetAvailableUsersParams,
    GetAvailableUsersResult
  >(
    API_REQ_ACTION_TYPES.PROCESSES.SECURITY_GROUPS.GET_AVAILABLE_USERS,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.securityGroups.availableUsers._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
      }),
      params: excludeObjectProperty(
        params,
        ApiUrlParams.releaseOrDraftProcessId
      ),
    }),
    {
      withPrevPageOnLessTotal: true,
    }
  ),
  addMembers: apiReqSagaCreator<AddMembersParams, AddMembersResult>(
    API_REQ_ACTION_TYPES.PROCESSES.SECURITY_GROUPS.ADD_MEMBERS,
    (args) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.securityGroups.byId.members.add._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.groupId]: args[ApiUrlParams.groupId],
      }),
      data: excludeObjectProperty(
        args,
        ApiUrlParams.releaseOrDraftProcessId,
        ApiUrlParams.groupId
      ),
    })
  ),
  removeMembers: apiReqSagaCreator<RemoveMembersParams, RemoveMembersResult>(
    API_REQ_ACTION_TYPES.PROCESSES.SECURITY_GROUPS.REMOVE_MEMBERS,
    (args) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.securityGroups.byId.members.remove._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.groupId]: args[ApiUrlParams.groupId],
      }),
      data: excludeObjectProperty(
        args,
        ApiUrlParams.releaseOrDraftProcessId,
        ApiUrlParams.groupId
      ),
    })
  ),
  getGroups: apiReqSagaCreator<GetGroupsParams, GetGroupsResult>(
    API_REQ_ACTION_TYPES.PROCESSES.SECURITY_GROUPS.GET_LIST,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.securityGroups._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
      }),
      params: excludeObjectProperty(
        params,
        ApiUrlParams.releaseOrDraftProcessId
      ),
    })
  ),
  getGroupDetails: apiReqSagaCreator<
    GetGroupDetailsParams,
    GetGroupDetailsResult
  >(API_REQ_ACTION_TYPES.PROCESSES.SECURITY_GROUPS.GET_DETAILS, (params) => ({
    method: HTTP_METHODS.GET,
    url: ApiPaths.processes.byId.securityGroups.byId._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        params[ApiUrlParams.releaseOrDraftProcessId],
      [ApiUrlParams.groupId]: params[ApiUrlParams.groupId],
    }),
  })),
  getGroupMembers: apiReqSagaCreator<
    GetGroupMembersParams,
    GetGroupMembersResult
  >(
    API_REQ_ACTION_TYPES.PROCESSES.SECURITY_GROUPS.GET_GROUP_MEMBERS,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.securityGroups.byId.members._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.groupId]: params[ApiUrlParams.groupId],
      }),
    })
  ),
  create: apiReqSagaCreator<CreateParams, CreateResult>(
    API_REQ_ACTION_TYPES.PROCESSES.SECURITY_GROUPS.CREATE,
    (args) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.securityGroups._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
      }),
      data: excludeObjectProperty(args, ApiUrlParams.releaseOrDraftProcessId),
    })
  ),
  update: apiReqSagaCreator<UpdateParams, UpdateResult>(
    API_REQ_ACTION_TYPES.PROCESSES.SECURITY_GROUPS.UPDATE,
    (args) => ({
      method: HTTP_METHODS.PUT,
      url: ApiPaths.processes.byId.securityGroups.byId._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.groupId]: args[ApiUrlParams.groupId],
      }),
      data: excludeObjectProperty(
        args,
        ApiUrlParams.releaseOrDraftProcessId,
        ApiUrlParams.groupId
      ),
    })
  ),
  remove: apiReqSagaCreator<RemoveParams, RemoveResult>(
    API_REQ_ACTION_TYPES.PROCESSES.SECURITY_GROUPS.REMOVE,
    (params) => ({
      method: HTTP_METHODS.DELETE,
      url: ApiPaths.processes.byId.securityGroups.byId._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.groupId]: params[ApiUrlParams.groupId],
      }),
    })
  ),
};

export default SecurityGroups;
