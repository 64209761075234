import createAction from 'utils/createAction';

const PROCESS = {
  GET_PROCESS_INSTANCES: 'PROCESS.GET_PROCESS_INSTANCES',
  SET_PROCESS_INSTANCES: 'PROCESS.SET_PROCESS_INSTANCES',
  START_SUBMIT_INSTANCE_TASK: 'PROCESS.START_SUBMIT_NEXT_INSTANCE_TASK',
  CLEAR_DETAILS: 'PROCESS.CLEAR_DETAILS',
  CLEAR_ACTIVE_VERSION: 'PROCESS.CLEAR_ACTIVE_VERSION',
};

const ProcessActions = {
  getProcessInstances: createAction<{ releaseOrDraftProcessId: string }>(
    PROCESS.GET_PROCESS_INSTANCES
  ),
  clearDetails: createAction(PROCESS.CLEAR_DETAILS),
  clearActiveVersion: createAction(PROCESS.CLEAR_ACTIVE_VERSION),
};

export { PROCESS as PROCESS_ACTION_TYPES, ProcessActions };
