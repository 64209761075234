import ROUTES from 'app/routes/paths';
import { UiWithDefinitionPreviewParams } from 'app/routes/uiPathParams';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { all, put, select } from 'redux-saga/effects';

import * as ERROR_CODES from 'constants/errorCodes';
import { TOASTER_TYPE } from 'constants/toasterMessages';

import createAddToasterAction from 'utils/createAddToasterAction';

const ERRORS = {
  [ERROR_CODES.PROCESS_STAGES_NOT_FOUND]:
    'To activate process please create stages in process editor',
  [ERROR_CODES.INVALID_PROCESS_XML_SCHEMA]:
    'Process schema is not valid. Please check process editor and fix schema',
};

function* createVersionFailure({ payload }: ReduxAction<BackendError[]>) {
  const location: Location = yield select(getLocation);

  const matchParams: match<UiWithDefinitionPreviewParams> = yield matchPath(
    location?.pathname,
    {
      path: [
        ROUTES.process.definition.details.versions(),
        ROUTES.process.definition.details.fields(),
        ROUTES.process.definition.details.forms(),
        ROUTES.process.definition.details.security(),
        ROUTES.process.definition.details.emailTemplates(),
      ],
    }
  );
  yield all(
    payload.map((e) =>
      put(
        createAddToasterAction({
          message: ERRORS[e.code as keyof typeof ERRORS],
          type: TOASTER_TYPE.ERROR,
          action: {
            label: 'Create stage',
            moveTo: ROUTES.process.editProcess._(matchParams?.params),
          },
        })
      )
    )
  );
}

export default createVersionFailure;
