import { Action } from 'redux';
import { stopAsyncValidation } from 'redux-form';
import { put } from 'redux-saga/effects';

import * as ERROR_CODES from 'constants/errorCodes';
import { ERROR_KEYS } from 'constants/fieldErrors';
import { FORM_NAMES } from 'constants/form';

interface TaskAction extends Action, TaskEvent {
  type: string;
}

interface TaskEvent {
  payload: { code: number }[];
}

function* registrationFailure({ payload }: TaskAction) {
  if (payload.some((e) => e.code === ERROR_CODES.WRONG_USERNAME)) {
    yield put(
      stopAsyncValidation(FORM_NAMES.USER_REGISTRATION, {
        username: { key: ERROR_KEYS.DUPLICATION_USERNAME },
      })
    );
  }
}

export default registrationFailure;
