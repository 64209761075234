import { combineReducers } from 'redux';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import {
  GetAllResult,
  GetDetailsResult,
  ProcessVersionDetails,
  ProcessVersionGeneral,
} from 'api/endpoints/processVersions';

import { PROCESS_VERSION_ACTION_TYPES } from './actions';

export interface ProcessVersionState {
  list: Nullable<ProcessVersionGeneral[]>;
  total: Nullable<number>;
  details: Nullable<ProcessVersionDetails>;
}

const processVersion = combineReducers<ProcessVersionState>({
  list(
    state = null,
    { type, payload }: { type: string; payload: GetAllResult }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.PROCESSES.VERSIONS.GET_ALL.SUCCESS:
        return payload ? payload.data : null;
      default:
        return state;
    }
  },
  total(
    state = null,
    { type, payload }: { type: string; payload: GetAllResult }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.PROCESSES.VERSIONS.GET_ALL.SUCCESS:
        return payload ? payload.total : null;
      default:
        return state;
    }
  },
  details(
    state = null,
    { type, payload }: { type: string; payload: GetDetailsResult }
  ) {
    switch (type) {
      case PROCESS_VERSION_ACTION_TYPES.CLEAR_DETAILS:
      case API_REQ_ACTION_TYPES.PROCESSES.VERSIONS.GET_DETAILS.START:
        return null;
      case API_REQ_ACTION_TYPES.PROCESSES.VERSIONS.GET_DETAILS.SUCCESS:
        return payload ?? null;
      default:
        return state;
    }
  },
});

export default processVersion;
