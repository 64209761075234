import { combineReducers } from 'redux';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import { ValidateResult } from 'api/endpoints/external';
import { FormGeneral } from 'api/endpoints/processForm';

export interface ExternalStateFormParams {
  [fieldI: string]: number | null | string;
}

export interface ExternalFormGeneral {
  expiration?: number;
  data: FormGeneral;
  params: ExternalStateFormParams;
}

export interface ExternalState {
  form: Nullable<ExternalFormGeneral>;
}

const file = combineReducers<ExternalState>({
  form(
    state = null,
    { type, payload }: { type: string; payload?: ValidateResult }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.EXTERNAL.FORMS.VALIDATE.SUCCESS:
        return payload ? { ...payload, data: payload.form } : null;

      default:
        return state;
    }
  },
});

export default file;
