import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import {
  authLoginFailure,
  submitLoginSuccess,
  submitResetPasswordSuccess,
  validateForgotPasswordError,
} from './sagas';

function* watchersSaga() {
  yield takeLatest(
    [API_REQ_ACTION_TYPES.SESSION.LOGIN.SUCCESS],
    submitLoginSuccess
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.SESSION.VALIDATE_FORGOT_PASSWORD.ERROR,
    validateForgotPasswordError
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.SESSION.RESTORE_PASSWORD.SUCCESS,
    submitResetPasswordSuccess
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.SESSION.LOGIN.ERROR],
    authLoginFailure
  );
}
export default watchersSaga;
