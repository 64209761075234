import { Variables } from 'components/organisms/taskSelector';

import { ProcessInstanceDataModel } from 'api/endpoints/processInstance';
import {
  WithFieldId,
  WithName,
  WithProcessInstanceId,
  WithReleaseOrDraftProcessId,
  WithSplitDocContainerId,
  WithSplitDocInstanceId,
} from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS, RESPONSE_TYPE } from '../constants';

const { REACT_APP_API_DOCUMENT_SERVICE: documentServiceUrl } = process.env;

export interface SplitDocumentGeneral extends BaseEntity {
  pages?: string[];
  indexingId: string;
  instanceName?: string;
  params: ProcessInstanceDataModel;
}

export interface SplitDocumentDetails extends SplitDocumentGeneral {}

export interface Params {
  formToGet?: string;
}

export interface CreateDocument extends Params {
  name: string;
  pages: string[];
}

export interface DocumentToUpdate {
  documentId: string;
  instanceName?: string;
  pages: string[];
}

export interface BulkUpdateDocuments extends Params {
  documents: DocumentToUpdate[];
}

export interface UpdateDocument extends Params {
  name: string;
  instanceName?: string;
  pages?: string[];
  params?: Variables;
}

export interface RemovePagesParams {
  params: WithReleaseOrDraftProcessId &
    WithSplitDocInstanceId &
    WithSplitDocContainerId;
  data: {
    pages: string[];
  };
}

export interface TransformPageField {
  params: WithReleaseOrDraftProcessId &
    WithProcessInstanceId &
    WithFieldId &
    WithName;
  updateData: {
    rotate: {
      angle: number;
    };
  };
}

export interface UpdatePageParams {
  params: WithReleaseOrDraftProcessId &
    WithProcessInstanceId &
    WithFieldId &
    WithName;
  document: FormData;
}

export interface GetDocs {
  name?: string;
}

export type SplitParams = WithReleaseOrDraftProcessId & WithSplitDocInstanceId;
export type SplitResult = SplitDocumentGeneral;
export type CreateParams = CreateDocument &
  WithReleaseOrDraftProcessId &
  WithSplitDocInstanceId;
export type CreateResult = SplitDocumentGeneral;
export type GetParams = GetDocs &
  Pagination &
  WithReleaseOrDraftProcessId &
  WithSplitDocInstanceId;
export type GetResult = DataWithTotal<SplitDocumentGeneral>;
export type GetByIdParams = WithReleaseOrDraftProcessId &
  WithSplitDocInstanceId &
  WithSplitDocContainerId;
export type GetByIdResult = SplitDocumentDetails;
export type BulkUpdateParams = BulkUpdateDocuments &
  WithReleaseOrDraftProcessId &
  WithSplitDocInstanceId;
export type BulkUpdateResult = unknown;
export type DeleteDocumentParams = WithReleaseOrDraftProcessId &
  WithSplitDocInstanceId &
  WithSplitDocContainerId;
export type DeleteDocumentResult = unknown;
export type UpdateParams = UpdateDocument &
  WithReleaseOrDraftProcessId &
  WithSplitDocInstanceId &
  WithSplitDocContainerId;
export type RemovePagesResult = unknown;
export type RotatePageParams = TransformPageField;
export type RotatePageResult = unknown;
export type GetPageParams = WithReleaseOrDraftProcessId &
  WithProcessInstanceId &
  WithFieldId &
  WithName;
export type GetPageResult = Blob;
export type GetPagePreviewParams = WithReleaseOrDraftProcessId &
  WithProcessInstanceId &
  WithFieldId &
  WithName;
export type GetPagePreviewResult = Blob;
export type CancelParams = WithReleaseOrDraftProcessId & WithSplitDocInstanceId;
export type CancelResult = unknown;
export type FinishParams = WithReleaseOrDraftProcessId & WithSplitDocInstanceId;
export type FinishResult = unknown;
export type UpdatePageResult = unknown;

const SplitDocument = {
  split: apiReqSagaCreator<SplitParams, SplitResult>(
    API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.SPLIT,
    (params) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.split.byId._(params),
    })
  ),
  create: apiReqSagaCreator<CreateParams, CreateResult>(
    API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.CREATE_DOCUMENT,
    (args) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.split.byId.documents._({
        [ApiUrlParams.splitDocInstanceId]:
          args[ApiUrlParams.splitDocInstanceId],
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
      }),
      data: {
        name: args.name,
        pages: args.pages,
      },
    })
  ),
  get: apiReqSagaCreator<GetParams, GetResult>(
    API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.GET_DOCUMENTS,
    (args) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.split.byId.documents._({
        [ApiUrlParams.splitDocInstanceId]:
          args[ApiUrlParams.splitDocInstanceId],
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
      }),
      params: args,
    }),
    {
      withPrevPageOnLessTotal: true,
    }
  ),
  getById: apiReqSagaCreator<GetByIdParams, GetByIdResult>(
    API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.GET_DOCUMENT_BY_ID,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.split.byId.documents.byId._(params),
    })
  ),
  bulkUpdate: apiReqSagaCreator<BulkUpdateParams, BulkUpdateResult>(
    API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.BULK_UPDATE_DOCUMENTS,
    (args) => ({
      method: HTTP_METHODS.PUT,
      url: ApiPaths.processes.byId.split.byId.documents._({
        [ApiUrlParams.splitDocInstanceId]:
          args[ApiUrlParams.splitDocInstanceId],
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
      }),
      data: { splitDocuments: args.documents },
    })
  ),
  deleteDocument: apiReqSagaCreator<DeleteDocumentParams, DeleteDocumentResult>(
    API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.DELETE_DOCUMENT,
    (params) => ({
      method: HTTP_METHODS.DELETE,
      url: ApiPaths.processes.byId.split.byId.documents.byId._(params),
    })
  ),
  update: apiReqSagaCreator<UpdateParams, SplitDocumentGeneral>(
    API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.UPDATE_DOCUMENT,
    (args) => ({
      method: HTTP_METHODS.PUT,
      url: ApiPaths.processes.byId.split.byId.documents.byId._({
        [ApiUrlParams.splitDocInstanceId]:
          args[ApiUrlParams.splitDocInstanceId],
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.splitDocContainerId]:
          args[ApiUrlParams.splitDocContainerId],
      }),
      data: args,
    })
  ),
  removePages: apiReqSagaCreator<RemovePagesParams, RemovePagesResult>(
    API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.REMOVE_DOCUMENT_PAGES,
    (args) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.split.byId.documents.byId.removePages._(
        args.params
      ),
      data: args.data,
    })
  ),
  rotatePage: apiReqSagaCreator<RotatePageParams, RotatePageResult>(
    API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.UPDATE_DOCUMENT_PAGE,
    (args) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.split.instances.byId.fields.byId.pages.byName.transform._(
        args.params
      ),
      baseURL: documentServiceUrl,
      data: args.updateData,
    })
  ),
  getPage: apiReqSagaCreator<GetPageParams, GetPageResult>(
    API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.GET_DOCUMENT_PAGE,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.split.instances.byId.fields.byId.pages.byName._(
        params
      ),
      responseType: RESPONSE_TYPE.BLOB,
      baseURL: documentServiceUrl,
    })
  ),
  getPagePreview: apiReqSagaCreator<GetPagePreviewParams, GetPagePreviewResult>(
    API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.GET_DOCUMENT_PAGE_PREVIEW,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.split.instances.byId.fields.byId.pages.byName.preview._(
        params
      ),
      responseType: RESPONSE_TYPE.BLOB,
      baseURL: documentServiceUrl,
    })
  ),
  cancel: apiReqSagaCreator<CancelParams, CancelResult>(
    API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.CANCEL,
    (params) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.split.byId.cancel._(params),
    })
  ),
  finish: apiReqSagaCreator<FinishParams, FinishResult>(
    API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.FINISH,
    (params) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.split.byId.finish._(params),
    })
  ),
  updatePage: apiReqSagaCreator<UpdatePageParams, UpdatePageResult>(
    API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.UPDATE_PAGE,
    (args) => ({
      method: HTTP_METHODS.PUT,
      url: ApiPaths.processes.byId.split.instances.byId.fields.byId.pages.byName._(
        args.params
      ),
      baseURL: documentServiceUrl,
      data: args.document,
    })
  ),
};

export default SplitDocument;
