import React, { memo, PropsWithChildren } from 'react';

import Footer from 'components/organisms/footer';
import Header from 'components/organisms/header';

import { useAuthCache } from 'api/cache';

import sm from './styles.module.scss';

const Authenticated = ({ children }: PropsWithChildren<{}>) => {
  const { loggedIn } = useAuthCache();

  return (
    <div className={sm.Container}>
      {loggedIn && <Header />}
      <div className={sm.Container_Body}>{children}</div>
      {loggedIn && <Footer />}
    </div>
  );
  // endregion
};

export default memo(Authenticated);
