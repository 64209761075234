// import { LOST_INTERNET_CONNECTION_MESSAGE } from 'api/saga/error';

import * as ERROR_CODES from 'constants/errorCodes';

const WRONG_PROCESS = `The process ID is invalid or you don't have access to it`;
const NEED_REQUIRED_TEXT_FIELD =
  'You can’t set the form as Initial because there are no required text fields.';

const API_ERRORS_BY_CODES: KeyValuePairs = {
  [ERROR_CODES.WRONG_SEARCH_FIELD_ID]: `Your request doesn't match the configured search`,
  [ERROR_CODES.WRONG_RESTORE_PASSWORD_LINK]: 'Reset Password link has expired',
  [ERROR_CODES.WRONG_REGISTRATION_LINK]: 'Registration link has expired',
  [ERROR_CODES.WRONG_PROCESS_ID]: WRONG_PROCESS,
  [ERROR_CODES.WONG_UUID_LENGTH]: WRONG_PROCESS,
  [ERROR_CODES.LABEL_PARAM_IS_EMPTY]: 'Please choose label name to proceed',
  [ERROR_CODES.MISSED_FORM_FIELD]:
    'Some fields in the form are empty. Please fill them.',
  [ERROR_CODES.EMPTY_SOURCE_DOCUMENT_FOR_SPLIT]: `Can't finish re-index with an empty document.`,
  [ERROR_CODES.LESS_PAGES_FOR_SPLIT]: `Can't re-index the document with less than 2 pages.`,
  [ERROR_CODES.MORE_PAGES_FOR_SPLIT]:
    'The limit for re-index is 100 pages maximum',
  [ERROR_CODES.NOT_UNIQUE_SECURITY_GROUP_NAME]: 'Group name must be unique.',
  [ERROR_CODES.USER_NOT_VALID_FOR_ADDING_TO_THE_SECURITY_GROUP]: `Inactive user wasn't added to created group`,
  [ERROR_CODES.FILE_FOR_IMPORTING_IS_CORUPPTED]: 'File is corrupted.',
  [ERROR_CODES.NOT_UNIQUE_TENANT_NAME]: 'Tenant name must be unique.',
  [ERROR_CODES.DELETING_USING_CONNECTION]:
    'Cannot delete a connection. Connection is used in the Print Render scenario.',
  // TODO: IMPLEMENT FUNCTION FOR GETTING NOT UNIQUE ERROR BY ENTITY
  [ERROR_CODES.NOT_UNIQUE_CONNECTION_NAME]:
    'TMS Connection name must be unique.',
  [ERROR_CODES.NOT_UNIQUE_FTP_CONNECTION_NAME]:
    'FTP Connection name must be unique.',
  [ERROR_CODES.INDEX_FORM_FIELD_IS_REQUIRED]: `Required fields from “View Form” are not filled.`,
  [ERROR_CODES.REJECT_REMOVE_INSTANCE_WITH_ALL_PAGES]:
    'The document container with all pages cannot be deleted.',
  [ERROR_CODES.FILE_WAS_NOT_UPDATED]: 'File is not uploaded',
  // [ERROR_CODES.BASE_SERVER_ERROR]: LOST_INTERNET_CONNECTION_MESSAGE,
  [ERROR_CODES.CONNECTION_IN_USE]:
    'Cannot delete a connection. Connection is used in the Process schema.',
};

const TOASTER_TYPE = {
  INFO: 'info',
  DARK_INFO: 'dark-info',
  ERROR: 'error',
} as const;

export { API_ERRORS_BY_CODES, TOASTER_TYPE, NEED_REQUIRED_TEXT_FIELD };
