import { combineReducers } from 'redux';

import { API_ACTION_TYPES, API_REQ_ACTION_TYPES } from 'api/actions';
import {
  GetUserDetailsResult,
  GetUsersResult,
  SystemUserDetails,
  SystemUserGeneral,
  User,
} from 'api/endpoints/users';

import { SystemStatus } from 'constants/global';

import { USERS_ACTION_TYPES } from './actions';

export interface UsersState {
  info: Nullable<User>;
  list: SystemUserGeneral[] | null;
  total: number | null;
  userDetails: Nullable<SystemUserDetails>;
}

const users = combineReducers<UsersState>({
  info(state = null, { type, payload }: ReduxAction<User>) {
    switch (type) {
      case API_REQ_ACTION_TYPES.USERS.INFO.SUCCESS:
        return payload || null;

      case API_ACTION_TYPES.SIGNED_OUT:
        return null;

      default:
        return state;
    }
  },
  list(state = null, { type, payload }: ReduxAction<GetUsersResult>) {
    switch (type) {
      case API_REQ_ACTION_TYPES.USERS.GET_USERS.SUCCESS:
        return payload ? payload.data : null;
      default:
        return state;
    }
  },
  total(state = null, { type, payload }: ReduxAction<GetUsersResult>) {
    switch (type) {
      case API_REQ_ACTION_TYPES.USERS.GET_USERS.SUCCESS:
        return payload ? payload.total : null;
      default:
        return state;
    }
  },
  userDetails(
    state = null,
    { type, payload }: ReduxAction<GetUserDetailsResult>
  ) {
    switch (type) {
      case USERS_ACTION_TYPES.UPDATE_USER_STATUS: {
        const status = payload as unknown as SystemStatus;
        return {
          ...(state as SystemUserDetails),
          status,
        };
      }
      case API_REQ_ACTION_TYPES.USERS.GET_USER_DETAILS.SUCCESS:
      case USERS_ACTION_TYPES.CLEAR_USER_DETAILS:
        return payload || null;
      default:
        return state;
    }
  },
});

export default users;
