import { combineReducers } from 'redux';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import { RequestActionMeta } from 'api/apiReqSagaCreator';
import {
  FtpPollingScenarioDetails,
  FtpPollingScenarioGeneral,
  GetScenarioDetailsResult,
  GetScenarioParams,
  GetScenariosResult,
} from 'api/endpoints/ftpPolling';

export interface FtpPollingState {
  list: Nullable<FtpPollingScenarioGeneral[]>;
  total: Nullable<number>;
  activeScenario: Nullable<FtpPollingScenarioGeneral>;
  details: Nullable<FtpPollingScenarioDetails>;
}

const ftpPolling = combineReducers<FtpPollingState>({
  list(
    state = null,
    {
      type,
      payload,
      meta,
    }: ReduxAction<GetScenariosResult, RequestActionMeta<GetScenarioParams>>
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.FTP_POLLING.GET_SCENARIOS.SUCCESS:
        return meta?.input?.isActive ? state : (payload?.data ?? null);

      default:
        return state;
    }
  },
  total(
    state = 1,
    {
      type,
      payload,
      meta,
    }: ReduxAction<GetScenariosResult, RequestActionMeta<GetScenarioParams>>
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.FTP_POLLING.GET_SCENARIOS.SUCCESS:
        return meta?.input?.isActive ? state : (payload?.total ?? null);

      default:
        return state;
    }
  },
  activeScenario(
    state = null,
    {
      type,
      payload,
      meta,
    }: ReduxAction<GetScenariosResult, RequestActionMeta<GetScenarioParams>>
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.FTP_POLLING.GET_SCENARIOS.SUCCESS:
        if (!meta?.input?.isActive) return state;
        return payload?.data ? payload?.data[0] : null;

      default:
        return state;
    }
  },
  details(
    state = null,
    { type, payload }: ReduxAction<GetScenarioDetailsResult>
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.FTP_POLLING.GET_DETAILS.SUCCESS:
        return payload;

      case API_REQ_ACTION_TYPES.FTP_POLLING.GET_DETAILS.START:
        return null;

      default:
        return state;
    }
  },
});

export default ftpPolling;
