import { all, call, put } from 'redux-saga/effects';

import { FieldWithObligationType } from 'api/endpoints/processForm';
import { TaskWrap } from 'api/endpoints/processInstance';

import { ProcessForm } from 'store/forms/actions';
import { fetchCollectionFields } from 'store/forms/sagas';

export type FetchInstanceStepFormsFields = [TaskWrap[], string];

function* fetchInstanceStepFormsFields(
  tasks: TaskWrap[],
  releaseOrDraftProcessId: string
) {
  yield put(ProcessForm.setUpdatedCollectionFields());

  const filteredTasks = tasks.reduce(
    (acc, next) => {
      const hasCollection = (next.task.formToGet?.fields || []).some(
        (e) => e.type === 'collection'
      );
      return {
        ...acc,
        ...(hasCollection && {
          [next.task.formToGet?.id as string]:
            next.task.formToGet?.fields || [],
        }),
      };
    },
    {} as KeyValuePairs<FieldWithObligationType[]>
  );

  const fields: FieldWithObligationType[] = yield all(
    (Object.values(filteredTasks) || []).map((fds) =>
      call(fetchCollectionFields, fds, releaseOrDraftProcessId)
    )
  );

  const normalized = Object.keys(filteredTasks).reduce(
    (acc, next, idx) => ({
      ...acc,
      [next]: fields[idx],
    }),
    {}
  );

  yield put(ProcessForm.setUpdatedCollectionFields(normalized));
}

export default fetchInstanceStepFormsFields;
