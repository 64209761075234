import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { reducer as toastr, ToastrState } from 'react-redux-toastr';
import { combineReducers } from 'redux';
import { FormStateMap, reducer as form } from 'redux-form';

import api, { ApiState } from 'api/reducer';

import blockly, { BlocklyState } from 'store/blockly/reducer';
import emailPolling, { EmailPollingState } from 'store/emailPolling/reducer';
import emailTemplates, {
  EmailTemplatesState,
} from 'store/emailTemplates/reducer';
import external, { ExternalState } from 'store/external/reducer';
import field, { FieldState } from 'store/field/reducer';
import file, { FileState } from 'store/file/reducer';
import processForms, { FormsState } from 'store/forms/reducer';
import ftpConnections, {
  FtpConnectionsState,
} from 'store/ftpConnections/reducer';
import ftpPolling, { FtpPollingState } from 'store/ftpPolling/reducer';
import functionReducer, { FunctionState } from 'store/function/reducer';
import global, { GlobalState } from 'store/global/reducer';
import grid, { GridState } from 'store/grid/reducer';
import invitations, { InvitationsState } from 'store/invitations/reducer';
import licenses, { LicensesState } from 'store/licenses/reducer';
import ocr, { OcrState } from 'store/ocr/reducer';
import printRender, { PrintRenderState } from 'store/printRender/reducer';
import process, { ProcessState } from 'store/process/reducer';
import processScanConfig, {
  ScanConfigState,
} from 'store/processScanConfig/reducer';
import processScanning, { ScanningState } from 'store/processScanning/reducer';
import processVersion, {
  ProcessVersionState,
} from 'store/processVersion/reducer';
import registration, { RegistrationState } from 'store/registration/reducer';
import scanDocumentView, {
  ScanDocumentViewState,
} from 'store/scanDocumentView/reducer';
import searchTemplate, {
  SearchTemplateState,
} from 'store/searchTemplate/reducer';
import securityGroups, {
  SecurityGroupsState,
} from 'store/securityGroups/reducer';
import splitDocuments, {
  SplitDocumentsState,
} from 'store/splitDocuments/reducer';
import stage, { StageState } from 'store/stage/reducer';
import tenants, { TenantsState } from 'store/tenants/reducer';
import tmsConnections, {
  TmsConnectionsState,
} from 'store/tmsConnections/reducer';
import users, { UsersState } from 'store/users/reducer';

import printRenderLogs, {
  PrintRenderLogsState,
} from '../../store/printRenderLogs/reducer';

export interface RootState {
  api: ApiState;
  registration: RegistrationState;
  form: FormStateMap;
  router: RouterState;
  processForms: FormsState;
  users: UsersState;
  process: ProcessState;
  field: FieldState;
  stage: StageState;
  blockly: BlocklyState;
  processScanConfig: ScanConfigState;
  scanDocumentView: ScanDocumentViewState;
  processScanning: ScanningState;
  file: FileState;
  toastr: ToastrState;
  printRender: PrintRenderState;
  printRenderLogs: PrintRenderLogsState;
  processVersion: ProcessVersionState;
  external: ExternalState;
  searchTemplate: SearchTemplateState;
  grid: GridState;
  emailPolling: EmailPollingState;
  ocr: OcrState;
  functionReducer: FunctionState;
  emailTemplates: EmailTemplatesState;
  splitDocuments: SplitDocumentsState;
  securityGroups: SecurityGroupsState;
  global: GlobalState;
  licenses: LicensesState;
  tenants: TenantsState;
  tmsConnections: TmsConnectionsState;
  invitations: InvitationsState;
  ftpPolling: FtpPollingState;
  ftpConnections: FtpConnectionsState;
}

export default function createRootReducer(history: History) {
  return combineReducers({
    api,
    registration,
    router: connectRouter(history),
    toastr,
    form,
    processForms,
    scanDocumentView,
    users,
    licenses,
    process,
    field,
    stage,
    blockly,
    processScanConfig,
    processScanning,
    file,
    printRender,
    printRenderLogs,
    processVersion,
    searchTemplate,
    external,
    grid,
    emailPolling,
    ocr,
    functionReducer,
    emailTemplates,
    splitDocuments,
    securityGroups,
    global,
    tenants,
    tmsConnections,
    ftpConnections,
    invitations,
    ftpPolling,
  });
}
