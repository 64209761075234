import { fork } from 'redux-saga/effects';

import ApiUrlParams from 'constants/apiUrlParams';

import getDocuments from './getDocuments';

export function* reFetchDocumentsList(
  scanInstanceId: string,
  releaseOrDraftProcessId: string
) {
  yield fork(getDocuments, {
    [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
    [ApiUrlParams.scanInstanceId]: scanInstanceId,
  });
}
