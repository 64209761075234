import { useMemo } from 'react';

import { httpClient } from 'api/apiReqSagaCreator/httpRequest';
import { authCache } from 'api/cache';

import { AbstractServiceFactory } from './abstractServiceFactory';
import { AbstractBareClientRepo } from './services/bareClient/abstractBareClientRepo';
import { BareClientRepo } from './services/bareClient/BareClientRepo';
import { AbstractFreshdeskTokenRepo } from './services/freshdeskToken/abstractFreshdeskTokenRepo';
import { FreshdeskTokenRepo } from './services/freshdeskToken/freshdeskTokenRepo';
import { AbstractIndexingDocumentsRepo } from './services/indexingDocuments/abstractIndexingDocumentsRepo';
import { IndexingDocumentRepo } from './services/indexingDocuments/IndexingDocumentRepo';
import { AbstractIndexingSourcesRepo } from './services/indexingSources/abstractIndexingSourcesRepo';
import { IndexingSourcesRepo } from './services/indexingSources/IndexingSourcesRepo';
import { AbstractManagementEmailRepo } from './services/managementEmail/AbstractManagementEmailRepo';
import { ManagementEmailRepo } from './services/managementEmail/ManagementEmailRepo';
import { AbstractManagementLogsRepo } from './services/managementLogs/AbstractManagementLogsRepo';
import { ManagementLogsRepo } from './services/managementLogs/ManagementLogsRepo';
import { AbstractPrintRenderLogRepo } from './services/printRenderLogs/abstractPrintRenderRepo';
import { PrintRenderLogsRepo } from './services/printRenderLogs/printRenderLogsRepo';
import { AbstractProcessRepo } from './services/process/AbstractProcessRepo';
import { ProcessRepo } from './services/process/ProcessRepo';
import { AbstractProcessInstanceRepo } from './services/ProcessInstance/AbstractProcessInstanceRepo';
import { ProcessInstanceRepo } from './services/ProcessInstance/ProcessInstanceRepo';
import { AbstractScanRepo } from './services/Scan/abstractScanRepo';
import { ScanRepo } from './services/Scan/scanRepo';
import { AbstractScanScenarioRepo } from './services/ScanScenario/AbstractScanScenarioRepo';
import { ScanScenarioRepo } from './services/ScanScenario/ScanScenarioRepo';
import { AbstractSystemLogsRepo } from './services/systemLogs/abstractSystemLogsRepo';
import { SystemLogsRepo } from './services/systemLogs/systemLogsRepo';
import { AbstractUserRepo } from './services/users/AbstractUserRepo';
import { UserRepo } from './services/users/UserRepo';

export class ServiceFactory implements AbstractServiceFactory {
  private static tokenAccessor(): string {
    return authCache.authToken;
  }

  getScanRepo = (): AbstractScanRepo =>
    useMemo(() => new ScanRepo(httpClient, ServiceFactory.tokenAccessor), []);

  getPrintRenderRepo = (): AbstractPrintRenderLogRepo =>
    useMemo(
      () => new PrintRenderLogsRepo(httpClient, ServiceFactory.tokenAccessor),
      []
    );

  getIndexingSourceRepo = (): AbstractIndexingSourcesRepo =>
    useMemo(
      () => new IndexingSourcesRepo(httpClient, ServiceFactory.tokenAccessor),
      []
    );

  getIndexingDocumentsRepo = (): AbstractIndexingDocumentsRepo =>
    useMemo(
      () => new IndexingDocumentRepo(httpClient, ServiceFactory.tokenAccessor),
      []
    );

  getProcessInstanceRepo = (): AbstractProcessInstanceRepo =>
    useMemo(
      () => new ProcessInstanceRepo(httpClient, ServiceFactory.tokenAccessor),
      []
    );

  getScanScenarioRepo = (): AbstractScanScenarioRepo =>
    useMemo(
      () => new ScanScenarioRepo(httpClient, ServiceFactory.tokenAccessor),
      []
    );

  getFreshdeskTokenRepo = (): AbstractFreshdeskTokenRepo =>
    useMemo(
      () => new FreshdeskTokenRepo(httpClient, ServiceFactory.tokenAccessor),
      []
    );

  getSystemLogsRepo = (): AbstractSystemLogsRepo =>
    useMemo(
      () => new SystemLogsRepo(httpClient, ServiceFactory.tokenAccessor),
      []
    );

  getManagementLogsRepo = (): AbstractManagementLogsRepo =>
    useMemo(
      () => new ManagementLogsRepo(httpClient, ServiceFactory.tokenAccessor),
      []
    );

  getUserRepo = (): AbstractUserRepo =>
    useMemo(() => new UserRepo(httpClient, ServiceFactory.tokenAccessor), []);

  getManagementEmailRepo = (): AbstractManagementEmailRepo =>
    useMemo(
      () => new ManagementEmailRepo(httpClient, ServiceFactory.tokenAccessor),
      []
    );

  getProcessRepo = (): AbstractProcessRepo =>
    useMemo(
      () => new ProcessRepo(httpClient, ServiceFactory.tokenAccessor),
      []
    );

  getBareClientRepo = (): AbstractBareClientRepo =>
    useMemo(
      () => new BareClientRepo(httpClient, ServiceFactory.tokenAccessor),
      []
    );
}
