import emailMask from 'text-mask-addons/dist/emailMask';

import { ERROR_KEYS } from 'constants/fieldErrors';
import { DataModelFieldSubType, DataModelFieldType } from 'constants/global';

import { string } from 'utils/normalizers';
import {
  email,
  isDateFormat,
  isPhoneFormat,
  isUrlAddress,
  Validator,
} from 'utils/validators';

const SLASHED_DATE = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
const PHONE = [
  '+',
  '1',
  '-',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export type EnumedMask = {
  [key in DataModelFieldSubType | DataModelFieldType]?: (string | RegExp)[];
};

const MASKS: EnumedMask = {
  [DataModelFieldSubType.TextPhone]: PHONE,
  [DataModelFieldSubType.NumberPhone]: PHONE,
  [DataModelFieldSubType.TextEmail]: emailMask,
  [DataModelFieldType.Date]: SLASHED_DATE,
  [DataModelFieldSubType.DateReverse]: SLASHED_DATE,
  [DataModelFieldSubType.DateDefault]: SLASHED_DATE,
  [DataModelFieldSubType.DateDots]: [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};

const PHONE_PLACEHOLDER = '+1-***-***-****';

export type EnumedPlaceholders = {
  [key in DataModelFieldSubType | DataModelFieldType]?: string;
};

const PLACEHOLDERS: EnumedPlaceholders = {
  [DataModelFieldSubType.TextEmail]: '***@***.***',
  [DataModelFieldSubType.TextUrl]: 'https://*****.***',
  [DataModelFieldSubType.TextPhone]: PHONE_PLACEHOLDER,
  [DataModelFieldSubType.NumberPhone]: PHONE_PLACEHOLDER,
  [DataModelFieldType.Date]: 'mm/dd/yyyy',
  [DataModelFieldSubType.DateReverse]: 'dd/mm/yyyy',
  [DataModelFieldSubType.DateDefault]: 'mm/dd/yyyy',
  [DataModelFieldSubType.DateDots]: 'mm.dd.yyyy',
  [DataModelFieldType.Number]: '0',
};

export type EnumedValidators = {
  [key in DataModelFieldSubType | DataModelFieldType]?: Validator[];
};

const VALIDATORS: EnumedValidators = {
  [DataModelFieldSubType.TextEmail]: [email(ERROR_KEYS.WRONG_FORMAT_EMAIL)],
  [DataModelFieldSubType.TextUrl]: [isUrlAddress(ERROR_KEYS.WRONG_FORMAT_URL)],
  [DataModelFieldSubType.TextPhone]: [isPhoneFormat],
  [DataModelFieldSubType.NumberPhone]: [isPhoneFormat],
  [DataModelFieldType.Date]: [isDateFormat(DataModelFieldSubType.DateDefault)],
  [DataModelFieldSubType.DateReverse]: [
    isDateFormat(DataModelFieldSubType.DateReverse),
  ],
  [DataModelFieldSubType.DateDefault]: [
    isDateFormat(DataModelFieldSubType.DateDefault),
  ],
  [DataModelFieldSubType.DateDots]: [
    isDateFormat(DataModelFieldSubType.DateDots),
  ],
};

export type EnumedNormalizers = {
  [key in DataModelFieldSubType | DataModelFieldType]?: (
    val: string
  ) => string | number;
};

const NORMALIZERS: EnumedNormalizers = {
  [DataModelFieldType.Number]: string.getOnlyNumbers,
};

export type EnumedFormatters = {
  [key in DataModelFieldSubType | DataModelFieldType]?: (
    val: string
  ) => string | number;
};

const FORMATTERS: EnumedFormatters = {
  [DataModelFieldType.Number]: string.getOnlyNumbers,
};

export { MASKS, PLACEHOLDERS, VALIDATORS, NORMALIZERS, FORMATTERS };
