import ROUTES, { EmailPollingUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { call, select } from 'redux-saga/effects';

import EmailPolling from 'api/endpoints/emailPolling';

import ApiUrlParams from 'constants/apiUrlParams';

function* reFetchActiveScenario() {
  const { pathname, query } = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId },
  }: match<EmailPollingUiParams> = yield matchPath(pathname, {
    path: ROUTES.process.definition.services.emailPolling._(),
  });

  yield call(EmailPolling.getScenarios, {
    ...query,
    [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
    isActive: true,
  });
}

export default reFetchActiveScenario;
