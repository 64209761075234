import { compose } from 'redux';

const normalizeLessThanTen = (num: number) =>
  num < 10 ? `0${num}` : String(num);

const normalizeMonthFormat = (month: number) => month + 1;

export const toShortSlashes = (incomingDate: string | Date) => {
  const date = new Date(incomingDate);
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const normalizedMonth = compose(
    normalizeLessThanTen,
    normalizeMonthFormat
  )(month);

  return `${normalizedMonth}/${normalizeLessThanTen(day)}/${year}`;
};

export const convertTimeToHours = (seconds: number) => {
  const hours = Math.trunc(seconds / 3600);
  const minutes = Math.trunc((seconds % 3600) / 60);
  return `${hours} hour(s) ${minutes} minute(s)`;
};
