import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import {
  addFieldFailure,
  afterSuccessDataModelFieldDelete,
  dataModelFieldUpdateSuccessAction,
  reFetchFields,
  reFetchModelForms,
  updateUniqueField,
} from './sagas';

function* watcherSaga() {
  yield takeLatest(
    API_REQ_ACTION_TYPES.FIELDS.UPDATE_MODEL_FIELD_BY_ID.SUCCESS,
    reFetchFields
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.FIELDS.UPDATE_MODEL_FIELD_BY_ID.SUCCESS,
    dataModelFieldUpdateSuccessAction
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.FIELDS.DELETE_MODEL_FIELD_BY_ID.SUCCESS],
    afterSuccessDataModelFieldDelete
  );

  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.FIELDS.GET_BY_PROCESS_ID.START,
      API_REQ_ACTION_TYPES.FIELDS.GET_BY_PROCESS_ID.SUCCESS,
    ],
    updateUniqueField
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.FIELDS.UPDATE_MODEL_FIELD_BY_ID.SUCCESS],
    reFetchModelForms
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.FIELDS.UPDATE_MODEL_FIELD_BY_ID.ERROR],
    addFieldFailure
  );
}

export default watcherSaga;
