import { fork, put } from 'redux-saga/effects';

import createATA from 'utils/createAddToasterAction';

import { reFetchScenariosList } from '.';

const MESSAGE = 'The scenario was successfully deleted';

function* deleteScenarioSuccess() {
  yield fork(reFetchScenariosList);
  yield put(createATA({ message: MESSAGE }));
}

export default deleteScenarioSuccess;
