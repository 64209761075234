import { put } from 'redux-saga/effects';

import createATA from 'utils/createAddToasterAction';

function* showToasterAfterActivate({
  meta: {
    input: { version },
  },
}: {
  type: string;
  meta: { input: { version: number } };
}) {
  yield put(
    createATA({
      message: `Version ${version} was successfully activated`,
    })
  );
}

export default showToasterAfterActivate;
