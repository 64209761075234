import { startSubmit } from 'redux-form';
import { put } from 'redux-saga/effects';

function* handleStartSubmittingDynamicForm({
  payload,
}: {
  type: string;
  payload: { form: string };
}) {
  yield put(startSubmit(payload?.form as string));
}

export default handleStartSubmittingDynamicForm;
