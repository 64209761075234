import { WithReleaseOrDraftProcessId } from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { excludeObjectProperty } from 'utils/normalizers/object';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export interface GetAllProcessServicesParams
  extends WithReleaseOrDraftProcessId {}

export interface GetAllProcessServicesResult
  extends Pick<BaseEntity, 'createdAt' | 'updatedAt'> {
  processId: string;
  services: {
    ftpPolling: boolean;
    inboundEmail: boolean;
  };
}

export interface UpdateAllProcessServicesParams
  extends WithReleaseOrDraftProcessId {
  services: GetAllProcessServicesResult['services'];
}

export interface UpdateAllProcessServicesResult
  extends GetAllProcessServicesResult {}

export type GetProcessServicesEtaParams = unknown;

export interface GetProcessServicesEtaResult {
  eta: {
    ftpPolling: string;
    inboundEmail: string;
  };
}

const ProcessServices = {
  getAll: apiReqSagaCreator<
    GetAllProcessServicesParams,
    GetAllProcessServicesResult
  >(API_REQ_ACTION_TYPES.PROCESS_SERVICES.GET_ALL, (params) => ({
    method: HTTP_METHODS.GET,
    url: ApiPaths.processes.byId.services._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        params[ApiUrlParams.releaseOrDraftProcessId],
    }),
  })),
  updateAll: apiReqSagaCreator<
    UpdateAllProcessServicesParams,
    UpdateAllProcessServicesResult
  >(API_REQ_ACTION_TYPES.PROCESS_SERVICES.UPDATE_ALL, (args) => ({
    method: HTTP_METHODS.PUT,
    url: ApiPaths.processes.byId.services._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        args[ApiUrlParams.releaseOrDraftProcessId],
    }),
    data: excludeObjectProperty(args, 'releaseOrDraftProcessId'),
  })),
  getEta: apiReqSagaCreator<
    GetProcessServicesEtaParams,
    GetProcessServicesEtaResult
  >(
    API_REQ_ACTION_TYPES.PROCESS_SERVICES.GET_ETA,
    () => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.services.pollingEta._(),
    }),
    { muteError: true }
  ),
};

export default ProcessServices;
