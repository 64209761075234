import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export interface Licenses {
  userLimit: number;
  activeUsersCount: number;
}

export type GetLicensesParams = unknown;
export type GetLicensesResult = Licenses;

const LicensesEndpoints = {
  getLicenses: apiReqSagaCreator<GetLicensesParams, GetLicensesResult>(
    API_REQ_ACTION_TYPES.LICENSES.GET_LICENSES,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.licenses._(),
    })
  ),
};

export default LicensesEndpoints;
