import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GlobalActions } from 'store/global/actions';
import GlobalSelectors from 'store/global/selectors';

import { getIsMobile } from 'utils/device';

import { CloseFunc, HandleKeyDown, Props } from './types';

const useModal = (
  closeConfig: Props['closeConfig'],
  rejectConfig: Props['rejectConfig'],
  submitConfig: Props['submitConfig'],
  isVisible: Props['isVisible']
) => {
  // region *******************************DATA*********************************
  const isMobile = getIsMobile();

  const openedModals = useSelector(GlobalSelectors.getOpenedModals);

  const dispatch = useDispatch();

  const [id] = useState(crypto.randomUUID());

  const closeFunc: CloseFunc = useMemo(() => {
    if (typeof closeConfig === 'function') {
      return closeConfig;
    }
    if (closeConfig?.onClick) {
      return closeConfig.onClick;
    }
    if (rejectConfig?.onClick) {
      return rejectConfig.onClick;
    }
    if (submitConfig?.onClick) {
      return submitConfig.onClick;
    }
    return undefined;
  }, [closeConfig, rejectConfig, submitConfig]);
  // endregion

  // region *****************************CALLBACKS******************************
  const handleKeyDown: HandleKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      if (
        ev.key === 'Escape' &&
        id === openedModals[openedModals.length - 1] &&
        closeFunc
      ) {
        closeFunc();
        dispatch(GlobalActions.manageOpenedModals());
      }
    },
    [id, openedModals, closeFunc, dispatch]
  );
  // endregion

  // region ******************************EFFECTS*******************************
  useEffect(() => {
    if (isVisible) {
      dispatch(GlobalActions.manageOpenedModals({ id }));
    }

    return () => {
      dispatch(GlobalActions.manageOpenedModals({ id, remove: true }));
    };
  }, [id, dispatch, isVisible]);

  useEffect(() => {
    if (isVisible) document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown, isVisible]);

  return useMemo(
    () => ({
      isMobile,
    }),
    [isMobile]
  );
  // endregion
};

export default useModal;
