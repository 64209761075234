import { all, call, put, select } from 'redux-saga/effects';

import { ApiSagaResult } from 'api/apiReqSagaCreator';
import SplitDocument, {
  GetPagePreviewResult,
  SplitDocumentGeneral,
} from 'api/endpoints/splitDocument';

import { SplitDocumentsActions } from 'store/splitDocuments/actions';
import SplitDocumentsSelectors from 'store/splitDocuments/selectors';

import ApiUrlParams from 'constants/apiUrlParams';

function* receiveDocumentPages(
  document: SplitDocumentGeneral,
  processInstanceId: string,
  fieldId: string,
  releaseOrDraftProcessId: string
) {
  const receivedPages: Nullable<KeyValuePairs> = yield select(
    SplitDocumentsSelectors.getReceivedPages
  );

  const results: ApiSagaResult<GetPagePreviewResult>[] = yield all(
    document.pages?.map((e) =>
      !(receivedPages || {})[e]
        ? call(SplitDocument.getPagePreview, {
            [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
            [ApiUrlParams.name]: e,
            [ApiUrlParams.fieldId]: fieldId,
            [ApiUrlParams.processInstanceId]: processInstanceId,
          })
        : null
    ) ?? []
  );

  const normalized: KeyValuePairs = (document.pages || []).reduce(
    (acc, next, idx) => ({
      ...acc,
      [next]: (results[idx] && results[idx].ok
        ? URL.createObjectURL(results[idx].result as GetPagePreviewResult)
        : (receivedPages || {})[next]) as string,
    }),
    {}
  );

  yield put(
    SplitDocumentsActions.setReceivedPages({
      ...(receivedPages ?? {}),
      ...normalized,
    })
  );

  return normalized;
}

export default receiveDocumentPages;
