import BaseRepo from 'container/BaseRepo';

export interface ScanScenario {}

export abstract class AbstractScanScenarioRepo extends BaseRepo {
  abstract getScanScenario(
    processDraftOrReleaseId: string,
    scanScenarioId: string
  ): ScanScenario;
}
