import { call } from 'redux-saga/effects';

import { Tenants } from 'api/endpoints';

import { RECEIVE_ALL_TENANTS_ACTION } from 'store/tenants/reducer';

function* submitLoginSuccess() {
  yield call(Tenants.getTenants, undefined, {
    title: RECEIVE_ALL_TENANTS_ACTION,
  });
}
export default submitLoginSuccess;
