import { stopAsyncValidation } from 'redux-form';
import { put } from 'redux-saga/effects';

import { ERROR_KEYS } from 'constants/fieldErrors';
import { FORM_NAMES } from 'constants/form';

function* addScanConfigFailure() {
  yield put(
    stopAsyncValidation(FORM_NAMES.ADD_SCAN_CONFIGURATION, {
      name: { key: ERROR_KEYS.DUPLICATES_SCAN_NAME },
    })
  );
}

export default addScanConfigFailure;
