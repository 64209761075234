import { combineReducers } from 'redux';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import { SearchTemplateGeneral } from 'api/endpoints/searchTemplate';

export interface SearchTemplateState {
  list: Nullable<SearchTemplateGeneral[]>;
  total: Nullable<number>;
}

const searchTemplate = combineReducers<SearchTemplateState>({
  list(state = null, { type, payload }) {
    switch (type) {
      case API_REQ_ACTION_TYPES.SEARCH_TEMPLATE.GET_ALL.SUCCESS:
        return payload?.data;
      default:
        return state;
    }
  },
  total(state = null, { type, payload }) {
    switch (type) {
      case API_REQ_ACTION_TYPES.SEARCH_TEMPLATE.GET_ALL.SUCCESS:
        return payload?.total;
      default:
        return state;
    }
  },
});

export default searchTemplate;
