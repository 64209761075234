import { fork } from 'redux-saga/effects';

import Function from 'api/endpoints/function';
import { WithReleaseOrDraftProcessId } from 'api/types/pathParams';

function* reFetchStagesList({
  meta: { input },
}: {
  type: string;
  meta: { input: WithReleaseOrDraftProcessId & { reFetchStages?: boolean } };
}) {
  if (input.reFetchStages) {
    yield fork(Function.getList, input);
  }
}

export default reFetchStagesList;
