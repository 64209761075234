import { ObligationTypeField } from 'types/field';

import {
  FieldWithObligationType,
  FormGeneral,
} from 'api/endpoints/processForm';

import createAction from 'utils/createAction';

const FORMS_DETAILS = {
  SET_PROCESS_FORMS_DETAILS: 'FORMS_DETAILS.SET_PROCESS_FORMS_DETAILS',
  SET_UPDATED_COLLECTION_FIELDS: 'FORMS_DETAILS.SET_UPDATED_COLLECTION_FIELDS',
  SET_INITIAL_FORM: 'FORMS_DETAILS.SET_INITIAL_FORM',
  EDIT_OBLIGATION_FIELDS: 'FORMS_DETAILS.EDIT_OBLIGATION_FIELDS',
  CLEAR_OBLIGATION_FIELDS: 'FORMS_DETAILS.CLEAR_OBLIGATION_FIELDS',
};

const ProcessForm = {
  setProcessFormDetails: createAction<FormGeneral>(
    FORMS_DETAILS.SET_PROCESS_FORMS_DETAILS
  ),
  setUpdatedCollectionFields: createAction<{
    [key: string]: FieldWithObligationType[];
  }>(FORMS_DETAILS.SET_UPDATED_COLLECTION_FIELDS),
  setInitialForm: createAction<FormGeneral>(FORMS_DETAILS.SET_INITIAL_FORM),
  editObligationFields: createAction<ObligationTypeField>(
    FORMS_DETAILS.EDIT_OBLIGATION_FIELDS
  ),
  clearObligationFields: createAction(FORMS_DETAILS.CLEAR_OBLIGATION_FIELDS),
};

export type SetUpdatedCollectionFieldsAction = ReturnType<
  typeof ProcessForm.setUpdatedCollectionFields
>;

export { FORMS_DETAILS as FORMS_DETAILS_ACTION_TYPES, ProcessForm };
