import { stopAsyncValidation } from 'redux-form';
import { put } from 'redux-saga/effects';

import { ERROR_KEYS } from 'constants/fieldErrors';
import { FORM_NAMES } from 'constants/form';

function* createSearchTemplateFailure() {
  yield put(
    stopAsyncValidation(FORM_NAMES.SAVE_SEARCH_TEMPLATE, {
      name: { key: ERROR_KEYS.DUPLICATION_SEARCH_TEMPLATE_NAME },
    })
  );
}

export default createSearchTemplateFailure;
