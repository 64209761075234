import { put, select } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import { FieldGeneral, GetDMFsByTypeResult } from 'api/endpoints/processFields';

import { SearchTypes } from 'constants/global';

import { ProcessFields } from '../actions';
import FieldSelectors from '../selectors';

const watchingTypes = [SearchTypes.quick, SearchTypes.view];

type UpdateUniqueFieldParams = {
  type: string;
  meta: { title: string };
  payload: GetDMFsByTypeResult;
};

function* updateUniqueField({ type, meta, payload }: UpdateUniqueFieldParams) {
  const state: KeyValuePairs<Nullable<FieldGeneral | FieldGeneral[]>> =
    yield select(FieldSelectors.getUniqueFields);

  let result;

  switch (type) {
    case API_REQ_ACTION_TYPES.FIELDS.GET_BY_PROCESS_ID.START:
      result = watchingTypes.includes(meta?.title as SearchTypes)
        ? { ...state, [meta?.title]: null }
        : state;
      break;
    case API_REQ_ACTION_TYPES.FIELDS.GET_BY_PROCESS_ID.SUCCESS:
      result =
        watchingTypes.includes(meta?.title as SearchTypes) && payload?.data
          ? {
              ...state,
              [meta?.title]:
                meta?.title === SearchTypes.view
                  ? payload?.data
                  : payload?.data[0],
            }
          : state;
      break;
    default:
      result = state;
  }

  yield put(
    ProcessFields.updateUniqueFields(
      result as KeyValuePairs<Nullable<FieldGeneral>>
    )
  );
}

export default updateUniqueField;
