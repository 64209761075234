import {
  ApiResponseAsync,
  translateAxiosErrorToApiError,
} from 'container/models';

import { authHeaderName } from 'api/apiReqSagaCreator/config';
import { handleExpiredTokens } from 'api/apiReqSagaCreator/httpRequest';
import { ApiPaths } from 'api/constants';
import { ProcessGeneral } from 'api/endpoints/processes';

import ApiUrlParams from 'constants/apiUrlParams';

import { AbstractProcessRepo } from './AbstractProcessRepo';

export class ProcessRepo extends AbstractProcessRepo {
  getProcessDetails = (
    draftProcessId: string
  ): ApiResponseAsync<ProcessGeneral> =>
    handleExpiredTokens(() =>
      this.client.get<ProcessGeneral>(
        ApiPaths.processes.byId._({
          [ApiUrlParams.releaseOrDraftProcessId]: draftProcessId,
        }),
        {
          headers: { [authHeaderName]: this.tokenAccessor() },
        }
      )
    ).then(({ data }) => data, translateAxiosErrorToApiError);
}
