import { setSubmitSucceeded } from 'redux-form';
import { put } from 'redux-saga/effects';

import { FORM_NAMES } from 'constants/form';

function* afterSucceededPrintRenderScenarioSubmit() {
  yield put(setSubmitSucceeded(FORM_NAMES.CREATE_PRINT_RENDER_SCENARIO));
}

export default afterSucceededPrintRenderScenarioSubmit;
