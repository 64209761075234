import { Action } from 'redux';
import { put, select } from 'redux-saga/effects';

import { DocumentViewActions } from '../actions';
import documentBarcodeData from '../documentBarcodeData';
import ScanDocumentViewSelector from '../selectors';

interface TaskEvent {
  meta: {};
  payload: {
    documentId: string;
    value: string;
  };
}

interface TaskAction extends Action, TaskEvent {
  type: string;
}

function* setBarcodeValue(params: TaskAction) {
  const persistedBarcodeValue = {
    ...JSON.parse(documentBarcodeData.getDocumentsBarcodeData()),
    [params?.payload?.documentId]: params?.payload?.value,
  };

  documentBarcodeData.setBarcodeValueToDocument(persistedBarcodeValue);

  const barcodeValuesList: Nullable<KeyValuePairs> = yield select(
    ScanDocumentViewSelector.getBarcodeValuesList
  );

  yield put(
    DocumentViewActions.setBarcodeValue({
      ...barcodeValuesList,
      ...persistedBarcodeValue,
    })
  );
}

export default setBarcodeValue;
