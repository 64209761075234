import subSelectorCreator from 'utils/subSelectorCreator';

import { SplitDocumentsState } from './reducer';

const createSplitDocumentsSelector = subSelectorCreator<SplitDocumentsState>(
  (state) => state.splitDocuments
);

const SplitDocumentsSelectors = {
  getList: createSplitDocumentsSelector(({ list }) => list),
  getTotal: createSplitDocumentsSelector(({ total }) => total),
  getReceivedPages: createSplitDocumentsSelector(
    ({ receivedPages }) => receivedPages
  ),
  getDetails: createSplitDocumentsSelector(({ details }) => details),
};

export default SplitDocumentsSelectors;
