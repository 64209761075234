const POSITIONS = {
  TOP: 'top',
  LEFT: 'left',
  RIGHT: 'right',
  BOTTOM: 'bottom',
} as const;

const ZERO = 0;

const HIDE_BACKGROUND_STYLES = {
  width: ZERO,
  height: ZERO,
  padding: ZERO,
};

export { POSITIONS, HIDE_BACKGROUND_STYLES };
