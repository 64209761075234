import { useAppServices } from 'container/depContext';
import { isApiError } from 'container/models';
import { AbstractFreshdeskTokenRepo } from 'container/services/freshdeskToken/abstractFreshdeskTokenRepo';
import useToaster from 'hooks/useToaster';
import { useEffect, useState } from 'react';

import MaterialIcon from 'components/atoms/MaterialIcon';

import { TOASTER_TYPE } from 'constants/toasterMessages';

import styles from './styles.module.scss';

function setupWidgetAuth(
  repo: AbstractFreshdeskTokenRepo,
  successCallback: (success: boolean) => void
) {
  function authenticate() {
    repo.getFreshdeskToken().then((response) => {
      if (isApiError(response)) {
        console.error(`Couldn't get FreshDesk widget token`, response);
        successCallback(false);
        return;
      }

      FreshworksWidget('authenticate', {
        token: response.token,
        callback: authenticate,
      });

      successCallback(true);
    });
  }

  authenticate();
}

export default function HelpButton(): JSX.Element {
  const { getFreshdeskTokenRepo } = useAppServices();
  const repo = getFreshdeskTokenRepo();
  const { addToaster } = useToaster();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    setupWidgetAuth(repo, setIsAuthenticated); // authenticate the widget on mount
    return () => FreshworksWidget('logout'); // logout the widget on unmount

    // We only want to run this effect once per mount/unmount
  }, [repo]);

  const altText = isAuthenticated
    ? 'Help'
    : 'Help is not available at this time. Please try again later.';

  return (
    <button
      onClick={() =>
        isAuthenticated
          ? FreshworksWidget('open')
          : addToaster({
              message: altText,
              type: TOASTER_TYPE.ERROR,
            })
      }
      className={[styles.wrapper, isAuthenticated ? '' : styles.disabled].join(
        ' '
      )}
    >
      <MaterialIcon
        name={'help'}
        alt={altText}
        size={34}
        weight={300}
        className={styles.icon}
        color=""
      />
    </button>
  );
}
