import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

export type HandleToggleShowModal = EmptyArgsVoidFunc;

export type UseModal = [
  boolean,
  HandleToggleShowModal,
  Dispatch<SetStateAction<boolean>>,
];

const useModal = (): UseModal => {
  const [showModal, setShowModal] = useState(false);

  const handleToggleShowModal: HandleToggleShowModal = useCallback(() => {
    setShowModal((prevState) => !prevState);
  }, []);

  return useMemo(
    () => [showModal, handleToggleShowModal, setShowModal],
    [showModal, setShowModal, handleToggleShowModal]
  );
};

export default useModal;
