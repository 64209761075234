import { Action } from 'redux';
import { stopAsyncValidation } from 'redux-form';
import { put } from 'redux-saga/effects';

import * as ERROR_CODES from 'constants/errorCodes';
import { ERROR_KEYS } from 'constants/fieldErrors';
import { FORM_NAMES } from 'constants/form';

interface Task extends Action, TaskEvent {}

interface TaskEvent {
  payload: BackendError[];
}

function* afterFailPrintRenderScenarioSubmit({ payload }: Task) {
  if (payload.some((e) => e.code === ERROR_CODES.BASE_CLIENT_ERROR)) {
    yield put(
      stopAsyncValidation(FORM_NAMES.CREATE_PRINT_RENDER_SCENARIO, {
        name: { key: ERROR_KEYS.DUPLICATION_PRINT_RENDER_SCENARIO_NAME },
      })
    );
  }
}

export default afterFailPrintRenderScenarioSubmit;
