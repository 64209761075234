import { getLocation, push } from 'connected-react-router';
import { put, select } from 'redux-saga/effects';

function* moveToPrevPage({
  payload,
  meta,
}: ReduxAction<
  { data: DataWithTotal },
  { withPrevPageOnLessTotal?: boolean }
>) {
  const { query, pathname } = yield select(getLocation);

  const { limit, offset, page } = query;

  if (
    meta.withPrevPageOnLessTotal &&
    Number(offset) &&
    Number.isInteger(payload?.data?.total) &&
    payload?.data?.total === Number(offset)
  ) {
    yield put(
      push(
        `${pathname}?page=${Number(page - 1)}&limit=${limit}&offset=${
          offset - Number(limit)
        }`
      )
    );
  }
}

export default moveToPrevPage;
