import subSelectorCreator from 'utils/subSelectorCreator';

import { GlobalState } from './reducer';

const createGlobalSelector = subSelectorCreator<GlobalState>(
  (state) => state.global
);

const GlobalSelectors = {
  getHidePageWrap: createGlobalSelector(({ hidePageWrap }) => hidePageWrap),
  getOpenedModals: createGlobalSelector(({ openedModals }) => openedModals),
};

export default GlobalSelectors;
