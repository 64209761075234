import { TableInstances } from 'types/table';

import ModelEmpty from 'assets/img/datamodel-empty.svg';

import { FieldGeneral } from 'api/endpoints/processFields';

import {
  DATA_MODEL_FIELD_LABELS,
  DATA_MODEL_FIELD_SUB_LABELS,
  DataModelFieldSubType,
  DataModelFieldType,
} from 'constants/global';
import {
  BASE_TYPE,
  CELL_TYPE,
  CELL_WIDTH,
  TABLE_ACTION,
} from 'constants/table';

const TEXTS = {
  TITLE: 'There are no Data Model fields yet',
  DESCRIPTION:
    'You don’t have any fields in Data Model, but you can add them right now',
  CREATE_LABEL: 'Add Field',
  Q_SEARCH: {
    LABEL: 'Are you sure you want to change your quick search field?',
    DESCRIPTION: 'Only one data model field can be used for a quick search.',
  },
  DELETE_FIELD: {
    LABEL: 'Are you sure you want to delete the data model field?',
  },
  DATA_MODEL_FIELD_DELETE_ERROR_MODAL: {
    LABEL: 'The data model field is currently used and cannot be deleted',
    DESCRIPTION:
      'Please check Forms, Process Design, Scanning and Print render.',
    SUBMIT_BTN: 'OK',
  },
  DATA_MODEL_FIELD_EDIT_CONSTANT_ERROR_MODAL: {
    LABEL: 'The constant is currently used and cannot be deleted or renamed.',
    DESCRIPTION: 'Please check Process Design.',
    SUBMIT_BTN: 'OK',
  },
  REJECT: 'Cancel',
  CONFIRM: 'Change',
  DELETE: 'Delete',
  ALT: {
    EMPTY: 'Data model empty',
  },
  COPY_ID: 'Data model field ID has been copied',
  EDIT_DATA_MODEL_DIALOG_TITLE: 'Edit Field',
  SHOW_IN_GRID_SUCCESS_TOAST: 'Field was set to be shown to user.',
  REMOVE_FROM_GRID_SUCCESS_TOAST: 'Field was hidden from the user.',
};

const getTableColumns = (isPreview = false) => [
  {
    accessor: `${BASE_TYPE.DISPLAY}-icon`,
    minWidth: 54,
    maxWidth: 54,
  },
  {
    label: 'Name (A-Z)',
    accessor: 'name',
    minWidth: 150,
    maxWidth: 200,
    withSemiBoldWeight: true,
  },
  {
    label: 'Field type',
    accessor: 'type',
    minWidth: 80,
    maxWidth: 150,
  },
  {
    label: 'Sub-type',
    accessor: 'metadata.maskType',
    minWidth: 80,
    maxWidth: 150,
  },
  {
    label: 'Alias',
    accessor: 'alias',
    minWidth: 150,
    maxWidth: 200,
  },
  {
    label: 'Description',
    accessor: 'description',
    minWidth: 150,
    maxWidth: 200,
  },
  {
    accessor: CELL_TYPE.ICONS,
    withEndContent: true,
    icons: [
      {
        width: CELL_WIDTH.ICON,
        type: `${BASE_TYPE.DISPLAY}-quickSearch`,
      },
      {
        width: CELL_WIDTH.ICON,
        type: `${BASE_TYPE.DISPLAY}-advancedSearch`,
      },
      {
        width: CELL_WIDTH.ICON,
        type: `${BASE_TYPE.DISPLAY}-view`,
      },
      ...(!isPreview
        ? [
            {
              width: CELL_WIDTH.ICON,
              type: TABLE_ACTION.MENU,
            },
          ]
        : []),
      {
        width: CELL_WIDTH.ICON,
        type: TABLE_ACTION.EXPAND,
      },
    ],
  },
];

const RESTRICTED_TYPES = [
  DataModelFieldType.Collection,
  DataModelFieldType.File,
];

const TABLE_MENU_ACCESSOR = {
  Q_SEARCH: 'q_search',
  A_SEARCH: 'a_search',
  GET_ID: 'get_id',
  DISPLAY_FIELD_IN_GRID: 'display_field_in_grid',
  REMOVE_FILED_FROM_GRID: 'remove_field_from_grid',
  EDIT: 'edit',
  DELETE: 'delete',
};

const hideIfFileType = (({ type }: FieldGeneral) =>
  RESTRICTED_TYPES.some((e) => e === type)) as (
  item: TableInstances
) => boolean;

const TABLE_MENU_CONFIG = [
  {
    label: 'Get ID',
    accessor: TABLE_MENU_ACCESSOR.GET_ID,
    isHidden: ((item: FieldGeneral) =>
      RESTRICTED_TYPES.includes(item.type as DataModelFieldType)) as (
      item: TableInstances
    ) => boolean,
  },
  {
    label: 'Show to user',
    accessor: TABLE_MENU_ACCESSOR.DISPLAY_FIELD_IN_GRID,
    isHidden: ((item: FieldGeneral) =>
      item.type === DataModelFieldType.Collection || item.isForView) as (
      item: TableInstances
    ) => boolean,
  },
  {
    label: 'Hide from user',
    accessor: TABLE_MENU_ACCESSOR.REMOVE_FILED_FROM_GRID,
    isHidden: ((item: FieldGeneral) =>
      item.type === DataModelFieldType.Collection || !item.isForView) as (
      item: TableInstances
    ) => boolean,
  },
  {
    label: 'Set as Quick Search',
    accessor: TABLE_MENU_ACCESSOR.Q_SEARCH,
    isHidden: hideIfFileType,
    isDisabled: ((item: FieldGeneral) => item.isForQuickSearch) as (
      item: TableInstances
    ) => boolean,
  },
  {
    label: 'Set as Advanced Search',
    accessor: TABLE_MENU_ACCESSOR.A_SEARCH,
    isHidden: hideIfFileType,
  },
  {
    label: 'Edit',
    accessor: TABLE_MENU_ACCESSOR.EDIT,
  },
  {
    label: 'Delete',
    accessor: TABLE_MENU_ACCESSOR.DELETE,
    isDangerAction: true,
  },
];

const DEFAULT_COLLECTION_VALUE = {
  value: 'none',
  label: 'None',
};

const FORM_VALUES = {
  collection: DEFAULT_COLLECTION_VALUE,
  options: [
    { id: '0', name: '' },
    { id: '1', name: '' },
  ],
};

const getModalTexts = (type?: string) => {
  switch (type) {
    case TABLE_MENU_ACCESSOR.Q_SEARCH:
      return {
        label: TEXTS.Q_SEARCH.LABEL,
        description: TEXTS.Q_SEARCH.DESCRIPTION,
      };

    default:
      return {
        label: '-',
        description: '-',
      };
  }
};

const transformOptions = (list: string[] = []) =>
  list.map((e) => ({
    name: e,
    id: e,
  }));

const transformFieldType = (
  type: DataModelFieldType,
  subType?: DataModelFieldSubType | DataModelFieldType
) => {
  if (
    subType &&
    !Object.values(DataModelFieldType).includes(subType as DataModelFieldType)
  ) {
    return {
      label: DATA_MODEL_FIELD_SUB_LABELS[subType as DataModelFieldSubType],
      value: subType,
    };
  }

  switch (type) {
    case DataModelFieldType.Date:
      return {
        label: DATA_MODEL_FIELD_LABELS[DataModelFieldType.Date],
        value: DataModelFieldType.Date,
      };
    case DataModelFieldType.Collection:
      return {
        label: DATA_MODEL_FIELD_LABELS[DataModelFieldType.Collection],
        value: DataModelFieldType.Collection,
      };
    case DataModelFieldType.File:
      return {
        label: DATA_MODEL_FIELD_LABELS[DataModelFieldType.File],
        value: DataModelFieldType.File,
      };
    case DataModelFieldType.Number:
      return {
        label: DATA_MODEL_FIELD_LABELS[DataModelFieldType.Number],
        value: DataModelFieldType.Number,
      };
    case DataModelFieldType.Select:
      return {
        label: DATA_MODEL_FIELD_LABELS[DataModelFieldType.Select],
        value: DataModelFieldType.Select,
      };
    default:
      return {
        label: DATA_MODEL_FIELD_LABELS[DataModelFieldType.Text],
        value: DataModelFieldType.Text,
      };
  }
};

const EMPTY_STATE_CONFIG = {
  imgConfig: {
    imgSource: ModelEmpty,
    imgAlt: TEXTS.ALT.EMPTY,
  },
  description: TEXTS.DESCRIPTION,
  title: TEXTS.TITLE,
};

export {
  EMPTY_STATE_CONFIG,
  TEXTS,
  getTableColumns,
  FORM_VALUES,
  TABLE_MENU_CONFIG,
  TABLE_MENU_ACCESSOR,
  DEFAULT_COLLECTION_VALUE,
  getModalTexts,
  transformOptions,
  transformFieldType,
};
