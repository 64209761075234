import ROUTES from 'app/routes/paths';
import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';

import { UpdateParams } from 'api/endpoints/stage';

import UiUrlParams from 'constants/uiUrlParams';

import { string } from 'utils/formatters/';

function* updateFunctionSuccess({
  meta: {
    input: { reFetchStages, releaseOrDraftProcessId },
  },
}: {
  type: string;
  meta: { input: UpdateParams };
}) {
  if (!reFetchStages) {
    yield put(
      push(
        string.withDefaultPagination(
          ROUTES.process.definition.details.versions({
            [UiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
          })
        )
      )
    );
  }
}

export default updateFunctionSuccess;
