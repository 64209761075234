import cn from 'classnames';
import React, { createElement, memo, useCallback, useMemo } from 'react';

import sm from './styles.module.scss';

export interface Props {
  content: string | undefined;
  withWhiteColor?: boolean;
  withGreyColor?: boolean;
  withRedColor?: boolean;
  with10Font?: boolean;
  with12Font?: boolean;
  with14Font?: boolean;
  with16Font?: boolean;
  with17Font?: boolean;
  with18Font?: boolean;
  with20Font?: boolean;
  with24Font?: boolean;
  with30Font?: boolean;
  with36Font?: boolean;
  with48Font?: boolean;
  withMediumWeight?: boolean;
  withSemiBoldWeight?: boolean;
  withBoldWeight?: boolean;
  withCapitalize?: boolean;
  withUpperCase?: boolean;
  withBlueColor?: boolean;
  withEllipsis?: boolean;
  withPreLine?: boolean;
  asBlock?: boolean;
  href?: string;
  someProp?: {} | Function;
}

export const Text = ({ content, href, ...classes }: Props) => {
  const classNames = useMemo(
    () =>
      cn(
        sm.Base,
        Object.entries(classes)
          .filter(([, value]) => value)
          .reduce((acc, [key, value]) => ({ ...acc, [sm[key]]: value }), {})
      ),
    [classes]
  );

  const NodeToRender = useCallback(
    () =>
      href
        ? createElement('a', { className: classNames, href }, content)
        : createElement('span', { className: classNames }, content),
    [classNames, content, href]
  );

  return <NodeToRender />;
};

export default memo(Text);
