import { RootState } from 'app/redux/createRootReducer';
import { FormErrors, getFormSyncErrors, getFormValues } from 'redux-form';

export type FormValuesSelector<T> = (state: RootState) => T | undefined;

function getFormValuesWrapper<FormData>(formName: string) {
  return getFormValues(formName) as unknown as FormValuesSelector<FormData>;
}

function getFormSyncErrorsWrapper<FormData>(formName: string) {
  return getFormSyncErrors(formName) as unknown as (
    state: RootState
  ) => FormErrors<FormData>;
}

export { getFormValuesWrapper, getFormSyncErrorsWrapper };
