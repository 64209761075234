import createAction from 'utils/createAction';

import { PrintRenderScenarioWithHypermedia } from '../../api/endpoints/printRender';

const PRINT_RENDER_LOGS = {
  SET_PRINT_RENDER_ACTIVE_SCENARIO:
    'PRINT_RENDER_LOGS.SET_PRINT_RENDER_ACTIVE_SCENARIO',
};

const PrintRenderLogsActions = {
  setPrintRenderActiveScenario: createAction<PrintRenderScenarioWithHypermedia>(
    PRINT_RENDER_LOGS.SET_PRINT_RENDER_ACTIVE_SCENARIO
  ),
};

export {
  PRINT_RENDER_LOGS as PRINT_RENDER_LOGS_ACTION_TYPES,
  PrintRenderLogsActions,
};
