import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import { reFetchTenantsList } from './sagas';

function* watcher() {
  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.TENANTS.ADD_TENANT.SUCCESS,
      API_REQ_ACTION_TYPES.TENANTS.UPDATE_TENANT.SUCCESS,
      API_REQ_ACTION_TYPES.TENANTS.DELETE_TENANT.SUCCESS,
    ],
    reFetchTenantsList
  );
}

export default watcher;
