import { call, select } from 'redux-saga/effects';

import FtpPolling from 'api/endpoints/ftpPolling';
import { ProcessVersionGeneral } from 'api/endpoints/processVersions';

import ProcessSelectors from 'store/process/selectors';

import ApiUrlParams from 'constants/apiUrlParams';

function* reFetchActiveScenarioFromExecution() {
  const activeVersion: ProcessVersionGeneral = yield select(
    ProcessSelectors.getActiveVersion
  );

  yield call(FtpPolling.getScenarios, {
    isActive: true,
    limit: 1,
    offset: 0,
    [ApiUrlParams.releaseOrDraftProcessId]: activeVersion.releaseId,
  });
}

export default reFetchActiveScenarioFromExecution;
