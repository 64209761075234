import cn from 'classnames';
import React, { memo, PropsWithChildren, useMemo } from 'react';

import { ReactComponent as Cross } from 'assets/icon/cross-black.svg';
import { ReactComponent as CrossRound } from 'assets/icon/cross-circle.svg';
import { ReactComponent as InfoIcon } from 'assets/icon/info-blue.svg';

import Portal from 'components/atoms/portal';
import Text from 'components/atoms/text';
import ModalButtons from 'components/molecules/modalButtons';
import { SubmitConfig } from 'components/molecules/modalButtons/types';

import sm from './styles.module.scss';
import { Props } from './types';
import useModal from './useModal';

export const Modal = ({
  withTopButtonsMargin,
  label,
  description,
  children,
  isVisible,
  withBottomMargin,
  withBiggestZIndex,
  closeConfig,
  rejectConfig,
  submitConfig,
  withoutPadding,
  withoutRoundedBorders,
  withHalfDescriptionMargin,
  rightContent,
  withInfoStylesDescription,
  wrapClasses,
  leaveInDom,
  modalClass,
  customZIndex,
  dialogExtension,
}: PropsWithChildren<Props>) => {
  const { isMobile } = useModal(
    closeConfig,
    rejectConfig,
    submitConfig,
    isVisible
  );

  return (
    <Portal leaveInDom={leaveInDom} isVisible={isVisible}>
      <div
        style={useMemo(
          () => ({
            zIndex: customZIndex,
          }),
          [customZIndex]
        )}
        className={useMemo(
          () =>
            cn([sm.Overlay], {
              [sm.WithBiggestZIndex]: withBiggestZIndex,
            }),
          [withBiggestZIndex]
        )}
      >
        <div className={cn([sm.Wrap, wrapClasses])}>
          {typeof closeConfig !== 'function' && closeConfig && (
            <div
              className={cn(sm.Wrap_Close, {
                [sm.Wrap_Close_Outside]:
                  closeConfig.isOutsideModal && !closeConfig.withFixedPosition,
                [sm.Wrap_Close_Fixed]: closeConfig.withFixedPosition,
                [sm.Wrap_Close_MultipleBtn]: dialogExtension,
              })}
            >
              {dialogExtension && dialogExtension.draw({})}
              <button
                className={sm.Wrap_Close_Btn}
                onClick={closeConfig.onClick}
              >
                {closeConfig.withRoundIcon ? <CrossRound /> : <Cross />}
              </button>
            </div>
          )}
          <div
            className={useMemo(
              () =>
                cn([sm.Modal, modalClass], {
                  [sm.Modal_BottomMargin]: withBottomMargin,
                  [sm.Modal_WithMinWidth]: !children,
                  [sm.Modal_WithoutPadding]: withoutPadding,
                  [sm.Modal_WithoutRoundedBorders]: withoutRoundedBorders,
                }),
              [
                children,
                modalClass,
                withBottomMargin,
                withoutPadding,
                withoutRoundedBorders,
              ]
            )}
          >
            {(!!label || !!rightContent) && (
              <div className={sm.Modal_Labels}>
                {!!label && (
                  <div className={sm.Modal_Labels_Left}>
                    <Text
                      content={label}
                      withSemiBoldWeight
                      with24Font={!isMobile}
                      with16Font={isMobile}
                    />
                  </div>
                )}
                {!!rightContent && (
                  <div className={sm.Modal_Labels_Right}>
                    {typeof rightContent === 'string' ? (
                      <Text
                        content={rightContent}
                        with16Font={!isMobile}
                        with12Font={isMobile}
                      />
                    ) : (
                      rightContent
                    )}
                  </div>
                )}
              </div>
            )}

            {!!description && (
              <div
                className={cn(sm.Modal_Description, {
                  [sm.Modal_Description_HalfMargin]: withHalfDescriptionMargin,
                })}
              >
                {withInfoStylesDescription && <InfoIcon />}
                {typeof description === 'string' ? (
                  <Text
                    content={description}
                    with18Font={!withInfoStylesDescription}
                    withSemiBoldWeight={withInfoStylesDescription}
                    withPreLine
                    with12Font={withInfoStylesDescription}
                    withBlueColor={withInfoStylesDescription}
                  />
                ) : (
                  description
                )}
              </div>
            )}
            {!!children && <div className={sm.Modal_Content}>{children}</div>}
            {(rejectConfig || submitConfig) && (
              <ModalButtons
                withTopButtonsMargin={withTopButtonsMargin}
                rejectConfig={rejectConfig}
                submitConfig={submitConfig as SubmitConfig}
              />
            )}
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default memo(Modal);
