import { combineReducers } from 'redux';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import {
  ReusableFunctionDetails,
  ReusableFunctionGeneral,
} from 'api/endpoints/function';

import { FUNCTION_ACTION_TYPES } from './actions';

export interface FunctionState {
  list: Nullable<ReusableFunctionGeneral[]>;
  active: Nullable<ReusableFunctionDetails>;
}

const functionReducer = combineReducers<FunctionState>({
  list(
    state = null,
    { type, payload }: { type: string; payload: ReusableFunctionGeneral[] }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.FUNCTIONS.GET_LIST.SUCCESS:
        return payload ? [...payload] : null;

      case FUNCTION_ACTION_TYPES.CLEAR_LIST:
        return null;

      default:
        return state;
    }
  },
  active(
    state = null,
    {
      type,
      payload,
    }: {
      type: string;
      payload: ReusableFunctionDetails | ReusableFunctionGeneral[];
    }
  ) {
    switch (type) {
      case FUNCTION_ACTION_TYPES.UPDATE_ACTIVE:
      case API_REQ_ACTION_TYPES.FUNCTIONS.GET_DETAILS.SUCCESS:
        return (payload as ReusableFunctionDetails) ?? null;

      case API_REQ_ACTION_TYPES.FUNCTIONS.UPDATE.SUCCESS:
        return (payload as ReusableFunctionDetails).id === state?.id
          ? { ...state, ...payload }
          : state;

      case API_REQ_ACTION_TYPES.FUNCTIONS.GET_LIST.SUCCESS:
        return (payload as ReusableFunctionGeneral[])?.length ? state : null;

      case FUNCTION_ACTION_TYPES.CLEAR_LIST:
        return null;

      default:
        return state;
    }
  },
});

export default functionReducer;
