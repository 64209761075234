import { getLocation } from 'connected-react-router';
import { fork, select } from 'redux-saga/effects';

import { Invitations, Licenses } from 'api/endpoints';

function* reFetchInvitationsListAndLicenses() {
  const { query } = yield select(getLocation);

  yield fork(Invitations.getInvitations, query);

  yield fork(Licenses.getLicenses, undefined);
}

export default reFetchInvitationsListAndLicenses;
