import { useEffect, useState } from 'react';
import { TypedEventTarget } from 'typescript-event-target';

export const IS_PROD = process.env.REACT_APP_CURRENT_ENV === 'production';

export const FLAG_REGISTRY: Array<LocalFlag> = [];

export class LocalFlag extends TypedEventTarget<{
  enabled: CustomEvent<void>;
  disabled: CustomEvent<void>;
  toggled: CustomEvent<void>;
}> {
  private _name: string;
  private _description: string;
  private _devOnly: boolean;

  constructor(
    /** A short name for this flag. Will be used for this flag's state in LocalStorage, so it must be unique there. */
    name: string,

    /** What does this flag mean? */
    description: string,

    /** Setting this `true` means that this flag's value can *only* be true for developers (local environments, development and testing clusters) */
    devOnly?: boolean
  ) {
    super();

    FLAG_REGISTRY.push(this);

    this._name = name;
    this._description = description;
    this._devOnly = devOnly ?? false;

    if (IS_PROD && this._devOnly) return;

    window.addEventListener('storage', ({ key, newValue, oldValue }) => {
      if (key !== this._name) return;

      const oldEnabled = oldValue !== null;
      const newEnabled = newValue !== null;

      if (oldEnabled === newEnabled) return;

      if (newEnabled) {
        this.dispatchTypedEvent('enabled', new CustomEvent('enabled'));
      } else {
        this.dispatchTypedEvent('disabled', new CustomEvent('disabled'));
      }

      this.dispatchTypedEvent('toggled', new CustomEvent('toggled'));
    });
  }

  get value() {
    if (IS_PROD && this._devOnly) return false;

    return localStorage.getItem(this._name) !== null;
  }

  set value(enabled: boolean) {
    if (this.value === enabled) return;
    if (IS_PROD && this._devOnly) return;

    if (enabled) {
      localStorage.setItem(this._name, 'true');
      this.dispatchTypedEvent('enabled', new CustomEvent('enabled'));
    } else {
      localStorage.removeItem(this._name);
      this.dispatchTypedEvent('disabled', new CustomEvent('disabled'));
    }

    this.dispatchTypedEvent('toggled', new CustomEvent('toggled'));
  }

  get description() {
    return this._description;
  }

  get name() {
    return this._name;
  }
}

export function useLocalFlagValue(flag: LocalFlag) {
  const [enabled, setEnabled] = useState(flag.value);

  useEffect(() => {
    const listener = () => {
      setEnabled(flag.value);
    };

    flag.addEventListener('toggled', listener);
    return () => {
      flag.removeEventListener('toggled', listener);
    };
  }, [flag]);

  return enabled;
}

export const ENABLE_MSW = new LocalFlag(
  'ENABLE_MSW',
  'Enables the Mock Service Worker, which will mock API requests where configured',
  true
);

export const ENABLE_WYDR = new LocalFlag(
  'ENABLE_WYDR',
  'Enables "Why Did You Render", a tool for detecting unnecessary rerenders in React components',
  true
);

export const FORCE_OUTDATED_BANNER = new LocalFlag(
  'FORCE_OUTDATED_BANNER',
  'Forces the client to display the "there\'s an update available" banner',
  true
);

export const NEW_SCANNING_BETA = new LocalFlag(
  'beta-flag-NEW_SCANNING',
  'Indicates whether users on this machine have opted into the Webviewer-based indexer.'
);
// the previous way of reading this used 'enabled' and 'disabled' instead of existence checking; this migrates it
if (localStorage.getItem(NEW_SCANNING_BETA.name) === 'disabled') {
  NEW_SCANNING_BETA.value = false;
}
