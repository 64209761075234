import createAction from 'utils/createAction';

const CONNECTIONS = {
  SET_USER_CREDENTIALS_STATUS: 'CONNECTIONS.SET_USER_CREDENTIALS_STATUS',
  CLEAR_CONNECTION_DETAILS: 'CONNECTIONS.CLEAR_CONNECTION_DETAILS',
};

const TmsConnectionsActions = {
  setUserCredentialsStatus: createAction<Nullable<boolean>>(
    CONNECTIONS.SET_USER_CREDENTIALS_STATUS
  ),
  clearConnectionDetails: createAction(CONNECTIONS.CLEAR_CONNECTION_DETAILS),
};

export { CONNECTIONS as TMS_CONNECTIONS_ACTION_TYPES, TmsConnectionsActions };
