import createAction from 'utils/createAction';
import createAsyncActionTypes from 'utils/createAsyncActionTypes';

const REGISTRATION = {
  SET_OTT: 'REGISTRATION.SET_OTT',
};

const REGISTRATION_REQ = {
  REGISTER: createAsyncActionTypes('REGISTRATION_REQ.SESSION.REGISTER'),
  VALIDATE_INVITATION_OTT: createAsyncActionTypes(
    'REGISTRATION_REQ.VALIDATE_INVITATION_OTT'
  ),
};

const RegistrationActions = {
  setOtt: createAction<string>(REGISTRATION.SET_OTT),
};

export {
  REGISTRATION as REGISTRATION_ACTION_TYPES,
  REGISTRATION_REQ as REGISTRATION_REQ_ACTION_TYPES,
  RegistrationActions,
};
