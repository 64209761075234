import subSelectorCreator from 'utils/subSelectorCreator';

import { ProcessState } from './reducer';

const createProcessSelector = subSelectorCreator<ProcessState>(
  (state) => state.process
);

const ProcessSelectors = {
  getList: createProcessSelector(({ list }) => list),
  getTotal: createProcessSelector(({ total }) => total),
  getDetails: createProcessSelector(({ details }) => details),
  getActiveVersion: createProcessSelector(({ activeVersion }) => activeVersion),
  getInstanceCount: createProcessSelector(({ instanceCount }) => instanceCount),
  getProcessInstance: createProcessSelector(
    ({ processInstance }) => processInstance
  ),
  getProcessInstanceFields: createProcessSelector(
    ({ instanceFieldsList }) => instanceFieldsList
  ),
  getProcessInstanceFieldsTotal: createProcessSelector(
    ({ instanceFieldsTotal }) => instanceFieldsTotal
  ),
  getInstancesList: createProcessSelector(({ instancesList }) => instancesList),
};

export default ProcessSelectors;
