import { combineReducers } from 'redux';
import { ScanDocPage, ScanDocumentContainerWithPages } from 'types/scanning';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import {
  GetAllDocumentsByNameResult,
  GetListDocumentsResult,
  ScanDocumentContainer,
} from 'api/endpoints/processScan';

import { DOCUMENT_VIEW_ACTION_TYPES } from './actions';

export interface ScanDocumentViewState {
  list: Nullable<ScanDocumentContainerWithPages[]>;
  allList: Nullable<ScanDocumentContainer[]>;
  barcodeValues: Nullable<KeyValuePairs>;
  searchList: Nullable<ScanDocumentContainer[]>;
  searchValue: string;
  total: Nullable<number>;
  selectedPages: ScanDocPage[];
  selectedPageInfo: Nullable<ScanDocPage>;
  documentData: Nullable<ScanDocumentContainer>;
  pageMoved: boolean;
}

const scanDocumentView = combineReducers<ScanDocumentViewState>({
  list(
    state = null,
    {
      type,
      payload,
    }: { type: string; payload: DataWithTotal<ScanDocumentContainerWithPages> }
  ) {
    switch (type) {
      case DOCUMENT_VIEW_ACTION_TYPES.SET_DOCUMENT_VIEW_DATA:
        return payload ? payload.data : null;
      case API_REQ_ACTION_TYPES.SCANNING.DOCUMENTS.GET_LIST.START:
        return null;
      default:
        return state;
    }
  },
  searchValue(
    state = '',
    { type, payload }: { type: string; payload: string }
  ) {
    switch (type) {
      case DOCUMENT_VIEW_ACTION_TYPES.SEARCH_DOCUMENTS:
        return payload || '';
      default:
        return state;
    }
  },
  allList(
    state = null,
    { type, payload }: { type: string; payload: GetListDocumentsResult }
  ) {
    switch (type) {
      case DOCUMENT_VIEW_ACTION_TYPES.SET_ALL_DOCUMENTS:
        return payload ? payload.data : null;
      default:
        return state;
    }
  },
  searchList(
    state = null,
    { type, payload }: { type: string; payload: GetAllDocumentsByNameResult }
  ) {
    switch (type) {
      case DOCUMENT_VIEW_ACTION_TYPES.SET_SEARCH_DOCUMENTS:
      case DOCUMENT_VIEW_ACTION_TYPES.SET_ALL_DOCUMENTS:
      case API_REQ_ACTION_TYPES.SCANNING.DOCUMENTS.GET_ALL_LIST.SUCCESS:
        return payload ? payload.data : null;
      case API_REQ_ACTION_TYPES.SCANNING.DOCUMENTS.GET_ALL_LIST.START:
        return null;
      default:
        return state;
    }
  },
  total(
    state = null,
    { type, payload }: { type: string; payload: GetListDocumentsResult }
  ) {
    switch (type) {
      case DOCUMENT_VIEW_ACTION_TYPES.SET_DOCUMENT_VIEW_DATA:
        return payload ? payload.total : null;
      default:
        return state;
    }
  },
  selectedPages(
    state = [],
    { type, payload }: { type: string; payload: ScanDocPage[] }
  ) {
    switch (type) {
      case DOCUMENT_VIEW_ACTION_TYPES.SET_SELECTED_PAGE:
        return payload || [];
      case API_REQ_ACTION_TYPES.SCANNING.DOCUMENTS.GET_LIST.START:
      case API_REQ_ACTION_TYPES.SCANNING.PAGES.TRANSFORM_PAGE.START:
        return [];
      default:
        return state;
    }
  },
  documentData(
    state = null,
    { type, payload }: { type: string; payload: ScanDocumentContainer }
  ) {
    switch (type) {
      case DOCUMENT_VIEW_ACTION_TYPES.SET_DOCUMENT_DATA:
        return payload || [];
      default:
        return state;
    }
  },
  selectedPageInfo(
    state = null,
    { type, payload }: { type: string; payload: ScanDocPage }
  ) {
    switch (type) {
      case DOCUMENT_VIEW_ACTION_TYPES.SET_SELECTED_PAGE_INFO:
        return payload;
      default:
        return state;
    }
  },
  pageMoved(
    state = false,
    { type, payload }: { type: string; payload: boolean }
  ) {
    switch (type) {
      case DOCUMENT_VIEW_ACTION_TYPES.SET_PAGE_MOVED:
        return payload || false;
      default:
        return state;
    }
  },
  barcodeValues(
    state = null,
    { type, payload }: { type: string; payload: KeyValuePairs }
  ) {
    switch (type) {
      case DOCUMENT_VIEW_ACTION_TYPES.SET_BARCODE_VALUE:
        return payload || null;
      default:
        return state;
    }
  },
});

export default scanDocumentView;
