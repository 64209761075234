import {
  PrintRenderDetails,
  ProcessExecutionStatus,
  ProcessManagement,
  ProcessManagementDetails,
  ProcessManagementScanningScenario,
  ProcessManagementScanningScenarioOld,
} from 'app/routes/lazyPages';
import ROUTES from 'app/routes/paths';
import onlySignedInPage from 'hocs/onlySignedInPage';

import { ACCESSIBLE_ROLES } from 'constants/roles';

import { accessOrDefaultRedirect } from 'utils/accessOrDefaultRedirect';

const executionRoutes: RoutesConfig = [
  {
    path: ROUTES.process.execution._(),
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.ALL)(ProcessManagement)
    ),
    exact: true,
  },
  {
    path: ROUTES.process.execution.details.printRender._(),
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.ALL)(PrintRenderDetails)
    ),
    exact: false,
  },
  {
    path: ROUTES.process.execution.details.status._(),
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.ALL)(ProcessExecutionStatus)
    ),
    exact: false,
  },
  {
    path: ROUTES.process.execution.details._(),
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.ALL)(ProcessManagementDetails)
    ),
    exact: false,
  },
  {
    path: ROUTES.process.scanningScenarioOld(),
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.ALL)(
        ProcessManagementScanningScenarioOld
      )
    ),
    exact: false,
  },
  {
    path: ROUTES.process.scanningScenario(),
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.ALL)(
        ProcessManagementScanningScenario
      )
    ),
    exact: false,
  },
];

export { executionRoutes };
