import {
  WithFunctionId,
  WithReleaseOrDraftProcessId,
} from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export interface CreateFunctionParams {
  name: string;
}

export interface UpdateFunctionParams extends CreateFunctionParams {
  schema: string;
}

export interface ReusableFunctionGeneral extends BaseEntity {}

export interface ReusableFunctionDetails extends ReusableFunctionGeneral {
  schema: string;
}

export type GetListParams = WithReleaseOrDraftProcessId;
export type GetListResult = ReusableFunctionGeneral[];
export type GetDetailsParams = WithReleaseOrDraftProcessId & WithFunctionId;
export type GetDetailsResult = ReusableFunctionDetails;
export type CreateParams = WithReleaseOrDraftProcessId & CreateFunctionParams;
export type CreateResult = ReusableFunctionDetails;
export type UpdateParams = WithReleaseOrDraftProcessId &
  WithFunctionId &
  UpdateFunctionParams;
export type UpdateResult = ReusableFunctionDetails;
export type DeleteParams = WithReleaseOrDraftProcessId & WithFunctionId;
export type DeleteResult = unknown;

const Function = {
  getList: apiReqSagaCreator<GetListParams, GetListResult>(
    API_REQ_ACTION_TYPES.FUNCTIONS.GET_LIST,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.functions._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
      }),
    })
  ),
  getDetails: apiReqSagaCreator<GetDetailsParams, GetDetailsResult>(
    API_REQ_ACTION_TYPES.FUNCTIONS.GET_DETAILS,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.functions.byId._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.functionId]: params[ApiUrlParams.functionId],
      }),
    })
  ),
  create: apiReqSagaCreator<CreateParams, CreateResult>(
    API_REQ_ACTION_TYPES.FUNCTIONS.CREATE,
    (args) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.functions._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
      }),
      data: {
        name: args.name,
        schema: '',
      },
    })
  ),
  update: apiReqSagaCreator<UpdateParams, UpdateResult>(
    API_REQ_ACTION_TYPES.FUNCTIONS.UPDATE,
    (args) => ({
      method: HTTP_METHODS.PUT,
      url: ApiPaths.processes.byId.functions.byId._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.functionId]: args[ApiUrlParams.functionId],
      }),
      data: {
        name: args?.name,
        schema: args?.schema,
      },
    })
  ),
  delete: apiReqSagaCreator<DeleteParams, DeleteResult>(
    API_REQ_ACTION_TYPES.FUNCTIONS.DELETE,
    (params) => ({
      method: HTTP_METHODS.DELETE,
      url: ApiPaths.processes.byId.functions.byId._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.functionId]: params[ApiUrlParams.functionId],
      }),
    })
  ),
};

export default Function;
