import { combineReducers } from 'redux';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import {
  EmailTemplateGeneral,
  GetListResult,
  IsUsedInfo,
} from 'api/endpoints/emailTemplates';

import { PRINT_RENDER_EMAIL_TEMPLATES_ACTION_TYPES } from './actions';

export interface EmailTemplatesState {
  list: Nullable<EmailTemplateGeneral[]>;
  total: Nullable<number>;
  createScenarioList: Nullable<EmailTemplateGeneral[]>;
  isUsedInfo: Nullable<IsUsedInfo & { emailTemplateId: string }>;
}

const emailTemplates = combineReducers<EmailTemplatesState>({
  list(
    state = null,
    { type, payload }: { type: string; payload: GetListResult }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.PRINT_RENDER.EMAIL_TEMPLATES.GET_LIST.SUCCESS:
        return payload.data ?? null;
      default:
        return state;
    }
  },
  total(
    state = null,
    { type, payload }: { type: string; payload: GetListResult }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.PRINT_RENDER.EMAIL_TEMPLATES.GET_LIST.SUCCESS:
        return payload.total ?? null;
      default:
        return state;
    }
  },
  createScenarioList(
    state = null,
    {
      type,
      payload,
      meta,
    }: {
      type: string;
      payload: GetListResult;
      meta: { concat?: boolean };
    }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.PRINT_RENDER.EMAIL_TEMPLATES.GET_LIST.SUCCESS:
        if (payload.data) {
          return meta.concat
            ? [...(state || []), ...payload.data]
            : payload.data;
        }
        return null;
      default:
        return state;
    }
  },
  isUsedInfo(
    state = null,
    {
      type,
      payload,
      meta,
    }: {
      type: string;
      payload: IsUsedInfo;
      meta: { input: { emailTemplateId: string } };
    }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.PRINT_RENDER.EMAIL_TEMPLATES.GET_IS_USED
        .SUCCESS:
        return payload
          ? { ...payload, emailTemplateId: meta?.input?.emailTemplateId }
          : null;

      case API_REQ_ACTION_TYPES.PRINT_RENDER.EMAIL_TEMPLATES.DELETE.SUCCESS:
      case PRINT_RENDER_EMAIL_TEMPLATES_ACTION_TYPES.CLEAR_IS_USED_INFO:
        return null;

      default:
        return state;
    }
  },
});

export default emailTemplates;
