import { combineReducers } from 'redux';

import { API_ACTION_TYPES } from './actions';
import { authCache } from './cache';

export interface ApiState {
  exchangePending: boolean;
  cachedAuthTokenVerified: boolean;
  signedIn: boolean;
  xhrPending: number;
  pendingActions: KeyValuePairs<boolean>;
}

const api = combineReducers<ApiState>({
  cachedAuthTokenVerified(
    state = false,
    { type, payload }: { type: string; payload: boolean }
  ) {
    switch (type) {
      case API_ACTION_TYPES.AUTH.CACHED_TOKEN_VERIFIED:
        return payload;
      default:
        return state;
    }
  },
  exchangePending(state = false, { type }) {
    switch (type) {
      case API_ACTION_TYPES.EXCHANGE.START:
        return true;
      case API_ACTION_TYPES.EXCHANGE.FINISH:
        return false;
      default:
        return state;
    }
  },
  signedIn(state = Boolean(authCache.authToken), { type }) {
    switch (type) {
      case API_ACTION_TYPES.SIGNED_IN:
        return true;
      case API_ACTION_TYPES.SIGNED_OUT:
        return false;
      default:
        return state;
    }
  },
  xhrPending(state = 0, { type }) {
    switch (type) {
      case API_ACTION_TYPES.XHR.START:
        return state + 1;
      case API_ACTION_TYPES.XHR.SUCCESS:
      case API_ACTION_TYPES.XHR.ERROR:
        return state - 1;
      default:
        return state;
    }
  },
  pendingActions(state = {}, { type, meta }) {
    switch (type) {
      case API_ACTION_TYPES.XHR.START:
        return {
          ...state,
          [meta.title]: true,
        };
      case API_ACTION_TYPES.XHR.SUCCESS:
      case API_ACTION_TYPES.XHR.ERROR:
        // eslint-disable-next-line no-case-declarations
        const { [meta.title]: removed, ...filteredState } = state;
        return {
          ...filteredState,
        };
      default:
        return state;
    }
  },
});

export default api;
