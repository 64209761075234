import { useCallback } from 'react';

import { TOASTER_TYPE } from '../constants/toasterMessages';
import { ApplicationError } from '../types/errorHandling';
import useToaster from './useToaster';

/**
 * @returns A function that takes an ApplicationError and displays it to the user.
 * If the error has showToDeveloper set to true, it will also log the error to
 * the console.
 */
export function useApplicationErrorHandling() {
  const { addToaster } = useToaster();

  return useCallback(
    (error: ApplicationError) => {
      addToaster({
        message: error.userMessage,
        type: TOASTER_TYPE.ERROR,
      });

      if (error.showToDeveloper) {
        error.print();
      }
    },
    [addToaster]
  );
}

/**
 * @param wrappedFunc - A no-args function to be wrapped. It can be synchronous or
 * asynchronous. Return values will be ignored.
 * @returns A no-args async function that wraps the handler and catches any errors
 * that occur. It always returns a resolved promise.
 */
export const useWrapApplicationErrorHandling = <
  WrappedFunc extends (...args: any) => any,
>(
  wrappedFunc: WrappedFunc
) => {
  const handleApplicationError = useApplicationErrorHandling();

  return (...args: Parameters<WrappedFunc>) =>
    (async () => await wrappedFunc(...args))().catch((reason) => {
      const appError = ApplicationError.FromError(reason, 'An error occurred');
      handleApplicationError(appError);
      return Promise.resolve();
    });
};

type Response<T> = SuccessResponse<T> | ErrorResponse;
type SuccessResponse<T> = { ok: true; value: T; error: undefined };
type ErrorResponse = { ok: false; value: undefined; error: ApplicationError };

/**
 * @returns An async function that takes a no-args function. It will call the
 * function and return a Response object. If the function succeeds, the Response
 * will have `ok` set to `true` and `value` set to the return value of the function.
 * If the function fails, the Response will have `ok` set to `false` and `error`
 * set to an ApplicationError.
 */
export const useAsyncCallHandling =
  () =>
  <T>(call: AsyncEmptyArgsFunc<T>): Promise<Response<T>> =>
    (async () => await call())()
      .then(
        (response): SuccessResponse<T> => ({
          ok: true,
          value: response,
          error: undefined,
        })
      )
      .catch((reason): ErrorResponse => {
        const appError = ApplicationError.FromError(
          reason,
          'An error occurred'
        );
        return {
          ok: false,
          value: undefined,
          error: appError,
        };
      });
