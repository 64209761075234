import { Fields as FtpFields } from 'components/organisms/form/createFtpConnection';

import { TenantGeneral } from 'api/endpoints/tenants';
import { WithFTPConnectionId } from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { excludeObjectProperty } from 'utils/normalizers/object';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export interface FtpConnectionGeneral extends BaseEntity {
  isValid: boolean;
  host?: string;
  port?: number;
  username?: string;
}

export interface FtpConnectionDetails extends FtpConnectionGeneral {}

export type ValidateFtpCredentials = FtpFields;
export type GetFtpConnectionsParams = Pagination | unknown;
export type GetFtpConnectionsResult = DataWithTotal<FtpConnectionGeneral>;
export type AddFtpConnectionParams = ValidateFtpCredentials;
export type AddFtpConnectionResult = unknown;
export type UpdateFtpConnectionParams = ValidateFtpCredentials &
  WithFTPConnectionId;
export type UpdateFtpConnectionResult = TenantGeneral;
export type DeleteFtpConnectionParams = WithFTPConnectionId;
export type DeleteFtpConnectionResult = unknown;
export type GetFtpConnectionDetailsParams = WithFTPConnectionId;
export type GetFtpConnectionDetailsResult = FtpConnectionDetails;
export type ValidateFtpCredentialsParams = ValidateFtpCredentials;

export interface ValidateFtpCredentialsResult {
  isValid: boolean;
}

const FtpConnections = {
  getConnections: apiReqSagaCreator<
    GetFtpConnectionsParams,
    GetFtpConnectionsResult
  >(
    API_REQ_ACTION_TYPES.FTP_CONNECTIONS.GET_CONNECTIONS,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.ftpConnections._(),
      params,
    }),
    {
      withPrevPageOnLessTotal: true,
    }
  ),
  addConnection: apiReqSagaCreator<
    AddFtpConnectionParams,
    AddFtpConnectionResult
  >(API_REQ_ACTION_TYPES.FTP_CONNECTIONS.ADD_CONNECTION, (data) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.ftpConnections._(),
    data,
  })),
  updateConnection: apiReqSagaCreator<
    UpdateFtpConnectionParams,
    UpdateFtpConnectionResult
  >(API_REQ_ACTION_TYPES.FTP_CONNECTIONS.UPDATE_CONNECTION, (args) => ({
    method: HTTP_METHODS.PUT,
    url: ApiPaths.ftpConnections.byId._({
      [ApiUrlParams.ftpConnectionId]: args[ApiUrlParams.ftpConnectionId],
    }),
    data: excludeObjectProperty(args, ApiUrlParams.ftpConnectionId),
  })),
  deleteConnection: apiReqSagaCreator<
    DeleteFtpConnectionParams,
    DeleteFtpConnectionResult
  >(API_REQ_ACTION_TYPES.FTP_CONNECTIONS.DELETE_CONNECTION, (params) => ({
    method: HTTP_METHODS.DELETE,
    url: ApiPaths.ftpConnections.byId._({
      [ApiUrlParams.ftpConnectionId]: params[ApiUrlParams.ftpConnectionId],
    }),
  })),
  getConnectionDetails: apiReqSagaCreator<
    GetFtpConnectionDetailsParams,
    GetFtpConnectionDetailsResult
  >(API_REQ_ACTION_TYPES.FTP_CONNECTIONS.GET_CONNECTION_DETAILS, (params) => ({
    method: HTTP_METHODS.GET,
    url: ApiPaths.ftpConnections.byId._({
      [ApiUrlParams.ftpConnectionId]: params[ApiUrlParams.ftpConnectionId],
    }),
  })),
  validateCredentials: apiReqSagaCreator<
    ValidateFtpCredentialsParams,
    ValidateFtpCredentialsResult
  >(API_REQ_ACTION_TYPES.FTP_CONNECTIONS.VALIDATE_CREDENTIALS, (data) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.ftpConnections.validateCredentials._(),
    data,
  })),
};

export default FtpConnections;
