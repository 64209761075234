import ROUTES, { ExecutionPrintRenderUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { match, matchPath } from 'react-router-dom';
import { put, select } from 'redux-saga/effects';

import { NO_ACTIVE_PRINT_RENDER_SCENARIO } from 'constants/errorCodes';
import { TOASTER_TYPE } from 'constants/toasterMessages';

import createATA from 'utils/createAddToasterAction';
import { string } from 'utils/formatters';

const FILE_SIZE_EXCEEDED = 'Please use FTP service for files >20mb.';
const WRONG_FILE_FORMAT = 'Wrong file format.';
const NO_ACTIVE_SCENARIO =
  'Please, activate Print Render scenario to upload files.';

function* fileUploadError({
  payload,
}: {
  type: string;
  payload: BackendError[];
}) {
  const location: Location = yield select(getLocation);

  const mathParams: match<ExecutionPrintRenderUiParams> = yield matchPath(
    location?.pathname,
    {
      path: [ROUTES.process.execution.details.printRender._()],
    }
  );

  if (payload.some((e) => e.code === NO_ACTIVE_PRINT_RENDER_SCENARIO)) {
    yield put(
      createATA({
        message: NO_ACTIVE_SCENARIO,
        type: TOASTER_TYPE.ERROR,
        action: {
          label: 'Activate scenario',
          moveTo: string.withDefaultPagination(
            ROUTES.process.definition.services.printRender._(mathParams?.params)
          ),
        },
      })
    );
  }

  if (payload.some((e) => e.code === 4222)) {
    yield put(
      createATA({
        message: FILE_SIZE_EXCEEDED,
        type: TOASTER_TYPE.ERROR,
      })
    );
  }

  if (payload.some((e) => e.code === 4082 || e.code === 4053)) {
    yield put(
      createATA({
        message: WRONG_FILE_FORMAT,
        type: TOASTER_TYPE.ERROR,
      })
    );
  }
}

export default fileUploadError;
