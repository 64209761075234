import React, { memo, PropsWithChildren } from 'react';

import DefaultMenu from 'assets/icon/hamburger_menu_default.svg';
import OpenMenu from 'assets/icon/hamburger_menu_open.svg';

import { TEXTS } from './config';
import sm from './styles.module.scss';
import useHamburgerMenu from './useHamburgerMenu';

export interface Props {
  isMobile: boolean;
}

export const HamburgerMenu = ({
  isMobile,
  children,
}: PropsWithChildren<Props>) => {
  const { openHamburgerHandler, wrapperVisible, isOpen } =
    useHamburgerMenu(isMobile);

  return (
    <>
      <div
        tabIndex={0}
        role="button"
        onKeyDown={openHamburgerHandler}
        onClick={openHamburgerHandler}
      >
        <img
          className={sm.Hamburger_Icon}
          src={isOpen ? OpenMenu : DefaultMenu}
          alt={TEXTS.ALT.HAMBURGER_MENU}
        />
      </div>
      <div style={{ display: wrapperVisible }} className={sm.Hamburger_Wrapper}>
        {children}
      </div>
    </>
  );
};

export default memo(HamburgerMenu);
