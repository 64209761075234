import { translateAxiosErrorToApiError } from 'container/models';

import { AbstractUserRepo } from './AbstractUserRepo';

export class UserRepo extends AbstractUserRepo {
  resetPassword(username: string) {
    return this.client
      .post<void>(`/auth/forgot-password`, { username })
      .then((res) => res.data)
      .catch(translateAxiosErrorToApiError);
  }
}
