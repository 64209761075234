import { fork, put } from 'redux-saga/effects';

import createATA from 'utils/createAddToasterAction';

import reFetchAllFields from './reFetchAllFields';

const SUCCESS_MSG = 'The data model field was successfully deleted';

function* afterSuccessDataModelFieldDelete() {
  yield put(
    createATA({
      message: SUCCESS_MSG,
    })
  );

  yield fork(reFetchAllFields);
}

export default afterSuccessDataModelFieldDelete;
