import { RootState } from 'app/redux/createRootReducer';
import { Login } from 'app/routes/lazyPages';
import { ComponentClass, ComponentType } from 'react';
import { connect } from 'react-redux';
import { branch, compose, renderComponent } from 'recompose';

import ApiSelectors from 'api/selectors';

/*
  We have to do this because Recompose doesn't support React 17.
*/
interface ComponentEnhancer<TInner, TOutter> {
  (component: ComponentType<TInner>): ComponentClass<TOutter>;
}

const onlySignedInPage = compose<any, {}>(
  connect((state: RootState) => ({
    isSignedIn: ApiSelectors.isSignedIn(state),
  })),
  branch<{ isSignedIn: boolean }>(
    ({ isSignedIn }) => !isSignedIn,
    renderComponent(Login)
  )
) as ComponentEnhancer<any, {}>;

export default onlySignedInPage;
