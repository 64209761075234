import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import {
  createUpdateFailure,
  handleFunctionCreate,
  reFetchList,
  updateFunctionSuccess,
  updateCreateFunctionSuccess,
} from './sagas';

function* watchersSaga() {
  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.FUNCTIONS.CREATE.SUCCESS,
      API_REQ_ACTION_TYPES.FUNCTIONS.UPDATE.SUCCESS,
      API_REQ_ACTION_TYPES.FUNCTIONS.DELETE.SUCCESS,
    ],
    reFetchList
  );

  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.FUNCTIONS.CREATE.ERROR,
      API_REQ_ACTION_TYPES.FUNCTIONS.UPDATE.ERROR,
    ],
    createUpdateFailure
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.FUNCTIONS.UPDATE.SUCCESS],
    updateFunctionSuccess
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.FUNCTIONS.CREATE.SUCCESS],
    handleFunctionCreate
  );

  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.FUNCTIONS.CREATE.SUCCESS,
      API_REQ_ACTION_TYPES.FUNCTIONS.UPDATE.SUCCESS,
    ],
    updateCreateFunctionSuccess
  );
}
export default watchersSaga;
