import { PERMISSION } from 'components/organisms/form/manageUser/config';

import { TenantRole } from 'api/endpoints/tenants';
import { SystemUserPermissions } from 'api/endpoints/users';

import { TENANT_ROLE, USER_ROLE } from 'constants/roles';

import subSelectorCreator from 'utils/subSelectorCreator';

import { UsersState } from './reducer';

const createUsersSelector = subSelectorCreator<UsersState>(
  (state) => state.users
);

const adminChecker = (state: UsersState) =>
  state.info?.role === USER_ROLE.ADMIN;

const getCurrentTenantId = (state: UsersState) =>
  state.info?.profile.currentTenant.id;

const getTenantObject = (state: UsersState) =>
  (state.info?.tenants || []).find(
    (e) => e.tenant.id === getCurrentTenantId(state)
  );

const getUserPermissions = (isUser: boolean) =>
  Object.keys(PERMISSION).reduce(
    (acc, next) => ({
      ...acc,
      [next]: !isUser,
    }),
    {}
  );

export interface UserTenantRolesMap {
  [tenantId: string]: TenantRole;
}

const UsersSelectors = {
  getEmail: createUsersSelector(({ info }) => info?.email),
  getList: createUsersSelector(({ list }) => list),
  getTotal: createUsersSelector(({ total }) => total),
  getUserDetails: createUsersSelector(({ userDetails }) => userDetails),
  getOrgName: createUsersSelector(({ info }) => info?.organization.name),
  getOrgReplyTo: createUsersSelector(({ info }) => info?.organization.replyTo),
  getIsUserAdmin: createUsersSelector(adminChecker),
  getCurrentTenantId: createUsersSelector(getCurrentTenantId),
  getLinks: createUsersSelector(({ info }) => info?.links),
  getCurrentTenantRole: createUsersSelector((state) => {
    if (adminChecker(state)) {
      return USER_ROLE.ADMIN;
    }
    return getTenantObject(state)?.userRole || '';
  }),
  getUserRoleInTenant: createUsersSelector(
    ({ info }) =>
      (id: string) =>
        (info?.tenants || []).find((e) => e.tenant.id === id)?.userRole
  ),
  getIsUserTenantUser: createUsersSelector(function cb(state) {
    return (
      !adminChecker(state) &&
      getTenantObject(state)?.userRole === TENANT_ROLE.USER
    );
  }),
  getIsUserTenantManager: createUsersSelector(function cb(state) {
    return (
      !adminChecker(state) &&
      getTenantObject(state)?.userRole === TENANT_ROLE.MANAGER
    );
  }),
  getIsUserTenantAdmin: createUsersSelector(function cb(state) {
    return (
      !adminChecker(state) &&
      getTenantObject(state)?.userRole === TENANT_ROLE.ADMIN
    );
  }),
  getUserPermissions: createUsersSelector(
    ({ info }) => info?.organization.features
  ),
  getUserInfo: createUsersSelector(({ info }) => info),
  getUserCurrentTenant: createUsersSelector(
    ({ info }) => info?.profile.currentTenant
  ),
  getUserTenants: createUsersSelector(({ info }) => info?.tenants || []),
  getUserTenantRoles: createUsersSelector(
    ({ info }) =>
      (info?.tenants || []).reduce(
        (acc, next) => ({
          ...acc,
          [next.tenant.id]: next.userRole,
        }),
        {}
      ) as UserTenantRolesMap
  ),
  getUserPermissionsForCurrentTenant: createUsersSelector(
    (state) =>
      (!adminChecker(state) &&
      getTenantObject(state)?.userRole === TENANT_ROLE.USER
        ? getTenantObject(state)?.permissions || getUserPermissions(true)
        : getUserPermissions(false)) as SystemUserPermissions
  ),
};

export default UsersSelectors;
