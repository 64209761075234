import whyDidYouRender from '@welldone-software/why-did-you-render';
import React from 'react';

import { ENABLE_WYDR } from 'utils/localFlags';

if (ENABLE_WYDR.value) {
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}
ENABLE_WYDR.addEventListener('toggled', () => {
  window.location.reload();
});
