import { stopAsyncValidation, touch } from 'redux-form';
import { put } from 'redux-saga/effects';

import { ERROR_KEYS } from 'constants/fieldErrors';
import { FORM_NAMES } from 'constants/form';
import { TOASTER_TYPE } from 'constants/toasterMessages';

import createATA from 'utils/createAddToasterAction';

function* saveGroupFailure({ payload }: ReduxAction<BackendError[]>) {
  if (payload.some((e) => e.field === 'name' && e.description === 'required')) {
    yield put(
      createATA({
        type: TOASTER_TYPE.ERROR,
        message: 'Group name is required',
      })
    );

    yield put(
      stopAsyncValidation(FORM_NAMES.SECURITY_GROUP_CONFIG, {
        name: {
          key: ERROR_KEYS.IS_REQUIRED,
        },
      })
    );

    yield put(touch(FORM_NAMES.SECURITY_GROUP_CONFIG, 'name'));
  }
}

export default saveGroupFailure;
