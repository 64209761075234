import { getLocation, replace } from 'connected-react-router';
import { put, select } from 'redux-saga/effects';

import * as ERROR_CODES from 'constants/errorCodes';

import { string } from 'utils/formatters';

function* getInstancesListError({
  payload,
}: {
  type: string;
  payload: BackendError[];
}) {
  const { pathname } = yield select(getLocation);
  if (payload.some((e) => e.code === ERROR_CODES.WRONG_ALIAS_ID)) {
    yield put(replace(string.withDefaultPagination(pathname)));
  }
}

export default getInstancesListError;
