const ROUTES_HISTORY = 'routes_history';

const routesHistory = {
  setRoutes(routes: LocalHistoryRoute[]) {
    return window.localStorage.setItem(ROUTES_HISTORY, JSON.stringify(routes));
  },

  getRoutes() {
    return window.localStorage.getItem(ROUTES_HISTORY);
  },
};

export default routesHistory;
