const WRONG_PROCESS_ID = 4004;
const WRONG_SEARCH_FIELD_ID = 4021;
const ORGANIZATION_LIMIT = 4023;
const WRONG_USERNAME = 4025;
const WRONG_REGISTRATION_LINK = 4029;
const WONG_UUID_LENGTH = 4030;
const WRONG_RESTORE_PASSWORD_LINK = 4032;
const ALREADY_USED_FIELD_FOR_OCR = 4037;
const ALREADY_USED_CONFIDENCE_FIELD_FOR_OCR = 4038;
const ALREADY_USED_INDEX = 4041;
const MISSED_FORM_FIELD = 4043;
const INDEX_FORM_FIELD_IS_REQUIRED = 4044;
const OCR_IN_PROGRESS = 4052;
const WRONG_ALIAS_ID = 4054;
const PROCESS_STAGES_NOT_FOUND = 4061;
const INVALID_PROCESS_XML_SCHEMA = 4063;
const LABEL_PARAM_IS_EMPTY = 4071;
const DUPLICATE_FUNCTION_NAME = 4080;
const REQUIRED_TEXT_INSTANCE_FIELD = 4083;
const UNSUPPORTED_VERSION = 4090;
const FILE_FOR_IMPORTING_IS_CORUPPTED = 4091;
const OBJECT_SCHEMA_IN_USED = 4100;
const EMPTY_SOURCE_DOCUMENT_FOR_SPLIT = 4110;
const LESS_PAGES_FOR_SPLIT = 4113;
const MORE_PAGES_FOR_SPLIT = 4114;
const REJECT_REMOVE_ROOT_SPLIT_DOCUMENT = 4111;
const REJECT_REMOVE_SPLIT_DOCUMENT_WITH_ALL_PAGES = 4112;
const NO_ACTIVE_PRINT_RENDER_SCENARIO = 4115;
const NOT_UNIQUE_SECURITY_GROUP_NAME = 4130;
const USER_NOT_VALID_FOR_ADDING_TO_THE_SECURITY_GROUP = 4131;
const NOT_UNIQUE_TENANT_NAME = 4136;
const DELETING_USING_CONNECTION = 4137;
const NOT_UNIQUE_CONNECTION_NAME = 4138;
const CONNECTION_IN_USE = 4150;
const NOT_UNIQUE_FTP_CONNECTION_NAME = 4151;
const REJECT_REMOVE_INSTANCE_WITH_ALL_PAGES = 4152;
const FILE_WAS_NOT_UPDATED = 4160;
const BASE_CLIENT_ERROR = 4222;
const BASE_SERVER_ERROR = 5000;

export {
  WRONG_ALIAS_ID,
  ALREADY_USED_FIELD_FOR_OCR,
  WRONG_SEARCH_FIELD_ID,
  WRONG_PROCESS_ID,
  WONG_UUID_LENGTH,
  WRONG_USERNAME,
  WRONG_REGISTRATION_LINK,
  WRONG_RESTORE_PASSWORD_LINK,
  BASE_CLIENT_ERROR,
  OCR_IN_PROGRESS,
  PROCESS_STAGES_NOT_FOUND,
  INVALID_PROCESS_XML_SCHEMA,
  LABEL_PARAM_IS_EMPTY,
  OBJECT_SCHEMA_IN_USED,
  ALREADY_USED_CONFIDENCE_FIELD_FOR_OCR,
  DUPLICATE_FUNCTION_NAME,
  ALREADY_USED_INDEX,
  UNSUPPORTED_VERSION,
  REQUIRED_TEXT_INSTANCE_FIELD,
  MISSED_FORM_FIELD,
  ORGANIZATION_LIMIT,
  REJECT_REMOVE_ROOT_SPLIT_DOCUMENT,
  REJECT_REMOVE_SPLIT_DOCUMENT_WITH_ALL_PAGES,
  EMPTY_SOURCE_DOCUMENT_FOR_SPLIT,
  LESS_PAGES_FOR_SPLIT,
  MORE_PAGES_FOR_SPLIT,
  NO_ACTIVE_PRINT_RENDER_SCENARIO,
  NOT_UNIQUE_SECURITY_GROUP_NAME,
  USER_NOT_VALID_FOR_ADDING_TO_THE_SECURITY_GROUP,
  FILE_FOR_IMPORTING_IS_CORUPPTED,
  NOT_UNIQUE_TENANT_NAME,
  DELETING_USING_CONNECTION,
  NOT_UNIQUE_CONNECTION_NAME,
  INDEX_FORM_FIELD_IS_REQUIRED,
  REJECT_REMOVE_INSTANCE_WITH_ALL_PAGES,
  NOT_UNIQUE_FTP_CONNECTION_NAME,
  FILE_WAS_NOT_UPDATED,
  BASE_SERVER_ERROR,
  CONNECTION_IN_USE,
};
