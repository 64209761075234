import { FormFields } from 'components/organisms/form/advancedSearchConfigure/types';

export const SEPARATOR = '"';

export const convertAdvancedSearchToUrlParams = (searchData: FormFields) => {
  const queryString = new URLSearchParams();

  if (searchData.operator) queryString.append('operator', searchData.operator);

  Object.values(searchData.fields)
    .filter(Boolean)
    .map(
      (e) =>
        `${e.field.value}${SEPARATOR}${e.function.value}${SEPARATOR}${
          e.function.type
        }${
          e.value !== null && typeof e.value !== 'undefined'
            ? `${SEPARATOR}${encodeURIComponent(e.value)}`
            : ''
        }`
    )
    .forEach((e) => queryString.append('advanced', e));

  return queryString;
};
