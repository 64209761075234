import { getLocation } from 'connected-react-router';
import { fork, select } from 'redux-saga/effects';

import Processes from 'api/endpoints/processes';

import { SystemStatus } from 'constants/global';

function* reFetchList() {
  const { query } = yield select(getLocation);
  yield fork(Processes.getAll, { ...query, state: SystemStatus.Draft });
}

export default reFetchList;
