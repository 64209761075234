import { createContext, FC, useContext } from 'react';

import { AbstractServiceFactory } from './abstractServiceFactory';
import { AbstractBareClientRepo } from './services/bareClient/abstractBareClientRepo';
import { AbstractFreshdeskTokenRepo } from './services/freshdeskToken/abstractFreshdeskTokenRepo';
import { AbstractIndexingDocumentsRepo } from './services/indexingDocuments/abstractIndexingDocumentsRepo';
import { AbstractIndexingSourcesRepo } from './services/indexingSources/abstractIndexingSourcesRepo';
import { AbstractManagementEmailRepo } from './services/managementEmail/AbstractManagementEmailRepo';
import { AbstractManagementLogsRepo } from './services/managementLogs/AbstractManagementLogsRepo';
import { AbstractPrintRenderLogRepo } from './services/printRenderLogs/abstractPrintRenderRepo';
import { AbstractProcessRepo } from './services/process/AbstractProcessRepo';
import { AbstractProcessInstanceRepo } from './services/ProcessInstance/AbstractProcessInstanceRepo';
import { AbstractScanRepo } from './services/Scan/abstractScanRepo';
import { AbstractScanScenarioRepo } from './services/ScanScenario/AbstractScanScenarioRepo';
import { AbstractSystemLogsRepo } from './services/systemLogs/abstractSystemLogsRepo';
import { AbstractUserRepo } from './services/users/AbstractUserRepo';

const defaultFactory: AbstractServiceFactory = {
  getScanRepo(): AbstractScanRepo {
    return {} as AbstractScanRepo;
  },
  getPrintRenderRepo(): AbstractPrintRenderLogRepo {
    return {} as AbstractPrintRenderLogRepo;
  },
  getIndexingSourceRepo(): AbstractIndexingSourcesRepo {
    return {} as AbstractIndexingSourcesRepo;
  },
  getIndexingDocumentsRepo(): AbstractIndexingDocumentsRepo {
    return {} as AbstractIndexingDocumentsRepo;
  },
  getProcessInstanceRepo(): AbstractProcessInstanceRepo {
    return {} as AbstractProcessInstanceRepo;
  },
  getScanScenarioRepo(): AbstractScanScenarioRepo {
    return {} as AbstractScanScenarioRepo;
  },
  getFreshdeskTokenRepo(): AbstractFreshdeskTokenRepo {
    return {} as AbstractFreshdeskTokenRepo;
  },
  getSystemLogsRepo(): AbstractSystemLogsRepo {
    return {} as AbstractSystemLogsRepo;
  },
  getManagementLogsRepo(): AbstractManagementLogsRepo {
    return {} as AbstractManagementLogsRepo;
  },
  getUserRepo(): AbstractUserRepo {
    return {} as AbstractUserRepo;
  },
  getManagementEmailRepo(): AbstractManagementEmailRepo {
    return {} as AbstractManagementEmailRepo;
  },
  getProcessRepo(): AbstractProcessRepo {
    return {} as AbstractProcessRepo;
  },
  getBareClientRepo(): AbstractBareClientRepo {
    return {} as AbstractBareClientRepo;
  },
};

const DepsContext = createContext<AbstractServiceFactory>(defaultFactory);

interface serviceProps {
  serviceFactory: AbstractServiceFactory;
  children?: JSX.Element;
}

export function useAppServices(): AbstractServiceFactory {
  return useContext(DepsContext);
}

export const DepsProvider: FC<serviceProps> = ({
  children,
  serviceFactory,
}) => (
  <DepsContext.Provider value={serviceFactory}>{children}</DepsContext.Provider>
);
