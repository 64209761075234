import { destroy, setSubmitSucceeded } from 'redux-form';
import { put } from 'redux-saga/effects';

import { FORM_NAMES } from 'constants/form';

import createATA from 'utils/createAddToasterAction';

const SUCCESS_TOASTER_MESSAGE = 'The template has been saved';

function* successSaveTemplate() {
  yield put(setSubmitSucceeded(FORM_NAMES.SAVE_GRID_TEMPLATE));

  yield put(destroy(FORM_NAMES.SAVE_GRID_TEMPLATE));

  yield put(
    createATA({
      message: SUCCESS_TOASTER_MESSAGE,
    })
  );
}

export default successSaveTemplate;
