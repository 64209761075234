import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import Session from 'api/endpoints/session';

function* watcher() {
  yield takeLatest(
    API_REQ_ACTION_TYPES.REPLY_TO.CHANGE_REPLY_TO.SUCCESS,
    Session.info,
    undefined
  );
}

export default watcher;
