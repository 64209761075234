import { MAX_HISTORY_LENGTH } from 'constants/global';

import routesHistory from '../routesHistory';

function updateRoutes({
  payload,
}: {
  type: string;
  payload: LocalHistoryRoute;
}) {
  const routes = routesHistory.getRoutes();

  if (!routes || (routes && !JSON.parse(routes).length)) {
    return routesHistory.setRoutes([payload]);
  }

  const parsedRoutes = JSON.parse(routes);

  const [{ uri: lastUri, query: lasTQuery }] = parsedRoutes;

  if (lastUri === payload.uri && lasTQuery === payload.query) {
    return null;
  }

  return routesHistory.setRoutes(
    [payload, ...parsedRoutes].splice(0, MAX_HISTORY_LENGTH)
  );
}

export default updateRoutes;
