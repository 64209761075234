import { FieldGeneral } from 'api/endpoints/processFields';

const getSumByProperty = <T = KeyValuePairs<number | string>>(
  list: T[],
  accessor: string
) =>
  list.reduce(
    (acc, next) =>
      acc +
      (next[accessor as keyof T] &&
      !isNaN(next[accessor as keyof T] as unknown as number)
        ? Number(next[accessor as keyof T])
        : 0),
    0
  );

const getFieldsByType = (
  type: string,
  fields: FieldGeneral[],
  bySubType = false
) =>
  fields.reduce(
    (acc, next) => [
      ...acc,
      ...(type === (bySubType ? next.metadata?.maskType : next.type)
        ? [next]
        : []),
      ...(next.collection
        ? next.collection.filter(
            (e) => type === (bySubType ? e.metadata?.maskType : e.type)
          )
        : []),
    ],
    [] as FieldGeneral[]
  );

const getOptionItemsValues = (list: OptionItem[]) =>
  list.reduce(
    (acc: string[], next: OptionItem) => [
      ...acc,
      ...(next.name ? [next.name] : []),
    ],
    []
  );

export { getSumByProperty, getFieldsByType, getOptionItemsValues };
