import { TenantGeneral, TenantRole } from 'api/endpoints/tenants';
import { UserRole } from 'api/endpoints/users';
import { WithInvitationId } from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export interface InviteParams {
  email: string;
  tenantId?: string;
  role: string;
}

export interface InvitedUserGeneral
  extends Pick<BaseEntity, 'createdAt' | 'updatedAt'> {
  email: string;
  organizationId: string;
  role: UserRole | TenantRole;
  tenant: TenantGeneral;
}

export type ReInviteUserParams = WithInvitationId;
export type ReInviteUserResult = unknown;
export type RevokeUserInvitationParams = WithInvitationId;
export type RevokeUserInvitationResult = unknown;
export type GetInvitationsParams = Pagination;
export type GetInvitationsResult = DataWithTotal<InvitedUserGeneral>;
export type InviteResult = unknown;

const Invitations = {
  reInviteUser: apiReqSagaCreator<ReInviteUserParams, ReInviteUserResult>(
    API_REQ_ACTION_TYPES.INVITATIONS.RE_INVITE_USER,
    (params) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.invitations.byId.resend._({
        [ApiUrlParams.invitationId]: params[ApiUrlParams.invitationId],
      }),
    })
  ),
  revokeUserInvitation: apiReqSagaCreator<
    RevokeUserInvitationParams,
    RevokeUserInvitationResult
  >(API_REQ_ACTION_TYPES.INVITATIONS.REVOKE_USER_INVITATION, (params) => ({
    method: HTTP_METHODS.DELETE,
    url: ApiPaths.invitations.byId._({
      [ApiUrlParams.invitationId]: params[ApiUrlParams.invitationId],
    }),
  })),
  getInvitations: apiReqSagaCreator<GetInvitationsParams, GetInvitationsResult>(
    API_REQ_ACTION_TYPES.INVITATIONS.GET_INVITATIONS,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.invitations._(),
      params,
    }),
    {
      withPrevPageOnLessTotal: true,
    }
  ),
  invite: apiReqSagaCreator<InviteParams, InviteResult>(
    API_REQ_ACTION_TYPES.INVITATIONS.INVITE_USER,
    (data) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.invitations._(),
      data,
    })
  ),
};

export default Invitations;
