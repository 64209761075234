export type TypeGuard<T> = (o: any) => o is T;

export function parseJSON<T>(
  text: string,
  guard: TypeGuard<T>
): [T, ''] | [null, string] {
  try {
    const parsed = JSON.parse(text);
    return guard(parsed) ? [parsed, ''] : [null, 'cannot convert JSON to type'];
  } catch (error) {
    const errorMessage =
      error instanceof SyntaxError ? error.message : 'error parsing JSON';
    return [null, errorMessage];
  }
}

export function parseJSONArrayBuffer<T>(
  value: ArrayBuffer,
  guard: TypeGuard<T>
) {
  const text = new TextDecoder('utf-8').decode(value);
  return parseJSON<T>(text, guard);
}
