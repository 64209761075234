import { combineReducers } from 'redux';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import { RequestActionMeta } from 'api/apiReqSagaCreator';
import { TenantGeneral } from 'api/endpoints/tenants';

export interface TenantsState {
  list: Nullable<TenantGeneral[]>;
  total: Nullable<number>;
  allList: TenantGeneral[];
}

export const RECEIVE_ALL_TENANTS_ACTION = 'RECEIVE_ALL_TENANTS_ACTION';

const tenants = combineReducers<TenantsState>({
  list(
    state = null,
    {
      type,
      payload,
      meta,
    }: ReduxAction<
      DataWithTotal<TenantGeneral>,
      Pick<RequestActionMeta<DataWithTotal<TenantGeneral>>, 'title'>
    >
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.TENANTS.GET_TENANTS.SUCCESS:
        return meta.title !== RECEIVE_ALL_TENANTS_ACTION
          ? payload?.data || null
          : state;
      default:
        return state;
    }
  },
  total(
    state = null,
    { type, payload }: ReduxAction<DataWithTotal<TenantGeneral>>
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.TENANTS.GET_TENANTS.SUCCESS:
        return payload?.total || null;
      default:
        return state;
    }
  },
  allList(
    state = [],
    {
      type,
      payload,
      meta,
    }: ReduxAction<
      DataWithTotal<TenantGeneral>,
      Pick<RequestActionMeta<DataWithTotal<TenantGeneral>>, 'title'>
    >
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.TENANTS.GET_TENANTS.SUCCESS:
        return meta.title === RECEIVE_ALL_TENANTS_ACTION
          ? payload?.data || []
          : state;
      default:
        return state;
    }
  },
});

export default tenants;
