import { Organization, TenantGeneral, TenantRole } from 'api/endpoints/tenants';
import { WithTenantId, WithUserId } from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export interface UpdateUserDetails {
  data: Pick<
    SystemUserGeneral,
    'firstName' | 'lastName' | 'role' | 'phone' | 'email'
  >;
}

export interface UserTenant extends Pick<BaseEntity, 'id' | 'name'> {
  userRole: TenantRole | 'admin';
}

export type UserRole = 'admin' | 'user';

export interface UserInfoTenant {
  tenant: TenantGeneral;
  permissions: SystemUserPermissions;
  userRole: TenantRole;
}

export interface User extends BaseEntity, WithHypermedia {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  status: string;
  role: UserRole;
  username: string;
  organization: Organization;
  tenants?: UserInfoTenant[];
  profile: Pick<BaseEntity, 'createdAt' | 'updatedAt'> & {
    currentTenant: BaseEntity;
  };
}

export interface SystemUserGeneral extends Omit<BaseEntity, 'name'> {
  email: string;
  phone: string;
  username: string;
  firstName: string;
  lastName: string;
  status: string;
  role: UserRole;
}

export interface SystemUserPermissions {
  allowDeleteInstance: boolean;
  allowUpdateVariables: boolean;
}

export interface SystemUserDetails extends SystemUserGeneral, WithHypermedia {
  tenants?: UserInfoTenant[];
}

export type GetUsersParams = Pagination;
export type GetUsersResult = DataWithTotal<SystemUserGeneral>;
export type ActivateUserParams = WithUserId;
export type ActivateUserResult = unknown;
export type DeactivateUserParams = WithUserId;
export type DeactivateUserResult = unknown;
export type GetUserDetailsParams = WithUserId;
export type GetUserDetailsResult = SystemUserDetails;
export type UpdateUserDetailsParams = UpdateUserDetails & WithUserId;
export type UpdateUserDetailsResult = SystemUserDetails;
export type UpdateUserActiveTenantParams = WithTenantId;
export type UpdateUserActiveTenantResult = User;

const Users = {
  getUsers: apiReqSagaCreator<GetUsersParams, GetUsersResult>(
    API_REQ_ACTION_TYPES.USERS.GET_USERS,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.users._(),
      params,
    }),
    {
      withPrevPageOnLessTotal: true,
    }
  ),
  activateUser: apiReqSagaCreator<ActivateUserParams, ActivateUserResult>(
    API_REQ_ACTION_TYPES.USERS.ACTIVATE_USER,
    (params) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.users.byId.activate._({
        [ApiUrlParams.userId]: params[ApiUrlParams.userId],
      }),
    })
  ),
  deactivateUser: apiReqSagaCreator<DeactivateUserParams, DeactivateUserResult>(
    API_REQ_ACTION_TYPES.USERS.DEACTIVATE_USER,
    (params) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.users.byId.deactivate._({
        [ApiUrlParams.userId]: params[ApiUrlParams.userId],
      }),
    })
  ),
  getUserDetails: apiReqSagaCreator<GetUserDetailsParams, GetUserDetailsResult>(
    API_REQ_ACTION_TYPES.USERS.GET_USER_DETAILS,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.users.byId._({
        [ApiUrlParams.userId]: params[ApiUrlParams.userId],
      }),
    })
  ),
  updateUserDetails: apiReqSagaCreator<
    UpdateUserDetailsParams,
    UpdateUserDetailsResult
  >(API_REQ_ACTION_TYPES.USERS.UPDATE_USER_DETAILS, (args) => ({
    method: HTTP_METHODS.PUT,
    url: ApiPaths.users.byId._({
      [ApiUrlParams.userId]: args[ApiUrlParams.userId],
    }),
    data: args.data,
  })),
  updateUserActiveTenant: apiReqSagaCreator<
    UpdateUserActiveTenantParams,
    UpdateUserActiveTenantResult
  >(API_REQ_ACTION_TYPES.USERS.UPDATE_USER_ACTIVE_TENANT, (args) => ({
    method: HTTP_METHODS.PUT,
    url: ApiPaths.users.profile._(),
    data: {
      currentTenantId: args.tenantId,
    },
  })),
};

export default Users;
