import BaseRepo from 'container/BaseRepo';
import { ApiResponseAsync } from 'container/models';

import { OdataURLBuilder } from 'api/types/OdataURLBuilder';

export interface ApplicationLogDTO {
  id: string;
  sessionId: string;
  userName: string;
  userEmail: string;
  action: string;
  details: string;
  actionDate: Date; // arrives from the backend as a string
}

export abstract class AbstractManagementLogsRepo extends BaseRepo {
  abstract getApplicationLogs(
    request: OdataURLBuilder
  ): ApiResponseAsync<ApplicationLogDTO[] | number>;
}
