import { put } from 'redux-saga/effects';

import * as ERROR_CODES from 'constants/errorCodes';
import { TOASTER_TYPE } from 'constants/toasterMessages';

import createATA from 'utils/createAddToasterAction';

export const MESSAGE =
  'The barcode field is empty. Please add the barcode value';

function* finishScanningFailure({
  payload,
}: {
  type: string;
  payload: BackendError[];
}) {
  if (payload.some((e) => e.code === ERROR_CODES.BASE_CLIENT_ERROR)) {
    yield put(
      createATA({
        type: TOASTER_TYPE.ERROR,
        message: MESSAGE,
      })
    );
  }
}

export default finishScanningFailure;
