import { getLocation } from 'connected-react-router';
import { fork, select } from 'redux-saga/effects';

import { Licenses, Users } from 'api/endpoints';

function* reFetchUsersListAndLicenses() {
  const { query } = yield select(getLocation);

  yield fork(Users.getUsers, query);

  yield fork(Licenses.getLicenses, undefined);
}

export default reFetchUsersListAndLicenses;
