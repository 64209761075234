import { AxiosError } from 'axios';
import { all, put, takeEvery } from 'redux-saga/effects';

import { API_ACTION_TYPES } from 'api/actions';
import { RequestActionMeta } from 'api/apiReqSagaCreator';

import { TOASTER_TYPE } from 'constants/toasterMessages';

import createATA from 'utils/createAddToasterAction';

export default function* watcherSaga() {
  yield takeEvery([API_ACTION_TYPES.XHR.ERROR], workerSaga);
}

// Ticket Number: WO-97
// TODO: Implement correct error message
// export const LOST_INTERNET_CONNECTION_MESSAGE =
//   'Your internet connection appears to be down. Please check it and try again.';

type ErrorPayload = AxiosError<{ errors: BackendError[] }> & {
  errors: BackendError[];
};

interface ErrorAction {
  type: string;
  payload: ErrorPayload;
  meta: RequestActionMeta<unknown>;
}

function* workerSaga({
  payload: { errors, response },
  meta: { muteError },
}: ErrorAction) {
  if (muteError || !errors || !Array.isArray(errors)) {
    if (muteError && errors) {
      console.error('error => %s', JSON.stringify(errors));
    }
    return null;
  }
  // Ticket Number: WO-97
  // Author: Jaehyun Jun
  // TODO: Implement correct error message
  // if (errors.some(({ message }) => message === 'Network Error')) {
  //   yield put(
  //     createATA({
  //       type: TOASTER_TYPE.ERROR,
  //       message: LOST_INTERNET_CONNECTION_MESSAGE,
  //     })
  //   );
  //   return null;
  // }

  if (response && response.status === 401) {
    const description = errors && errors.length > 0 ? errors[0].field : '';
    console.error('unauthorized error => %s', description);
    return null;
  }

  const messages = errors.map(({ code, description, field }) =>
    description
      ? description
      : field
        ? field
        : code
          ? 'code: ' + code
          : 'unknown error'
  );

  yield all(
    messages.map((message) =>
      put(
        createATA({
          type: TOASTER_TYPE.ERROR,
          message: message as string,
        })
      )
    )
  );
}
