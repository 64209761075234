import { put } from 'redux-saga/effects';

import createAddToasterAction from 'utils/createAddToasterAction';

const MESSAGE = 'Function was saved successfully';

function* updateCreateFunctionSuccess() {
  yield put(
    createAddToasterAction({
      message: MESSAGE,
    })
  );
}

export default updateCreateFunctionSuccess;
