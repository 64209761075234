const TEXTS = {
  LABEL: 'Shortcuts',
  OK: 'Ok',
  SHORTCUT_LABEL: {
    START_PULL_BACK: 'Start data pull back while executing index block',
    SUBMIT_TASK_WHILE_EXECUTION:
      'Proceed to the next step while process execution',
    MOVE_TO_PREV_INSTANCE:
      'Move to previous process instance while executing process',
    MOVE_TO_NEXT_INSTANCE:
      'Move to next process instance while executing process',
    MOVE_IN_DROPDOWN: 'Move between the options in the dropdown',
  },
};

const BUTTONS = {
  CONTROL: {
    label: '^',
    tooltip: 'Control',
  },
  WIN_OPTION: {
    label: 'alt',
  },
  WIN_CONTROL: {
    label: 'ctrl',
  },
  SHIFT: {
    label: '⇧',
    tooltip: 'Shift',
  },
  COMMAND: {
    label: '⌘',
    tooltip: 'Command',
  },
  RETURN: {
    label: '↵',
    tooltip: 'Return',
  },
  OPTION: {
    label: '⌥',
    tooltip: 'Option',
  },
  LEFT_ARROW: {
    label: '←',
    tooltip: 'Leftwards Arrow',
  },
  UP_ARROW: {
    label: '↑',
    tooltip: 'Upwards Arrow',
  },
  RIGHT_ARROW: {
    label: '→',
    tooltip: 'Rightwards Arrow',
  },
  DOWN_ARROW: {
    label: '↓',
    tooltip: 'Downwards Arrow',
  },
};

const MAC_SHORTCUTS = [
  {
    label: TEXTS.SHORTCUT_LABEL.START_PULL_BACK,
    keys: [
      BUTTONS.COMMAND,
      BUTTONS.SHIFT,
      {
        label: 'l',
      },
    ],
  },
  {
    label: TEXTS.SHORTCUT_LABEL.SUBMIT_TASK_WHILE_EXECUTION,
    keys: [BUTTONS.CONTROL, BUTTONS.RETURN],
  },
  {
    label: TEXTS.SHORTCUT_LABEL.MOVE_TO_PREV_INSTANCE,
    keys: [BUTTONS.OPTION, BUTTONS.LEFT_ARROW],
  },
  {
    label: TEXTS.SHORTCUT_LABEL.MOVE_TO_NEXT_INSTANCE,
    keys: [BUTTONS.OPTION, BUTTONS.RIGHT_ARROW],
  },
  {
    label: TEXTS.SHORTCUT_LABEL.MOVE_IN_DROPDOWN,
    keys: [BUTTONS.UP_ARROW, BUTTONS.DOWN_ARROW],
  },
];

const WIN_SHORTCUTS = [
  {
    label: TEXTS.SHORTCUT_LABEL.START_PULL_BACK,
    keys: [
      BUTTONS.WIN_OPTION,
      BUTTONS.SHIFT,
      {
        label: 'l',
      },
    ],
  },
  {
    label: TEXTS.SHORTCUT_LABEL.SUBMIT_TASK_WHILE_EXECUTION,
    keys: [BUTTONS.WIN_CONTROL, BUTTONS.RETURN],
  },
  {
    label: TEXTS.SHORTCUT_LABEL.MOVE_TO_PREV_INSTANCE,
    keys: [BUTTONS.WIN_CONTROL, BUTTONS.LEFT_ARROW],
  },
  {
    label: TEXTS.SHORTCUT_LABEL.MOVE_TO_NEXT_INSTANCE,
    keys: [BUTTONS.WIN_CONTROL, BUTTONS.RIGHT_ARROW],
  },
  {
    label: TEXTS.SHORTCUT_LABEL.MOVE_IN_DROPDOWN,
    keys: [BUTTONS.UP_ARROW, BUTTONS.DOWN_ARROW],
  },
];

export { TEXTS, MAC_SHORTCUTS, WIN_SHORTCUTS };
