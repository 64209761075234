import ROUTES from 'app/routes/paths';
import { UiWithDefinitionPreviewParams } from 'app/routes/uiPathParams';

import UiUrlParams from 'constants/uiUrlParams';

type PathCreator<T> = (params: UiWithDefinitionPreviewParams & T) => string;

export interface UrlCreator<T> {
  preview: PathCreator<T>;
  _: PathCreator<T>;
}

export enum DefinitionEntity {
  fields = 'fields',
  versions = 'versions',
  forms = 'forms',
  security = 'security',
  emailTemplates = 'emailTemplates',
  indexingSources = 'indexingSources',
  processPreview = 'processPreview',
}

export enum DefinitionVersionEntity {
  fields = 'fields',
  forms = 'forms',
  processPreview = 'processPreview',
  security = 'security',
  emailTemplates = 'emailTemplates',
  indexingSources = 'indexingSources',
  scanning = 'scanning',
}

const getRouteByVersion = <T = {}>({
  ids,
  entity,
  urlCreator,
}: {
  ids: UiWithDefinitionPreviewParams & T;
  entity?: DefinitionEntity | DefinitionVersionEntity;
  urlCreator?: UrlCreator<T>;
}) => {
  if (urlCreator) {
    return ids[UiUrlParams.processVersionId]
      ? urlCreator.preview(ids)
      : urlCreator._(ids);
  }
  return ids[UiUrlParams.processVersionId]
    ? ROUTES.process.definition.details.version[
        entity as DefinitionVersionEntity
      ](ids)
    : ROUTES.process.definition.details[entity as DefinitionEntity](ids);
};

export { getRouteByVersion };
