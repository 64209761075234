enum ApiUrlParams {
  draftProcessId = 'draftProcessId',
  releaseOrDraftProcessId = 'releaseOrDraftProcessId',
  stageId = 'stageId',
  groupId = 'groupId',
  templateId = 'templateId',
  userId = 'userId',
  tenantId = 'tenantId',
  fieldId = 'fieldId',
  name = 'name',
  ocrKey = 'ocrKey',
  inboundEmailScenarioId = 'inboundEmailScenarioId',
  ftpPollingScenarioId = 'ftpPollingScenarioId',
  processVersionId = 'processVersionId',
  processInstanceId = 'processInstanceId',
  stepId = 'stepId',
  formId = 'formId',
  functionId = 'functionId',
  externalFormId = 'externalFormId',
  searchTemplateId = 'searchTemplateId',
  invitationId = 'invitationId',
  tmsConnectionId = 'tmsConnectionId',
  ftpConnectionId = 'ftpConnectionId',
  splitDocInstanceId = 'splitDocInstanceId',
  splitDocContainerId = 'splitDocContainerId',
  emailTemplateId = 'emailTemplateId',
  printRenderScenarioId = 'printRenderScenarioId',
  printRenderInstanceId = 'printRenderInstanceId',
  scanInstanceId = 'scanInstanceId',
  scanConfigurationId = 'scanConfigurationId',
  scanDocContainerId = 'scanDocContainerId',
  limit = 'limit',
  offset = 'offset',
}

export default ApiUrlParams;
