import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import { EXTERNAL_ACTION_TYPES } from './actions';
import {
  handleStartSubmittingDynamicForm,
  redirectToFormSuccessScreen,
  submitForm,
} from './sagas';

function* watcherSaga() {
  yield takeLatest(
    API_REQ_ACTION_TYPES.EXTERNAL.FORMS.SUBMIT.SUCCESS,
    redirectToFormSuccessScreen
  );

  yield takeLatest(EXTERNAL_ACTION_TYPES.FORM.START_SUBMIT, submitForm);

  yield takeLatest(
    [EXTERNAL_ACTION_TYPES.FORM.START_SUBMIT],
    handleStartSubmittingDynamicForm
  );
}

export default watcherSaga;
