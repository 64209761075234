import { appStore } from 'app';
import { ConfigureStore } from 'app/redux/configureStore';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Saga, Task } from 'redux-saga';

import { ApiSagaResult } from 'api/apiReqSagaCreator';

import type { useWriteAPI } from '../useAPI';

type StartAction<A extends unknown[], R = void> = (
  ...args: A
) => Promise<ApiSagaResult<R>>;

export interface Params<A extends unknown[], R = void> {
  isPending: boolean;
  startAction: StartAction<A, R>;
}

/** @deprecated use {@link useWriteAPI} */
export default function useTriggeredFetchAction<A extends unknown[], R = void>(
  saga: Saga,
  startWithPending?: boolean
): Params<A, R> {
  const [isProcessing, setIsProcessing] = useState(!!startWithPending);

  const taskRef = useRef<Task | null>(null);

  const cancelAction = useCallback(() => {
    if (!taskRef.current || taskRef.current.isCancelled()) {
      return;
    }

    taskRef.current.cancel();
    taskRef.current = null;
  }, []);

  useEffect(() => cancelAction, [cancelAction]);

  const startAction: StartAction<A, R> = useCallback(
    async (...sagaArgs) => {
      setIsProcessing(true);

      taskRef.current = (appStore as ConfigureStore<A[]>).runSaga(
        saga,
        ...(sagaArgs as A[])
      );

      let rv: any = undefined;
      try {
        rv = await taskRef.current?.toPromise();
      } catch (e) {
        if (e instanceof Error) {
          const err: ApiSagaResult<R> = {
            ok: false,
            result: [
              {
                description: e.message,
              } as BackendError,
            ],
          };
          rv = err;
        } else {
          console.error('Improperly handled error', e);
          rv = e;
        }
      } finally {
        setIsProcessing(false);
      }
      return rv;
    },
    [saga]
  );

  return {
    startAction,
    isPending: isProcessing,
  };
}
