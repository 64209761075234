import { Result } from 'hooks/useGetAdvancedSearchParams';

import {
  NUMBER_FUNCTIONS,
  STRING_FUNCTIONS,
} from 'components/organisms/form/advancedSearchConfigure/config';
import {
  FormFields as AdvancedFields,
  Operator,
} from 'components/organisms/form/advancedSearchConfigure/types';

import { DataModelFieldType } from 'constants/global';

import { string } from '.';

const STATIC_FIELD_NAMES = ['created_at', 'updated_at', 'name'];

const normalizeField = (field: Result, nameKey: string) => ({
  [nameKey]: field.id,
  ...(typeof field.value !== 'undefined' && {
    value:
      field.type === DataModelFieldType.Number
        ? Number(field.value)
        : decodeURIComponent(field.value as string),
  }),
  ...(field.type === DataModelFieldType.Number
    ? NUMBER_FUNCTIONS[field.operation as keyof typeof NUMBER_FUNCTIONS].config
    : STRING_FUNCTIONS[field.operation as keyof typeof STRING_FUNCTIONS]
        .config),
});

const normalizeFieldValue = (type: string, value?: string) => {
  switch (type) {
    case DataModelFieldType.Date:
      // TODO: CREATE UTIL WITH NAMED REGULAR EXPRESSIONS
      return value?.replace(/\//g, '');
    default:
      return value;
  }
};

const normalizeAdvancedValues = <T extends AdvancedFields>(entity: T) => {
  const { fields, ...rest } = entity;
  const filteredFields = Object.fromEntries(
    Object.entries(fields || {}).filter(([, value]) => !!value)
  );

  return {
    ...rest,
    fields: filteredFields,
  };
};

const normalizeAdvancedParams = (operator: Operator, params: Result[]) => {
  const dynamicFields = params.filter(
    (e) => !STATIC_FIELD_NAMES.includes(e.id)
  );

  const staticFields = params.filter((e) => STATIC_FIELD_NAMES.includes(e.id));

  return {
    operator,
    variables: dynamicFields.map((e) => normalizeField(e, 'aliasId')),
    default_fields: staticFields.map((e) => normalizeField(e, 'field_name')),
  };
};

export type SearchParam = SearchVariable & {
  aliasId?: string;
  fieldName?: string;
};

const normalizeSearchVariable =
  (idKey: keyof SearchParam) => (e: SearchParam) => ({
    id: e[idKey] as string,
    operation: `${string.camelize(e.operation)}${e.not ? 'Not' : ''}`,
    type: typeof e.value === 'number' ? 'number' : 'string',
    ...(typeof e.value !== 'undefined' && { value: e.value }),
  });

const excludeObjectProperty = <T extends {}>(object: T, ...exclude: string[]) =>
  Object.fromEntries(
    Object.entries(object).filter(([key]) =>
      Array.isArray(exclude) ? !exclude.includes(key) : exclude !== key
    )
  );

export {
  normalizeAdvancedParams,
  normalizeFieldValue,
  normalizeAdvancedValues,
  normalizeSearchVariable,
  excludeObjectProperty,
};
