import ROUTES, { EditorDetailsFormUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { call, select } from 'redux-saga/effects';

import ProcessForm, { FormData, FormGeneral } from 'api/endpoints/processForm';

import FormsSelectors from 'store/forms/selectors';

import ApiUrlParams from 'constants/apiUrlParams';

import { setFormData } from '.';

export interface SubmitToggleFormAsInitialArgs {
  formId: string;
  selectedFieldId: string;
  isUpdateInDetails?: boolean;
  initial: boolean;
  stay?: boolean;
}

function* submitToggleFormAsInitial({
  formId,
  selectedFieldId,
  isUpdateInDetails,
  initial,
  stay,
}: SubmitToggleFormAsInitialArgs) {
  const { pathname }: Location = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId },
  }: match<EditorDetailsFormUiParams> = yield matchPath(pathname, {
    path: [
      ROUTES.process.definition.details.forms(),
      ROUTES.process.formDetails._(),
      ROUTES.process.editProcessForm(),
      ROUTES.process.editProcess.forms._(),
      ROUTES.process.editProcess.forms.details._(),
      ROUTES.process.editProcess.forms.edit(),
    ],
  });
  let formDetails: Nullable<FormGeneral>;
  formDetails = yield select(FormsSelectors.getDetails);

  if (!formDetails) {
    yield call(setFormData, { releaseOrDraftProcessId, formId });
    formDetails = yield select(FormsSelectors.getDetails);
  }

  const data: FormData['data'] = {
    name: formDetails?.name || '',
    description: formDetails?.description || '',
    fields: (formDetails?.fields || []).map((e) => ({
      fieldId: e.id,
      fieldObligationType: e.obligationType,
    })),
    instanceNameFieldId: selectedFieldId,
    isInitial: initial,
  };

  yield call(
    ProcessForm.updateById,
    {
      [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
      [ApiUrlParams.formId]: formId,
      data,
      isUpdateInDetails,
      stay,
    },
    {
      title: initial ? 'SET' : 'UNSET',
    }
  );
}

export default submitToggleFormAsInitial;
