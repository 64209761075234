import ROUTES, { ExternalFromUiParams } from 'app/routes/paths';
import {
  AxiosError,
  AxiosHeaders,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { getLocation } from 'connected-react-router';
import { translateAxiosErrorToApiError } from 'container/models';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { call, put, select } from 'redux-saga/effects';

import devConsole from 'utils/devConsole';

import { ApiActions } from '../actions';
import { externalAuthHeaderName, headersSelector } from './config';
import httpRequest, { handleExpiredTokens } from './httpRequest';
import { RequestActionMeta } from './index';

export default function* xhrSaga<S>(
  reqConfig: AxiosRequestConfig,
  meta: RequestActionMeta<unknown>
) {
  yield put(ApiActions.xhr.start(reqConfig, meta));

  const headers: ReturnType<typeof headersSelector> =
    yield select(headersSelector);

  const { pathname } = yield select(getLocation);

  const match: match<ExternalFromUiParams> = yield matchPath(pathname, {
    path: [ROUTES.external.form._()],
  });

  const requestHeaders: AxiosRequestHeaders = new AxiosHeaders();

  if (reqConfig.headers) Object.assign(requestHeaders, reqConfig.headers);
  if (headers) Object.assign(requestHeaders, headers);
  // if (!authRequired) delete requestHeaders[authHeaderName];
  if (match?.params.token)
    requestHeaders[externalAuthHeaderName] = match.params.token;

  const completeReqConfig: InternalAxiosRequestConfig = {
    ...reqConfig,
    headers: requestHeaders,
  };

  try {
    const result: AxiosResponse = yield call(() =>
      handleExpiredTokens(() => httpRequest<S>(completeReqConfig))
    );

    yield put(ApiActions.xhr.success(result, meta));

    return result;
  } catch (error) {
    const axiosError = error as AxiosError<{ errors: BackendError[] }>;

    let errors = translateAxiosErrorToApiError(axiosError).data;

    const errorPayload: AxiosError<{ errors: BackendError[] }> & {
      errors: BackendError[];
    } = { errors, ...axiosError };
    yield put(ApiActions.xhr.error(errorPayload, meta));

    let sagaErrorResponse: AxiosResponse<{ errors: BackendError[] }>;
    if (axiosError && axiosError.response) {
      sagaErrorResponse = axiosError.response;
    } else {
      devConsole.error(error);
      sagaErrorResponse = {
        status: 500,
        statusText: '',
        headers: {},
        config: completeReqConfig,
        data: { errors },
      };
    }

    return sagaErrorResponse;
  }
}
