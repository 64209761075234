import BaseRepo from 'container/BaseRepo';
import { ApiResponseAsync } from 'container/models';

import { ProcessInstanceDetails } from 'api/endpoints/processInstance';

import { LockDocumentResponse } from '../indexingDocuments/abstractIndexingDocumentsRepo';

export abstract class AbstractProcessInstanceRepo extends BaseRepo {
  abstract getProcessInstance(
    link: Link
  ): ApiResponseAsync<ProcessInstanceDetails>;
  abstract getIndexingInputFile(
    processInstance: ProcessInstanceDetails,
    lockDocumentResponse: LockDocumentResponse
  ): ApiResponseAsync<Blob>;
  abstract deleteProcessInstance(
    processInstanceId: string,
    processReleaseId: string
  ): ApiResponseAsync<void>;
}
