import ROUTES from 'app/routes/paths';
import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';

import * as ERROR_CODES from 'constants/errorCodes';

import { withDefaultPagination } from 'utils/formatters/string';

function* handleErrorGetInstancesById({
  payload,
  meta,
}: {
  type: string;
  payload: BackendError[];
  meta: { muteError?: boolean };
}) {
  if (
    !meta.muteError &&
    payload.some((e) =>
      [ERROR_CODES.WRONG_PROCESS_ID, ERROR_CODES.WONG_UUID_LENGTH].includes(
        e.code
      )
    )
  )
    yield put(push(withDefaultPagination(ROUTES.process.execution._())));
}

export default handleErrorGetInstancesById;
