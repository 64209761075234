import { stopAsyncValidation } from 'redux-form';
import { put } from 'redux-saga/effects';

import { ERROR_KEYS } from 'constants/fieldErrors';
import { FORM_NAMES } from 'constants/form';

const ERROR = { key: ERROR_KEYS.WRONG_CREDENTIALS };

function* authLoginFailure() {
  yield put(
    stopAsyncValidation(FORM_NAMES.LOGIN, {
      username: ERROR,
      password: ERROR,
    })
  );
}

export default authLoginFailure;
