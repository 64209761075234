import isEqual from 'lodash.isequal';
import { setSubmitSucceeded } from 'redux-form';
import { all, call, put, select } from 'redux-saga/effects';

import { PERMISSION } from 'components/organisms/form/manageUser/config';
import { TenantsSections as SectionsInterface } from 'components/organisms/form/manageUser/types';

import { ApiSagaResult } from 'api/apiReqSagaCreator';
import { Tenants, Users } from 'api/endpoints';
import {
  GetUserToTenantResult,
  RemoveUserFromTenantResult,
  UpdateUserInTenantResult,
  UserData,
} from 'api/endpoints/tenants';
import { SystemUserDetails, UpdateUserDetails } from 'api/endpoints/users';

import ApiUrlParams from 'constants/apiUrlParams';
import { FORM_NAMES } from 'constants/form';
import { TENANT_ROLE } from 'constants/roles';

import createAddToasterAction from 'utils/createAddToasterAction';

import { UsersActions } from '../actions';
import UsersSelectors from '../selectors';

export type SubmitEditUserParams = Pick<
  SystemUserDetails,
  'firstName' | 'lastName' | 'email'
> & {
  phoneNumber: string;
  systemRole: SelectItem;
  tenants?: SectionsInterface;
};

function* submitEditUser({
  systemRole,
  tenants,
  phoneNumber,
  lastName,
  email,
  firstName,
}: SubmitEditUserParams) {
  const initialUser: SystemUserDetails = yield select(
    UsersSelectors.getUserDetails
  );

  const oldPersonalInfo = {
    lastName: initialUser.lastName,
    firstName: initialUser.firstName,
    email: initialUser.email,
    phone: initialUser.phone,
    role: initialUser.role,
  };

  const newPersonalInfo = {
    firstName,
    lastName,
    email,
    role: systemRole.value,
    phone: phoneNumber,
  };

  let allSaved = true;

  if (!isEqual(oldPersonalInfo, newPersonalInfo)) {
    const { ok } = yield call(Users.updateUserDetails, {
      [ApiUrlParams.userId]: initialUser.id,
      data: newPersonalInfo as UpdateUserDetails['data'],
    });
    allSaved = ok;
  }

  const normalizedTenants: KeyValuePairs<Omit<UserData, 'userId'>> =
    Object.values(tenants ?? {}).reduce(
      (acc, next) => ({
        ...acc,
        [next.tenant.value]: {
          tenantRole: next.role.value,
          ...(next.role.value === TENANT_ROLE.USER
            ? {
                permissions: Object.keys(PERMISSION).reduce(
                  (keys, key) => ({
                    ...keys,
                    [key]: (Array.isArray(next.permissions)
                      ? next.permissions
                      : []
                    )
                      .map((e) => e.value)
                      .includes(key),
                  }),
                  {}
                ),
              }
            : {}),
        },
      }),
      {}
    );

  const initialUserTenantsIds = (initialUser.tenants || []).map(
    (t) => t.tenant.id
  );

  if (
    newPersonalInfo.role !== 'admin' &&
    !isEqual(initialUser.tenants, normalizedTenants)
  ) {
    const needToAdd = Object.entries(normalizedTenants).filter(
      ([tenantId]) => !initialUserTenantsIds.includes(tenantId)
    );

    if (needToAdd.length) {
      const res: ApiSagaResult<GetUserToTenantResult>[] = yield all(
        needToAdd.map(([tenantId, tenantInfo]) =>
          call(Tenants.addUsersToTenant, {
            tenantId,
            body: {
              users: [
                {
                  userId: initialUser.id,
                  ...tenantInfo,
                },
              ],
            },
          })
        )
      );
      allSaved = res.every((e: { ok: boolean }) => e.ok);
    }

    const needToRemove = initialUserTenantsIds.filter(
      (tenantId) => !Object.keys(normalizedTenants).includes(tenantId)
    );

    if (needToRemove.length) {
      const res: ApiSagaResult<RemoveUserFromTenantResult>[] = yield all(
        needToRemove.map((tenantId) =>
          call(Tenants.removeUserFromTenant, {
            [ApiUrlParams.userId]: initialUser.id,
            [ApiUrlParams.tenantId]: tenantId,
          })
        )
      );
      allSaved = res.every((e: { ok: boolean }) => e.ok);
    }

    const needToUpdate = Object.entries(normalizedTenants).filter(
      ([tenId, info]) => {
        const currentTenant = initialUser.tenants?.find(
          (e) => e.tenant.id === tenId
        );
        const userWasInTenant = initialUserTenantsIds.includes(tenId);
        const roleWasChanged = currentTenant?.userRole !== info.tenantRole;
        const permissionsWereChanged =
          currentTenant?.userRole === TENANT_ROLE.USER &&
          currentTenant?.permissions &&
          !isEqual(currentTenant?.permissions, info.permissions);
        return userWasInTenant && (roleWasChanged || permissionsWereChanged);
      }
    );

    if (needToUpdate.length) {
      const res: ApiSagaResult<UpdateUserInTenantResult>[] = yield all(
        needToUpdate.map(([tenantId, info]) =>
          call(Tenants.updateUserInTenant, {
            [ApiUrlParams.userId]: initialUser.id,
            [ApiUrlParams.tenantId]: tenantId,
            body: info,
          })
        )
      );
      allSaved = res.every((e: { ok: boolean }) => e.ok);
    }
  }

  if (allSaved) {
    yield put(setSubmitSucceeded(FORM_NAMES.MANAGE_USER));
    yield put(
      createAddToasterAction({
        message: 'User information was updated successfully.',
      })
    );
    yield put(UsersActions.userWasEditedSuccessfully());
    yield put(UsersActions.clearUserDetails());
  }
}

export default submitEditUser;
