import { combineReducers } from 'redux';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import {
  GetKeysResult,
  OcrSettingGeneral,
  OcrTestResult,
} from 'api/endpoints/ocr';

import * as ERROR_CODES from 'constants/errorCodes';

export interface OcrState {
  testResults: Nullable<OcrTestResult[]>;
  testInProgress: boolean;
  settings: Nullable<OcrSettingGeneral[]>;
  settingsCount: Nullable<number>;
}

const ocr = combineReducers<OcrState>({
  testResults(state = null, { type, payload }) {
    switch (type) {
      case API_REQ_ACTION_TYPES.OCR.GET_TEST_RESULTS.SUCCESS:
        return payload;

      case API_REQ_ACTION_TYPES.OCR.TEST_FILE.SUCCESS:
        return null;

      default:
        return state;
    }
  },
  testInProgress(
    state = false,
    { type, payload }: { type: string; payload: BackendError[] }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.OCR.GET_TEST_RESULTS.ERROR:
        return payload[0].code === ERROR_CODES.OCR_IN_PROGRESS;
      default:
        return state;
    }
  },
  settings(
    state = null,
    { type, payload }: { type: string; payload: GetKeysResult }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.OCR.GET_KEYS.SUCCESS:
        return payload.data;
      default:
        return state;
    }
  },
  settingsCount(
    state = null,
    { type, payload }: { type: string; payload: GetKeysResult }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.OCR.GET_KEYS.SUCCESS:
        return payload.total;
      default:
        return state;
    }
  },
});

export default ocr;
