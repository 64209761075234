import React, { memo, useMemo } from 'react';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

const ToasterContainer = () => (
  <ReduxToastr
    closeOnToastrClick
    className="workoptima-toastr"
    timeOut={5000}
    newestOnTop
    preventDuplicates
    position="top-right"
    transitionIn="fadeIn"
    transitionOut="fadeOut"
    progressBar={false}
    options={useMemo(
      () => ({
        component: (props: {}) => <div>{JSON.stringify(props)}</div>,
      }),
      []
    )}
  />
);

export default memo(ToasterContainer);
