import ROUTES, { EditorDetailsFormUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { Action } from 'redux';
import { fork, select } from 'redux-saga/effects';

import { setFormData } from 'store/forms/sagas';

import ApiUrlParams from 'constants/apiUrlParams';

interface TaskAction extends Action, TaskEvent {
  type: string;
}

interface TaskEvent {
  meta: {
    input: {
      isUpdateInDetails?: boolean;
    };
  };
}

function* reFetchFormDetails({ meta }: TaskAction) {
  const { pathname } = yield select(getLocation);
  if (meta.input?.isUpdateInDetails) {
    const {
      params: { releaseOrDraftProcessId, formId },
    }: match<EditorDetailsFormUiParams> = yield matchPath(pathname, {
      path: [
        ROUTES.process.formDetails._(),
        ROUTES.process.editProcessForm(),
        ROUTES.process.editProcess.forms.details._(),
        ROUTES.process.editProcess.forms.edit(),
      ],
    });

    yield fork(setFormData, {
      [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
      [ApiUrlParams.formId]: formId,
    });
  }
}

export default reFetchFormDetails;
