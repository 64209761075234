import { startAsyncValidation, stopAsyncValidation } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';

import { Fields } from 'components/organisms/form/createTmsConnection/types';

import { TmsConnections } from 'api/endpoints';
import { ConnectionType } from 'api/endpoints/tmsConnections';

import { FORM_NAMES } from 'constants/form';

import {
  FormValuesSelector,
  getFormValuesWrapper,
} from 'utils/getFormValuesWrapper';

import { TmsConnectionsActions } from '../actions';

function* validateThirdPartyServicesCredentials() {
  const prSelector: FormValuesSelector<Fields> = yield getFormValuesWrapper(
    FORM_NAMES.CREATE_TMS_CONNECTION
  );

  const { tmsService, baseUrl, password, username, authUrl }: Fields =
    yield select(prSelector);

  const normalizedValues = {
    baseUrl,
    password,
    username,
    authUrl,
    type: tmsService.value as ConnectionType,
  };

  const { result } = yield call(
    TmsConnections.validateCredentials,
    normalizedValues
  );

  yield put(startAsyncValidation(FORM_NAMES.CREATE_TMS_CONNECTION));

  yield put(TmsConnectionsActions.setUserCredentialsStatus(result?.valid));

  yield put(stopAsyncValidation(FORM_NAMES.CREATE_TMS_CONNECTION));
}

export default validateThirdPartyServicesCredentials;
