import { Auth } from 'types/auth';

import { User } from 'api/endpoints/users';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export interface LoginParams {
  username: string;
  password: string;
}

export type LoginResult = Auth;
export type RefreshTokenParams = string;
export type RefreshTokenResult = Tokens;
export type LogOutParams = unknown;
export type LogOutResult = unknown;

export interface ForgotPasswordParams {
  username: string;
}

export type ForgotPasswordResult = unknown;
export type ValidateForgotPasswordParams = string;
export type ValidateForgotPasswordResult = unknown;

export interface RestorePasswordParams {
  ott: string;
  password: string;
}

export type RestorePasswordResult = unknown;
export type InfoParams = undefined;
export type InfoResult = User;

const Session = {
  logOut: apiReqSagaCreator<LogOutParams, LogOutResult>(
    API_REQ_ACTION_TYPES.SESSION.LOG_OUT,
    () => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.users.logOut._(),
    })
  ),
  forgotPassword: apiReqSagaCreator<ForgotPasswordParams, ForgotPasswordResult>(
    API_REQ_ACTION_TYPES.SESSION.FORGOT_PASSWORD,
    (data) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.auth.forgotPassword(),
      data,
    })
  ),
  validateForgotPassword: apiReqSagaCreator<
    ValidateForgotPasswordParams,
    ValidateForgotPasswordResult
  >(API_REQ_ACTION_TYPES.SESSION.VALIDATE_FORGOT_PASSWORD, (ott) => ({
    method: HTTP_METHODS.GET,
    url: ApiPaths.auth.validateForgotPassword(),
    params: {
      ott,
    },
  })),
  restorePassword: apiReqSagaCreator<
    RestorePasswordParams,
    RestorePasswordResult
  >(API_REQ_ACTION_TYPES.SESSION.RESTORE_PASSWORD, (data) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.auth.restorePassword(),
    data,
  })),
  info: apiReqSagaCreator<InfoParams, InfoResult>(
    API_REQ_ACTION_TYPES.USERS.INFO,
    () => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.users.info._(),
    })
  ),
};

export default Session;
