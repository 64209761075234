import { all, fork } from 'redux-saga/effects';

import api from 'api/saga';

import auth from 'store/auth/saga';
import emailPolling from 'store/emailPolling/saga';
import printRenderEmailTemplates from 'store/emailTemplates/saga';
import external from 'store/external/saga';
import fields from 'store/field/saga';
import processForm from 'store/forms/saga';
import ftpConnections from 'store/ftpConnections/saga';
import ftpPolling from 'store/ftpPolling/saga';
import functionSaga from 'store/function/saga';
import grid from 'store/grid/saga';
import history from 'store/history/saga';
import invitations from 'store/invitations/saga';
import ocr from 'store/ocr/saga';
import printRender from 'store/printRender/saga';
import process from 'store/process/saga';
import scanConfig from 'store/processScanConfig/saga';
import processScanning from 'store/processScanning/saga';
import processVersion from 'store/processVersion/saga';
import reduxForm from 'store/reduxForm/saga';
import registration from 'store/registration/saga';
import replyTo from 'store/replyTo/saga';
import scanDocumentView from 'store/scanDocumentView/saga';
import searchTemplate from 'store/searchTemplate/saga';
import securityGroups from 'store/securityGroups/saga';
import splitDocuments from 'store/splitDocuments/saga';
import stage from 'store/stage/saga';
import tenants from 'store/tenants/saga';
import tmsConnections from 'store/tmsConnections/saga';
import users from 'store/users/saga';

const sagas = [
  stage,
  process,
  auth,
  api,
  registration,
  history,
  scanDocumentView,
  fields,
  users,
  printRender,
  processVersion,
  processForm,
  searchTemplate,
  external,
  grid,
  emailPolling,
  reduxForm,
  ocr,
  functionSaga,
  printRenderEmailTemplates,
  scanConfig,
  processScanning,
  splitDocuments,
  securityGroups,
  invitations,
  replyTo,
  tenants,
  tmsConnections,
  ftpConnections,
  ftpPolling,
];

export default function* rootSaga() {
  yield all(sagas.map((saga) => fork(saga)));
}
