import cn from 'classnames';
import React, { InputHTMLAttributes, memo, ReactNode, useMemo } from 'react';
import { RefCallBack } from 'react-hook-form';
import MaskedInput from 'react-text-mask';

import { HIDE_FROM_FOCUS } from 'constants/global';

import sm from './styles.module.scss';

export interface Props
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'onDragStart' | 'onDrag' | 'onDrop'
  > {
  preIcon?: ReactNode;
  postIcon?: ReactNode;
  isError?: boolean;
  inputRef?: RefCallBack;
  isSelect?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  withEllipsis?: boolean;
  withAutoFocus?: boolean;
  withoutBorder?: boolean;
  mask?: (string | RegExp)[] | Function;
}

export const Input = ({
  withAutoFocus,
  type,
  className,
  preIcon,
  postIcon,
  value,
  onChange = () => null,
  isError,
  isSelect,
  isDisabled,
  isReadOnly,
  withEllipsis,
  withoutBorder,
  mask,
  ...rest
}: Props) => {
  const RenderInput = useMemo(
    () =>
      mask
        ? MaskedInput
        : React.forwardRef((props: Props, _ref) => (
            <input ref={props.inputRef} {...props} />
          )),
    [mask]
  );

  return (
    <div
      className={cn(sm.Input, {
        [sm.Input_HasPre]: Boolean(preIcon),
        [sm.Input_HasPost]: Boolean(postIcon),
      })}
    >
      {!!preIcon && <div className={cn(sm.Input_PreIcon)}>{preIcon}</div>}
      <RenderInput
        autoFocus={withAutoFocus}
        {...(mask && { guide: false })}
        mask={mask}
        type={type}
        {...((isDisabled || isReadOnly) && { tabIndex: HIDE_FROM_FOCUS })}
        autoComplete="off"
        readOnly={isReadOnly}
        className={useMemo(
          () =>
            cn(sm.Input_Native, className, {
              [sm.Input_Native_Error]: isError,
              [sm.Input_Native_HasPre]: Boolean(preIcon),
              [sm.Input_Native_HasPost]: Boolean(postIcon),
              [sm.Input_Native_Disabled]: isDisabled,
              [sm.Input_Native_ReadOnly]: isReadOnly,
              [sm.Input_Native_WithEllipsis]: withEllipsis,
              [sm.Input_Native_WithoutBorder]: withoutBorder,
            }),
          [
            className,
            isDisabled,
            isError,
            isReadOnly,
            postIcon,
            preIcon,
            withEllipsis,
            withoutBorder,
          ]
        )}
        value={value}
        onChange={onChange}
        {...rest}
      />
      {!!postIcon && <div className={cn(sm.Input_PostIcon)}>{postIcon}</div>}
    </div>
  );
};

export default memo(Input);
