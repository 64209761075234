import BaseRepo from 'container/BaseRepo';
import { ApiResponseAsync } from 'container/models';

// see api:src/services/application-email/email-providers/models.go

/**
 * This lists all the options availble for users to select as an email provider.
 */
export const PROVIDERS = {
  office365: {
    label: 'Microsoft 365',
  },
};

type ProviderKey = keyof typeof PROVIDERS;

type ProviderConfig<provider extends ProviderKey, config> = {
  emailProvider: provider;
  configuration: config;
};

type Office365Config = ProviderConfig<
  'office365',
  {
    tenantId: string;
    applicationId: string;
    userId: string;
    secret: string;
    maxAttachmentSize: number;
  }
>;

/** ProviderConfigs is a union (`|`) of all specific provider configs. */
type ProviderConfigs = Office365Config;

interface SharedConfig {
  id: string;
  name: string;
}

export type OrganizationEmailProviderConfig = SharedConfig & ProviderConfigs;

export interface TestEmailPayload {
  sender: string;
  subject: string;
  body: string;
  recipient: string;
  replyTo: string;
  attachmentName?: string;
  attachmentContent?: Blob | string; // string must be base64 encoded
}

export abstract class AbstractManagementEmailRepo extends BaseRepo {
  abstract getReplyTo(): ApiResponseAsync<string>;
  abstract setReplyTo(email: string): ApiResponseAsync<null>;

  abstract testEmailServerConfig(
    link: Link,
    config: OrganizationEmailProviderConfig,
    email: TestEmailPayload
  ): ApiResponseAsync<null>;
}
