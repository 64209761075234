import { call, select } from 'redux-saga/effects';

import { ProcessDetails } from 'api/endpoints/processes';
import Stage, { ProcessDefinitionStageGeneral } from 'api/endpoints/stage';

import ProcessSelectors from 'store/process/selectors';

import ApiUrlParams from 'constants/apiUrlParams';

function* handleStageCreate({
  payload,
}: ReduxAction<ProcessDefinitionStageGeneral>) {
  const processDetails: ProcessDetails = yield select(
    ProcessSelectors.getDetails
  );

  yield call(Stage.getDetails, {
    [ApiUrlParams.stageId]: payload.id,
    [ApiUrlParams.releaseOrDraftProcessId]: processDetails.id,
  });
}

export default handleStageCreate;
