import useCheckRoute, { UseCheckRoute } from 'hooks/useCheckRoute';
import useModal from 'hooks/useModal';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { User } from 'api/endpoints/users';

import UsersSelectors from 'store/users/selectors';

import { getTabs, Tab } from './config';
import { ProfileRef } from './types';

const useHeader = () => {
  // region *******************************DATA*********************************
  const routesState: UseCheckRoute = useCheckRoute();

  const [showProfile, handleToggleProfile] = useModal();

  const profileRef: ProfileRef = useRef<Nullable<HTMLDivElement>>(null);

  const isTenantUser: boolean = useSelector(UsersSelectors.getIsUserTenantUser);

  const userInfo: Nullable<User> = useSelector(UsersSelectors.getUserInfo);

  const tabs: Tab[] = useMemo(
    () => (!userInfo ? [] : getTabs(isTenantUser)),
    [isTenantUser, userInfo]
  );

  const screenWidth: number = window.innerWidth;

  const isMobile: boolean = screenWidth < 768;

  return useMemo(
    () => ({
      handleToggleProfile,
      showProfile,
      routesState,
      tabs,
      isMobile,
      profileRef,
    }),
    [handleToggleProfile, showProfile, routesState, tabs, isMobile]
  );
};

export default useHeader;
