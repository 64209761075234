import { fork } from 'redux-saga/effects';

import { Fields } from 'components/organisms/form/checkGSuitCreds/types';

import { RequestActionMeta } from 'api/apiReqSagaCreator';
import emailproviders, {
  ValidateCredentialsParams,
  ValidateCredentialsResult,
} from 'api/endpoints/emailproviders';

function* fetchLabels({
  meta: { input, submittedForm },
  payload,
}: ReduxAction<ValidateCredentialsResult, RequestActionMeta<Fields>>) {
  if (payload.isValid) {
    yield fork(emailproviders.getLabels, input as ValidateCredentialsParams, {
      submittedForm,
    });
  }
}

export default fetchLabels;
