import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import { call, select } from 'redux-saga/effects';

import { ApiSagaResult } from 'api/apiReqSagaCreator';
import {
  DocumentFormData,
  ScanDocumentContainer,
} from 'api/endpoints/processScan';

import ScanningSelector from '../selectors';
import createDocument from './createDocument';
import updateDocument from './updateDocument';

function* createManualScenario(
  scanInstanceId: string,
  currentFormData: DocumentFormData,
  prevFormData: DocumentFormData,
  pages: string[]
) {
  const lastCreatedDocument: Nullable<ScanDocumentContainer> = yield select(
    ScanningSelector.getLastCreatedDocument
  );

  if (isEmpty(lastCreatedDocument) || !isEqual(currentFormData, prevFormData)) {
    const createDocumentResponse: ApiSagaResult<any> = yield call(
      createDocument,
      scanInstanceId,
      currentFormData,
      pages
    );
    return createDocumentResponse;
  } else {
    yield call(updateDocument, scanInstanceId, pages);
    return {
      ok: true,
      result: null,
    } as ApiSagaResult<any>;
  }
}

export default createManualScenario;
