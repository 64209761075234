import { combineReducers } from 'redux';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import { FILE_ACTION_TYPES } from './actions';

export interface FileState {
  items: {
    [key: string]: Blob | null;
  };
}

const file = combineReducers<FileState>({
  items(
    state = {},
    {
      type,
      payload,
      meta,
    }: { type: string; payload: Blob; meta: { input: string } }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.FILES.GET_BY_URL.SUCCESS:
        return {
          ...state,
          [meta.input]: payload,
        };
      case FILE_ACTION_TYPES.REMOVE:
        return Object.entries(state)
          .filter(([key]) => key !== (payload as unknown as string))
          .reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: value,
            }),
            {}
          );
      default:
        return state;
    }
  },
});

export default file;
