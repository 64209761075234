import { getFormValues } from 'redux-form';
import { call, select } from 'redux-saga/effects';

import { Fields } from 'components/organisms/form/ftpPollingScenario';

import FtpPolling, { CreateScenarioData } from 'api/endpoints/ftpPolling';

import ApiUrlParams from 'constants/apiUrlParams';
import { FORM_NAMES } from 'constants/form';

const scenarioFormValuesSelector = getFormValues(
  FORM_NAMES.CREATE_FTP_POLLING_SCENARIO
);

export type CreateUpdateScenarioArgs = [string, string | undefined];

export const sanitizeFormData = <T extends {}>(formFieldsValue: T) =>
  Object.keys(formFieldsValue).reduce((result, fieldName) => {
    const getFieldValue = formFieldsValue[fieldName as keyof T];
    // @ts-ignore
    const getValueFromField = getFieldValue?.value;

    return getValueFromField
      ? { ...result, [fieldName]: getValueFromField }
      : { ...result, [fieldName]: getFieldValue };
  }, {});

export type TransformPrintRenderScenarioData = ReturnType<
  typeof sanitizeFormData
>;

function* createUpdateScenario(
  releaseOrDraftProcessId: string,
  ftpPollingScenarioId?: string
) {
  const scenarioData: Fields = yield select(scenarioFormValuesSelector);

  const values = sanitizeFormData(scenarioData) as CreateScenarioData['data'];

  if (!ftpPollingScenarioId) {
    yield call(
      FtpPolling.createScenario,
      {
        [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
        data: values,
      },
      { submittedForm: FORM_NAMES.CREATE_FTP_POLLING_SCENARIO }
    );
  } else {
    yield call(
      FtpPolling.updateScenario,
      {
        [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
        [ApiUrlParams.ftpPollingScenarioId]: ftpPollingScenarioId,
        data: values,
      },
      { submittedForm: FORM_NAMES.CREATE_FTP_POLLING_SCENARIO }
    );
  }
}

export default createUpdateScenario;
