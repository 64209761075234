import { put } from 'redux-saga/effects';

import createATA from 'utils/createAddToasterAction';

const SUCCEEDED_ACTIVATE_MSG =
  'The scenario was successfully unset as an automatic';

function* afterSucceededPrintRenderScenarioDeActivate() {
  yield put(
    createATA({
      message: SUCCEEDED_ACTIVATE_MSG,
    })
  );
}

export default afterSucceededPrintRenderScenarioDeActivate;
