import ROUTES, { SplitDocumentsUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { call, select } from 'redux-saga/effects';

import SplitDocument from 'api/endpoints/splitDocument';

import ApiUrlParams from 'constants/apiUrlParams';

function* searchDocuments(name: string) {
  const { pathname } = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId, splitDocInstanceId },
  }: match<SplitDocumentsUiParams> = yield matchPath(pathname, {
    path: [ROUTES.process.instance.splitDocuments()],
  });

  const { result } = yield call(SplitDocument.get, {
    [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
    [ApiUrlParams.splitDocInstanceId]: splitDocInstanceId,
    name,
  });

  return result.data ?? null;
}

export default searchDocuments;
