import { ScanDocPage, ScanDocumentContainerWithPages } from 'types/scanning';

import {
  GetAllDocumentsByNameResult,
  GetListDocumentsResult,
  ScanDocumentContainer,
} from 'api/endpoints/processScan';

import createAction from 'utils/createAction';

const DOCUMENT_VIEW = {
  SET_DOCUMENT_VIEW_DATA: 'DOCUMENT_VIEW.SET_DOCUMENT_VIEW_DATA',
  SET_ALL_DOCUMENTS: 'DOCUMENT_VIEW.SET_ALL_DOCUMENTS',
  SET_SEARCH_DOCUMENTS: 'DOCUMENT_VIEW.SET_SEARCH_DOCUMENTS',
  SEARCH_DOCUMENTS: 'DOCUMENT_VIEW.SEARCH_DOCUMENTS',
  SET_SELECTED_PAGE: 'SET_SELECTED_PAGE',
  CHANGE_BARCODE_VALUE: 'CHANGE_BARCODE_VALUE',
  SET_BARCODE_VALUE: 'SET_BARCODE_VALUE',
  SET_SELECTED_PAGE_INFO: 'SET_SELECTED_PAGE_INFO',
  SET_DOCUMENT_DATA: 'DOCUMENT_VIEW.SET_DOCUMENT_DATA',
  SET_PAGE_MOVED: 'DOCUMENT_VIEW.SET_PAGE_MOVED',
  MOVE_PAGES: 'DOCUMENT_VIEW.MOVE_PAGES',
};

const DocumentViewActions = {
  movePage: createAction<object>(DOCUMENT_VIEW.MOVE_PAGES),
  setProcessInstances: createAction<
    DataWithTotal<ScanDocumentContainerWithPages>
  >(DOCUMENT_VIEW.SET_DOCUMENT_VIEW_DATA),
  setAllDocuments: createAction<Nullable<GetAllDocumentsByNameResult>>(
    DOCUMENT_VIEW.SET_ALL_DOCUMENTS
  ),
  setSearchDocuments: createAction<GetListDocumentsResult>(
    DOCUMENT_VIEW.SET_SEARCH_DOCUMENTS
  ),
  setSelectedPage: createAction<ScanDocPage[]>(DOCUMENT_VIEW.SET_SELECTED_PAGE),
  changeBarcodeValue: createAction<{
    value: string;
    documentId: string;
  }>(DOCUMENT_VIEW.CHANGE_BARCODE_VALUE),
  setBarcodeValue: createAction<Nullable<KeyValuePairs>>(
    DOCUMENT_VIEW.SET_BARCODE_VALUE
  ),
  setPageMoved: createAction<boolean>(DOCUMENT_VIEW.SET_PAGE_MOVED),
  setSelectedPageInfo: createAction<ScanDocPage>(
    DOCUMENT_VIEW.SET_SELECTED_PAGE_INFO
  ),
  searchDocuments: createAction<string>(DOCUMENT_VIEW.SEARCH_DOCUMENTS),
  setDocumentData: createAction<ScanDocumentContainer>(
    DOCUMENT_VIEW.SET_DOCUMENT_DATA
  ),
};

export { DOCUMENT_VIEW as DOCUMENT_VIEW_ACTION_TYPES, DocumentViewActions };
