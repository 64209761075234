import ROUTES, { ScanScenarioInstanceUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationError } from 'types/errorHandling';

import { ApiSagaResult } from 'api/apiReqSagaCreator';
import ProcessScan, { DocumentFormData } from 'api/endpoints/processScan';

import ApiUrlParams from 'constants/apiUrlParams';

import { ProcessScanningActions } from '../actions';

function* createDocument(
  scanInstanceId: string,
  formData: DocumentFormData,
  pages: string[],
  withBarcode?: boolean,
  barcode?: Nullable<string>
) {
  const { pathname } = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId },
  }: match<ScanScenarioInstanceUiParams> = yield matchPath(pathname, {
    path: [ROUTES.process.scanningScenarioOld()],
  });

  const response: ApiSagaResult<any> = yield call(ProcessScan.createDocument, {
    [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
    [ApiUrlParams.scanInstanceId]: scanInstanceId,
    data: {
      formData,
      pages,
      ...(withBarcode && { barcode }),
    },
  });
  if (!response.ok)
    throw new ApplicationError(
      'Unable to create document',
      `failed to create document: ${JSON.stringify(response.result)}`
    );

  yield put(ProcessScanningActions.saveLastCreatedDocument(response.result));
  return response;
}

export default createDocument;
