import { FieldGeneral } from 'api/endpoints/processFields';
import { WithReleaseOrDraftProcessId, WithOcrKey } from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, CONTENT_TYPE, HTTP_METHODS } from '../constants';

const { REACT_APP_API_DOCUMENT_SERVICE: documentServiceUrl } = process.env;

export interface OcrTestResult {
  key: string;
  value: string;
  confidenceLevel: number;
  keysFields?: FieldGeneral[];
}

export interface OcrSettingGeneral extends Omit<BaseEntity, 'name'> {
  confidenceLevelField?: FieldGeneral;
  confidenceLevelFieldId?: string;
  keys: string[];
  keysField: FieldGeneral;
  keysFieldId: string;
  processId: string;
}

export interface CreateKeysData {
  keysFieldId: string;
  keys: string;
  confidenceLevelFieldId?: string;
}

export interface UploadFileData {
  ocrFile: FormData;
}

export interface TestFileParams {
  fileUrl: string;
}

export interface UploadFileResult {
  url: string;
}

export type CreateKeysParams = CreateKeysData & WithReleaseOrDraftProcessId;
export type CreateKeysResult = OcrSettingGeneral;
export type GetKeysParams = WithReleaseOrDraftProcessId & Pagination;
export type GetKeysResult = DataWithTotal<OcrSettingGeneral>;
export type DeleteKeyParams = WithReleaseOrDraftProcessId & WithOcrKey;
export type DeleteKeyResult = unknown;
export type EditKeyParams = WithReleaseOrDraftProcessId &
  WithOcrKey &
  CreateKeysParams;
export type EditKeyResult = OcrSettingGeneral;
export type UploadFileParams = WithReleaseOrDraftProcessId & UploadFileData;
export type TestFilePrams = TestFileParams & WithReleaseOrDraftProcessId;
export type TestFileResult = unknown;
export type GetTestResultsParams = WithReleaseOrDraftProcessId;
export type GetTestResultsResult = OcrTestResult;

const Ocr = {
  createKeys: apiReqSagaCreator<CreateKeysParams, CreateKeysResult>(
    API_REQ_ACTION_TYPES.OCR.CREATE_KEYS,
    (args) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.ocr.keys._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
      }),
      data: {
        keysFieldId: args.keysFieldId,
        keys: args.keys,
        confidenceLevelFieldId: args.confidenceLevelFieldId,
      },
    })
  ),
  getKeys: apiReqSagaCreator<GetKeysParams, GetKeysResult>(
    API_REQ_ACTION_TYPES.OCR.GET_KEYS,
    (args) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.ocr.keys._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
      }),
      params: {
        limit: args?.limit,
        offset: args?.offset,
      },
    })
  ),
  deleteKey: apiReqSagaCreator<DeleteKeyParams, DeleteKeyResult>(
    API_REQ_ACTION_TYPES.OCR.DELETE_KEY,
    (params) => ({
      method: HTTP_METHODS.DELETE,
      url: ApiPaths.processes.byId.ocr.keys.byId._(params),
    })
  ),
  editKey: apiReqSagaCreator<EditKeyParams, EditKeyResult>(
    API_REQ_ACTION_TYPES.OCR.EDIT_KEY,
    (args) => ({
      method: HTTP_METHODS.PUT,
      url: ApiPaths.processes.byId.ocr.keys.byId._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.ocrKey]: args[ApiUrlParams.ocrKey],
      }),
      data: {
        keysFieldId: args.keysFieldId,
        keys: args.keys,
        confidenceLevelFieldId: args.confidenceLevelFieldId,
      },
    })
  ),
  uploadFile: apiReqSagaCreator<UploadFileParams, UploadFileResult>(
    API_REQ_ACTION_TYPES.OCR.UPLOAD_FILE,
    (args) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.ocr._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
      }),
      data: args.ocrFile,
      baseURL: documentServiceUrl,
      headers: {
        'Content-Type': CONTENT_TYPE.MULTIPART_FORMDATA,
      },
    })
  ),
  testFile: apiReqSagaCreator<TestFilePrams, TestFileResult>(
    API_REQ_ACTION_TYPES.OCR.TEST_FILE,
    (args) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.ocr.test._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
      }),
      data: {
        fileUrl: args.fileUrl,
      },
    })
  ),
  getTestResults: apiReqSagaCreator<GetTestResultsParams, GetTestResultsResult>(
    API_REQ_ACTION_TYPES.OCR.GET_TEST_RESULTS,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.ocr.test._(params),
    })
  ),
};

export default Ocr;
