import ReactTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import React, { FC, ReactNode } from 'react';

import { HIDE_BACKGROUND_STYLES, POSITIONS } from './config';
import sm from './styles.module.scss';

type Placement =
  | 'left'
  | 'right'
  | 'top'
  | 'bottom'
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight';

export interface Props {
  title?: ReactNode;
  position?: Placement;
  staticallyVisible?: boolean;
  withBiggestZIndex?: boolean;
  hideBackground?: boolean;
  maxWidth?: number;
}

export const Tooltip: FC<Props> = ({
  staticallyVisible,
  children,
  title,
  position = POSITIONS.TOP,
  withBiggestZIndex,
  hideBackground,
  maxWidth,
}) => (
  <ReactTooltip
    {...(staticallyVisible && { visible: staticallyVisible })}
    overlay={title}
    placement={position}
    overlayClassName={withBiggestZIndex ? sm.OverAll : undefined}
    overlayStyle={{ maxWidth }}
    overlayInnerStyle={hideBackground ? HIDE_BACKGROUND_STYLES : undefined}
  >
    <div>{children}</div>
  </ReactTooltip>
);

export default Tooltip;
