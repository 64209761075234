import createAction from 'utils/createAction';

const FILE = {
  REMOVE: 'FILE.REMOVE',
};

const FileActions = {
  remove: createAction<string>(FILE.REMOVE),
};

export type FileRemoveAction = ReturnType<typeof FileActions.remove>;

export { FILE as FILE_ACTION_TYPES, FileActions };
