import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import { USERS_ACTION_TYPES } from 'store/users/actions';

import {
  moveFromRestrictedRoute,
  reFetchUsersListAndLicenses,
  refreshUI,
} from './sagas';

function* watcherSaga() {
  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.USERS.DEACTIVATE_USER.SUCCESS,
      API_REQ_ACTION_TYPES.USERS.ACTIVATE_USER.SUCCESS,
    ],
    reFetchUsersListAndLicenses
  );

  yield takeLatest(
    [USERS_ACTION_TYPES.USER_WAS_EDITED_SUCCESSFULLY],
    reFetchUsersListAndLicenses
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.USERS.UPDATE_USER_ACTIVE_TENANT.SUCCESS],
    moveFromRestrictedRoute
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.USERS.UPDATE_USER_ACTIVE_TENANT.SUCCESS],
    refreshUI
  );
}

export default watcherSaga;
