import ApiUrlParams from 'constants/apiUrlParams';
import UiUrlParams from 'constants/uiUrlParams';

const PARAM_PREFIX = ':';

export default function pathCreator<
  P extends {
    [key in UiUrlParams | ApiUrlParams]?: string;
  },
>(path: string) {
  return function createPath(params?: P) {
    if (!params) return path;
    return Object.entries(params)
      .filter(([, value]) => value)
      .reduce(
        (resultPath, [key, value]) =>
          resultPath.replace(
            new RegExp(`${PARAM_PREFIX}${key}`),
            value.toString()
          ),
        path
      );
  };
}
