import { translateAxiosErrorToApiError } from 'container/models';

import { authHeaderName } from 'api/apiReqSagaCreator/config';
import { handleExpiredTokens } from 'api/apiReqSagaCreator/httpRequest';

import {
  AbstractScanScenarioRepo,
  ScanScenario,
} from './AbstractScanScenarioRepo';

export class ScanScenarioRepo extends AbstractScanScenarioRepo {
  getScanScenario = (
    processDraftOrReleaseId: string,
    scanScenarioId: string
  ): ScanScenario =>
    handleExpiredTokens(() =>
      this.client.get<ScanScenario>(
        `/processes/${processDraftOrReleaseId}/scan/configurations/${scanScenarioId}`,
        {
          headers: {
            [authHeaderName]: this.tokenAccessor(),
          },
        }
      )
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);
}
