import { RootState } from 'app/redux/createRootReducer';

import { authCache } from 'api/cache';

import { API_ACTION_TYPES, API_REQ_ACTION_TYPES, ApiActions } from '../actions';
import ApiSelectors from '../selectors';

export const AUTH_TOKEN_EXPIRED = API_ACTION_TYPES.AUTH.TOKEN_EXPIRED;

export const AUTH_TOKEN_EXCHANGE = {
  SUCCESS: API_REQ_ACTION_TYPES.SESSION.RESTORE.SUCCESS,
  ERROR: API_REQ_ACTION_TYPES.SESSION.RESTORE.ERROR,
};

export const AuthTokenExpired = ApiActions.auth.tokenExpired;

export const authHeaderName = 'Access-Token';

export const externalAuthHeaderName = 'External-Token';

export const headersSelector = (state: RootState) => ({
  [authHeaderName]: authCache.authToken,
});

export const isRetryPossibleSelector = (state: RootState) =>
  Boolean(authCache.exchangeToken);

export const isExchangePendingSelector = ApiSelectors.isExchangePending;
