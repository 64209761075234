import React from 'react';
import { actions, AddToastPayload, toastType } from 'react-redux-toastr';

import Toaster from 'components/molecules/toaster';

import { TOASTER_TYPE } from 'constants/toasterMessages';

export type ToasterPayload = Omit<AddToastPayload, 'type'> & {
  type?: (typeof TOASTER_TYPE)[keyof typeof TOASTER_TYPE];
  action?: {
    label: string;
    onClick?: () => void;
    moveTo?: string;
  };
};

export default function createAddToasterAction({
  options,
  id = new Date().toISOString(),
  type = TOASTER_TYPE.INFO,
  ...payload
}: ToasterPayload) {
  return actions.add({
    id,
    type: type as toastType,
    ...payload,
    options: {
      component: <Toaster payload={{ id, type, ...payload }} />,
      removeOnHover: true,
      ...options,
    },
  });
}
