import ROUTES, { ProcessInstanceUiParams } from 'app/routes/paths';
import { getLocation, push } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { put, select } from 'redux-saga/effects';

import { FinishProcessInstanceParams } from 'api/endpoints/processInstance';

import UiUrlParams from 'constants/uiUrlParams';

import { string } from 'utils/formatters';

function* redirectToProcessDetails({
  meta: {
    input: { status },
  },
}: {
  type: string;
  meta: { input: FinishProcessInstanceParams };
}) {
  const { pathname } = yield select(getLocation);

  const {
    params: { draftProcessId },
  }: match<ProcessInstanceUiParams> = yield matchPath(pathname, {
    path: ROUTES.process.instance._(),
  });

  switch (status) {
    case 'success':
      yield put(
        push(
          string.withDefaultPagination(
            ROUTES.process.execution.details.success._({
              [UiUrlParams.releaseOrDraftProcessId]: draftProcessId,
            })
          )
        )
      );

      break;
    case 'failed':
      yield put(
        push(
          string.withDefaultPagination(
            ROUTES.process.execution.details.failed._({
              [UiUrlParams.releaseOrDraftProcessId]: draftProcessId,
            })
          )
        )
      );

      break;
    default:
      yield put(
        push(
          string.withDefaultPagination(
            ROUTES.process.execution.details.running._({
              [UiUrlParams.releaseOrDraftProcessId]: draftProcessId,
            })
          )
        )
      );
  }
}

export default redirectToProcessDetails;
