import { all, call } from 'redux-saga/effects';

import { ApiSagaResult } from 'api/apiReqSagaCreator';
import External from 'api/endpoints/external';
import ProcessScan, {
  UploadFileWhileExecuteResult,
} from 'api/endpoints/processScan';

import ApiUrlParams from 'constants/apiUrlParams';
import { UPLOAD_FILE_FIELD_NAME } from 'constants/global';

export type FieldsIncoming = DynamicFormParams;

export interface Params {
  [ApiUrlParams.releaseOrDraftProcessId]?: string;
  [ApiUrlParams.processInstanceId]?: string;
  form?: string;
  fields: FieldsIncoming;
  ott?: string;
}

function* normalizedFormFiles(payload?: Params) {
  let transformFieldsResult = {};

  if (payload?.fields) {
    const { form, releaseOrDraftProcessId, processInstanceId, ott, ...rest } =
      payload;
    const fileFields = [] as { [key: string]: File }[];
    const restFields: KeyValuePairs<string | number> = {};

    for (const [fieldId, fieldValue] of Object.entries(rest?.fields)) {
      if (fieldValue instanceof File) {
        fileFields.push({ [fieldId]: fieldValue });
      } else if (
        fieldValue &&
        Object.prototype.hasOwnProperty.call(fieldValue, 'value')
      ) {
        restFields[fieldId] = (fieldValue as SelectItem).value;
      } else {
        restFields[fieldId] = fieldValue as string | number;
      }
    }

    const uploadResults: ApiSagaResult<UploadFileWhileExecuteResult>[] =
      yield all(
        fileFields.map((e) => {
          const [[fieldId, file]] = Object.entries(e);
          const fileFormData = new FormData();
          fileFormData.append(UPLOAD_FILE_FIELD_NAME, file);

          if (ott) {
            fileFormData.append('ott', ott);
            return call(External.uploadFile, {
              [ApiUrlParams.externalFormId]: fieldId,
              field: fileFormData,
            });
          }

          return call(
            ProcessScan.uploadFileWhileExecute,
            {
              [ApiUrlParams.processInstanceId]:
                processInstanceId || (releaseOrDraftProcessId as string),
              [ApiUrlParams.fieldId]: fieldId,
              field: fileFormData,
            },
            {
              submittedForm: form,
            }
          );
        })
      );

    if (uploadResults.some((e) => !e.ok)) return;

    const normalizedFiles = fileFields.reduce(
      (acc, next, idx) => ({
        ...acc,
        [Object.keys(next)[0]]: (
          uploadResults[idx].result as UploadFileWhileExecuteResult
        ).url,
      }),
      {} as KeyValuePairs
    );

    transformFieldsResult = { ...restFields, ...normalizedFiles };
  }

  return transformFieldsResult;
}

export default normalizedFormFiles;
