import ROUTES, { ExecutionDetailsUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { getFormValues } from 'redux-form';
import { call, select } from 'redux-saga/effects';

import { FormFields } from 'components/organisms/form/advancedSearchConfigure/types';

import { ApiSagaResult } from 'api/apiReqSagaCreator';
import SearchTemplate, { CreateResult } from 'api/endpoints/searchTemplate';

import { FORM_NAMES } from 'constants/form';
import UiUrlParams from 'constants/uiUrlParams';

import { string } from 'utils/formatters';
import { convertAdvancedSearchToUrlParams } from 'utils/normalizers/transformAdvancedSearchToUrlParam';

export interface Params {
  templateName: string;
}

function* saveSearchTemplate({ templateName }: Params) {
  const { pathname } = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId },
  }: match<ExecutionDetailsUiParams> = yield matchPath(pathname, {
    path: [ROUTES.process.execution.details._()],
  });

  const valuesSelector = getFormValues(FORM_NAMES.ADVANCED_SEARCH_CONFIGURE);

  const fieldsValue: FormFields = yield select(valuesSelector);

  const baseUrl = window.location.origin;

  const path = string.withDefaultPagination(
    ROUTES.process.execution.details.all.search({
      [UiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
    })
  );

  const params = encodeURI(
    `&${convertAdvancedSearchToUrlParams(fieldsValue).toString()}`
  );

  const { ok }: ApiSagaResult<CreateResult> = yield call(
    SearchTemplate.create,
    {
      name: templateName,
      url: `${baseUrl}${path}${params}`,
      processId: releaseOrDraftProcessId,
    }
  );

  return { ok };
}

export default saveSearchTemplate;
