import React, { memo, useCallback } from 'react';

import Text from 'components/atoms/text';

import sm from './styles.module.scss';

interface Props {
  href: string;
  content: string;
}

export const BlankLink = ({ href, content }: Props) => {
  const stopBubbling: ReactCallBack = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <span
      role="button"
      onClick={stopBubbling}
      onKeyDown={stopBubbling}
      tabIndex={0}
    >
      <a
        rel="noopener noreferrer"
        target="_blank"
        href={href}
        className={sm.Container}
      >
        <Text content={content} />
      </a>
    </span>
  );
};

export default memo(BlankLink);
