import BaseRepo from 'container/BaseRepo';
import { ApiResponseAsync } from 'container/models';

export abstract class AbstractBareClientRepo extends BaseRepo {
  abstract get<Response>(
    url: string,
    opts?: { responseTransformerStopPaths?: string[] }
  ): ApiResponseAsync<Response>;

  abstract patch<Payload, Response>(
    url: string,
    data: Payload,
    opts?: {
      requestTransformerStopPaths?: string[];
      responseTransformerStopPaths?: string[];
    }
  ): ApiResponseAsync<Response>;

  abstract delete<Response>(
    url: string,
    opts?: {
      responseTransformerStopPaths?: string[];
    }
  ): ApiResponseAsync<Response>;

  abstract post<Payload, Response>(
    url: string,
    data: Payload,
    opts?: {
      requestTransformerStopPaths?: string[];
      responseTransformerStopPaths?: string[];
    }
  ): ApiResponseAsync<Response>;

  abstract put<Payload, Response>(
    url: string,
    data: Payload,
    opts?: {
      requestTransformerStopPaths?: string[];
      responseTransformerStopPaths?: string[];
    }
  ): ApiResponseAsync<Response>;
}
