import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from 'react-redux-toastr';

import { ToasterProps } from 'components/molecules/toaster';

import createAddToasterAction from 'utils/createAddToasterAction';

export interface UseToaster {
  addToaster: (payload: ToasterProps['payload']) => void;
  removeToaster: (id: string) => void;
}

const useToaster = () => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      addToaster: (payload: ToasterProps['payload']) => {
        dispatch(createAddToasterAction(payload));
      },
      removeToaster: (id: string) => {
        dispatch(actions.remove(id));
      },
    }),
    [dispatch]
  );
};

export default useToaster;
