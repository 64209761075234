import { put } from 'redux-saga/effects';

import createATA from 'utils/createAddToasterAction';

const SUCCESS_TOASTER_MESSAGE = 'File is successfully updated.';

function* fileUploadSuccess() {
  yield put(
    createATA({
      message: SUCCESS_TOASTER_MESSAGE,
    })
  );
}

export default fileUploadSuccess;
