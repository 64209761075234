import { combineReducers } from 'redux';
import { NormalizedSplitDocument } from 'types/splitDocument';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import {
  GetByIdResult,
  SplitDocumentDetails,
} from 'api/endpoints/splitDocument';

import {
  SetListAction,
  SetReceivedPagesAction,
  SPLIT_DOCUMENTS_ACTION_TYPES,
} from './actions';

export interface SplitDocumentsState {
  list: Nullable<NormalizedSplitDocument[]>;
  total: Nullable<number>;
  receivedPages: Nullable<KeyValuePairs>;
  details: Nullable<SplitDocumentDetails>;
}

const splitDocuments = combineReducers<SplitDocumentsState>({
  list(state = null, { type, payload }: SetListAction) {
    switch (type) {
      case SPLIT_DOCUMENTS_ACTION_TYPES.SET_LIST:
        return [...(payload || [])];

      case SPLIT_DOCUMENTS_ACTION_TYPES.CLEAR_STATE:
        return null;

      default:
        return state;
    }
  },
  total(state = null, { type, payload }) {
    switch (type) {
      case API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.GET_DOCUMENTS.SUCCESS:
        return payload?.total;

      case SPLIT_DOCUMENTS_ACTION_TYPES.CLEAR_STATE:
        return null;

      default:
        return state;
    }
  },
  receivedPages(state = null, { type, payload }: SetReceivedPagesAction) {
    switch (type) {
      case SPLIT_DOCUMENTS_ACTION_TYPES.SET_RECEIVED_PAGES:
        return payload ?? null;

      case SPLIT_DOCUMENTS_ACTION_TYPES.CLEAR_STATE:
        return null;

      default:
        return state;
    }
  },
  details(state = null, { type, payload }: ReduxAction<GetByIdResult>) {
    switch (type) {
      case API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.GET_DOCUMENT_BY_ID.SUCCESS:
        return payload || null;

      case SPLIT_DOCUMENTS_ACTION_TYPES.CLEAR_DETAILS:
        return null;

      default:
        return state;
    }
  },
});

export default splitDocuments;
