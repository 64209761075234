import { put } from 'redux-saga/effects';

import createATA from 'utils/createAddToasterAction';

const MESSAGE = 'The Grid Template was successfully removed';

function* removeGridTemplateSuccess() {
  yield put(
    createATA({
      message: MESSAGE,
    })
  );
}

export default removeGridTemplateSuccess;
