import ROUTES, { SplitDocumentsUiParams } from 'app/routes/paths';
import { getLocation, replace } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { put, select } from 'redux-saga/effects';

function* leaveReIndexSuccess() {
  const { pathname } = yield select(getLocation);

  const { params }: match<SplitDocumentsUiParams> = yield matchPath(pathname, {
    path: [ROUTES.process.instance.splitDocuments()],
  });

  yield put(replace(ROUTES.process.instance._(params)));
}

export default leaveReIndexSuccess;
