import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Spinner from 'components/atoms/spinner';

import { TenantRole } from 'api/endpoints/tenants';
import ApiSelectors from 'api/selectors';

import UsersSelectors from 'store/users/selectors';

import { USER_ROLE } from 'constants/roles';

type AccessibleByRole = (
  redirectPath: string
) => (roles: string[]) => (Component: React.ComponentType) => () => JSX.Element;
const accessibleByRole: AccessibleByRole =
  (redirectPath) => (roles) => (Component) => () => {
    const currentRole: typeof USER_ROLE.ADMIN | TenantRole = useSelector(
      UsersSelectors.getCurrentTenantRole
    );

    const isSignedIn: boolean = useSelector(ApiSelectors.isSignedIn);

    const isLoading: boolean = isSignedIn && !currentRole;

    const isAllow: boolean =
      roles.includes(currentRole) || currentRole === USER_ROLE.ADMIN;

    if (isLoading) {
      return <Spinner />;
    }

    if (isAllow) {
      return <Component />;
    }

    return <Redirect to={redirectPath} />;
  };

export default accessibleByRole;
