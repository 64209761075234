import { fork, put } from 'redux-saga/effects';

import { authCache } from 'api/cache';

import { RECEIVE_ALL_TENANTS_ACTION } from 'store/tenants/reducer';

import { ApiActions } from '../actions';
import { Tenants } from '../endpoints';
import Session from '../endpoints/session';
import error from './error';
import watchersSaga from './watchers';

export default function* apiSaga() {
  yield fork(error);

  yield fork(watchersSaga);

  yield put(ApiActions.auth.cachedTokenVerified(false));

  if (authCache.authToken) {
    yield fork(Tenants.getTenants, undefined, {
      title: RECEIVE_ALL_TENANTS_ACTION,
    });

    yield fork(Session.info, undefined);
  }

  yield put(ApiActions.auth.cachedTokenVerified(true));
}
