import { takeLatest } from 'redux-saga/effects';

import { API_ACTION_TYPES, API_REQ_ACTION_TYPES } from '../actions';
import Session from '../endpoints/session';
import authTokenExpired from './authTokenExpired';
import signedIn from './signedIn';

export default function* watchersSaga() {
  yield takeLatest(API_ACTION_TYPES.AUTH.TOKEN_EXPIRED, authTokenExpired);

  yield takeLatest(API_REQ_ACTION_TYPES.SESSION.LOGIN.SUCCESS, signedIn);
  yield takeLatest(API_ACTION_TYPES.SIGNED_IN, Session.info, undefined);
}
