import ROUTES from 'app/routes/paths';
import { UseCheckRoute } from 'hooks/useCheckRoute';

import HomeActive from 'assets/icon/home-page-active.svg';
import HomeDefault from 'assets/icon/home-page-default.svg';
import ScheduleActive from 'assets/icon/schedule-active.svg';
import Schedule from 'assets/icon/schedule.svg';
import TimeActive from 'assets/icon/time-active.svg';
import Time from 'assets/icon/time.svg';
import UserActive from 'assets/icon/user-active.svg';
import User from 'assets/icon/user.svg';

import { string } from 'utils/formatters';

const TEXTS = {
  ALT: {
    LOGO: 'Logo',
    TIME: 'Time',
    SCHEDULE: 'Schedule',
    MANAGEMENT: 'Management',
    HOME: 'Home',
    PROFILE: 'Profile',
  },
  HOME: 'My Tasks',
  DEFINITION: 'Process Definition',
  EXECUTION: 'Process Execution',
  MANAGEMENT: 'Management',
  TENANT: 'Tenant Management',
  TYPE: {
    HOME: 'HOME',
    DEFINITION: 'DEFINITION',
    EXECUTION: 'EXECUTION',
    MANAGEMENT: 'MANAGEMENT',
  },
};

export interface Tab extends Record<string, string | boolean> {
  type: string;
  label: string;
  img: string;
  imgActive: string;
  imgAlt: string;
  path: string;
  mobileView: boolean;
}

type FilterForMobile = (tabs: Tab[]) => Tab[];

const filterForMobile: FilterForMobile = (tabs) => {
  const isMobile = window.innerWidth < 1024;

  if (!isMobile) return tabs;

  const key = 'mobileView';
  return tabs.filter((item) => item[key]);
};

type GetTabs = (isTenantUser: boolean) => Tab[];

const getTabs: GetTabs = (isTenantUser) => {
  const tabs = [
    {
      type: TEXTS.TYPE.HOME,
      label: TEXTS.HOME,
      img: HomeDefault,
      imgActive: HomeActive,
      imgAlt: TEXTS.ALT.HOME,
      path: string.withDefaultPagination(ROUTES.process.home._()),
      mobileView: false,
    },
    ...(!isTenantUser
      ? [
          {
            type: TEXTS.TYPE.DEFINITION,
            label: TEXTS.DEFINITION,
            img: Time,
            imgActive: TimeActive,
            imgAlt: TEXTS.ALT.TIME,
            path: string.withDefaultPagination(ROUTES.process.definition._()),
            mobileView: false,
          },
        ]
      : []),
    {
      type: TEXTS.TYPE.EXECUTION,
      label: TEXTS.EXECUTION,
      img: Schedule,
      imgActive: ScheduleActive,
      imgAlt: TEXTS.ALT.SCHEDULE,
      path: string.withDefaultPagination(ROUTES.process.execution._()),
      mobileView: true,
    },
    ...(!isTenantUser
      ? [
          {
            type: TEXTS.TYPE.MANAGEMENT,
            label: TEXTS.MANAGEMENT,
            img: User,
            imgActive: UserActive,
            imgAlt: TEXTS.ALT.MANAGEMENT,
            path: string.withDefaultPagination(ROUTES.management.users._()),
            mobileView: false,
          },
        ]
      : []),
  ] as Tab[];

  return filterForMobile(tabs);
};

type GetIsRouteActive = (type: string, routes: UseCheckRoute) => boolean;

const getIsRouteActive: GetIsRouteActive = (type, routes) => {
  switch (type) {
    case TEXTS.TYPE.DEFINITION:
      return routes.isDefinitionRoute;

    case TEXTS.TYPE.EXECUTION:
      return routes.isExecutionRoute;

    case TEXTS.TYPE.MANAGEMENT:
      return routes.isUserRoute;

    case TEXTS.TYPE.HOME:
      return routes.isHomeRoute;

    default:
      return false;
  }
};

export { TEXTS, getTabs, getIsRouteActive };
