const normalizeSortingParams = (sortBy: string | undefined) => {
  if (sortBy === 'createdAt') {
    return 'created_at';
  }

  if (sortBy === 'updatedAt') {
    return 'updated_at';
  }

  return sortBy;
};

export default normalizeSortingParams;
