import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import { reFetchConnectionsList } from './sagas';

function* watcher() {
  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.FTP_CONNECTIONS.ADD_CONNECTION.SUCCESS,
      API_REQ_ACTION_TYPES.FTP_CONNECTIONS.UPDATE_CONNECTION.SUCCESS,
      API_REQ_ACTION_TYPES.FTP_CONNECTIONS.DELETE_CONNECTION.SUCCESS,
    ],
    reFetchConnectionsList
  );
}

export default watcher;
