import ROUTES from 'app/routes/paths';
import { push } from 'connected-react-router';
import { ApplicationError } from 'types/errorHandling';

import { authCache } from 'api/cache';

import { string } from 'utils/formatters';

export default function* authTokenExpiredSaga() {
  yield authCache.refreshTokens().catch((error) => {
    const err = ApplicationError.FromError(
      error,
      'Failed to refresh user session'
    );
    err.details.catchMessage = 'Refresh was requested by authTokenExpiredSaga';

    err.print();

    return authCache.logout().then(() => {
      push(string.withDefaultPagination(ROUTES.process.execution._()));
      document.location.reload();
    });
  });
}
