import ROUTES, { SplitDocumentsUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { all, call, put, select } from 'redux-saga/effects';

import SplitDocument, {
  SplitDocumentGeneral,
} from 'api/endpoints/splitDocument';

import { SplitDocumentsActions } from 'store/splitDocuments/actions';
import { receiveDocumentPages } from 'store/splitDocuments/sagas';

import ApiUrlParams from 'constants/apiUrlParams';

function* reFetchDocuments() {
  const { pathname, query } = yield select(getLocation);

  const {
    params: {
      releaseOrDraftProcessId,
      splitDocInstanceId,
      processInstanceId,
      fieldId,
    },
  }: match<SplitDocumentsUiParams> = yield matchPath(pathname, {
    path: [ROUTES.process.instance.splitDocuments()],
  });

  const { result } = yield call(SplitDocument.get, {
    ...query,
    [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
    [ApiUrlParams.splitDocInstanceId]: splitDocInstanceId,
  });

  const pages: KeyValuePairs[] = yield all(
    (result?.data ?? []).map((ele: SplitDocumentGeneral) =>
      call(
        receiveDocumentPages,
        ele,
        processInstanceId,
        fieldId,
        releaseOrDraftProcessId
      )
    )
  );

  const normalizedPages = pages.reduce(
    (acc: KeyValuePairs, next: KeyValuePairs) => ({ ...acc, ...next }),
    {}
  );

  const normalizedList = (result?.data ?? []).map(
    (e: SplitDocumentGeneral) => ({
      ...e,
      pages: (e.pages ?? []).map((page) => ({
        name: page,
        url: normalizedPages[page],
      })),
    })
  );

  yield put(SplitDocumentsActions.setList(normalizedList));
}

export default reFetchDocuments;
