import { FieldGeneral, GetFieldsByIdResult } from 'api/endpoints/processFields';

import createAction from 'utils/createAction';

const FIELDS = {
  SET_PROCESS_FIELDS: 'FIELDS.SET_PROCESS_FIELDS',
  SET_FIELD_DETAILS: 'FIELDS.SET_FIELD_DETAILS',
  UPDATE_UNIQUE_FIELDS: 'FIELDS.UPDATE_UNIQUE_FIELDS',
  TRIGGER_RE_FETCH_FIELDS: 'FIELDS.TRIGGER_RE_FETCH_FIELDS',
};

export const SEARCH_FIELDS_META_TITLE = {
  Q_SEARCH: 'GET_Q_S_FIELD',
  A_SEARCH: 'GET_A_S_FIELD',
};

const ProcessFields = {
  setProcessFields: createAction<GetFieldsByIdResult>(
    FIELDS.SET_PROCESS_FIELDS
  ),
  updateUniqueFields: createAction<KeyValuePairs<Nullable<FieldGeneral>>>(
    FIELDS.UPDATE_UNIQUE_FIELDS
  ),
  setFieldDetails: createAction<Nullable<FieldGeneral>>(
    FIELDS.SET_FIELD_DETAILS
  ),
  triggerReFetchFields: createAction(FIELDS.TRIGGER_RE_FETCH_FIELDS),
};

export { FIELDS as FIELDS_ACTION_TYPES, ProcessFields };
