import ROUTES from 'app/routes/paths';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { authCache } from 'api/cache';
import { Users } from 'api/endpoints';
import { TenantGeneral } from 'api/endpoints/tenants';
import {
  UpdateUserActiveTenantParams,
  UpdateUserActiveTenantResult,
  User,
} from 'api/endpoints/users';
import useTriggeredFetchAction from 'api/hooks/useTriggeredFetchAction';

import TenantsSelectors from 'store/tenants/selectors';
import UsersSelectors, { UserTenantRolesMap } from 'store/users/selectors';

import { SYSTEM_ROLE_FULL, TENANT_ROLE_FULL } from 'constants/roles';

import { string } from 'utils/formatters';

import { ProfileMenuProps } from './types';

type HandleConfirmLogOut = EmptyArgsVoidFunc;
type HandleChangeTenant = (selected: SelectItem | string) => void;
type HandleSetAction = (type: string) => () => void;
type HandleResetActiveAction = EmptyArgsVoidFunc;
const useProfileMenu = (onItemClick: ProfileMenuProps['onItemClick']) => {
  const { push } = useHistory();

  const [activeAction, setActiveAction] =
    useState<Nullable<ActiveAction<undefined>>>(null);

  const info: User | null = useSelector(UsersSelectors.getUserInfo);

  const isUserAdmin: boolean = useSelector(UsersSelectors.getIsUserAdmin);

  const tenants: TenantGeneral[] = useSelector(TenantsSelectors.getAllList);

  const {
    isPending: isUpdateUserActiveTenantPending,
    startAction: startUpdateUserActiveTenant,
  } = useTriggeredFetchAction<
    [UpdateUserActiveTenantParams],
    UpdateUserActiveTenantResult
  >(Users.updateUserActiveTenant);

  const userTenantRoles: UserTenantRolesMap = useSelector(
    UsersSelectors.getUserTenantRoles
  );

  const userCurrentTenant: BaseEntity | undefined = useSelector(
    UsersSelectors.getUserCurrentTenant
  );

  const currentTenantRole:
    | keyof typeof SYSTEM_ROLE_FULL
    | keyof typeof TENANT_ROLE_FULL = useMemo(
    () =>
      isUserAdmin
        ? SYSTEM_ROLE_FULL.admin
        : TENANT_ROLE_FULL[
            (userTenantRoles || {})[
              userCurrentTenant?.id as keyof typeof userTenantRoles
            ] as string
          ],
    [isUserAdmin, userCurrentTenant, userTenantRoles]
  );

  const activeTenant: SelectItem | undefined = useMemo(
    () =>
      info
        ? {
            label: info?.profile.currentTenant.name,
            value: info?.profile.currentTenant.id,
          }
        : undefined,
    [info]
  );

  const normalizedTenants: SelectItem[] = useMemo(
    () => tenants?.map((e) => ({ label: e.name, value: e.id })),
    [tenants]
  );

  const [isLogOutPending, setIsLogOutPending] = useState(false);
  const handleConfirmLogOut: HandleConfirmLogOut = useCallback(async () => {
    setIsLogOutPending(true);
    await authCache.logout();

    push(string.withDefaultPagination(ROUTES.process.execution._()));
    document.location.reload();
  }, [push]);

  const anyPending: boolean = isUpdateUserActiveTenantPending;

  const handleChangeTenant: HandleChangeTenant = useCallback(
    async (selected) => {
      if ((selected as SelectItem).value !== activeTenant?.value) {
        await startUpdateUserActiveTenant({
          tenantId: (selected as SelectItem).value,
        });
        if (onItemClick) {
          onItemClick();
        }
      }
    },
    [activeTenant, onItemClick, startUpdateUserActiveTenant]
  );

  const handleSetAction: HandleSetAction = useCallback(
    (type) => () => {
      setActiveAction({ type });
    },
    []
  );

  const handleResetActiveAction: HandleResetActiveAction = useCallback(() => {
    setActiveAction(null);
  }, []);

  return useMemo(
    () => ({
      handleConfirmLogOut,
      isLogOutPending,
      info,
      currentTenantRole,
      normalizedTenants,
      handleChangeTenant,
      activeTenant,
      anyPending,
      handleSetAction,
      activeAction,
      handleResetActiveAction,
    }),
    [
      handleConfirmLogOut,
      isLogOutPending,
      info,
      currentTenantRole,
      normalizedTenants,
      handleChangeTenant,
      activeTenant,
      anyPending,
      handleSetAction,
      activeAction,
      handleResetActiveAction,
    ]
  );
};

export default useProfileMenu;
