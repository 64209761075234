import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export interface ChangeReplyToParams {
  email: string;
}

export type ChangeReplyToResult = unknown;

const ReplyTo = {
  changeReplyTo: apiReqSagaCreator<ChangeReplyToParams, ChangeReplyToResult>(
    API_REQ_ACTION_TYPES.REPLY_TO.CHANGE_REPLY_TO,
    (args) => ({
      method: HTTP_METHODS.PUT,
      url: ApiPaths.replyTo._(),
      data: {
        replyTo: args.email,
      },
    })
  ),
};

export default ReplyTo;
