enum UiUrlParams {
  releaseOrDraftProcessId = 'releaseOrDraftProcessId',
  draftProcessId = 'draftProcessId',
  id = 'id',
  source = 'source',
  token = 'token',
  stageId = 'stageId',
  groupId = 'groupId',
  ott = 'ott',
  inboundEmailScenarioId = 'inboundEmailScenarioId',
  ftpPollingScenarioId = 'ftpPollingScenarioId',
  processVersionId = 'processVersionId',
  processInstanceId = 'processInstanceId',
  stepId = 'stepId',
  formId = 'formId',
  fieldId = 'fieldId',
  functionId = 'functionId',
  tmsConnectionId = 'tmsConnectionId',
  ftpConnectionId = 'ftpConnectionId',
  outboundEmailConnectionId = 'outboundEmailConnectionId',
  splitDocInstanceId = 'splitDocInstanceId',
  emailTemplateId = 'emailTemplateId',
  printRenderScenarioId = 'printRenderScenarioId',
  printRenderInstanceId = 'printRenderInstanceId',
  scanInstanceId = 'scanInstanceId',
  scanConfigurationId = 'scanConfigurationId',
}

export default UiUrlParams;
