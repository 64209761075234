import ROUTES from 'app/routes/paths';
import { UiWithReleaseOrDraftProcessId } from 'app/routes/uiPathParams';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { setSubmitSucceeded } from 'redux-form';
import { fork, put, select } from 'redux-saga/effects';

import ProcessScan from 'api/endpoints/processScan';

import ApiUrlParams from 'constants/apiUrlParams';
import { FORM_NAMES } from 'constants/form';

function* reFetchScanList() {
  const { query, pathname } = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId },
  }: match<UiWithReleaseOrDraftProcessId> = yield matchPath(pathname, {
    path: ROUTES.process.definition.services.scanning._(),
  });

  yield fork(ProcessScan.getAllScanConfigurations, {
    [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
    ...query,
  });
  yield put(setSubmitSucceeded(FORM_NAMES.ADD_SCAN_CONFIGURATION));
}

export default reFetchScanList;
