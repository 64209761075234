import { fork } from 'redux-saga/effects';

import Grid, { SaveTemplateParams } from 'api/endpoints/grid';

function* reFetchTemplates({
  meta: { input },
}: {
  type: string;
  meta: { input: SaveTemplateParams };
}) {
  yield fork(Grid.getList, {
    processId: input.processId,
    type: input.type,
  });
}

export default reFetchTemplates;
