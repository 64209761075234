import { combineReducers } from 'redux';

import { PrintRenderScenarioWithHypermedia } from 'api/endpoints/printRender';

import { PRINT_RENDER_LOGS_ACTION_TYPES } from './actions';

export interface PrintRenderLogsState {
  activePrintRenderScenario: PrintRenderScenarioWithHypermedia | undefined;
}

const printRenderLogs = combineReducers({
  activePrintRenderScenario(
    state = null,
    {
      type,
      payload,
    }: ReduxAction<PrintRenderScenarioWithHypermedia | undefined>
  ) {
    switch (type) {
      case PRINT_RENDER_LOGS_ACTION_TYPES.SET_PRINT_RENDER_ACTIVE_SCENARIO:
        return payload;
      default:
        return state;
    }
  },
});

export default printRenderLogs;
