import { SearchTypes } from 'constants/global';

import subSelectorCreator from 'utils/subSelectorCreator';

import { FieldState } from './reducer';

const createFieldSelector = subSelectorCreator<FieldState>(
  (state) => state.field
);

const FieldSelectors = {
  getList: createFieldSelector(({ list }) => list),
  getTotal: createFieldSelector(({ total }) => total),
  getUniqueField: (type: SearchTypes) =>
    createFieldSelector(({ uniqueField }) => uniqueField[type]),
  getUniqueFields: createFieldSelector(({ uniqueField }) => uniqueField),
  getASFields: createFieldSelector(({ aSFields }) => aSFields),
  getFieldDetails: createFieldSelector(({ fieldDetails }) => fieldDetails),
  getQSField: createFieldSelector(({ qSField }) => qSField),
  getAliases: createFieldSelector(({ aliases }) => aliases),
  getAliasesTotal: createFieldSelector(({ aliasesTotal }) => aliasesTotal),
  getReFetchFieldsTrigger: createFieldSelector(
    ({ reFetchFieldsTrigger }) => reFetchFieldsTrigger
  ),
  getListByType: createFieldSelector(
    ({ listByType }) =>
      (type: string) =>
        listByType[type]
  ),
};

export default FieldSelectors;
