import ROUTES, { ExecutionDetailsUiParamsAll } from 'app/routes/paths';
import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';

import * as ERROR_CODES from 'constants/errorCodes';
import UiUrlParams from 'constants/uiUrlParams';

import { withDefaultPagination } from 'utils/formatters/string';

function* handleErrorSearch({
  payload,
  meta: {
    input: { releaseOrDraftProcessId },
  },
}: {
  type: string;
  payload: BackendError[];
  meta: { input: ExecutionDetailsUiParamsAll };
}) {
  if (payload.some((e) => e.code === ERROR_CODES.WRONG_SEARCH_FIELD_ID))
    yield put(
      push(
        withDefaultPagination(
          ROUTES.process.execution.details.all._({
            [UiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
          })
        )
      )
    );
}

export default handleErrorSearch;
