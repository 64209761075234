import ROUTES from 'app/routes/paths';
import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';

import { withDefaultPagination } from 'utils/formatters/string';

export const SKIP_REDIRECT_TITLE = 'SKIP_REDIRECT_TITLE';

function* moveFromRestrictedRoute({
  meta: { title },
}: ReduxAction<unknown, { title?: string }>) {
  if (title !== SKIP_REDIRECT_TITLE) {
    yield put(push(withDefaultPagination(ROUTES.process.execution._())));
  }
}

export default moveFromRestrictedRoute;
