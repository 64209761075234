import { put } from 'redux-saga/effects';

import createATA from 'utils/createAddToasterAction';

const MESSAGE = 'All changes are saved';

function* saveSettingSuccess() {
  yield put(
    createATA({
      message: MESSAGE,
    })
  );
}

export default saveSettingSuccess;
