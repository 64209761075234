import ROUTES, { ProcessInstanceUiParams } from 'app/routes/paths';
import { getLocation, push } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { put, select } from 'redux-saga/effects';

import { SplitParams } from 'api/endpoints/splitDocument';

import UiUrlParams from 'constants/uiUrlParams';

import { string } from 'utils/formatters';

export interface Params {
  type: string;
  payload: SplitParams & { fieldId: string; formToGet: string };
}

function* createDocumentSuccess({
  payload: { splitDocInstanceId, formToGet, fieldId },
}: Params) {
  const { pathname } = yield select(getLocation);

  const { params }: match<ProcessInstanceUiParams> = yield matchPath(pathname, {
    path: [ROUTES.process.instance._()],
  });

  yield put(
    push(
      string.withDefaultPagination(
        ROUTES.process.instance.splitDocuments({
          ...params,
          [UiUrlParams.splitDocInstanceId]: splitDocInstanceId,
          [UiUrlParams.formId]: formToGet,
          [UiUrlParams.fieldId]: fieldId,
        }),
        5
      )
    )
  );
}

export default createDocumentSuccess;
