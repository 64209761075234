import cn from 'classnames';
import React, { memo, MutableRefObject, useCallback } from 'react';

import DropDownListItem from 'components/molecules/dropDownListItem';

import sm from './styles.module.scss';
import { UseDropDownList } from './types';
import useLogic from './useLogic';

export interface OptionRefs {
  [key: string]: HTMLButtonElement;
}

export interface Props {
  items: SelectItem[];
  onClick: (value: SelectItem) => void;
  selectedValue?: SelectItem | string;
  withSmallerIndent?: boolean;
  isNested?: boolean;
  parentRefs?: MutableRefObject<OptionRefs>;
  renderPostIcon?: (e: SelectItem | string) => Nullable<JSX.Element>;
}

export type RenderItem = (item: SelectItem) => JSX.Element;

export const DropDownList = ({
  items,
  selectedValue,
  onClick,
  withSmallerIndent,
  isNested,
  parentRefs,
  renderPostIcon,
}: Props) => {
  const { handleSetRef, handleKeyPress, refs }: UseDropDownList = useLogic(
    items,
    parentRefs
  );

  const renderItem: RenderItem = useCallback(
    (item) => {
      const isActive =
        typeof selectedValue === 'string'
          ? item.value === selectedValue
          : item.value === selectedValue?.value;

      return (
        <div key={item.value}>
          <DropDownListItem
            item={item as SelectItemGeneral}
            isActive={isActive}
            onClick={onClick}
            withLeftPadding={isNested}
            setRef={handleSetRef(item)}
            withSmallerIndent={withSmallerIndent}
            renderPostIcon={renderPostIcon}
          />
          {item.nested && (
            <DropDownList
              isNested
              parentRefs={refs.current as MutableRefObject<OptionRefs>}
              items={item.nested}
              selectedValue={selectedValue}
              onClick={onClick}
              withSmallerIndent={withSmallerIndent}
              renderPostIcon={renderPostIcon}
            />
          )}
        </div>
      );
    },
    [
      handleSetRef,
      isNested,
      onClick,
      refs,
      renderPostIcon,
      selectedValue,
      withSmallerIndent,
    ]
  );

  return (
    <div
      onKeyDown={isNested ? undefined : handleKeyPress}
      className={cn(sm.SelectList, {
        [sm.SelectList_WithSmallerIndent]: withSmallerIndent,
      })}
    >
      {items?.map(renderItem)}
    </div>
  );
};
export default memo(DropDownList);
