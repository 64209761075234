import ROUTES from 'app/routes/paths';
import { UiWithReleaseOrDraftProcessId } from 'app/routes/uiPathParams';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { fork, select } from 'redux-saga/effects';

import ProcessForm from 'api/endpoints/processForm';

import ApiUrlParams from 'constants/apiUrlParams';

function* reFetchForms() {
  const { query, pathname } = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId },
  }: match<UiWithReleaseOrDraftProcessId> = yield matchPath(pathname, {
    path: [
      ROUTES.process.definition.details.forms(),
      ROUTES.process.editProcess.forms._(),
    ],
  });

  yield fork(ProcessForm.getAllForms, {
    [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
    ...query,
  });
}

export default reFetchForms;
