import { stopAsyncValidation } from 'redux-form';
import { put } from 'redux-saga/effects';

import * as ERROR_CODES from 'constants/errorCodes';
import { ERROR_KEYS } from 'constants/fieldErrors';
import { FORM_NAMES } from 'constants/form';

function* createUpdateFailure({
  payload,
}: {
  type: string;
  payload: BackendError[];
}) {
  if (payload.some((e) => e.code === ERROR_CODES.DUPLICATE_FUNCTION_NAME)) {
    yield put(
      stopAsyncValidation(FORM_NAMES.CREATE_ENTITY, {
        name: {
          key: ERROR_KEYS.DUPLICATES_FUNCTION_NAME,
        },
      })
    );
  }
}

export default createUpdateFailure;
