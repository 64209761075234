import BaseRepo from 'container/BaseRepo';
import { ApiResponseAsync } from 'container/models';

import { JsonPatch } from '../../../api/types/jsonPatch';
import { OdataURLBuilder } from '../../../api/types/OdataURLBuilder';

export interface IndexingSource {
  id: string;
  name: string;
  createdAt: string;
  preserveSource: boolean;
}
export type IndexingSourceWithHypermedia = IndexingSource & WithHypermedia;

export abstract class AbstractIndexingSourcesRepo extends BaseRepo {
  abstract searchIndexingSources(
    request: OdataURLBuilder
  ): ApiResponseAsync<Array<IndexingSourceWithHypermedia>>;

  abstract createIndexingSource(
    processId: string,
    name: string,
    preserveSource: boolean
  ): ApiResponseAsync<IndexingSourceWithHypermedia>;

  abstract updateIndexingSource(
    requestURL: string,
    patch: JsonPatch[]
  ): ApiResponseAsync<IndexingSourceWithHypermedia>;
}
