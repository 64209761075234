import { call, put } from 'redux-saga/effects';

import createATA from 'utils/createAddToasterAction';

import reFetchForms from './reFetchForms';

const SUCCESS_MSG = 'The form was successfully deleted';

function* afterSuccessDeleteForm() {
  yield put(
    createATA({
      message: SUCCESS_MSG,
    })
  );

  yield call(reFetchForms);
}

export default afterSuccessDeleteForm;
