import BaseRepo from 'container/BaseRepo';
import { ApiResponseAsync } from 'container/models';

import { ProcessInstanceDetails } from 'api/endpoints/processInstance';

import { ScanInstanceGeneral } from '../../../api/endpoints/processScan';

export interface IndexDocument extends BaseEntity {
  processDraftId: string;
  processInstanceId: string;
  processReleaseId: string;
  indexingSourceId: string;
  fileFieldId: string;
}

export type IndexDocumentWithHyperMedia = IndexDocument & WithHypermedia;

export type LockDocumentResponse = {
  document: IndexDocumentWithHyperMedia;
  instance: ScanInstanceGeneral;
};

export type ProcessInstanceShort = {
  id: string;
  name: string;
};

// Change data model based on backend agreement within WO-728
export type IndexDocumentResponse = {
  documentId: string;
  errorMessage?: string;
  processInstance?: ProcessInstanceShort;
};

export type FinishIndexDocumentResponse = {
  results: IndexDocumentResponse[];
};

export abstract class AbstractIndexingDocumentsRepo extends BaseRepo {
  abstract getProcessInstance(
    link: Link
  ): ApiResponseAsync<ProcessInstanceDetails>;

  abstract upsert(
    link: Link,
    name: string,
    draftProcessId: string,
    processInstanceId: string,
    indexingSourceId: string,
    fileFieldId: string
  ): ApiResponseAsync<IndexDocumentWithHyperMedia>;

  abstract lock(
    link: Link,
    scanConfigurationId: string,
    indexingDocumentId: string
  ): ApiResponseAsync<LockDocumentResponse>;

  abstract lockNext(
    link: Link
  ): ApiResponseAsync<Nullable<LockDocumentResponse>>;

  abstract unLock(link: Link): ApiResponseAsync<IndexDocumentWithHyperMedia>;

  abstract finish(link: Link): ApiResponseAsync<FinishIndexDocumentResponse>;

  abstract deleteDoc(
    document: IndexDocumentWithHyperMedia
  ): ApiResponseAsync<null>;

  abstract getDocumentCount(
    link: Link,
    indexingSourceId: string
  ): ApiResponseAsync<number>;
}
