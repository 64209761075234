import { call, put } from 'redux-saga/effects';

import SecurityGroups, {
  SecurityGroupUserGeneral,
} from 'api/endpoints/securityGroups';

import { SecurityGroupsActions } from 'store/securityGroups/actions';

export type FetchGroupDetailsArgs = [string, string | undefined];

function* fetchGroupDetails(releaseOrDraftProcessId: string, groupId?: string) {
  if (!groupId) return;

  const params = { releaseOrDraftProcessId, groupId };

  const { result: details } = yield call(
    SecurityGroups.getGroupDetails,
    params
  );

  const {
    result: { data: members },
  } = yield call(SecurityGroups.getGroupMembers, params);

  yield put(
    SecurityGroupsActions.setDetails({
      ...details,
      users: (members || []).filter(
        (e: SecurityGroupUserGeneral) => e.status === 'active'
      ),
    })
  );
}

export default fetchGroupDetails;
