import { put } from 'redux-saga/effects';

import createAddToasterAction from 'utils/createAddToasterAction';

const MESSAGE = 'Stage was saved successfully';

function* updateCreateStageSuccess() {
  yield put(
    createAddToasterAction({
      message: MESSAGE,
    })
  );
}

export default updateCreateStageSuccess;
