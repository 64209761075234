import { call, select } from 'redux-saga/effects';

import Function, { ReusableFunctionGeneral } from 'api/endpoints/function';
import { ProcessDetails } from 'api/endpoints/processes';

import ProcessSelectors from 'store/process/selectors';

import ApiUrlParams from 'constants/apiUrlParams';

function* handleFunctionCreate({
  payload,
}: {
  type: string;
  payload: ReusableFunctionGeneral;
}) {
  const processDetails: ProcessDetails = yield select(
    ProcessSelectors.getDetails
  );

  yield call(Function.getDetails, {
    [ApiUrlParams.functionId]: payload.id,
    [ApiUrlParams.releaseOrDraftProcessId]: processDetails.id,
  });
}

export default handleFunctionCreate;
