import { stopAsyncValidation } from 'redux-form';
import { put } from 'redux-saga/effects';

import * as ERROR_CODES from 'constants/errorCodes';
import { ERROR_KEYS } from 'constants/fieldErrors';
import { FORM_NAMES } from 'constants/form';

function* saveScenarioError({
  payload,
}: {
  type: string;
  payload: BackendError[];
}) {
  if (payload.some((e) => e.code === ERROR_CODES.BASE_CLIENT_ERROR)) {
    yield put(
      stopAsyncValidation(FORM_NAMES.CREATE_EMAIL_POLLING_SCENARIO, {
        name: { key: ERROR_KEYS.DUPLICATES_EMAIL_POLLING_SCENARIO_NAME },
      })
    );
  }
}

export default saveScenarioError;
