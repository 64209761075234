import cn from 'classnames';
import React, { PropsWithChildren, ReactPortal } from 'react';
import { createPortal } from 'react-dom';

import { PORTAL_ROOT_IDS } from 'constants/global';

import sm from './styles.module.scss';

interface Props {
  isVisible?: boolean;
  leaveInDom?: boolean;
  containerId?: string;
  key?: Parameters<typeof createPortal>[2];
}

export default function Portal({
  isVisible,
  leaveInDom,
  children,
  containerId = PORTAL_ROOT_IDS.MODAL,
  key,
}: PropsWithChildren<Props>) {
  const modalRoot = document.getElementById(containerId) as HTMLElement;

  if (leaveInDom)
    return createPortal(
      <div
        className={cn(sm.Container, {
          [sm.Container_Visible]: isVisible,
        })}
      >
        {children}
      </div>,
      modalRoot,
      key
    ) as ReactPortal;

  return createPortal(
    <div>{isVisible && children}</div>,
    modalRoot,
    key
  ) as ReactPortal;
}
