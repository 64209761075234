import { authHeaderName } from 'api/apiReqSagaCreator/config';
import { handleExpiredTokens } from 'api/apiReqSagaCreator/httpRequest';

import { PrintRenderScenarioWithHypermedia } from '../../../api/endpoints/printRender';
import { OdataURLBuilder } from '../../../api/types/OdataURLBuilder';
import { ApiResponseAsync, translateAxiosErrorToApiError } from '../../models';
import {
  AbstractPrintRenderLogRepo,
  PrintRenderLog,
} from './abstractPrintRenderRepo';

export class PrintRenderLogsRepo extends AbstractPrintRenderLogRepo {
  getLogs = (
    request: OdataURLBuilder
  ): ApiResponseAsync<DataWithTotal<PrintRenderLog>> =>
    handleExpiredTokens(() =>
      this.client.get<DataWithTotal<PrintRenderLog>>(request.toString(), {
        headers: {
          [authHeaderName]: this.tokenAccessor(),
        },
      })
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);

  getScenarios = (
    processId: string
  ): ApiResponseAsync<DataWithTotal<PrintRenderScenarioWithHypermedia>> =>
    handleExpiredTokens(() =>
      this.client.get<DataWithTotal<PrintRenderScenarioWithHypermedia>>(
        `/processes/${processId}/print-render/scenarios`,
        {
          headers: {
            [authHeaderName]: this.tokenAccessor(),
          },
        }
      )
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);
}
