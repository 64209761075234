import ROUTES from 'app/routes/paths';
import { UiWithReleaseOrDraftProcessId } from 'app/routes/uiPathParams';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { fork, select } from 'redux-saga/effects';

import PrintRender from 'api/endpoints/printRender';

import ApiUrlParams from 'constants/apiUrlParams';

function* reFetchAllPrintRenderScenarios() {
  const { query, pathname } = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId },
  }: match<UiWithReleaseOrDraftProcessId> = yield matchPath(pathname, {
    path: ROUTES.process.definition.services.printRender._(),
  });

  const body = { releaseOrDraftProcessId, ...query };

  yield fork(PrintRender.getAllScenarios, body, {
    withPrevPageOnLessTotal: true,
  });

  yield fork(PrintRender.getAllScenarios, {
    [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
    isActive: true,
  });
}

export default reFetchAllPrintRenderScenarios;
