import { SystemStatus } from 'constants/global';

import createAction from 'utils/createAction';

const USERS = {
  USER_WAS_EDITED_SUCCESSFULLY: 'USERS.USER_WAS_EDITED_SUCCESSFULLY',
  CLEAR_USER_DETAILS: 'USERS.CLEAR_USER_DETAILS',
  UPDATE_USER_STATUS: 'USERS.UPDATE_USER_STATUS',
};

const UsersActions = {
  userWasEditedSuccessfully: createAction(USERS.USER_WAS_EDITED_SUCCESSFULLY),
  clearUserDetails: createAction(USERS.CLEAR_USER_DETAILS),
  updateUserStatus: createAction<SystemStatus>(USERS.UPDATE_USER_STATUS),
};

export { UsersActions, USERS as USERS_ACTION_TYPES };
