import { Management, ManagementConnectionsDetails } from 'app/routes/lazyPages';
import ROUTES from 'app/routes/paths';
import onlySignedInPage from 'hocs/onlySignedInPage';

import { ACCESSIBLE_ROLES } from 'constants/roles';

import { accessOrDefaultRedirect } from 'utils/accessOrDefaultRedirect';

const managementRoutes: RoutesConfig = [
  {
    path: [
      ROUTES.management.connections.tms.details._(),
      ROUTES.management.connections.ftp.details._(),
    ],
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.ADMIN)(
        ManagementConnectionsDetails
      )
    ),
    exact: true,
  },
  {
    path: ROUTES.management._(),
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.MANAGERS)(Management)
    ),
    exact: false,
  },
];

export { managementRoutes };
