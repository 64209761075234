import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import {
  createTemplateFailure,
  deleteTemplateSuccess,
  reFetchList,
} from './sagas';

function* watcher() {
  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.PRINT_RENDER.EMAIL_TEMPLATES.CREATE.ERROR,
      API_REQ_ACTION_TYPES.PRINT_RENDER.EMAIL_TEMPLATES.UPDATE.ERROR,
    ],
    createTemplateFailure
  );

  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.PRINT_RENDER.EMAIL_TEMPLATES.CREATE.SUCCESS,
      API_REQ_ACTION_TYPES.PRINT_RENDER.EMAIL_TEMPLATES.UPDATE.SUCCESS,
      API_REQ_ACTION_TYPES.PRINT_RENDER.EMAIL_TEMPLATES.DELETE.SUCCESS,
    ],
    reFetchList
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.PRINT_RENDER.EMAIL_TEMPLATES.DELETE.SUCCESS,
    deleteTemplateSuccess
  );
}

export default watcher;
