import ROUTES, { ScanScenarioInstanceUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { getFormValues } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';
import { FullScanConfiguration } from 'types/scanning';

import { ApiSagaResult } from 'api/apiReqSagaCreator';

import normalizedFormFiles from 'store/process/sagas/normalizedFormFiles';
import ScanConfigSelector from 'store/processScanConfig/selectors';

import ApiUrlParams from 'constants/apiUrlParams';
import { FORM_NAMES } from 'constants/form';

import { ProcessScanningActions, SetSavedPagesAction } from '../actions';
import ScanningSelector from '../selectors';
import createDocument from './createDocument';
import createManualScenario from './createManualScenario';

function* saveScanningData(
  scanInstanceId: string,
  pageName: string,
  page: File,
  barcode: string
) {
  const { pathname } = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId },
  }: match<ScanScenarioInstanceUiParams> = yield matchPath(pathname, {
    path: ROUTES.process.scanningScenarioOld(),
  });

  const uploadedFiles: Nullable<KeyValuePairs<File>> = yield select(
    ScanningSelector.getUploadedFiles
  ) ?? {};

  const savedPages: SetSavedPagesAction['payload'] = yield select(
    ScanningSelector.getSavedPages
  );

  const prevFormData: Nullable<DynamicFormParams> = yield select(
    ScanningSelector.getPrevFormData
  );

  const scanningDetails: Nullable<FullScanConfiguration> = yield select(
    ScanConfigSelector.getFiledDetails
  );

  const currentFormData: DynamicFormParams = yield select(
    getFormValues(FORM_NAMES.SCANNING_INDEXING)
  );

  const pageNames = [...(savedPages?.names || []), pageName];

  const pageFiles = [...(savedPages?.files || []), page];

  yield put(
    ProcessScanningActions.setSavedPages({
      names: pageNames,
      files: pageFiles,
    })
  );

  yield put(ProcessScanningActions.saveFormData(currentFormData || {}));

  const selectedFiles = Object.entries(currentFormData || {}).filter(
    ([, value]) => value instanceof File
  );

  const restFields = Object.fromEntries(
    Object.entries(currentFormData || {}).filter(
      ([, value]) => !(value instanceof File)
    )
  ) as KeyValuePairs;

  const needToUpdateFiles = Object.fromEntries(
    selectedFiles.filter(
      ([fieldId, file]) =>
        ((prevFormData ?? {})[fieldId] as File)?.name !== (file as File).name
    )
  ) as KeyValuePairs<File>;

  const transformCurrentFormData: DynamicFormNormalizedParams = yield call(
    normalizedFormFiles,
    {
      form: FORM_NAMES.SCANNING_INDEXING,
      [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
      [ApiUrlParams.processInstanceId]: scanInstanceId,
      fields: { ...restFields, ...uploadedFiles, ...needToUpdateFiles },
    }
  );
  if (transformCurrentFormData && Object.values(needToUpdateFiles).length) {
    yield put(
      ProcessScanningActions.setUploadedFiles(
        Object.fromEntries(
          Object.entries(transformCurrentFormData).filter(
            ([key]) => needToUpdateFiles[key]
          )
        ) as KeyValuePairs
      )
    );
  }

  const transformPrevFormData: DynamicFormNormalizedParams = yield call(
    normalizedFormFiles,
    {
      form: FORM_NAMES.SCANNING_INDEXING,
      [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
      [ApiUrlParams.processInstanceId]: scanInstanceId,
      fields: { ...prevFormData, ...uploadedFiles },
    }
  );

  // Creating of document-containers implement in saveBarcodePerDocumentData saga
  switch (scanningDetails?.strategy?.type) {
    case 'barcodePerPage': {
      const createDocumentResponse: ApiSagaResult<any> = yield call(
        createDocument,
        scanInstanceId,
        transformCurrentFormData,
        [pageName],
        true,
        barcode
      );
      return createDocumentResponse;
    }
    case 'manual': {
      const createManualScenarioResult: ApiSagaResult<any> = yield call(
        createManualScenario,
        scanInstanceId,
        transformCurrentFormData || null,
        transformPrevFormData,
        [pageName]
      );

      yield put(
        ProcessScanningActions.setIsPageSaved(createManualScenarioResult.ok)
      );
      return createManualScenarioResult;
    }
    default: {
      const defaultResponse: ApiSagaResult<any> = { ok: true, result: null };
      return defaultResponse;
    }
  }
}

export default saveScanningData;
