import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import {
  afterSuccessDeleteForm,
  createFormFailure,
  editInitialFormError,
  reFetchFormDetails,
  reFetchProcessesForms,
  unSetAsInitialSuccess,
} from './sagas';

function* watcherSaga() {
  yield takeLatest(
    API_REQ_ACTION_TYPES.FORM.DELETE_FORM_BY_ID.SUCCESS,
    afterSuccessDeleteForm
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.FORM.UPDATE_FORM_BY_ID.SUCCESS,
    reFetchFormDetails
  );

  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.PROCESSES.CREATE_FORM.ERROR,
      API_REQ_ACTION_TYPES.FORM.UPDATE_FORM_BY_ID.ERROR,
    ],
    createFormFailure
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.FORM.UPDATE_FORM_BY_ID.SUCCESS,
    unSetAsInitialSuccess
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.FORM.UPDATE_FORM_BY_ID.SUCCESS],
    reFetchProcessesForms
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.FORM.UPDATE_FORM_BY_ID.ERROR],
    editInitialFormError
  );
}

export default watcherSaga;
