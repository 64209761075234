import createAction from 'utils/createAction';

const GLOBAL = {
  TOGGLE_PAGE_WRAP_VISIBILITY: 'GLOBAL.TOGGLE_PAGE_WRAP_VISIBILITY',
  TOGGLE_OPENED_MODAL: 'GLOBAL.TOGGLE_OPENED_MODAL',
};

const GlobalActions = {
  togglePageWrapVisibility: createAction(GLOBAL.TOGGLE_PAGE_WRAP_VISIBILITY),
  manageOpenedModals: createAction<{ id: string; remove?: boolean }>(
    GLOBAL.TOGGLE_OPENED_MODAL
  ),
};

export type ToggleModalCloseCallbacks = ReturnType<
  typeof GlobalActions.manageOpenedModals
>;

export { GLOBAL as GLOBAL_ACTION_TYPES, GlobalActions };
