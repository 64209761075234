import { put } from 'redux-saga/effects';

import createATA from 'utils/createAddToasterAction';

function* revokeInvitationSuccess() {
  yield put(
    createATA({
      message: 'Invitation successfully canceled.',
    })
  );
}

export default revokeInvitationSuccess;
