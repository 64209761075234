import ROUTES, { EmailTemplatesPreviewUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { fork, select } from 'redux-saga/effects';

import EmailTemplates from 'api/endpoints/emailTemplates';

import ApiUrlParams from 'constants/apiUrlParams';

function* reFetchList() {
  const { pathname, query } = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId },
  }: match<EmailTemplatesPreviewUiParams> = yield matchPath(pathname, {
    path: [
      ROUTES.process.definition.details.version.emailTemplates(),
      ROUTES.process.definition.details.emailTemplates(),
      ROUTES.process.editProcess.emailTemplates._(),
    ],
  });

  yield fork(
    EmailTemplates.getList,
    {
      ...query,
      [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
    },
    { withPrevPageOnLessTotal: true }
  );
}

export default reFetchList;
