import { call } from 'redux-saga/effects';

import { Fields } from 'components/organisms/form/checkGSuitCreds/types';

import emailproviders, {
  ValidateCredentialsParams,
  ValidateCredentialsResult,
} from 'api/endpoints/emailproviders';

import { ApiSagaResult } from '../../../api/apiReqSagaCreator';

function* checkCredentials(credentials: Fields, emailProvider: string) {
  const result: ApiSagaResult<ValidateCredentialsResult> = yield call(
    emailproviders.validateCredentials,
    {
      ...credentials,
      provider: emailProvider,
    } as ValidateCredentialsParams
  );
  return result;
}

export default checkCredentials;
