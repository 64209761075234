import { combineReducers } from 'redux';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import {
  TmsConnectionDetails,
  TmsConnectionGeneral,
  GetTmsConnectionDetailsResult,
  GetTmsConnectionsResult,
} from 'api/endpoints/tmsConnections';

import { SystemStatus } from 'constants/global';

import { TMS_CONNECTIONS_ACTION_TYPES } from './actions';

export interface TmsConnectionsState {
  list: Nullable<TmsConnectionGeneral[]>;
  total: Nullable<number>;
  details: Nullable<TmsConnectionDetails>;
  credentialsStatus: Nullable<SystemStatus.Valid | SystemStatus.InValid>;
}

const tmsConnections = combineReducers<TmsConnectionsState>({
  list(state = null, { type, payload }: ReduxAction<GetTmsConnectionsResult>) {
    switch (type) {
      case API_REQ_ACTION_TYPES.TMS_CONNECTIONS.GET_CONNECTIONS.SUCCESS:
        return payload ? payload.data : null;
      default:
        return state;
    }
  },
  total(state = null, { type, payload }: ReduxAction<GetTmsConnectionsResult>) {
    switch (type) {
      case API_REQ_ACTION_TYPES.TMS_CONNECTIONS.GET_CONNECTIONS.SUCCESS:
        return payload ? payload.total : null;
      default:
        return state;
    }
  },
  details(
    state = null,
    { type, payload }: ReduxAction<GetTmsConnectionDetailsResult>
  ) {
    switch (type) {
      case TMS_CONNECTIONS_ACTION_TYPES.CLEAR_CONNECTION_DETAILS:
      case API_REQ_ACTION_TYPES.TMS_CONNECTIONS.GET_CONNECTION_DETAILS.SUCCESS:
        return payload || null;
      default:
        return state;
    }
  },
  credentialsStatus(
    state = null,
    { type, payload }: ReduxAction<boolean | undefined>
  ) {
    switch (type) {
      case TMS_CONNECTIONS_ACTION_TYPES.SET_USER_CREDENTIALS_STATUS:
        if (typeof payload !== 'boolean') return null;
        return payload ? SystemStatus.Valid : SystemStatus.InValid;
      default:
        return state;
    }
  },
});

export default tmsConnections;
