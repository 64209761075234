import { authHeaderName } from 'api/apiReqSagaCreator/config';
import { handleExpiredTokens } from 'api/apiReqSagaCreator/httpRequest';

import { ApiResponseAsync, translateAxiosErrorToApiError } from '../../models';
import { AbstractScanRepo } from './abstractScanRepo';

export class ScanRepo extends AbstractScanRepo {
  deleteScanInstance = (
    processId: string,
    scanInstanceId: string
  ): ApiResponseAsync<any> =>
    handleExpiredTokens(() =>
      this.client.delete<any>(
        `/processes/${processId}/scan/instances/${scanInstanceId}`,
        {
          headers: {
            [authHeaderName]: this.tokenAccessor(),
          },
        }
      )
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);
}
