import ROUTES from 'app/routes/paths';
import { UiWithReleaseOrDraftProcessId } from 'app/routes/uiPathParams';
import { getLocation, push } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { Action } from 'redux';
import { fork, put, select } from 'redux-saga/effects';

import ProcessesForm from 'api/endpoints/processForm';

import ApiUrlParams from 'constants/apiUrlParams';
import UiUrlParams from 'constants/uiUrlParams';

import { string } from 'utils/formatters';

interface TaskAction extends Action, TaskEvent {
  type: string;
}

interface TaskEvent {
  meta: {
    input: {
      isUpdateInDetails?: boolean;
      stay?: boolean;
    };
  };
}

function* reFetchProcessesForms({ meta }: TaskAction) {
  const { pathname, query } = yield select(getLocation);

  const getMatch: match<UiWithReleaseOrDraftProcessId> = yield matchPath(
    pathname,
    {
      path: [
        ROUTES.process.createProcessForm(),
        ROUTES.process.editProcessForm(),
      ],
    }
  );

  const getMatchByProcessEditor: match<UiWithReleaseOrDraftProcessId> =
    yield matchPath(pathname, {
      path: [
        ROUTES.process.editProcess.forms.create(),
        ROUTES.process.editProcess.forms.edit(),
      ],
    });

  if (getMatch && !meta.input.stay) {
    yield put(
      push(
        string.withDefaultPagination(
          ROUTES.process.definition.details.forms({
            [UiUrlParams.releaseOrDraftProcessId]:
              getMatch.params[UiUrlParams.releaseOrDraftProcessId],
          })
        )
      )
    );
  } else if (getMatchByProcessEditor && !meta.input.stay) {
    yield put(
      push(
        string.withDefaultPagination(
          ROUTES.process.editProcess.forms._({
            [UiUrlParams.releaseOrDraftProcessId]:
              getMatchByProcessEditor.params[
                UiUrlParams.releaseOrDraftProcessId
              ],
          })
        )
      )
    );
  } else if (!meta.input?.isUpdateInDetails) {
    const {
      params: { releaseOrDraftProcessId },
    }: match<UiWithReleaseOrDraftProcessId> = yield matchPath(pathname, {
      path: [
        ROUTES.process.definition.details.forms(),
        ROUTES.process.editProcess.forms._(),
      ],
    });

    yield fork(ProcessesForm.getAllForms, {
      [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
      ...query,
    });
  }
}

export default reFetchProcessesForms;
