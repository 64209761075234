import { NormalizedSplitDocument } from 'types/splitDocument';

import createAction from 'utils/createAction';

const SPLIT_DOCUMENTS = {
  SET_LIST: 'SPLIT_DOCUMENTS.SET_LIST',
  SET_RECEIVED_PAGES: 'SPLIT_DOCUMENTS.SET_RECEIVED_PAGES',
  CLEAR_STATE: 'SPLIT_DOCUMENTS.CLEAR_STATE',
  CLEAR_DETAILS: 'SPLIT_DOCUMENTS.CLEAR_DETAILS',
};

const SplitDocumentsActions = {
  setList: createAction<NormalizedSplitDocument[]>(SPLIT_DOCUMENTS.SET_LIST),
  setReceivedPages: createAction<KeyValuePairs>(
    SPLIT_DOCUMENTS.SET_RECEIVED_PAGES
  ),
  clearState: createAction(SPLIT_DOCUMENTS.CLEAR_STATE),
  clearDetails: createAction(SPLIT_DOCUMENTS.CLEAR_DETAILS),
};

export type SetListAction = ReturnType<typeof SplitDocumentsActions.setList>;

export type SetReceivedPagesAction = ReturnType<
  typeof SplitDocumentsActions.setReceivedPages
>;

export {
  SPLIT_DOCUMENTS as SPLIT_DOCUMENTS_ACTION_TYPES,
  SplitDocumentsActions,
};
