import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import { SECURITY_GROUPS_ACTION_TYPES } from './actions';
import { reFetchList, saveGroupFailure, successCreateGroup } from './sagas';

function* watcherSaga() {
  yield takeLatest(
    [SECURITY_GROUPS_ACTION_TYPES.MEMBERS_WAS_UPDATED_AND_SAVED],
    successCreateGroup
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.PROCESSES.SECURITY_GROUPS.REMOVE.SUCCESS],
    reFetchList
  );

  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.PROCESSES.SECURITY_GROUPS.CREATE.ERROR,
      API_REQ_ACTION_TYPES.PROCESSES.SECURITY_GROUPS.UPDATE.ERROR,
    ],
    saveGroupFailure
  );
}

export default watcherSaga;
