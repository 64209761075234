import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import {
  leaveReIndexSuccess,
  reFetchDocuments,
  removeDocumentError,
  splitDocumentError,
  updatePageSuccess,
} from './sagas';

function* watcherSaga() {
  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.CREATE_DOCUMENT.SUCCESS,
      API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.BULK_UPDATE_DOCUMENTS.SUCCESS,
      API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.DELETE_DOCUMENT.SUCCESS,
      API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.UPDATE_DOCUMENT.SUCCESS,
      API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.UPDATE_PAGE.SUCCESS,
    ],
    reFetchDocuments
  );

  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.SPLIT.ERROR,
      API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.SPLIT.SUCCESS,
    ],
    splitDocumentError
  );

  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.FINISH.SUCCESS,
      API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.CANCEL.SUCCESS,
    ],
    leaveReIndexSuccess
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.DELETE_DOCUMENT.ERROR,
    removeDocumentError
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.SPLIT_DOCUMENT.UPDATE_PAGE.SUCCESS,
    updatePageSuccess
  );
}

export default watcherSaga;
