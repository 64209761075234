import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware, { Saga, SagaMonitor, Task } from 'redux-saga';

import createRootReducer from './createRootReducer';
import rootSaga from './rootSaga';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: <F extends Function>(f: F) => F;
    __SAGA_MONITOR_EXTENSION__: SagaMonitor;
  }
}

const { REACT_APP_WITH_REDUX_LOGGER: withReduxLogger } = process.env;

const monitor =
  process.env.NODE_ENV === 'development' && window.__SAGA_MONITOR_EXTENSION__
    ? window.__SAGA_MONITOR_EXTENSION__
    : undefined;

const sagaMiddleware = createSagaMiddleware({
  sagaMonitor: monitor,
  onError: (error) => {
    console.error(
      'saga middleware error; message: "' +
        error.message +
        '"; stack: "' +
        error.stack
    );
    //   Sentry.captureException(args);
  },
});

const composeEnhancers =
  process.env.NODE_ENV === 'development' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

export const initialRootState = {};

export const history = createBrowserHistory();

export type RunSaga<S extends unknown[]> = (
  saga: Saga<S>,
  ...args: Parameters<Saga<S>>
) => Task;

export interface ConfigureStore<T extends unknown[]> {
  runSaga: RunSaga<T>;
}

export default function configureStore(initialState = { ...initialRootState }) {
  const runSaga: RunSaga<unknown[]> = (saga, ...args) =>
    sagaMiddleware.run(saga, ...args);
  return {
    ...createStore(
      createRootReducer(history),
      initialState,
      composeEnhancers(
        applyMiddleware(
          ...[
            sagaMiddleware,
            routerMiddleware(history),
            withReduxLogger ? logger : undefined,
          ].filter(Boolean)
        )
      )
    ),
    runSaga,
  };
}

export const runRootSaga = () => sagaMiddleware.run(rootSaga);
