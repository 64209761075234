import React, { memo, useMemo } from 'react';

import Text from 'components/atoms/text';
import Modal from 'components/molecules/modal';
import Tooltip from 'components/molecules/tooltip';

import { MAC_SHORTCUTS, TEXTS, WIN_SHORTCUTS } from './config';
import sm from './styles.module.scss';

interface Props {
  isVisible: boolean;
  onClose: EmptyArgsVoidFunc;
}

const ShortcutsModal = ({ isVisible, onClose }: Props) => {
  const shortcuts = useMemo(
    () =>
      navigator.appVersion.indexOf('Mac') !== -1
        ? MAC_SHORTCUTS
        : WIN_SHORTCUTS,
    []
  );

  return (
    <Modal
      withBiggestZIndex
      label={TEXTS.LABEL}
      isVisible={isVisible}
      submitConfig={useMemo(
        () => ({
          label: TEXTS.OK,
          onClick: onClose,
        }),
        [onClose]
      )}
    >
      <div className={sm.Modal}>
        <div className={sm.Modal_Shortcuts}>
          {shortcuts.map((shortcut) => (
            <div className={sm.Modal_Shortcuts_Item} key={shortcut.label}>
              <Text content={shortcut.label} with16Font />
              <div className={sm.Modal_Shortcuts_Item_Keys}>
                {shortcut.keys.map(
                  (key: { label: string; tooltip?: string }) => (
                    <div
                      key={key.label}
                      className={sm.Modal_Shortcuts_Item_Keys_Key}
                    >
                      <Tooltip
                        hideBackground={!key.tooltip}
                        withBiggestZIndex
                        title={key.tooltip}
                      >
                        <Text content={key.label} />
                      </Tooltip>
                    </div>
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default memo(ShortcutsModal);
