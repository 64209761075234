import { AxiosInstance } from 'axios';

/**
 * BaseRepo handles the boilerplate of setting up a repository, specifically the
 * axios client and the token accessor.
 *
 * Instead of being fully private, which means that no class *except* BaseRepo
 * can access it, we use public getters to restrict access only to BaseRepo and
 * subclass instances.
 *
 * Add it at the top of the inheritance chain, like so:
 * ```ts
 *   abstract class AbstractFooRepo extends BaseRepo {}
 *   class FooRepo extends AbstractFooRepo {}
 * ```
 */
export default abstract class BaseRepo {
  private _client: AxiosInstance;
  private readonly _tokenAccessor: () => string;
  constructor(client: AxiosInstance, tokenAccessor: () => string) {
    this._client = client;
    this._tokenAccessor = tokenAccessor;
  }

  get client() {
    if (this instanceof BaseRepo) return this._client;
    throw new TypeError(`property 'client' is protected`);
  }

  get tokenAccessor() {
    if (this instanceof BaseRepo) return this._tokenAccessor;
    throw new TypeError(`property 'tokenAccessor' is protected`);
  }
}
