import {
  ApiResponse,
  ApiResponseAsync,
  translateAxiosErrorToApiError,
} from 'container/models';

import { authHeaderName } from 'api/apiReqSagaCreator/config';
import { handleExpiredTokens } from 'api/apiReqSagaCreator/httpRequest';
import { ApiPaths } from 'api/constants';
import { User } from 'api/endpoints/users';

import {
  AbstractManagementEmailRepo,
  OrganizationEmailProviderConfig,
  TestEmailPayload,
} from './AbstractManagementEmailRepo';

export class ManagementEmailRepo extends AbstractManagementEmailRepo {
  getReplyTo = (): ApiResponseAsync<string> =>
    handleExpiredTokens(() =>
      this.client.get<User>(ApiPaths.users.info._(), {
        headers: { [authHeaderName]: this.tokenAccessor() },
      })
    )
      .then((response) => response.data.organization.replyTo)
      .catch(translateAxiosErrorToApiError);

  setReplyTo = (email: string): ApiResponseAsync<null> =>
    handleExpiredTokens(() =>
      this.client.put<null>(
        ApiPaths.replyTo._(),
        { replyTo: email },
        { headers: { [authHeaderName]: this.tokenAccessor() } }
      )
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);

  async testEmailServerConfig(
    link: Link,
    config: OrganizationEmailProviderConfig,
    email: TestEmailPayload
  ): Promise<ApiResponse<null>> {
    const content = email.attachmentContent;
    if (content !== undefined && content instanceof Blob) {
      try {
        const b64string: string = await new Promise((resolve, reject) => {
          const fr = new FileReader();
          fr.onload = () => resolve(fr.result as string);
          fr.onerror = reject;
          fr.readAsDataURL(content);
        });

        email.attachmentContent = b64string.split(',')[1];
      } catch {}
    }

    return handleExpiredTokens(() =>
      this.client.post<null>(
        link.href,
        { config, email },
        { headers: { [authHeaderName]: this.tokenAccessor() } }
      )
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);
  }
}
