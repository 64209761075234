import { ProcessGeneral } from 'api/endpoints/processes';
import {
  WithReleaseOrDraftProcessId,
  WithProcessVersionId,
} from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export type VersionStatus = 'active' | 'inactive';

export interface ProcessVersionGeneral extends Omit<BaseEntity, 'name'> {
  draftProcessId: string;
  releaseId: string;
  status: VersionStatus;
  version: number;
}

export interface ProcessVersionDetails extends ProcessVersionGeneral {
  process: ProcessGeneral;
}

export interface VersionNumber {
  version: number;
}

export type ActivateProcessVersionArgs = WithReleaseOrDraftProcessId &
  WithProcessVersionId &
  VersionNumber;

export type CreateProcessVersionParams = WithReleaseOrDraftProcessId;
export type CreateProcessVersionResult = ProcessVersionGeneral;
export type ActivateProcessVersionParams = ActivateProcessVersionArgs;
export type ActivateProcessVersionResult = unknown;
export type GetAllParams = Pagination & WithReleaseOrDraftProcessId;
export type GetAllResult = DataWithTotal<ProcessVersionGeneral>;
export type GetDetailsParams = WithReleaseOrDraftProcessId &
  WithProcessVersionId;
export type GetDetailsResult = ProcessVersionDetails;

const processVersions = {
  createProcessVersion: apiReqSagaCreator<
    CreateProcessVersionParams,
    CreateProcessVersionResult
  >(API_REQ_ACTION_TYPES.PROCESSES.VERSIONS.CREATE, (params) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.processes.byId.versions._(params),
  })),
  activateProcessVersion: apiReqSagaCreator<
    ActivateProcessVersionParams,
    ActivateProcessVersionResult
  >(API_REQ_ACTION_TYPES.PROCESSES.VERSIONS.ACTIVATE, (params) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.processes.byId.versions.byId.activate._(params),
  })),
  getAll: apiReqSagaCreator<GetAllParams, GetAllResult>(
    API_REQ_ACTION_TYPES.PROCESSES.VERSIONS.GET_ALL,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.versions._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
      }),
      params: { limit: params.limit, offset: params.offset },
    })
  ),
  getDetails: apiReqSagaCreator<GetDetailsParams, GetDetailsResult>(
    API_REQ_ACTION_TYPES.PROCESSES.VERSIONS.GET_DETAILS,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.versions.byId._(params),
    })
  ),
};

export default processVersions;
