import { GridColumn } from 'components/organisms/grid/types';

import createAction from 'utils/createAction';

const GRID = {
  CLEAR_ACTIVE_TEMPLATE: 'GRID.CLEAR_ACTIVE_TEMPLATE',
  SET_SELECTED_COLUMNS: 'GRID.SET_SELECTED_COLUMNS',
  UPDATE_SELECTED_FIELDS_BY_INSTANCE: 'GRID.UPDATE_SELECTED_FIELDS_BY_INSTANCE',
  CLEAR_SELECTED_FIELDS_BY_INSTANCES: 'GRID.CLEAR_SELECTED_FIELDS_BY_INSTANCES',
};

const GridActions = {
  clearActiveTemplate: createAction(GRID.CLEAR_ACTIVE_TEMPLATE),
  setSelectedColumns: createAction<GridColumn[]>(GRID.SET_SELECTED_COLUMNS),
  updateSelectedFieldsByInstance: createAction<{
    instanceId: string;
    fieldId: string;
  }>(GRID.UPDATE_SELECTED_FIELDS_BY_INSTANCE),
  clearSelectedFields: createAction(GRID.CLEAR_SELECTED_FIELDS_BY_INSTANCES),
};

export { GRID as GRID_ACTION_TYPES, GridActions };
