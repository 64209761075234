import { all, call } from 'redux-saga/effects';

import ProcessFields, { FieldGeneral } from 'api/endpoints/processFields';
import { FieldWithObligationType } from 'api/endpoints/processForm';

import ApiUrlParams from 'constants/apiUrlParams';
import { DataModelFieldType } from 'constants/global';

function* fetchCollectionFields(
  fields: (FieldGeneral | FieldWithObligationType)[],
  releaseOrDraftProcessId: string
) {
  const collectionItems = [...(fields || [])]?.filter(
    (field: FieldGeneral) => field.type === DataModelFieldType.Collection
  );

  const collectionList: { result: FieldGeneral }[] = yield all(
    [...(collectionItems || [])]?.map((collectionField) =>
      call(ProcessFields.getFieldsByFieldId, {
        [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
        [ApiUrlParams.fieldId]: collectionField.id,
      })
    )
  );

  return fields?.map((filedItem) => {
    if (filedItem.type === DataModelFieldType.Collection) {
      return {
        ...filedItem,
        ...collectionList.find(
          (collectionItem) => collectionItem?.result?.id === filedItem?.id
        )?.result,
      };
    }

    return filedItem;
  });
}

export default fetchCollectionFields;
