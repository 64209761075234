import BaseRepo from 'container/BaseRepo';
import { ApiResponseAsync } from 'container/models';

export interface FreshdeskTokenResponse {
  token: string;
}

export abstract class AbstractFreshdeskTokenRepo extends BaseRepo {
  abstract getFreshdeskToken(): ApiResponseAsync<FreshdeskTokenResponse>;
}
