import BaseRepo from 'container/BaseRepo';
import { ApiResponseAsync } from 'container/models';

import { ProcessGeneral } from 'api/endpoints/processes';

export abstract class AbstractProcessRepo extends BaseRepo {
  abstract getProcessDetails(
    draftProcessId: string
  ): ApiResponseAsync<ProcessGeneral>;
}
