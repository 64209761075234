import App from 'app';
import { runRootSaga } from 'app/redux/configureStore';
import React from 'react';
import ReactDOM from 'react-dom';

import './styles/index.scss';
import { ENABLE_MSW } from 'utils/localFlags';

(async () => {
  if (ENABLE_MSW.value) {
    const { mockingWorker } = await import('api/mocking');
    await mockingWorker.start({ onUnhandledRequest: 'bypass' });
  }
  ENABLE_MSW.addEventListener('toggled', () => {
    window.location.reload();
  });

  // clean up now-unused service worker
  navigator.serviceWorker
    ?.getRegistration()
    .then((registration) => registration?.unregister());

  ReactDOM.render(<App />, document.getElementById('root'));

  runRootSaga();
})();
