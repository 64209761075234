import cn from 'classnames';
import React, { memo, useCallback } from 'react';

import Text from 'components/atoms/text';

import sm from './styles.module.scss';

export interface Props {
  item: SelectItemGeneral;
  isActive: boolean;
  withSmallerIndent?: boolean;
  onClick: (value: SelectItemGeneral) => void;
  withLeftPadding?: boolean;
  setRef?: (el: HTMLButtonElement) => void;
  renderPostIcon?: (e: SelectItem | string) => Nullable<JSX.Element>;
}

type SelectActiveHandler = (itemValue: SelectItemGeneral) => () => void;
export const DropDownListItem = ({
  item,
  onClick,
  isActive,
  withSmallerIndent,
  withLeftPadding,
  setRef,
  renderPostIcon,
}: Props) => {
  const selectActiveHandler: SelectActiveHandler = useCallback(
    (itemValue) => () => {
      onClick(itemValue);
    },
    [onClick]
  );

  return (
    <button
      ref={setRef}
      className={cn([sm.SelectList__item], {
        [sm.SelectList__item_Active]: isActive,
        [sm.SelectList__item_WithSmallerIndent]: withSmallerIndent,
        [sm.SelectList__item_WithLeftPadding]: withLeftPadding,
      })}
      onClick={selectActiveHandler(item)}
    >
      <Text content={item.label} withSemiBoldWeight={isActive} />
      {renderPostIcon && (
        <div className={sm.SelectList__item_PostIcon}>
          {renderPostIcon(item)}
        </div>
      )}
    </button>
  );
};

export default memo(DropDownListItem);
