import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import {
  inviteFailure,
  reFetchInvitationsListAndLicenses,
  reInviteSuccess,
  revokeInvitationSuccess,
} from './sagas';

function* watchersSaga() {
  yield takeLatest(
    [API_REQ_ACTION_TYPES.INVITATIONS.RE_INVITE_USER.SUCCESS],
    reInviteSuccess
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.INVITATIONS.RE_INVITE_USER.ERROR],
    inviteFailure
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.INVITATIONS.REVOKE_USER_INVITATION.SUCCESS,
    revokeInvitationSuccess
  );

  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.INVITATIONS.REVOKE_USER_INVITATION.SUCCESS,
      API_REQ_ACTION_TYPES.INVITATIONS.INVITE_USER.SUCCESS,
    ],
    reFetchInvitationsListAndLicenses
  );
}

export default watchersSaga;
