import subSelectorCreator from 'utils/subSelectorCreator';

import { TenantsState } from './reducer';

const createTenantsSelector = subSelectorCreator<TenantsState>(
  (state) => state.tenants
);

const TenantsSelectors = {
  getList: createTenantsSelector(({ list }) => list),
  getTotal: createTenantsSelector(({ total }) => total),
  getAllList: createTenantsSelector(({ allList }) => allList),
};

export default TenantsSelectors;
