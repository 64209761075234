const moveIndexes = <T = unknown>(
  arr: T[],
  fromIndex: number,
  toIndex: number
) => {
  const localArr = [...arr];
  const element = localArr[fromIndex];
  localArr.splice(fromIndex, 1);
  localArr.splice(toIndex, 0, element);

  return localArr;
};

export { moveIndexes };
