const LINKS = {
  TERMS: {
    content: 'Terms of Service',
    href: 'https://workoptima.freshdesk.com/support/solutions/articles/47000989037-terms-of-service',
  },
  POLICY: {
    content: 'Privacy Policy',
    href: 'https://workoptima.freshdesk.com/support/solutions/articles/47000988860-privacy-policy',
  },
};

const TEXTS = {
  AGREE: 'I agree to the ',
  AND: ' and ',
};

export { LINKS, TEXTS };
