import ROUTES, { OcrPreviewUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { fork, select } from 'redux-saga/effects';

import Ocr from 'api/endpoints/ocr';

import ApiUrlParams from 'constants/apiUrlParams';

function* reFetchKeys() {
  const { pathname, query } = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId },
  }: match<OcrPreviewUiParams> = yield matchPath(pathname, {
    path: [
      ROUTES.process.definition.services.ocr._(),
      ROUTES.process.definition.services.ocr.preview(),
    ],
  });

  yield fork(Ocr.getKeys, {
    [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
    ...query,
  });
}

export default reFetchKeys;
