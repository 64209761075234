import ROUTES from 'app/routes/paths';
import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';

import createATA from 'utils/createAddToasterAction';
import { string } from 'utils/formatters';

const RESET_PASSWORD_SUCCESS_MSG = 'Password was successfully changed';

function* submitResetPasswordSuccess() {
  yield put(push(string.withDefaultPagination(ROUTES.process.execution._())));

  yield put(
    createATA({
      type: 'info',
      message: RESET_PASSWORD_SUCCESS_MSG,
    })
  );
}

export default submitResetPasswordSuccess;
