import cn from 'classnames';
import React, { Fragment, memo, useCallback } from 'react';
import { Link } from 'react-router-dom';

import LogoBig from 'assets/img/logo-big.svg';

import MaterialIcon from 'components/atoms/MaterialIcon';
import Text from 'components/atoms/text';
import HamburgerMenu from 'components/molecules/hamburgerMenu';
import ProfileMenu from 'components/organisms/profileMenu';

import HelpButton from '../helpButton';
import { getIsRouteActive, TEXTS } from './config';
import sm from './styles.module.scss';
import { RenderTabs, UseHeader } from './types';
import useHeader from './useHeader';

const Header = () => {
  // region *******************************DATA*********************************
  const {
    showProfile,
    routesState,
    tabs,
    handleToggleProfile,
    isMobile,
    profileRef,
  }: UseHeader = useHeader();
  // endregion

  // region ********************************JSX*********************************
  const renderTabs: RenderTabs = useCallback(
    (e) => {
      const isRouteActive = getIsRouteActive(e.type, routesState);

      return (
        <Fragment key={e.label}>
          {/* type mismatch; waiting on react-router-dom update
          // @ts-expect-error */}
          <Link
            key={e.label}
            to={e.path}
            className={cn([sm.Container_Nav_Item], {
              [sm.Container_Nav_Item_Active]: isRouteActive,
            })}
          >
            <img
              className={sm.Container_Nav_Item_Icon}
              src={isRouteActive ? e.imgActive : e.img}
              alt={e.imgAlt}
            />
            <Text content={e.label} withSemiBoldWeight={isRouteActive} />
          </Link>
        </Fragment>
      );
    },
    [routesState]
  );

  return (
    <header className={sm.Container}>
      <img src={LogoBig} alt={TEXTS.ALT.LOGO} />
      <HamburgerMenu isMobile={isMobile}>
        <>
          <div className={sm.Container_Nav}>{tabs?.map(renderTabs)}</div>
          <div className={sm.Container_DropDownWrap}>
            {!isMobile && (
              <>
                <HelpButton></HelpButton>
                <button
                  className={sm.Container_Profile}
                  onClick={handleToggleProfile}
                >
                  <MaterialIcon
                    name={showProfile ? 'cancel' : 'account_circle'}
                    alt={TEXTS.ALT.PROFILE}
                    size={34}
                    weight={300}
                    className={sm.Container_Profile_Icon}
                    color=""
                  />
                </button>
              </>
            )}

            {(showProfile || isMobile) && (
              <div ref={profileRef} className={sm.Container_Profile_DropDown}>
                <ProfileMenu
                  isMobile={isMobile}
                  onItemClick={!isMobile ? handleToggleProfile : undefined}
                />
              </div>
            )}
          </div>
        </>
      </HamburgerMenu>
    </header>
  );
  // endregion
};

export default memo(Header);
