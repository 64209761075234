import ROUTES, { SplitDocumentsUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { DraggableLocation } from 'react-beautiful-dnd';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { call, select } from 'redux-saga/effects';
import { SelectedPage } from 'types/scanning';
import { NormalizedSplitDocument } from 'types/splitDocument';

import { ApiSagaResult } from 'api/apiReqSagaCreator';
import SplitDocument, { BulkUpdateResult } from 'api/endpoints/splitDocument';

import ApiUrlParams from 'constants/apiUrlParams';

export interface MovePageParams {
  moveData: {
    source: DraggableLocation;
    destination: DraggableLocation;
  };
  selectedPages: string[];
  documents: NormalizedSplitDocument[];
}

function filterBySelected(
  documentPages: SelectedPage[],
  selectedPages: string[]
) {
  return documentPages
    .filter((page) => !selectedPages.includes(page.name))
    .map((e) => e.name);
}

export function filterStartContainersPages(
  documents: NormalizedSplitDocument[],
  selectedPages: string[]
) {
  const startContainers = documents.filter((e) =>
    e.pages?.some((page) => selectedPages.includes(page.name))
  );

  return startContainers.map(({ id: documentId, pages }) => ({
    documentId,
    pages: filterBySelected(pages ?? [], selectedPages),
  }));
}

function* movePage({
  documents,
  moveData: {
    destination: { droppableId: endContainerId, index: endIndex },
    source: { droppableId: startContainerId },
  },
  selectedPages,
}: MovePageParams) {
  const { pathname } = yield select(getLocation);
  const {
    params: { releaseOrDraftProcessId, splitDocInstanceId },
  }: match<SplitDocumentsUiParams> = yield matchPath(pathname, {
    path: [ROUTES.process.instance.splitDocuments()],
  });

  const isSameContainer = startContainerId === endContainerId;

  const startContainersWithFilteredPages = filterStartContainersPages(
    documents,
    selectedPages
  );

  const startContainersPages = filterBySelected(
    documents.find((e) => e.id === startContainerId)?.pages || [],
    selectedPages
  );

  const endContainerPages = filterBySelected(
    documents.find((e) => e.id === endContainerId)?.pages || [],
    selectedPages
  );

  const arrayToCut = isSameContainer ? startContainersPages : endContainerPages;

  const newEndContainerPages = [
    ...arrayToCut.slice(0, endIndex),
    ...selectedPages,
    ...arrayToCut.slice(endIndex),
  ];

  const res: ApiSagaResult<BulkUpdateResult> = yield call(
    SplitDocument.bulkUpdate,
    {
      [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
      [ApiUrlParams.splitDocInstanceId]: splitDocInstanceId,
      documents: [
        ...startContainersWithFilteredPages.filter(
          (e) => e.documentId !== endContainerId
        ),
        {
          documentId: endContainerId,
          pages: newEndContainerPages,
        },
      ],
    }
  );

  return res;
}

export default movePage;
