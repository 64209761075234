import { combineReducers } from 'redux';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import {
  FtpConnectionDetails,
  FtpConnectionGeneral,
  GetFtpConnectionDetailsResult,
  GetFtpConnectionsResult,
} from 'api/endpoints/ftpConnections';

import { FTP_CONNECTIONS_ACTION_TYPES } from 'store/ftpConnections/actions';

import { SystemStatus } from 'constants/global';

export interface FtpConnectionsState {
  list: Nullable<FtpConnectionGeneral[]>;
  total: Nullable<number>;
  details: Nullable<FtpConnectionDetails>;
  credentialsStatus: Nullable<SystemStatus.Valid | SystemStatus.InValid>;
}

const ftpConnections = combineReducers<FtpConnectionsState>({
  list(state = null, { type, payload }: ReduxAction<GetFtpConnectionsResult>) {
    switch (type) {
      case API_REQ_ACTION_TYPES.FTP_CONNECTIONS.GET_CONNECTIONS.SUCCESS:
        return payload ? payload.data : null;
      default:
        return state;
    }
  },
  total(state = null, { type, payload }: ReduxAction<GetFtpConnectionsResult>) {
    switch (type) {
      case API_REQ_ACTION_TYPES.FTP_CONNECTIONS.GET_CONNECTIONS.SUCCESS:
        return payload ? payload.total : null;
      default:
        return state;
    }
  },
  details(
    state = null,
    { type, payload }: ReduxAction<GetFtpConnectionDetailsResult>
  ) {
    switch (type) {
      case FTP_CONNECTIONS_ACTION_TYPES.CLEAR_CONNECTION_DETAILS:
      case API_REQ_ACTION_TYPES.FTP_CONNECTIONS.GET_CONNECTION_DETAILS.SUCCESS:
        return payload || null;
      default:
        return state;
    }
  },
  credentialsStatus(
    state = null,
    { type, payload }: ReduxAction<boolean | undefined>
  ) {
    switch (type) {
      case FTP_CONNECTIONS_ACTION_TYPES.SET_USER_CREDENTIALS_STATUS:
        if (typeof payload !== 'boolean') return null;
        return payload ? SystemStatus.Valid : SystemStatus.InValid;
      default:
        return state;
    }
  },
});

export default ftpConnections;
