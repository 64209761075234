import subSelectorCreator from 'utils/subSelectorCreator';

import { ScanDocumentViewState } from './reducer';

const createScanDocumentViewSelector =
  subSelectorCreator<ScanDocumentViewState>((state) => state.scanDocumentView);

const ScanDocumentViewSelector = {
  getBarcodeValuesList: createScanDocumentViewSelector(
    ({ barcodeValues }) => barcodeValues
  ),
  getList: createScanDocumentViewSelector(({ list }) => list),
  getAllList: createScanDocumentViewSelector(({ allList }) => allList),
  getSearchList: createScanDocumentViewSelector(({ searchList }) => searchList),
  getSearchValue: createScanDocumentViewSelector(
    ({ searchValue }) => searchValue
  ),
  getTotal: createScanDocumentViewSelector(({ total }) => total),
  getSelectedPages: createScanDocumentViewSelector(
    ({ selectedPages }) => selectedPages
  ),
  getDocumentData: createScanDocumentViewSelector(
    ({ documentData }) => documentData
  ),
  getPageMoved: createScanDocumentViewSelector(({ pageMoved }) => pageMoved),
  getSelectedPageInfo: createScanDocumentViewSelector(
    ({ selectedPageInfo }) => selectedPageInfo
  ),
};

export default ScanDocumentViewSelector;
