import { AdditionalFieldProps } from 'hocs/asFormField';

export interface ImapFields {
  email: string;
  password?: string;
}

export interface MSGraphFields {
  tenantId?: string;
  applicationId?: string;
  userId?: string;
  secret?: string;
  email: string;
}

export type EmailProviders = 'gmail' | 'office365' | 'msgraph';

export type Fields = ImapFields | MSGraphFields;

export function credentialsAreEmpty(
  emailProvider: string,
  credentialsData: Fields
): boolean {
  if (!credentialsData) return true;

  let credentialsNotEmpty = false;
  const typedProvider = emailProvider as EmailProviders;
  if (typedProvider === 'gmail' || typedProvider === 'office365') {
    const imapCredentials = credentialsData as ImapFields;
    credentialsNotEmpty = !!(imapCredentials.password || imapCredentials.email);
  }
  if (typedProvider === 'msgraph') {
    const graphCredentials = credentialsData as MSGraphFields;
    credentialsNotEmpty = !!(
      graphCredentials.tenantId ||
      graphCredentials.applicationId ||
      graphCredentials.userId ||
      graphCredentials.secret
    );
  }

  return !credentialsNotEmpty;
}

export interface Props {
  emailProvider: string;
  isEditMode?: boolean;
  isDisabled?: boolean;
  onCheckCredsClick?: EmptyArgsVoidFunc;
}

export type TextComponentParams = Pick<
  AdditionalFieldProps,
  'label' | 'placeholder'
>;

export type AuthFieldsConfig = Record<string, TextComponentParams>;
