import ROUTES, { SplitDocumentsUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { all, call, fork, put, select } from 'redux-saga/effects';
import { NormalizedSplitDocument } from 'types/splitDocument';

import { SUB_MENU_ACTIONS } from 'components/organisms/pageSubmenu/config';

import SplitDocument from 'api/endpoints/splitDocument';

import { SplitDocumentsActions } from 'store/splitDocuments/actions';
import SplitDocumentsSelectors from 'store/splitDocuments/selectors';

import ApiUrlParams from 'constants/apiUrlParams';

import { array } from 'utils/formatters';

import { reFetchDocuments } from '.';

export interface PageActionsArgs {
  pageNames: string[];
  documentId: string;
  action: string;
  documents: NormalizedSplitDocument[];
}

// eslint-disable-next-line consistent-return
function* pageActions({
  action,
  pageNames,
  documentId,
  documents,
}: PageActionsArgs) {
  const { pathname } = yield select(getLocation);

  const {
    params: {
      releaseOrDraftProcessId,
      splitDocInstanceId,
      fieldId,
      processInstanceId,
    },
  }: match<SplitDocumentsUiParams> = yield matchPath(pathname, {
    path: [ROUTES.process.instance.splitDocuments()],
  });

  switch (action) {
    case SUB_MENU_ACTIONS.MOVE_LEFT:
    case SUB_MENU_ACTIONS.MOVE_RIGHT: {
      const currentPages = (
        documents.find((doc) => doc.id === documentId)?.pages ?? []
      ).map((e) => e.name);

      const currentIndex = Number(
        currentPages?.findIndex((page) => page === pageNames[0])
      );

      const newPages = array.moveIndexes<string>(
        currentPages,
        currentIndex,
        action === SUB_MENU_ACTIONS.MOVE_LEFT
          ? currentIndex - 1
          : currentIndex + 1
      );

      yield fork(SplitDocument.bulkUpdate, {
        [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
        [ApiUrlParams.splitDocInstanceId]: splitDocInstanceId,
        documents: [
          {
            documentId,
            pages: newPages,
          },
        ],
      });
      break;
    }
    case SUB_MENU_ACTIONS.ROTATE_LEFT:
    case SUB_MENU_ACTIONS.ROTATE_RIGHT: {
      try {
        yield all(
          pageNames.map((name) =>
            call(SplitDocument.rotatePage, {
              params: {
                [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
                [ApiUrlParams.processInstanceId]: processInstanceId,
                [ApiUrlParams.fieldId]: fieldId,
                [ApiUrlParams.name]: name,
              },
              updateData: {
                rotate: {
                  angle: action === SUB_MENU_ACTIONS.ROTATE_LEFT ? 90 : -90,
                },
              },
            })
          )
        );
        const receivedPages: Nullable<KeyValuePairs> = yield select(
          SplitDocumentsSelectors.getReceivedPages
        );
        yield put(
          SplitDocumentsActions.setReceivedPages(
            Object.fromEntries(
              Object.entries(receivedPages || {}).filter(
                ([key]) => !pageNames.includes(key)
              )
            ) as KeyValuePairs
          )
        );
        yield fork(reFetchDocuments);
        return true;
      } catch (e) {
        return false;
      }
    }
    case SUB_MENU_ACTIONS.DELETE: {
      try {
        yield call(SplitDocument.removePages, {
          params: {
            [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
            [ApiUrlParams.splitDocInstanceId]: splitDocInstanceId,
            [ApiUrlParams.splitDocContainerId]: documentId,
          },
          data: {
            pages: pageNames,
          },
        });
        yield fork(reFetchDocuments);
        return true;
      } catch {
        return false;
      }
    }
  }
}

export default pageActions;
