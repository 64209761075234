import { ApplicationError } from 'types/errorHandling';

export class OdataURLBuilder {
  private parameters: Record<string, any> = {};
  private readonly prefix: string;

  public constructor(link: Link, parameters?: Record<string, string>) {
    this.prefix = link.href;
    if (link.templated) {
      if (!parameters)
        throw new ApplicationError(
          'Malformed link',
          'Parameters are required for templated links',
          { details: link }
        );
      for (const [key, value] of Object.entries(parameters ?? {})) {
        this.prefix = this.prefix.replace(`{${key}}`, value);
      }
    }
  }

  setTop(value: number): any {
    this.setParameter('$top', value);
  }

  setSkip(value: number): any {
    this.setParameter('$skip', value);
  }

  setFilter(value: string): any {
    this.setParameter('$filter', value);
  }

  setCount(value: boolean): any {
    this.setParameter('$count', value);
  }

  setOrderBy(value: string): any {
    this.setParameter('$orderby', value);
  }

  getParameter(name: string, defaultValue: any): any {
    return this.parameters[name] ?? defaultValue;
  }

  setParameter(name: string, value: any): void {
    this.parameters[name] = value;
  }

  deleteParameter(name: string): void {
    delete this.parameters[name];
  }

  toString(): string {
    const url = new URL(this.prefix);
    const entries = Object.entries(this.parameters);
    entries.forEach((value: [string, any]) => {
      url.searchParams.append(value[0], String(value[1]));
    });
    return url.href;
  }
}
