import { call, fork } from 'redux-saga/effects';

import Ocr from 'api/endpoints/ocr';

import ApiUrlParams from 'constants/apiUrlParams';
import { UPLOAD_FILE_FIELD_NAME } from 'constants/global';

export type UploadAndTestFileArgs = [File, string];

function* uploadAndTestFile(file: File, releaseOrDraftProcessId: string) {
  const formData = new FormData();

  formData.append(UPLOAD_FILE_FIELD_NAME, file);

  const { ok, result } = yield call(Ocr.uploadFile, {
    ocrFile: formData,
    [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
  });
  if (ok) {
    yield fork(Ocr.testFile, {
      fileUrl: result.url,
      [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
    });
  }
}

export default uploadAndTestFile;
