import { FieldGeneral } from 'api/endpoints/processFields';
import {
  WithReleaseOrDraftProcessId,
  WithInboundEmailScenarioId,
} from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export interface ImapAuthShort {
  email: string;
}

export interface MSGraphAuthShort {
  tenantId: string;
  applicationId: string;
  userId: string;
  email: string;
}

export interface ImapAuth extends ImapAuthShort {
  password?: string;
}

export interface MSGraphAuth extends MSGraphAuthShort {
  secret: string;
}

export type EmailProviderAuthWithValid = (ImapAuthShort | MSGraphAuthShort) & {
  isValid: boolean;
};

export type EmailProviderAuth = ImapAuth | MSGraphAuth;

/*
function IsImapAuth(a: EmailProviderAuth): a is ImapAuth {
  return (a as ImapAuth).password !== undefined;
}

function IsMSGraphAuth(a: EmailProviderAuth): a is MSGraphAuth {
  return (a as MSGraphAuth).secret !== undefined;
}
 */

export interface PollingScenarioGeneralBase extends BaseEntity {
  isActive: boolean;
  processId: string;
  isLaunched: boolean;
}

export interface PollingScenarioGeneral extends PollingScenarioGeneralBase {
  imapAuth: EmailProviderAuthWithValid;
}

export type PollingScenarioProvider = 'gmail' | 'office365' | 'msgraph';

export interface ApiLabelPair {
  input: string;
  processed: string;
  indexingSourceId: Nullable<string>;
  individualAttachments: boolean;
}

export interface PollingScenarioDetails extends PollingScenarioGeneral {
  emailSenderField: FieldGeneral;
  emailSubjectField: FieldGeneral;
  emailBodyField: FieldGeneral;
  emailAttachmentField: FieldGeneral;
  emailAttachmentFileNameField: FieldGeneral;
  emailLabelField: FieldGeneral;
  labels: ApiLabelPair[];
  provider: PollingScenarioProvider;
}

export interface CreateScenarioParams extends WithReleaseOrDraftProcessId {
  data: {
    name: string;
    emailSenderFieldId: string;
    emailSubjectFieldId: string;
    emailBodyFieldId: string;
    emailAttachmentFieldId: string;
    emailAttachmentFileNameFieldId: string;
    emailLabelFieldId: string;
    isPolicyAccepted: boolean;
    provider: string;
    imapAuth?: EmailProviderAuth;
    labels?: ApiLabelPair[];
  };
}

export interface GetScenariosParams
  extends Pagination,
    WithReleaseOrDraftProcessId {
  isActive?: boolean;
}

export interface UpdateScenarioParams
  extends CreateScenarioParams,
    WithInboundEmailScenarioId {}

export type CreateScenarioResult = PollingScenarioDetails;
export type UpdateScenarioResult = PollingScenarioDetails;
export type GetScenariosResult = DataWithTotal<PollingScenarioGeneral>;
export type ActivateScenarioParams = WithReleaseOrDraftProcessId &
  WithInboundEmailScenarioId;
export type ActivateScenarioResult = unknown;
export type DeactivateScenarioParams = WithReleaseOrDraftProcessId &
  WithInboundEmailScenarioId;
export type DeactivateScenarioResult = unknown;
export type GetScenarioDetailsParams = WithReleaseOrDraftProcessId &
  WithInboundEmailScenarioId;
export type GetScenarioDetailsResult = PollingScenarioDetails;
export type DeleteScenarioParams = WithReleaseOrDraftProcessId &
  WithInboundEmailScenarioId;
export type DeleteScenarioResult = unknown;

const EmailPolling = {
  createScenario: apiReqSagaCreator<CreateScenarioParams, CreateScenarioResult>(
    API_REQ_ACTION_TYPES.INBOUND_EMAIL.SAVE_SCENARIO,
    (args) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.inboundEmail.scenarios._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
      }),
      data: args.data,
    })
  ),
  updateScenario: apiReqSagaCreator<UpdateScenarioParams, UpdateScenarioResult>(
    API_REQ_ACTION_TYPES.INBOUND_EMAIL.UPDATE_SCENARIO,
    (args) => ({
      method: HTTP_METHODS.PUT,
      url: ApiPaths.processes.byId.inboundEmail.scenarios.byId._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.inboundEmailScenarioId]:
          args[ApiUrlParams.inboundEmailScenarioId],
      }),
      data: args.data,
    })
  ),
  getScenarios: apiReqSagaCreator<GetScenariosParams, GetScenariosResult>(
    API_REQ_ACTION_TYPES.INBOUND_EMAIL.GET_SCENARIOS,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.inboundEmail.scenarios._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
      }),
      params,
    })
  ),
  activateScenario: apiReqSagaCreator<
    ActivateScenarioParams,
    ActivateScenarioResult
  >(API_REQ_ACTION_TYPES.INBOUND_EMAIL.ACTIVATE_SCENARIO, (params) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.processes.byId.inboundEmail.scenarios.byId.activate._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        params[ApiUrlParams.releaseOrDraftProcessId],
      [ApiUrlParams.inboundEmailScenarioId]:
        params[ApiUrlParams.inboundEmailScenarioId],
    }),
  })),
  deactivateScenario: apiReqSagaCreator<
    DeactivateScenarioParams,
    DeactivateScenarioResult
  >(API_REQ_ACTION_TYPES.INBOUND_EMAIL.DEACTIVATE_SCENARIO, (params) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.processes.byId.inboundEmail.scenarios.byId.deactivate._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        params[ApiUrlParams.releaseOrDraftProcessId],
      [ApiUrlParams.inboundEmailScenarioId]:
        params[ApiUrlParams.inboundEmailScenarioId],
    }),
  })),
  getScenarioDetails: apiReqSagaCreator<
    GetScenarioDetailsParams,
    GetScenarioDetailsResult
  >(API_REQ_ACTION_TYPES.INBOUND_EMAIL.GET_DETAILS, (params) => ({
    method: HTTP_METHODS.GET,
    url: ApiPaths.processes.byId.inboundEmail.scenarios.byId._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        params[ApiUrlParams.releaseOrDraftProcessId],
      [ApiUrlParams.inboundEmailScenarioId]:
        params[ApiUrlParams.inboundEmailScenarioId],
    }),
  })),
  deleteScenario: apiReqSagaCreator<DeleteScenarioParams, DeleteScenarioResult>(
    API_REQ_ACTION_TYPES.INBOUND_EMAIL.DELETE_SCENARIO,
    (params) => ({
      method: HTTP_METHODS.DELETE,
      url: ApiPaths.processes.byId.inboundEmail.scenarios.byId._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.inboundEmailScenarioId]:
          params[ApiUrlParams.inboundEmailScenarioId],
      }),
    })
  ),
};

export default EmailPolling;
