import { takeEvery } from 'redux-saga/effects';

import { API_ACTION_TYPES } from 'api/actions';

import { HISTORY_ACTION_TYPES } from './actions';
import { moveToPrevPage, updateRoutes } from './sagas';

function* watcherSaga() {
  yield takeEvery([HISTORY_ACTION_TYPES.SET_ROUTE], updateRoutes);

  yield takeEvery([API_ACTION_TYPES.XHR.SUCCESS], moveToPrevPage);
}

export default watcherSaga;
