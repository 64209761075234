import ROUTES from 'app/routes/paths';
import { push } from 'connected-react-router';
import { getLocation } from 'connected-react-router';
import { match, matchPath } from 'react-router-dom';
import { fork, put, select } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import { RequestActionMeta } from 'api/apiReqSagaCreator';
import { Users } from 'api/endpoints';
import {
  CreateProcessParams,
  CreateProcessResult,
  UpdateProcessParams,
} from 'api/endpoints/processes';

import { SKIP_REDIRECT_TITLE } from 'store/users/sagas/moveFromRestrictedRoute';
import UsersSelectors from 'store/users/selectors';

import ApiUrlParams from 'constants/apiUrlParams';
import UiUrlParams from 'constants/uiUrlParams';

import { string } from 'utils/formatters/';

function* redirectToProcess({
  type,
  payload: { id: processId },
  meta,
}: ReduxAction<
  CreateProcessResult,
  RequestActionMeta<CreateProcessParams | UpdateProcessParams>
>) {
  const currentTenant: string = yield select(UsersSelectors.getCurrentTenantId);

  const location: Location = yield select(getLocation);

  const roteMatch: match = yield matchPath(location?.pathname, {
    path: [ROUTES.process.definition._()],
  });

  let tenantId: string;

  if (type === API_REQ_ACTION_TYPES.PROCESSES.CREATE.SUCCESS) {
    const {
      input: { tenantId: id },
    } = meta as { input: CreateProcessParams };
    tenantId = id;
  } else {
    const {
      input: {
        data: { tenantId: id },
      },
    } = meta as { input: UpdateProcessParams };
    tenantId = id;
  }

  if (tenantId !== currentTenant || roteMatch.isExact) {
    yield put(
      push(
        string.withDefaultPagination(
          ROUTES.process.definition.details.versions({
            [UiUrlParams.releaseOrDraftProcessId]: String(processId),
          })
        )
      )
    );
  }

  if (tenantId !== currentTenant) {
    yield fork(
      Users.updateUserActiveTenant,
      { [ApiUrlParams.tenantId]: tenantId },
      { title: SKIP_REDIRECT_TITLE }
    );
  }
}

export default redirectToProcess;
