import { put } from 'redux-saga/effects';

import createATA from 'utils/createAddToasterAction';

const UNSET_MESSAGE = 'The Form has been unset as Initial';

function* unSetAsInitialSuccess({
  meta,
}: {
  type: string;
  meta: { title?: string };
}) {
  if (meta.title === 'UNSET') {
    yield put(
      createATA({
        message: UNSET_MESSAGE,
      })
    );
  }
}

export default unSetAsInitialSuccess;
