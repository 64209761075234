const FORM_NAMES = {
  LOGIN: 'LOGIN_FORM',
  USER_REGISTRATION: 'USER_REGISTRATION',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  CREATE_PROCESS: 'CREATE_PROCESS',
  ADD_DATA_MODEL_FIELD: 'ADD_DATA_MODEL_FIELD',
  CREATE_ENTITY: 'CREATE_ENTITY',
  CONFIGURE_EMAIL: 'CONFIGURE_EMAIL',
  CONFIGURE_SMS: 'CONFIGURE_SMS',
  CREATE_FORM: 'CREATE_FORM',
  CREATE_INSTANCE: 'CREATE_INSTANCE',
  CREATE_INSTANCE_WITH_VARIABLES: 'CREATE_INSTANCE_WITH_VARIABLES',
  ADD_SCAN_CONFIGURATION: 'ADD_SCAN_CONFIGURATION',
  FORM_FACTORY: 'FORM_FACTORY',
  SCAN_CONFIG: 'SCAN_CONFIG',
  SEARCH: 'SEARCH',
  ADVANCED_SEARCH_CONFIGURE: 'ADVANCED_SEARCH_CONFIGURE',
  WRAPPER: 'WRAPPER',
  INVITE_USER: 'INVITE_USER',
  CREATE_PRINT_RENDER_SCENARIO: 'CREATE_PRINT_RENDER_SCENARIO',
  PREVIEW_PRINT_RENDER_SCENARIO: 'PREVIEW_PRINT_RENDER_SCENARIO',
  SAVE_SEARCH_TEMPLATE: 'SAVE_SEARCH_TEMPLATE',
  CREATE_TMS_CONNECTION: 'CREATE_TMS_CONNECTION',
  CREATE_FTP_CONNECTION: 'CREATE_FTP_CONNECTION',
  SELECT_CONNECTION: 'SELECT_CONNECTION',
  GRID_TEMPLATES: 'GRID_TEMPLATES',
  SET_FIELD_FOR_INSTANCE_NAME: 'SET_FIELD_FOR_INSTANCE_NAME',
  SAVE_GRID_TEMPLATE: 'SAVE_GRID_TEMPLATE',
  CREATE_EMAIL_POLLING_SCENARIO: 'CREATE_EMAIL_POLLING_SCENARIO',
  CHECK_GSUIT_CREDENTIALS: 'CHECK_GSUIT_CREDENTIALS',
  PREVIEW_EMAIL_POLLING_SCENARIO: 'PREVIEW_EMAIL_POLLING_SCENARIO',
  DEFINE_OCR_KEYS: 'DEFINE_OCR_KEYS',
  UPLOAD_FILE: 'UPLOAD_FILE',
  SCANNING_INDEXING: 'SCANNING_INDEXING',
  CREATE_TEMPLATE: 'CREATE_TEMPLATE',
  PREVIEW_CREATE_TEMPLATE: 'PREVIEW_CREATE_TEMPLATE',
  DEFINITION_SEARCH: 'DEFINITION_SEARCH',
  EXECUTION_SEARCH: 'EXECUTION_SEARCH',
  SEARCH_INSTANCES: 'SEARCH_INSTANCES',
  SYSTEM_LOG_SEARCH: 'SYSTEM_LOG_SEARCH',
  UPDATE_INSTANCES: 'UPDATE_INSTANCES',
  SEARCH_INSTANCES_ADVANCED: 'SEARCH_INSTANCES_ADVANCED',
  UPDATE_INSTANCES_ADVANCED: 'UPDATE_INSTANCES_ADVANCED',
  REPLY_TO: 'REPLY_TO',
  EDIT_FINISHED_INSTANCE: 'EDIT_FINISHED_INSTANCE',
  INDEX_BLOCK: 'INDEX_BLOCK',
  CREATE_SECURITY_GROUP: 'CREATE_SECURITY_GROUP',
  SECURITY_GROUP_CONFIG: 'SECURITY_GROUP_CONFIG',
  GRID_SEARCH: 'GRID_SEARCH',
  IMPORT_PROCESS: 'IMPORT_PROCESS',
  MANAGE_USER: 'MANAGE_USER',
  SEND_FILES: 'SEND_FILES',
  ADD_TENANT: 'ADD_TENANT',
  DELETE_ENTITY: 'DELETE_ENTITY',
  DELETE_ENTITY_MULTISTEP: 'DELETE_ENTITY_MULTISTEP',
  EXTERNAL_TIMEOUT: 'EXTERNAL_TIMEOUT',
  FINISH_INSTANCE: 'FINISH_INSTANCE',
  RE_INDEX_FORM: 'RE_INDEX_FORM',
  SEND_EMAIL_WITH_FILE_OR_FORM: 'SEND_EMAIL_WITH_FILE_OR_FORM',
  DATA_PULL_BACK_WRAP: 'DATA_PULL_BACK_WRAP',
  SCANNING_DOCUMENT_CONTAINER: 'SCANNING_DOCUMENT_CONTAINER',
  CREATE_FTP_POLLING_SCENARIO: 'CREATE_FTP_POLLING_SCENARIO',
};

enum ApiObligationType {
  readOnly = 'read_only',
  fillRequired = 'fill_required',
  fillOptional = 'fill_optional',
}

/** @deprecated use {@link ApiObligationType} */
enum ObligationType {
  optional = 'optional',
  required = 'required',
  readOnly = 'readOnly',
}

const SELECT_ALL_LABEL = 'Select All';

const SELECT_ALL = {
  LABEL: SELECT_ALL_LABEL,
  OPTION: {
    label: SELECT_ALL_LABEL,
    value: 'SELECT_ALL',
  },
};

export { FORM_NAMES, ApiObligationType, ObligationType, SELECT_ALL };
