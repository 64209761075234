import { getLocation } from 'connected-react-router';
import { fork, select } from 'redux-saga/effects';

import { TmsConnections } from 'api/endpoints';

function* reFetchConnectionsList() {
  const { query } = yield select(getLocation);

  yield fork(TmsConnections.getConnections, query);
}

export default reFetchConnectionsList;
