import createAction from 'utils/createAction';

const HISTORY = {
  SET_ROUTE: 'HISTORY.SET_ROUTE',
};

const HistoryActions = {
  setRoute: createAction<LocalHistoryRoute>(HISTORY.SET_ROUTE),
};

export { HISTORY as HISTORY_ACTION_TYPES, HistoryActions };
