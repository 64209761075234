import {
  ApiResponseAsync,
  translateAxiosErrorToApiError,
} from 'container/models';

import { authHeaderName } from 'api/apiReqSagaCreator/config';
import {
  handleExpiredTokens,
  transformerConfigFactory,
} from 'api/apiReqSagaCreator/httpRequest';

import { AbstractBareClientRepo } from './abstractBareClientRepo';

export class BareClientRepo extends AbstractBareClientRepo {
  get<Response>(
    url: string,
    opts?: { responseTransformerStopPaths?: string[] }
  ) {
    return handleExpiredTokens(() =>
      this.client.get<Response>(url, {
        headers: {
          [authHeaderName]: this.tokenAccessor(),
        },
        ...transformerConfigFactory({
          responseStopPaths: opts?.responseTransformerStopPaths,
        }),
      })
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);
  }

  patch<Payload, Response>(
    url: string,
    data: Payload,
    opts?: {
      requestTransformerStopPaths?: string[];
      responseTransformerStopPaths?: string[];
    }
  ) {
    return handleExpiredTokens(() =>
      this.client.patch<Response>(url, data, {
        headers: {
          [authHeaderName]: this.tokenAccessor(),
        },
        ...transformerConfigFactory({
          requestStopPaths: opts?.requestTransformerStopPaths,
          responseStopPaths: opts?.responseTransformerStopPaths,
        }),
      })
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);
  }

  delete<Response>(
    url: string,
    opts?: {
      responseTransformerStopPaths?: string[];
    }
  ) {
    return handleExpiredTokens(() =>
      this.client.delete<Response>(url, {
        headers: { [authHeaderName]: this.tokenAccessor() },
        ...transformerConfigFactory({
          responseStopPaths: opts?.responseTransformerStopPaths,
        }),
      })
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);
  }

  post<Payload, Response>(
    url: string,
    data: Payload,
    opts?: {
      requestTransformerStopPaths?: string[];
      responseTransformerStopPaths?: string[];
    }
  ): ApiResponseAsync<Response> {
    return handleExpiredTokens(() =>
      this.client.post<Response>(url, data, {
        headers: {
          [authHeaderName]: this.tokenAccessor(),
        },
        ...transformerConfigFactory({
          requestStopPaths: opts?.requestTransformerStopPaths,
          responseStopPaths: opts?.responseTransformerStopPaths,
        }),
      })
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);
  }

  put<Payload, Response>(
    url: string,
    data: Payload,
    opts?: {
      requestTransformerStopPaths?: string[];
      responseTransformerStopPaths?: string[];
    }
  ): ApiResponseAsync<Response> {
    return handleExpiredTokens(() =>
      this.client.put<Response>(url, data, {
        headers: {
          [authHeaderName]: this.tokenAccessor(),
        },
        ...transformerConfigFactory({
          requestStopPaths: opts?.requestTransformerStopPaths,
          responseStopPaths: opts?.responseTransformerStopPaths,
        }),
      })
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);
  }
}
