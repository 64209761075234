import camelCase from 'camelcase';
import snakeCase from 'to-snake-case';

/**
 * Check if a string matches any of the regexes in an array.
 */
function has(array: Array<RegExp>, key: string) {
  return array.some((x) => {
    x.lastIndex = 0;
    return x.test(key);
  });
}

/**
 * Check if a value is a non-null object that is not a Date, RegExp, or Error.
 */
const isObject = (value: any) =>
  typeof value === 'object' &&
  value !== null &&
  ![Date, RegExp, Error].some((type) => value instanceof type);

interface Options {
  exclude?: Array<RegExp>;
  stopPaths?: string[];
  pascalCase?: boolean;
  toSnake?: boolean;
}

/** Add this symbol to any object to stop the API request transformer from transforming it or its values. */
export const STOP_API_TRANSFORMATION = '$$StopAPITransformation$$';

export default function transform(input: any, options?: Options) {
  if (!isObject(input)) {
    return input;
  }

  const { exclude, pascalCase, stopPaths, toSnake } = {
    exclude: [/[\da-f]{8}(-[\da-f]{4}){3}-[\da-f]{12}/i], // do not transform keys with GUIDs
    toSnake: false,
    ...options,
  };

  const stopPathsSet = new Set<string>(stopPaths);

  function map(o: any, path?: string) {
    // base case; stop recursing once we hit a non-object
    if (typeof o !== 'object' || o === null) return o;

    // stop transforming once we hit the specified key; delete the key before we return
    if (STOP_API_TRANSFORMATION in o) {
      delete o[STOP_API_TRANSFORMATION];
      return o;
    }

    // pick an object type to construct based on the original value
    const newObj: any = Array.isArray(o) ? [] : {};

    // for every enumerable property...
    for (const [key, value] of Object.entries(o)) {
      // path is computed from chain of keys
      const newPath = path === undefined ? key : `${path}.${key}`;

      // only transform value if path is not in stopPaths
      const newValue = stopPathsSet.has(newPath) ? value : map(value, newPath);

      // only transform keys when there are no exclude rules or key does not match any exclude rule
      if (exclude.length === 0 || !has(exclude, key)) {
        const newKey = toSnake
          ? snakeCase(key)
          : camelCase(key, { pascalCase });

        newObj[newKey] = newValue;
      } else newObj[key] = newValue;
    }

    return newObj;
  }

  return map(input);
}
