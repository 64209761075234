import ErrorPage from 'pages/errorPage';
import React, { memo, useCallback, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Authenticated from 'components/templates/authenticated';

import { HistoryActions } from 'store/history/actions';

import { string } from 'utils/formatters';

import { routes } from './config';
import PATHS from './paths';

const AppRoutes = () => {
  // region *******************************DATA*********************************
  const dispatch = useDispatch();

  const location = useLocation();
  // endregion

  // region ******************************EFFECTS*******************************
  useEffect(() => {
    dispatch(
      HistoryActions.setRoute({
        uri: location.pathname,
        ...(location.search && { query: location.search }),
      })
    );
  }, [dispatch, location]);
  // endregion

  // region ********************************JSX*********************************
  const renderRoute = useCallback(
    ({ path, component, exact }) => (
      <Route
        exact={exact}
        path={path}
        component={component}
        key={crypto.randomUUID()}
      />
    ),
    []
  );

  const redirectToDefault = useCallback(
    () => (
      <Redirect
        to={string.withDefaultPagination(PATHS.process.execution._())}
      />
    ),
    []
  );

  return (
    <main>
      <Authenticated>
        <ErrorBoundary fallback={<ErrorPage />} resetKeys={[location.pathname]}>
          <Switch>
            {routes.map(renderRoute)}
            <Route exact path="/" render={redirectToDefault} />
            {process.env.NODE_ENV === 'development' ? null : (
              <Route path="*" component={ErrorPage} />
            )}
          </Switch>
        </ErrorBoundary>
      </Authenticated>
    </main>
  );
  // endregion
};

export default memo(AppRoutes);
