import { useCallback, useMemo, useState } from 'react';

import { Props } from '.';
import { OpenHamburgerHandler, WrapperVisible } from './types';

const useHamburgerMenu = (isMobile: Props['isMobile']) => {
  const [isOpen, setOpen] = useState(false);

  const [wrapperVisible, setWrapperVisible] = useState(
    isMobile ? WrapperVisible.none : WrapperVisible.flex
  );

  const openHamburgerHandler: OpenHamburgerHandler = useCallback(() => {
    setOpen((prevState) => {
      setWrapperVisible(prevState ? WrapperVisible.none : WrapperVisible.flex);
      return !prevState;
    });
  }, [setOpen, setWrapperVisible]);

  return useMemo(
    () => ({
      openHamburgerHandler,
      wrapperVisible,
      isOpen,
    }),
    [isOpen, openHamburgerHandler, wrapperVisible]
  );
};

export default useHamburgerMenu;
