import { ProcessGeneral } from 'api/endpoints/processes';
import { WithSearchTemplateId } from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export interface SearchTemplateGeneral extends BaseEntity {
  url: string;
  process: ProcessGeneral;
}

export type GetAllParams = Pagination;
export type GetAllResult = DataWithTotal<SearchTemplateGeneral>;
export type CreateParams = Pick<SearchTemplateGeneral, 'name' | 'url'> & {
  processId: string;
};
export type CreateResult = SearchTemplateGeneral;
export type RemoveParams = WithSearchTemplateId;
export type RemoveResult = unknown;

const SearchTemplate = {
  getAll: apiReqSagaCreator<GetAllParams, GetAllResult>(
    API_REQ_ACTION_TYPES.SEARCH_TEMPLATE.GET_ALL,
    (data) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.searchTemplate._(),
      data,
    }),
    {
      withPrevPageOnLessTotal: true,
    }
  ),
  create: apiReqSagaCreator<CreateParams, CreateResult>(
    API_REQ_ACTION_TYPES.SEARCH_TEMPLATE.CREATE,
    (data) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.searchTemplate._(),
      data,
    })
  ),
  remove: apiReqSagaCreator<RemoveParams, RemoveResult>(
    API_REQ_ACTION_TYPES.SEARCH_TEMPLATE.REMOVE,
    (params) => ({
      method: HTTP_METHODS.DELETE,
      url: ApiPaths.searchTemplate.byId._({
        [ApiUrlParams.searchTemplateId]: params[ApiUrlParams.searchTemplateId],
      }),
    })
  ),
};

export default SearchTemplate;
