import { Fields } from 'components/organisms/form/createPrintRenderScenario/types';

import createAction from 'utils/createAction';

const PRINT_RENDER = {
  SET_PRINT_RENDER_DATA: 'PRINT_RENDER.SET_PRINT_RENDER_DATA',
  SET_PROCESS_INSTANCES: 'PRINT_RENDER.SET_PROCESS_INSTANCES',
  SET_INITIAL_PRINT_RENDER_ITEM_DATA:
    'PRINT_RENDER.SET_INITIAL_PRINT_RENDER_ITEM_DATA',
};

const PrintRenderActions = {
  setPrintRender: createAction(PRINT_RENDER.SET_PRINT_RENDER_DATA),
  setProcessInstancesCount: createAction<{
    success: number;
    running: number;
    failed: number;
  }>(PRINT_RENDER.SET_PROCESS_INSTANCES),
  setInitialPrintRenderItemData: createAction<Nullable<Fields>>(
    PRINT_RENDER.SET_INITIAL_PRINT_RENDER_ITEM_DATA
  ),
};

export { PRINT_RENDER as PRINT_RENDER_ACTION_TYPES, PrintRenderActions };
