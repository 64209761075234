import JSZip from 'jszip';
import { ScanDocPage } from 'types/scanning';

const sortKeysByReference = (
  targetObject: object,
  originalOrder?: string[]
) => {
  const reorderResult: string[] = [];

  Object.keys(targetObject).forEach((e) => {
    const [, first] = e.split('/');
    const idx = originalOrder?.indexOf(first);

    if (typeof idx !== 'undefined' && idx >= 0) {
      reorderResult[idx] = e;
    }
  });

  return reorderResult;
};

export interface CrateImageUrlFromBufferResult {
  documentId: string;
  pages: ScanDocPage[];
}

const crateImageUrlFromBuffer = async (
  data: ArrayBuffer,
  scanDocContainerId: string,
  originalOrder?: string[] | null
) => {
  if (!data.byteLength) return undefined;

  return JSZip.loadAsync(data)
    .then((zip: JSZip) => {
      const reorderResult = sortKeysByReference(zip.files, originalOrder || []);

      const promises = reorderResult.map(async (fileName) => {
        const file = zip.files[fileName];
        const blob = await file.async('blob');

        return [fileName, URL.createObjectURL(blob)];
      });

      return Promise.all(promises);
    })
    .then((result: string[][]) =>
      result
        .filter(Boolean)
        .reduce((acc: { id: string; url: string }[], val) => {
          const [name, url] = val;
          return name.includes('__MACOSX/')
            ? acc
            : [
                ...acc,
                {
                  id: crypto.randomUUID(),
                  name,
                  url,
                },
              ];
        }, [])
    )
    .then((normalizedPages) => ({
      documentId: scanDocContainerId,
      pages: normalizedPages,
    }));
};

export default crateImageUrlFromBuffer;
