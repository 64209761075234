import subSelectorCreator from 'utils/subSelectorCreator';

import { ScanningState } from './reducer';

const createScanConfigSelector = subSelectorCreator<ScanningState>(
  (state) => state.processScanning
);

const ScanConfigSelector = {
  getLastCreatedDocument: createScanConfigSelector(
    ({ lastCreatedDocument }) => lastCreatedDocument
  ),
  getPrevFormData: createScanConfigSelector(({ prevFormData }) => prevFormData),
  getIsSavedPage: createScanConfigSelector(({ isSavedPage }) => isSavedPage),
  getSavedPages: createScanConfigSelector(({ savedPages }) => savedPages),
  getBarcode: createScanConfigSelector(({ barcode }) => barcode),
  getUploadedFiles: createScanConfigSelector(
    ({ uploadedFiles }) => uploadedFiles
  ),
};

export default ScanConfigSelector;
