import React, { ComponentType, lazy, memo, ReactNode, Suspense } from 'react';

import Spinner from 'components/atoms/spinner';

export default function asLazyPage(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lazyFactory: () => Promise<{ default: ComponentType<any> }>,
  fallback: ReactNode = <Spinner />
) {
  // region *******************************DATA*********************************
  const LazyComponent = lazy(lazyFactory);
  // endregion

  // region ********************************JSX*********************************
  return memo(({ ...props }) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  ));
  // endregion
}
