import { put } from 'redux-saga/effects';

import * as ERROR_CODES from 'constants/errorCodes';
import { TOASTER_TYPE } from 'constants/toasterMessages';

import createAddToasterAction from 'utils/createAddToasterAction';

function* removeDocumentError({ payload }: ReduxAction<BackendError[]>) {
  if (
    payload.some(
      (e) => e.code === ERROR_CODES.REJECT_REMOVE_ROOT_SPLIT_DOCUMENT
    )
  ) {
    yield put(
      createAddToasterAction({
        message: `The source document can't be removed`,
        type: TOASTER_TYPE.ERROR,
      })
    );
  }

  if (
    payload.some(
      (e) => e.code === ERROR_CODES.REJECT_REMOVE_SPLIT_DOCUMENT_WITH_ALL_PAGES
    )
  ) {
    yield put(
      createAddToasterAction({
        message: `Can not remove document with all pages.`,
        type: TOASTER_TYPE.ERROR,
      })
    );
  }
}

export default removeDocumentError;
