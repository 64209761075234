import ROUTES, { SplitDocumentsUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { call, select } from 'redux-saga/effects';
import { NormalizedSplitDocument } from 'types/splitDocument';

import { ApiSagaResult } from 'api/apiReqSagaCreator';
import SplitDocument, {
  BulkUpdateResult,
  GetByIdResult,
} from 'api/endpoints/splitDocument';

import ApiUrlParams from 'constants/apiUrlParams';

import { filterStartContainersPages } from './movePage';

export type MovePagesToRemoteContainerArgs = [
  string[],
  NormalizedSplitDocument[],
  string,
];

function* movePagesToRemoteContainer(
  pageNames: string[],
  documents: NormalizedSplitDocument[],
  newDocumentId: string
) {
  const { pathname } = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId, splitDocInstanceId },
  }: match<SplitDocumentsUiParams> = yield matchPath(pathname, {
    path: [ROUTES.process.instance.splitDocuments()],
  });

  const startContainersWithFilteredPages = filterStartContainersPages(
    documents,
    pageNames
  );

  const { result }: ApiSagaResult<GetByIdResult> = yield call(
    SplitDocument.getById,
    {
      [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
      [ApiUrlParams.splitDocInstanceId]: splitDocInstanceId,
      [ApiUrlParams.splitDocContainerId]: newDocumentId,
    }
  );

  const res: ApiSagaResult<BulkUpdateResult> = yield call(
    SplitDocument.bulkUpdate,
    {
      [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
      [ApiUrlParams.splitDocInstanceId]: splitDocInstanceId,
      documents: [
        ...startContainersWithFilteredPages.filter(
          (e) => e.documentId !== newDocumentId
        ),
        {
          documentId: newDocumentId,
          pages: [...pageNames, ...((result as GetByIdResult)?.pages ?? [])],
        },
      ],
    }
  );

  return res;
}

export default movePagesToRemoteContainer;
