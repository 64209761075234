import createAction from 'utils/createAction';

const EMAIL_POLLING = {
  RESET_CREDENTIALS_FLAG: 'EMAIL_POLLING.RESET_CREDENTIALS_FLAG',
  SET_EMAIL_WAS_CHANGED: 'EMAIL_POLLING.EMAIL_WAS_CHANGED',
};

const EmailPollingActions = {
  resetCredentialsFlag: createAction(EMAIL_POLLING.RESET_CREDENTIALS_FLAG),
  setEmailWasChanged: createAction(EMAIL_POLLING.SET_EMAIL_WAS_CHANGED),
};

export { EMAIL_POLLING as EMAIL_POLLING_ACTION_TYPES, EmailPollingActions };
