import { WithReleaseOrDraftProcessId, WithFieldId } from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';
import {
  DataModelFieldSubType,
  DataModelFieldType,
  SearchTypes,
} from 'constants/global';

import { excludeObjectProperty } from 'utils/normalizers/object';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export interface CreateData {
  data: {
    name: string;
    description?: string;
    alias: string;
    metadata?: {
      maskType: string;
    };
    type: FieldType;
    options?: string[];
  };
}

export type FieldType =
  | 'text'
  | 'date'
  | 'number'
  | 'file'
  | 'collection'
  | 'select';

export interface AliasGeneral extends ById {
  name: string;
  type: FieldType;
  fields: FieldGeneral[];
}

export interface FieldPreview extends BaseEntity {
  alias: AliasGeneral;
  description: string;
  isForAdvancedSearch: boolean;
  isForQuickSearch: boolean;
  isForView: boolean;
  metadata: { maskType: DataModelFieldType | DataModelFieldSubType };
  type: FieldType;
}

export interface BaseField extends BaseEntity {
  aliasId: string;
  type: FieldType;
  isForQuickSearch: boolean;
  isForAdvancedSearch: boolean;
  isForView: boolean;
  alias: AliasGeneral;
  description?: string;
  options?: string[];
  metadata?: {
    maskType: string;
  };
}

export interface FieldGeneral extends BaseField {
  collection?: BaseField[];
}

export interface GetByType extends Pagination {
  type?: DataModelFieldType;
}

export interface GetDynamic extends Pagination {
  for?: SearchTypes;
}

export type GetDFMsByTypeParams = GetByType & WithReleaseOrDraftProcessId;
export type GetDMFsByTypeResult = DataWithTotal<FieldGeneral>;
export type DeleteDataModelFieldParams = WithReleaseOrDraftProcessId &
  WithFieldId;
export type DeleteDataModelFieldsResult = unknown;
export type GetAliasesParams = GetDynamic & WithReleaseOrDraftProcessId;
export type GetAliasesResult = DataWithTotal<AliasGeneral>;
export type GetFieldsByIdParams = GetDynamic & WithReleaseOrDraftProcessId;
export type GetFieldsByIdResult = DataWithTotal<FieldGeneral>;
export type GetFieldsByFieldIdParams = WithReleaseOrDraftProcessId &
  WithFieldId;
export type GetFieldsByFieldIdResult = FieldGeneral;
export type UpdateDataModelFieldParams = CreateData &
  WithReleaseOrDraftProcessId &
  WithFieldId;
export type UpdateDataModelFieldResult = FieldPreview;
export type CreateFieldParams = CreateData & WithReleaseOrDraftProcessId;
export type CreateFieldResult = FieldPreview;

const ProcessFields = {
  getDMFsByType: apiReqSagaCreator<GetDFMsByTypeParams, GetDMFsByTypeResult>(
    API_REQ_ACTION_TYPES.FIELDS.GET_BY_PROCESS_TYPE_ID,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.fields._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
      }),
      params: excludeObjectProperty(
        params,
        ApiUrlParams.releaseOrDraftProcessId
      ),
    })
  ),
  deleteDataModelField: apiReqSagaCreator<
    DeleteDataModelFieldParams,
    DeleteDataModelFieldsResult
  >(API_REQ_ACTION_TYPES.FIELDS.DELETE_MODEL_FIELD_BY_ID, (params) => ({
    method: HTTP_METHODS.DELETE,
    url: ApiPaths.processes.byId.fields.byId({
      [ApiUrlParams.releaseOrDraftProcessId]:
        params[ApiUrlParams.releaseOrDraftProcessId],
      [ApiUrlParams.fieldId]: params[ApiUrlParams.fieldId],
    }),
  })),
  getAliases: apiReqSagaCreator<GetAliasesParams, GetAliasesResult>(
    API_REQ_ACTION_TYPES.FIELDS.GET_ALIASES,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.fields.aliases._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
      }),
      params: {
        limit: params.limit,
        offset: params.offset,
        for: params.for,
      },
    })
  ),
  getFieldsById: apiReqSagaCreator<GetFieldsByIdParams, GetFieldsByIdResult>(
    API_REQ_ACTION_TYPES.FIELDS.GET_BY_PROCESS_ID,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.fields._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
      }),
      params: {
        limit: params.limit,
        offset: params.offset,
        for: params.for,
      },
    }),
    {
      withPrevPageOnLessTotal: true,
    }
  ),
  getFieldsByFieldId: apiReqSagaCreator<
    GetFieldsByFieldIdParams,
    GetFieldsByFieldIdResult
  >(API_REQ_ACTION_TYPES.FIELDS.GET_BY_PROCESS_ID_AND_FIELD_ID, (params) => ({
    method: HTTP_METHODS.GET,
    url: ApiPaths.processes.byId.fields.byId({
      [ApiUrlParams.releaseOrDraftProcessId]:
        params[ApiUrlParams.releaseOrDraftProcessId],
      [ApiUrlParams.fieldId]: params[ApiUrlParams.fieldId],
    }),
  })),
  updateDataModelField: apiReqSagaCreator<
    UpdateDataModelFieldParams,
    UpdateDataModelFieldResult
  >(API_REQ_ACTION_TYPES.FIELDS.UPDATE_MODEL_FIELD_BY_ID, (args) => ({
    method: HTTP_METHODS.PUT,
    url: ApiPaths.processes.byId.fields.byId({
      [ApiUrlParams.releaseOrDraftProcessId]:
        args[ApiUrlParams.releaseOrDraftProcessId],
      [ApiUrlParams.fieldId]: args[ApiUrlParams.fieldId],
    }),
    data: args.data,
  })),
  createField: apiReqSagaCreator<CreateFieldParams, CreateFieldResult>(
    API_REQ_ACTION_TYPES.PROCESSES.CREATE_MODEL,
    (args) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.fields._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
      }),
      data: args.data,
    })
  ),
};

export default ProcessFields;
