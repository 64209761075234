import { combineReducers } from 'redux';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import {
  ProcessDefinitionStageDetails,
  ProcessDefinitionStageGeneral,
} from 'api/endpoints/stage';

import { STAGE_ACTION_TYPES } from './actions';

export interface StageState {
  list: Nullable<ProcessDefinitionStageGeneral[]>;
  active: Nullable<ProcessDefinitionStageDetails>;
}

const stage = combineReducers<StageState>({
  list(
    state = null,
    {
      type,
      payload,
    }: { type: string; payload: ProcessDefinitionStageGeneral[] }
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.STAGES.GET_LIST.SUCCESS:
      case STAGE_ACTION_TYPES.UPDATE_LIST:
        return payload ? [...payload] : null;

      case STAGE_ACTION_TYPES.CLEAR_LIST:
        return null;

      default:
        return state;
    }
  },
  active(
    state = null,
    {
      type,
      payload,
    }: {
      type: string;
      payload: ProcessDefinitionStageDetails | ProcessDefinitionStageGeneral[];
    }
  ) {
    switch (type) {
      case STAGE_ACTION_TYPES.UPDATE_ACTIVE:
      case API_REQ_ACTION_TYPES.STAGES.GET_DETAILS.SUCCESS:
        return (payload as ProcessDefinitionStageDetails) ?? null;

      case API_REQ_ACTION_TYPES.STAGES.UPDATE.SUCCESS:
        return (payload as ProcessDefinitionStageDetails).id === state?.id
          ? { ...state, ...payload }
          : state;

      case API_REQ_ACTION_TYPES.STAGES.GET_LIST.SUCCESS:
        return (payload as ProcessDefinitionStageGeneral[])?.length
          ? state
          : null;

      case STAGE_ACTION_TYPES.CLEAR_LIST:
        return null;

      default:
        return state;
    }
  },
});

export default stage;
