import {
  ApiResponseAsync,
  translateAxiosErrorToApiError,
} from 'container/models';

import { authHeaderName } from 'api/apiReqSagaCreator/config';
import { handleExpiredTokens } from 'api/apiReqSagaCreator/httpRequest';

import {
  AbstractFreshdeskTokenRepo,
  FreshdeskTokenResponse,
} from './abstractFreshdeskTokenRepo';

export class FreshdeskTokenRepo extends AbstractFreshdeskTokenRepo {
  getFreshdeskToken = (): ApiResponseAsync<FreshdeskTokenResponse> =>
    handleExpiredTokens(() =>
      this.client.post<FreshdeskTokenResponse>(
        '/generate-freshdesk-token',
        {},
        {
          headers: {
            [authHeaderName]: this.tokenAccessor(),
          },
        }
      )
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);
}
