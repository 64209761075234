import ROUTES, { ScanScenarioInstanceUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { call, select } from 'redux-saga/effects';

import { ApiSagaResult } from 'api/apiReqSagaCreator';
import ProcessScan, { GetPagesPreviewResult } from 'api/endpoints/processScan';

import ApiUrlParams from 'constants/apiUrlParams';

export type GetDocumentsPagesResult = { id: string } & (
  | GetDocumentsPagesResultSuccess
  | GetDocumentsPagesResultFailure
);

export type GetDocumentsPagesResultSuccess = { ok: true; buffer: ArrayBuffer };
export type GetDocumentsPagesResultFailure = {
  ok: false;
  error: BackendError[];
};

function* getDocumentsPages(
  scanInstanceId: string,
  scanDocContainerId: string,
  pageNames: string[]
) {
  const { pathname } = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId },
  }: match<ScanScenarioInstanceUiParams> = yield matchPath(pathname, {
    path: ROUTES.process.scanningScenarioOld(),
  });

  const { ok, result }: ApiSagaResult<GetPagesPreviewResult> = yield call(
    ProcessScan.getPagesPreview,
    {
      [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
      [ApiUrlParams.scanInstanceId]: scanInstanceId,
      data: {
        names: pageNames,
      },
    }
  );

  let rv: GetDocumentsPagesResult;
  if (ok)
    rv = {
      id: scanDocContainerId,
      ok,
      buffer: result as ArrayBuffer,
    };
  else
    rv = {
      id: scanDocContainerId,
      ok,
      error: result as BackendError[],
    };

  return rv;
}

export default getDocumentsPages;
