import { put } from 'redux-saga/effects';

import createATA from 'utils/createAddToasterAction';

const SUCCEEDED_ACTIVATE_MSG =
  'The scenario was successfully used to process automatically';

function* afterSucceededPrintRenderScenarioActivate() {
  yield put(
    createATA({
      message: SUCCEEDED_ACTIVATE_MSG,
    })
  );
}

export default afterSucceededPrintRenderScenarioActivate;
