import { delay, fork } from 'redux-saga/effects';

import SplitDocument, { UpdateDocument } from 'api/endpoints/splitDocument';
import {
  WithReleaseOrDraftProcessId,
  WithSplitDocContainerId,
  WithSplitDocInstanceId,
} from 'api/types/pathParams';

export type SetInstanceNameParams = UpdateDocument &
  WithReleaseOrDraftProcessId &
  WithSplitDocInstanceId &
  WithSplitDocContainerId;

function* setInstanceName(params: SetInstanceNameParams) {
  yield delay(1500);

  yield fork(SplitDocument.update, params);
}

export default setInstanceName;
