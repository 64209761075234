const DOCUMENT_BARCODE_DATA = 'document_barcode_data';

const documentBarcodeData = {
  getDocumentsBarcodeData() {
    return window.localStorage.getItem(DOCUMENT_BARCODE_DATA) || '{}';
  },
  setBarcodeValueToDocument(data: KeyValuePairs) {
    window.localStorage.setItem(DOCUMENT_BARCODE_DATA, JSON.stringify(data));
  },
  clearDocumentsBarcodeData() {
    window.localStorage.removeItem(DOCUMENT_BARCODE_DATA);
  },
};

export default documentBarcodeData;
