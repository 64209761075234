import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export interface ImapValidateCredentials {
  email: string;
  password?: string;
}

export interface MSGraphValidateCredentials {
  tenantId: string;
  applicationId: string;
  userId: string;
  secret?: string;
  email: string;
}

export type ValidateCredentialsParams = (
  | ImapValidateCredentials
  | MSGraphValidateCredentials
) & { provider: string };

export interface ValidateCredentialsResult {
  isValid: boolean;
}

export interface GetLabelsResult {
  labels: string[];
}

export type GetLabelsParams = ValidateCredentialsParams;

const emailproviders = {
  validateCredentials: apiReqSagaCreator<
    ValidateCredentialsParams,
    ValidateCredentialsResult
  >(API_REQ_ACTION_TYPES.EMAILPROVIDERS.VALIDATE_CREDENTIALS, (data) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.emailprovider.validateCredentials._(),
    data,
  })),
  getLabels: apiReqSagaCreator<GetLabelsParams, GetLabelsResult>(
    API_REQ_ACTION_TYPES.EMAILPROVIDERS.GET_LABELS,
    (data) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.emailprovider.getLabels._(),
      data,
    })
  ),
};

export default emailproviders;
