import createAction from 'utils/createAction';

const PRINT_RENDER_EMAIL_TEMPLATES = {
  CLEAR_IS_USED_INFO: 'PRINT_RENDER_EMAIL_TEMPLATES.SET_ROUTE',
};

const PrintRenderEmailTemplatesActions = {
  clearIsUsedInfo: createAction(
    PRINT_RENDER_EMAIL_TEMPLATES.CLEAR_IS_USED_INFO
  ),
};

export {
  PRINT_RENDER_EMAIL_TEMPLATES as PRINT_RENDER_EMAIL_TEMPLATES_ACTION_TYPES,
  PrintRenderEmailTemplatesActions,
};
