import ROUTES from 'app/routes/paths';
import { UiWithReleaseOrDraftProcessId } from 'app/routes/uiPathParams';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { fork, select } from 'redux-saga/effects';

import ProcessFields from 'api/endpoints/processFields';

import ApiUrlParams from 'constants/apiUrlParams';
import { SearchTypes } from 'constants/global';

export type FetchUniqueFieldArgs = [SearchTypes];

function* fetchUniqueField(type = SearchTypes.quick) {
  const { pathname } = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId },
  }: match<UiWithReleaseOrDraftProcessId> = yield matchPath(pathname, {
    path: [
      ROUTES.process.definition.details.fields(),
      ROUTES.process.definition.details.version.fields(),
      ROUTES.process.execution.details._(),
      ROUTES.process.instance._(),
      ROUTES.process.editProcess.dataModelFields(),
    ],
  });

  yield fork(
    ProcessFields.getFieldsById,
    {
      [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
      for: type,
    },
    { title: type, withPrevPageOnLessTotal: false }
  );

  return {};
}

export default fetchUniqueField;
