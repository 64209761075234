import ROUTES from 'app/routes/paths';
import { replace } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';

import ProcessInstanceEndpoint from 'api/endpoints/processInstance';

import { INSTANCES_LIST_CONFIG } from 'constants/global';
import UiUrlParams from 'constants/uiUrlParams';

export type NavigateInsideInstance = [boolean | undefined];

function* navigateInsideInstance(back = false) {
  const { status, ...config } = yield JSON.parse(
    String(sessionStorage.getItem(INSTANCES_LIST_CONFIG))
  );
  const newCurrent = back ? config.current - 1 : config.current + 1;
  const {
    result: { data },
  } = yield call(ProcessInstanceEndpoint.getInstancesList, {
    ...config,
    ...(status !== 'all' && { status }),
    offset: newCurrent,
    limit: 1,
  });

  const [process] = data;

  yield sessionStorage.setItem(
    INSTANCES_LIST_CONFIG,
    JSON.stringify({ ...config, status, current: newCurrent })
  );

  yield put(
    replace(
      ROUTES.process.instance._({
        [UiUrlParams.releaseOrDraftProcessId]: process.version.process.id,
        [UiUrlParams.processInstanceId]: process.id,
        [UiUrlParams.draftProcessId]: config.releaseOrDraftProcessId,
      })
    )
  );
}

export default navigateInsideInstance;
