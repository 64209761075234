import { FormErrors, stopSubmit } from 'redux-form';
import { put } from 'redux-saga/effects';

import {
  ImapValidateCredentials,
  MSGraphValidateCredentials,
  ValidateCredentialsParams,
  ValidateCredentialsResult,
} from 'api/endpoints/emailproviders';

import { ERROR_KEYS } from 'constants/fieldErrors';
import { FORM_NAMES } from 'constants/form';

import { RequestActionMeta } from '../../../api/apiReqSagaCreator';

const IMAP_ERROR = {
  key: ERROR_KEYS.WRONG_GSUITE_CREDENTIALS,
};

const GRAPH_ERROR = {
  key: ERROR_KEYS.WRONG_MSGRAPH_CREDENTIALS,
};

function* checkCredentialsFailure({
  payload,
  meta,
}: ReduxAction<
  ValidateCredentialsResult,
  RequestActionMeta<ValidateCredentialsParams>
>) {
  if (!payload.isValid) {
    const { input } = meta;
    if (input?.provider === 'gmail' || input?.provider === 'office365') {
      const imapErrors: FormErrors<ImapValidateCredentials, { key: string }> =
        {};
      imapErrors.email = IMAP_ERROR;
      imapErrors.password = IMAP_ERROR;
      yield put(stopSubmit(FORM_NAMES.CHECK_GSUIT_CREDENTIALS, imapErrors));
    }
    if (input?.provider === 'msgraph') {
      const graphErrors: FormErrors<
        MSGraphValidateCredentials,
        { key: string }
      > = {};
      graphErrors.tenantId = GRAPH_ERROR;
      graphErrors.applicationId = GRAPH_ERROR;
      graphErrors.userId = GRAPH_ERROR;
      graphErrors.secret = GRAPH_ERROR;
      yield put(stopSubmit(FORM_NAMES.CHECK_GSUIT_CREDENTIALS, graphErrors));
    }
  }
}

export default checkCredentialsFailure;
