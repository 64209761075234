import BaseRepo from 'container/BaseRepo';

import { PrintRenderScenarioWithHypermedia } from '../../../api/endpoints/printRender';
import { OdataURLBuilder } from '../../../api/types/OdataURLBuilder';
import { ApiResponseAsync } from '../../models';

export interface PrintRenderLog {
  id: string;
  logType: string;
  event: string;
  description: string;
  createdAt: string;
}

export abstract class AbstractPrintRenderLogRepo extends BaseRepo {
  abstract getLogs(
    request: OdataURLBuilder
  ): ApiResponseAsync<DataWithTotal<PrintRenderLog>>;
  abstract getScenarios(
    processId: string
  ): ApiResponseAsync<DataWithTotal<PrintRenderScenarioWithHypermedia>>;
}
