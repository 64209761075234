import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import reFetchScanList from './sagas/reFetchScanList';

function* watchersSaga() {
  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.SCANNING_CONFIGURATION.UPDATE_BY_ID.SUCCESS,
      API_REQ_ACTION_TYPES.SCANNING_CONFIGURATION.DELETE_BY_ID.SUCCESS,
    ],
    reFetchScanList
  );
}

export default watchersSaga;
