import {
  ExternalForm,
  ExternalFormSubmitted,
  ForgotPassword,
  Home,
  InstanceDetailsMobile,
  PrintRenderResultDetailsPrintFile,
  ProcessDefinitionCreateSecurityGroup,
  ProcessDefinitionSecurityGroupDetails,
  ProcessInstance,
  ProcessInstanceCreation,
  ProcessInstanceEditor,
  Registration,
  ResetPassword,
  SplitDocuments,
  SystemLogs,
} from 'app/routes/lazyPages';
import ROUTES from 'app/routes/paths';
import onlySignedInPage from 'hocs/onlySignedInPage';

import { ACCESSIBLE_ROLES } from 'constants/roles';

import { accessOrDefaultRedirect } from 'utils/accessOrDefaultRedirect';

const processRoutes: RoutesConfig = [
  {
    path: ROUTES.process.home._(),
    component: onlySignedInPage(Home),
    exact: true,
  },
  {
    exact: true,
    path: [
      ROUTES.process.createSecurityGroup(),
      ROUTES.process.editSecurityGroup(),
    ],
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.MANAGERS)(
        ProcessDefinitionCreateSecurityGroup
      )
    ),
  },
  {
    path: [
      ROUTES.process.securityGroupDetails.preview(),
      ROUTES.process.securityGroupDetails._(),
    ],
    exact: true,
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.MANAGERS)(
        ProcessDefinitionSecurityGroupDetails
      )
    ),
  },
  {
    path: ROUTES.process.printFile(),
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.ALL)(
        PrintRenderResultDetailsPrintFile
      )
    ),
    exact: true,
  },
  // {
  //   path: ROUTES.process.scanningDocumentView(),
  //   component: onlySignedInPage(
  //     accessOrDefaultRedirect(ACCESSIBLE_ROLES.ALL)(
  //       ProcessManagementCreateDocumentView
  //     )
  //   ),
  //   exact: false,
  // },
  {
    path: ROUTES.process.instance._(),
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.ALL)(ProcessInstance)
    ),
    exact: true,
  },
  {
    path: ROUTES.process.instance.edit._(),
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.ALL)(ProcessInstanceEditor)
    ),
    exact: true,
  },
  {
    path: ROUTES.process.instance.detailsMobile._(),
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.ALL)(InstanceDetailsMobile)
    ),
    exact: true,
  },
  {
    path: ROUTES.process.instance.splitDocuments(),
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.ALL)(SplitDocuments)
    ),
    exact: true,
  },
  {
    path: ROUTES.process.registration._(),
    component: Registration,
    exact: false,
  },
  {
    path: ROUTES.process.forgotPassword._(),
    component: ForgotPassword,
    exact: false,
  },
  {
    path: ROUTES.process.resetPassword._(),
    component: ResetPassword,
    exact: false,
  },
  {
    path: ROUTES.external.form.submitted(),
    component: ExternalFormSubmitted,
    exact: true,
  },
  {
    path: ROUTES.external.form._(),
    component: ExternalForm,
    exact: true,
  },
  {
    path: ROUTES.process.systemLogs._(),
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.ALL)(SystemLogs)
    ),
    exact: true,
  },
  {
    path: ROUTES.process.instanceLogs._(),
    component: onlySignedInPage(
      accessOrDefaultRedirect(ACCESSIBLE_ROLES.ALL)(ProcessInstanceCreation)
    ),
    exact: true,
  },
];

export { processRoutes };
