import {
  KeyboardEvent,
  MutableRefObject,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import { KEYS_CODE } from 'constants/global';

import { OptionRefs, Props } from '.';
import { HandleKeyPress, HandleSetRef, Refs } from './types';

const findInTree = (list: SelectItem[], needed: string, down = false) => {
  const planeList = list.reduce(
    (acc, next) => [...acc, next, ...(next.nested ? next.nested : [])],
    [] as SelectItem[]
  );
  const currentIndex: number = planeList.findIndex((e) => e.value === needed);
  if (!currentIndex && !down) return null;
  return planeList[down ? currentIndex + 1 : currentIndex - 1];
};

const useLogic = (items: Props['items'], parentRefs: Props['parentRefs']) => {
  const refs: Refs = useRef(
    parentRefs || ({} as unknown as MutableRefObject<OptionRefs>)
  );

  const handleKeyPress: HandleKeyPress = useCallback(
    (ev: KeyboardEvent<HTMLDivElement>) => {
      if (![KEYS_CODE.ARROW_UP, KEYS_CODE.ARROW_DOWN].includes(ev.keyCode))
        return;
      const focusedElement = Object.entries(refs.current ?? {}).find(
        ([, e]) => document.activeElement === e
      );
      if (focusedElement) {
        const neededItem = findInTree(
          items,
          focusedElement[0],
          ev.keyCode === 40
        );
        const neededRef = Object.entries(refs.current).find(
          ([value]) => neededItem?.value === value
        );
        if (neededRef) {
          (neededRef as [string, HTMLButtonElement])[1].focus();
        }
      }
    },
    [items]
  );

  const handleSetRef: HandleSetRef = useCallback(
    (item) => (el: HTMLButtonElement) => {
      // @ts-ignore
      refs.current[item.value] = el;
    },
    []
  );

  return useMemo(
    () => ({
      handleKeyPress,
      handleSetRef,
      refs,
    }),
    [handleKeyPress, handleSetRef]
  );
};

export default useLogic;
