import { useMemo } from 'react';

import { useAuthCache } from 'api/cache';
import { ApiPaths } from 'api/constants';
import { User } from 'api/endpoints/users';

import { useReadAPI } from '../../../api/useAPI';

const useLogic = () => {
  const { loggedIn } = useAuthCache();

  const { data: user } = useReadAPI<User>(
    useMemo(() => (loggedIn ? ApiPaths.users.info._() : undefined), [loggedIn]),
    {
      lifespan: 300000 /* 5 minutes */,
    }
  );

  const content = useMemo(
    () =>
      user
        ? `${user.organization.name}: ${user.profile.currentTenant.name}`
        : undefined,
    [user]
  );

  return content;
};

export default useLogic;
