import ROUTES, { ScanScenarioInstanceUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { call, put, select } from 'redux-saga/effects';

import { ApiSagaResult } from 'api/apiReqSagaCreator';
import ProcessScan from 'api/endpoints/processScan';

import normalizedFormFiles from 'store/process/sagas/normalizedFormFiles';

import ApiUrlParams from 'constants/apiUrlParams';
import { FORM_NAMES } from 'constants/form';

import { ProcessScanningActions } from '../actions';

function* saveBarcodePerDocumentData(
  scanInstanceId: string,
  documents: { pageName: string; barcodeValue: Nullable<string> }[][],
  currentFormData: DynamicFormParams
) {
  const { pathname } = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId },
  }: match<ScanScenarioInstanceUiParams> = yield matchPath(pathname, {
    path: [ROUTES.process.scanningScenarioOld()],
  });

  const transformCurrentFormData: DynamicFormNormalizedParams = yield call(
    normalizedFormFiles,
    {
      form: FORM_NAMES.FORM_FACTORY,
      [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
      [ApiUrlParams.processInstanceId]: scanInstanceId,
      fields: currentFormData,
    }
  );

  const errors = [];

  for (const document of documents) {
    const barcode = document[0].barcodeValue;
    const documentPages = document.map((e) => e.pageName);

    const response: ApiSagaResult<any> = yield call(
      ProcessScan.createDocument,
      {
        [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
        [ApiUrlParams.scanInstanceId]: scanInstanceId,
        data: {
          formData: transformCurrentFormData,
          pages: documentPages,
          ...(barcode ? { barcode } : {}),
        },
      }
    );

    if (!response.ok) {
      errors.push(...response.result);
    }
    yield put(ProcessScanningActions.saveLastCreatedDocument(response.result));
  }

  const result: ApiSagaResult<any> = {
    ok: errors.length === 0,
    result: errors,
  };

  return result;

  // yield put(
  //   push(
  //     withDefaultPagination(
  //       ROUTES.process.scanningDocumentView({
  //         [UiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
  //         [UiUrlParams.scanConfigurationId]: scanConfigurationId,
  //         [UiUrlParams.scanInstanceId]: scanInstanceId,
  //       }),
  //       5
  //     )
  //   )
  // );
}

export default saveBarcodePerDocumentData;
