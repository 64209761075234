import BaseRepo from 'container/BaseRepo';

import { ApiResponseAsync } from '../../models';

export abstract class AbstractScanRepo extends BaseRepo {
  abstract deleteScanInstance(
    processId: string,
    scanInstanceId: string
  ): ApiResponseAsync<any>;
}
