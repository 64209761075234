import ROUTES, { ScanScenarioInstanceUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { Action } from 'redux';
import { call, select } from 'redux-saga/effects';
import { ScanDocPage } from 'types/scanning';

import ProcessScan, {
  BulkUpdateDocumentResult,
  ScanDocumentContainer,
  UpdateScanDoc,
} from 'api/endpoints/processScan';

import ApiUrlParams from 'constants/apiUrlParams';

import { ApiSagaResult } from '../../../api/apiReqSagaCreator';
import ScanDocumentViewSelector from '../selectors';
import { reFetchDocumentsList } from './reFetchDocumentsList';

interface TaskEvent {
  meta: {};
  payload: {
    reorderList: KeyValuePairs<Nullable<ScanDocPage[]>>;
    scanInstanceId: string;
  };
}

interface TaskAction extends Action, TaskEvent {
  type: string;
}

function* movePage({ payload }: TaskAction) {
  const list: Nullable<ScanDocumentContainer[]> = yield select(
    ScanDocumentViewSelector.getAllList
  );
  const { pathname } = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId },
  }: match<ScanScenarioInstanceUiParams> = yield matchPath(pathname, {
    path: ROUTES.process.scanningScenarioOld(),
  });

  const updateDocuments = Object.keys(payload?.reorderList ?? []).map((key) => {
    const documentItem = list?.find((e: ScanDocumentContainer) => e.id === key);

    if (documentItem) {
      const {
        scanInstanceId: excludeId,
        createdAt,
        updatedAt,
        ...restDocumentItem
      } = documentItem;

      return {
        ...restDocumentItem,
        pages: [...(payload.reorderList[key] || [])]
          .filter(Boolean)
          .map((el: ScanDocPage) => el.name),
      };
    }

    return null;
  });

  const sanitizedUpdateDocuments = updateDocuments.map((scanDocument) => {
    if (!scanDocument?.pages?.length && scanDocument?.formData) {
      const sanitizedDocument = { ...scanDocument };
      Object.keys(sanitizedDocument.formData as Object).forEach((key) => {
        sanitizedDocument.formData![key] = '';
      });
      return sanitizedDocument;
    }
    return scanDocument;
  });

  const result: ApiSagaResult<BulkUpdateDocumentResult> = yield call(
    ProcessScan.bulkUpdateDocument,
    {
      [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
      data: {
        scanDocuments: sanitizedUpdateDocuments.filter(
          Boolean
        ) as UpdateScanDoc['data'][],
      },
      [ApiUrlParams.scanInstanceId]: payload.scanInstanceId,
    }
  );
  if (result.ok) {
    yield call(
      reFetchDocumentsList,
      payload.scanInstanceId,
      releaseOrDraftProcessId
    );
  }
}

export default movePage;
