import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import { DOCUMENT_VIEW_ACTION_TYPES } from './actions';
import finishScanningFailure from './sagas/finishScanningFailure';
import movePage from './sagas/movePage';
import setBarcodeValue from './sagas/setBarcodeValue';
import updateDocumentPages from './sagas/updateDocumentPages';

function* watchersSaga() {
  yield takeLatest([DOCUMENT_VIEW_ACTION_TYPES.MOVE_PAGES], movePage);

  yield takeLatest(
    DOCUMENT_VIEW_ACTION_TYPES.CHANGE_BARCODE_VALUE,
    setBarcodeValue
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.SCANNING.FINISH_SCANNING.ERROR,
    finishScanningFailure
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.SCANNING.PAGES.UPDATE_PAGE.SUCCESS,
    updateDocumentPages
  );
}

export default watchersSaga;
