import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import {
  activateScenarioSuccess,
  checkCredentialsFailure,
  deleteScenarioSuccess,
  fetchLabels,
  reFetchScenariosList,
  saveScenarioError,
  saveScenarioSuccess,
} from './sagas';

function* watcherSaga() {
  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.INBOUND_EMAIL.SAVE_SCENARIO.SUCCESS,
      API_REQ_ACTION_TYPES.INBOUND_EMAIL.UPDATE_SCENARIO.SUCCESS,
    ],
    saveScenarioSuccess
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.INBOUND_EMAIL.ACTIVATE_SCENARIO.SUCCESS,
    activateScenarioSuccess
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.INBOUND_EMAIL.DEACTIVATE_SCENARIO.SUCCESS,
    reFetchScenariosList
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.INBOUND_EMAIL.DELETE_SCENARIO.SUCCESS,
    deleteScenarioSuccess
  );

  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.INBOUND_EMAIL.SAVE_SCENARIO.ERROR,
      API_REQ_ACTION_TYPES.INBOUND_EMAIL.UPDATE_SCENARIO.ERROR,
    ],
    saveScenarioError
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.EMAILPROVIDERS.VALIDATE_CREDENTIALS.SUCCESS,
    fetchLabels
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.EMAILPROVIDERS.VALIDATE_CREDENTIALS.SUCCESS,
    checkCredentialsFailure
  );
}

export default watcherSaga;
