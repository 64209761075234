import subSelectorCreator from 'utils/subSelectorCreator';

import { ApiState } from './reducer';

const createApiSelector = subSelectorCreator<ApiState>((state) => state.api);

const ApiSelectors = {
  isExchangePending: createApiSelector<boolean>(
    ({ exchangePending }) => exchangePending
  ),
  isSignedIn: createApiSelector<boolean>(({ signedIn }) => signedIn),
  getIsActionInPending: (action: string | string[]) =>
    createApiSelector<boolean>(({ pendingActions }) =>
      Array.isArray(action)
        ? action.some((e) => pendingActions[e])
        : pendingActions[action]
    ),
};

export default ApiSelectors;
