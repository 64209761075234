import createAction from 'utils/createAction';

const PROCESS_SCAN_CONFIG = {
  SET_SCAN_CONFIG: 'PROCESS_SCAN_CONFIG.SET_SCAN_CONFIG',
  CLEAR_LIST: 'PROCESS_SCAN_CONFIG.CLEAR_LIST',
};

const ProcessScanConfigActions = {
  setScanConfig: createAction(PROCESS_SCAN_CONFIG.SET_SCAN_CONFIG),
  clearList: createAction(PROCESS_SCAN_CONFIG.CLEAR_LIST),
};

export { ProcessScanConfigActions, PROCESS_SCAN_CONFIG };
