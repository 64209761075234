import download from 'js-file-download';

function* exportProcess({
  payload: { data, headers },
}: {
  type: string;
  payload: { data: Blob; headers: { 'content-disposition': string } };
}) {
  yield download(data, headers['content-disposition'].split('filename=')[1]);
}

export default exportProcess;
