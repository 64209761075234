import { fork, put, select } from 'redux-saga/effects';

import { UpdatePageParams } from 'api/endpoints/splitDocument';

import fileUploadSuccess from 'store/printRender/sagas/fileUploadSuccess';

import { SplitDocumentsActions } from '../actions';
import SplitDocumentsSelectors from '../selectors';

function* updatePageSuccess({
  meta: {
    input: {
      params: { name },
    },
  },
}: ReduxAction<unknown, { input: UpdatePageParams }>) {
  const { [name]: exclude, ...receivedPages } = yield select(
    SplitDocumentsSelectors.getReceivedPages
  );

  yield put(SplitDocumentsActions.setReceivedPages(receivedPages));

  yield fork(fileUploadSuccess);
}

export default updatePageSuccess;
