import { combineReducers } from 'redux';

import {
  BLOCKLY_ACTION_TYPES,
  BlocklyToggleConfigureEmailModalAction,
} from './actions';

export interface BlocklyState {
  entity: {
    [key: string]: {
      id: string;
      data?: string;
    } | null;
  };
}

const blockly = combineReducers<BlocklyState>({
  entity(
    state = {},
    { type, payload }: BlocklyToggleConfigureEmailModalAction
  ) {
    const { name, id, data } = payload || { name: '' };
    switch (type) {
      case BLOCKLY_ACTION_TYPES.TOGGLE_CONFIGURE_ENTITY_MODAL:
        return {
          ...state,
          [name]: id
            ? {
                id,
                data,
              }
            : null,
        };

      default:
        return state;
    }
  },
});

export default blockly;
