import { getLocation } from 'connected-react-router';
import { fork, select } from 'redux-saga/effects';

import SearchTemplate from 'api/endpoints/searchTemplate';

function* reFetchList() {
  const { query } = yield select(getLocation);

  yield fork(SearchTemplate.getAll, query);
}

export default reFetchList;
