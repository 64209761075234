import { combineReducers } from 'redux';

import {
  REGISTRATION_ACTION_TYPES,
  REGISTRATION_REQ_ACTION_TYPES,
} from './actions';

export interface RegistrationState {
  ott: Nullable<string>;
  userEmail: Nullable<string>;
}

const registration = combineReducers<RegistrationState>({
  ott(state = null, { type, payload }: { type: string; payload: string }) {
    switch (type) {
      case REGISTRATION_ACTION_TYPES.SET_OTT:
        return payload || null;
      default:
        return state;
    }
  },
  userEmail(
    state = null,
    { type, payload }: { type: string; payload: { email: string } }
  ) {
    switch (type) {
      case REGISTRATION_REQ_ACTION_TYPES.VALIDATE_INVITATION_OTT.SUCCESS:
        return payload.email;
      default:
        return state;
    }
  },
});

export default registration;
