import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import afterFailPrintRenderScenarioSubmit from './sagas/afterFailPrintRenderScenarioSubmit';
import afterSucceededPrintRenderScenarioActivate from './sagas/afterSucceededPrintRenderScenarioActivate';
import afterSucceededPrintRenderScenarioDeActivate from './sagas/afterSucceededPrintRenderScenarioDeActivate';
import afterSucceededPrintRenderScenarioSubmit from './sagas/afterSucceededPrintRenderScenarioSubmit';
import fileUploadError from './sagas/fileUploadError';
import fileUploadSuccess from './sagas/fileUploadSuccess';
import reFetchAllPrintRenderScenarios from './sagas/reFetchAllPrintRenderScenarios';

function* watchersSaga() {
  yield takeLatest(
    [API_REQ_ACTION_TYPES.PRINT_RENDER.CREATE.SUCCESS],
    afterSucceededPrintRenderScenarioSubmit
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.PRINT_RENDER.CREATE.ERROR],
    afterFailPrintRenderScenarioSubmit
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.PRINT_RENDER.ACTIVATE.SUCCESS,
    afterSucceededPrintRenderScenarioActivate
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.PRINT_RENDER.DE_ACTIVATE.SUCCESS,
    afterSucceededPrintRenderScenarioDeActivate
  );

  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.PRINT_RENDER.CREATE.SUCCESS,
      API_REQ_ACTION_TYPES.PRINT_RENDER.ACTIVATE.SUCCESS,
      API_REQ_ACTION_TYPES.PRINT_RENDER.DE_ACTIVATE.SUCCESS,
      API_REQ_ACTION_TYPES.PRINT_RENDER.DELETE_BY_ID.SUCCESS,
      API_REQ_ACTION_TYPES.PRINT_RENDER.UPDATE_BY_ID.SUCCESS,
    ],
    reFetchAllPrintRenderScenarios
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.PRINT_RENDER.UPLOAD_FILE.SUCCESS],
    fileUploadSuccess
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.PRINT_RENDER.UPLOAD_FILE.ERROR],
    fileUploadError
  );
}

export default watchersSaga;
