const TEXTS = {
  LOG_OUT: 'Log Out',
  SHORTCUTS: 'Shortcuts',
  ALT: {
    PROFILE: 'Profile',
  },
};

const MENU_ACCESSORS = {
  LOG_OUT: 'LOG_OUT',
  SHORTCUTS: 'SHORTCUTS',
};

export { TEXTS, MENU_ACCESSORS };
