import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import {
  defineKeysFailure,
  reFetchKeys,
  reFetchTestResults,
  removeKeysSuccess,
  saveSettingSuccess,
} from './sagas';

function* watchersSaga() {
  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.OCR.CREATE_KEYS.SUCCESS,
      API_REQ_ACTION_TYPES.OCR.DELETE_KEY.SUCCESS,
      API_REQ_ACTION_TYPES.OCR.EDIT_KEY.SUCCESS,
    ],
    reFetchKeys
  );

  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.OCR.CREATE_KEYS.SUCCESS,
      API_REQ_ACTION_TYPES.OCR.EDIT_KEY.SUCCESS,
    ],
    saveSettingSuccess
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.OCR.DELETE_KEY.SUCCESS,
    removeKeysSuccess
  );

  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.OCR.CREATE_KEYS.ERROR,
      API_REQ_ACTION_TYPES.OCR.EDIT_KEY.ERROR,
    ],
    defineKeysFailure
  );

  yield takeLatest(
    [API_REQ_ACTION_TYPES.OCR.TEST_FILE.SUCCESS],
    reFetchTestResults
  );
}

export default watchersSaga;
