import ROUTES from 'app/routes/paths';
import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';

import UiUrlParams from 'constants/uiUrlParams';

import { string } from 'utils/formatters';

function* successCreateGroup({
  payload: releaseOrDraftProcessId,
}: ReduxAction<string>) {
  yield put(
    push(
      string.withDefaultPagination(
        ROUTES.process.definition.details.security({
          [UiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
        })
      )
    )
  );
}

export default successCreateGroup;
