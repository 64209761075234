import { Action } from 'redux';
import { stopAsyncValidation } from 'redux-form';
import { call, put } from 'redux-saga/effects';

import { ERROR_KEYS } from 'constants/fieldErrors';
import { FORM_NAMES } from 'constants/form';

interface Task extends Action, TaskEvent {}

interface TaskEvent {
  payload: BackendError[];
}

interface Params {
  filedName: string;
  errorType: string;
}

function* setErrorToField({ filedName, errorType }: Params) {
  yield put(
    stopAsyncValidation(FORM_NAMES.ADD_DATA_MODEL_FIELD, {
      [filedName]: { key: errorType },
    })
  );
}

function* addFieldFailure({ payload }: Task) {
  if (payload.some((e) => e.code === 4222)) {
    yield call(setErrorToField, {
      filedName: 'name',
      errorType: ERROR_KEYS.DUPLICATES_FIELD_NAME,
    });
  }

  if (payload.some((e) => e.code === 4035)) {
    yield call(setErrorToField, {
      filedName: 'alias',
      errorType: ERROR_KEYS.DUPLICATION_ALIAS,
    });
  }

  if (payload.some((e) => e.code === 4034)) {
    yield call(setErrorToField, {
      filedName: 'type',
      errorType: ERROR_KEYS.INVALID_ALIAS_TYPE,
    });
  }
}

export default addFieldFailure;
