import { Entity } from 'components/organisms/blocklySideBar/types';

import { ProcessDefinitionStageDetails } from 'api/endpoints/stage';

import createAction from 'utils/createAction';

const STAGE = {
  CLEAR_LIST: 'STAGE.CLEAR_LIST',
  UPDATE_ACTIVE: 'STAGE.UPDATE_ACTIVE',
  UPDATE_LIST: 'STAGE.UPDATE_LIST',
};

const StageActions = {
  clearList: createAction(STAGE.CLEAR_LIST),
  updateActive: createAction<ProcessDefinitionStageDetails>(
    STAGE.UPDATE_ACTIVE
  ),
  updateList: createAction<Entity[]>(STAGE.UPDATE_LIST),
};

export { STAGE as STAGE_ACTION_TYPES, StageActions };
