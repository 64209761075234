const ERROR_KEYS = {
  IS_REQUIRED: 'IS_REQUIRED',
  INCORRECT_EMAIL: 'INCORRECT_EMAIL',
  INCORRECT_GUID: 'INCORRECT_GUID',
  WRONG_CREDENTIALS: 'WRONG_CREDENTIALS',
  INCORRECT_LENGTH: 'INCORRECT_LENGTH',
  DUPLICATION_USERNAME: 'DUPLICATION_USERNAME',
  DUPLICATION_PRINT_RENDER_SCENARIO_NAME:
    'DUPLICATION_PRINT_RENDER_SCENARIO_NAME',
  DUPLICATION_SEARCH_TEMPLATE_NAME: 'DUPLICATION_SEARCH_TEMPLATE_NAME',
  DUPLICATION_ALIAS: 'DUPLICATION_ALIAS',
  INVALID_ALIAS_TYPE: 'INVALID_ALIAS_TYPE',
  INCORRECT_RANGE_VALUE_LENGTH: 'INCORRECT_RANGE_VALUE_LENGTH',
  INCORRECT_PASSWORD_VALUE: 'INCORRECT_PASSWORD_VALUE',
  INCORRECT_ALIAS_VALUE: 'INCORRECT_ALIAS_VALUE',
  DUPLICATES_FIELD_NAME: 'DUPLICATES_FIELD_NAME',
  DUPLICATES_SCAN_NAME: 'DUPLICATES_SCAN_NAME',
  OPTIONS_NAME_IS_NOT_UNIQUE: 'OPTIONS_NAME_IS_NOT_UNIQUE',
  INCORRECT_OPTION_ITEM_LENGTH: 'INCORRECT_OPTION_ITEM_LENGTH',
  FIELD_IS_NOT_UNIQUE: 'FIELD_IS_NOT_UNIQUE',
  INCORRECT_USER_NAME: 'INCORRECT_USER_NAME',
  INCORRECT_USER_PASSWORD: 'INCORRECT_USER_PASSWORD',
  INCORRECT_USER_PASSWORD_LENGTH: 'INCORRECT_USER_PASSWORD_LENGTH',
  WRONG_SERVICES_URL: 'WRONG_SERVICES_URL',
  WRONG_SERVICES_CREDENTIALS: 'WRONG_SERVICES_CREDENTIALS',
  INCORRECT_URL_ADDRESS: 'INCORRECT_URL_ADDRESS',
  DUPLICATES_GRID_TEMPLATE_NAME: 'DUPLICATES_GRID_TEMPLATE_NAME',
  DUPLICATES_EMAIL_POLLING_SCENARIO_NAME:
    'DUPLICATES_EMAIL_POLLING_SCENARIO_NAME',
  DUPLICATES_FUNCTION_NAME: 'DUPLICATES_FUNCTION_NAME',
  WRONG_GSUITE_CREDENTIALS: 'WRONG_GSUITE_CREDENTIALS',
  WRONG_MSGRAPH_CREDENTIALS: 'WRONG_MSGRAPH_CREDENTIALS',
  INCORRECT_EXTERNAL_PASSWORD: 'INCORRECT_EXTERNAL_PASSWORD',
  ALREADY_USED_FIELD_FOR_DEFINE_OCR_KEYS:
    'ALREADY_USED_FIELD_FOR_DEFINE_OCR_KEYS',
  INCORRECT_STABLE_LENGTH: 'INCORRECT_STABLE_LENGTH',
  WRONG_EMAIL_TEMPLATE_INDEX: 'WRONG_EMAIL_TEMPLATE_INDEX',
  ALREADY_USED_INDEX: 'ALREADY_USED_INDEX',
  WRONG_FORMAT_EMAIL: 'WRONG_FORMAT_EMAIL',
  WRONG_FORMAT_PHONE: 'WRONG_FORMAT_PHONE',
  WRONG_FORMAT_URL: 'WRONG_FORMAT_URL',
  WRONG_FORMAT_DATE: 'WRONG_FORMAT_DATE',
  INCORRECT_PROCESS_ID: 'INCORRECT_PROCESS_ID',
  NOT_UNIQUE_EMAIL_IN_ARRAY: 'NOT_UNIQUE_EMAIL_IN_ARRAY',
  NUMBER_NOT_IN_RANGE: 'NUMBER_NOT_IN_RANGE',
};

const UNIQ_LABEL = 'Must be unique';
const UNIQ_NAME_LABEL = 'Name must be unique';

const ERROR_LABELS = {
  [ERROR_KEYS.IS_REQUIRED]: 'Required field',
  [ERROR_KEYS.INCORRECT_EMAIL]: 'Incorrect email',
  [ERROR_KEYS.INCORRECT_GUID]: 'Incorrect UUID',
  [ERROR_KEYS.WRONG_CREDENTIALS]: 'User Name or Password is not correct',
  [ERROR_KEYS.INCORRECT_LENGTH]: 'Maximum length: ::length characters',
  [ERROR_KEYS.INCORRECT_STABLE_LENGTH]: 'Length must be ::length characters',
  [ERROR_KEYS.DUPLICATION_USERNAME]: UNIQ_LABEL,
  [ERROR_KEYS.DUPLICATION_PRINT_RENDER_SCENARIO_NAME]: UNIQ_LABEL,
  [ERROR_KEYS.DUPLICATION_SEARCH_TEMPLATE_NAME]: UNIQ_LABEL,
  [ERROR_KEYS.ALREADY_USED_INDEX]: UNIQ_LABEL,
  [ERROR_KEYS.DUPLICATION_ALIAS]: 'Alias must be unique',
  [ERROR_KEYS.INVALID_ALIAS_TYPE]: 'This alias have other data type',
  [ERROR_KEYS.INCORRECT_RANGE_VALUE_LENGTH]:
    'Must be from ::minLength to ::maxLength symbols',
  [ERROR_KEYS.INCORRECT_PASSWORD_VALUE]:
    'Password can contain only letters and numbers',
  [ERROR_KEYS.INCORRECT_ALIAS_VALUE]:
    'Alias can contain only letters and numbers',
  [ERROR_KEYS.DUPLICATES_FIELD_NAME]: UNIQ_NAME_LABEL,
  [ERROR_KEYS.DUPLICATES_SCAN_NAME]: UNIQ_NAME_LABEL,
  [ERROR_KEYS.DUPLICATES_GRID_TEMPLATE_NAME]: UNIQ_NAME_LABEL,
  [ERROR_KEYS.DUPLICATES_EMAIL_POLLING_SCENARIO_NAME]: UNIQ_NAME_LABEL,
  [ERROR_KEYS.DUPLICATES_FUNCTION_NAME]: UNIQ_NAME_LABEL,
  [ERROR_KEYS.OPTIONS_NAME_IS_NOT_UNIQUE]: 'Value must be unique',
  [ERROR_KEYS.INCORRECT_OPTION_ITEM_LENGTH]: 'Options cannot be empty',
  [ERROR_KEYS.FIELD_IS_NOT_UNIQUE]: 'Types cannot be the same',
  [ERROR_KEYS.INCORRECT_USER_NAME]:
    'Only the characters, digits and .,-_:*&^%$#@!+= symbols are allowed',
  [ERROR_KEYS.INCORRECT_USER_PASSWORD]:
    'Only the characters and digits are allowed',
  [ERROR_KEYS.INCORRECT_USER_PASSWORD_LENGTH]:
    'Password must be from 8 to 64 characters',
  [ERROR_KEYS.WRONG_SERVICES_URL]: 'Incorrect address',
  [ERROR_KEYS.WRONG_SERVICES_CREDENTIALS]: 'Incorrect Username or Password',
  [ERROR_KEYS.INCORRECT_URL_ADDRESS]:
    'Please use http:// or https:// for correct web service address',
  [ERROR_KEYS.WRONG_GSUITE_CREDENTIALS]: 'Incorrect Email or Password',
  [ERROR_KEYS.WRONG_MSGRAPH_CREDENTIALS]: 'Incorrect MS Graph configuration',
  [ERROR_KEYS.ALREADY_USED_FIELD_FOR_DEFINE_OCR_KEYS]:
    'This data model field is already in use. Please choose another one to proceed',
  [ERROR_KEYS.INCORRECT_EXTERNAL_PASSWORD]:
    'Only the characters, digits and special symbols are allowed',
  [ERROR_KEYS.WRONG_EMAIL_TEMPLATE_INDEX]:
    'Index must contain only letters or numbers',
  [ERROR_KEYS.WRONG_FORMAT_EMAIL]: 'Wrong email format',
  [ERROR_KEYS.WRONG_FORMAT_PHONE]: 'Wrong phone number format',
  [ERROR_KEYS.WRONG_FORMAT_URL]: 'Wrong URL format',
  [ERROR_KEYS.WRONG_FORMAT_DATE]: 'Wrong date format',
  [ERROR_KEYS.INCORRECT_PROCESS_ID]: 'Incorrect Process ID',
  [ERROR_KEYS.NOT_UNIQUE_EMAIL_IN_ARRAY]: 'Email addresses should be unique',
  [ERROR_KEYS.NUMBER_NOT_IN_RANGE]:
    'Number should be in range of ::min and ::max',
};

export { ERROR_KEYS, ERROR_LABELS };
