import { RefObject, useEffect, useRef } from 'react';

export type ListenerEvent = (MouseEvent | KeyboardEvent) & {
  target: Element;
};

const useListenEvent = (
  ref: RefObject<HTMLElement>,
  callback?: (event: ListenerEvent) => void,
  eventType = 'click',
  listenOutside = true
) => {
  // region *******************************DATA*********************************
  const handlerRef = useRef(callback);
  // endregion

  // region ******************************EFFECTS*******************************
  useEffect(() => {
    handlerRef.current = callback;
  });

  useEffect(() => {
    const listener = (event: ListenerEvent) => {
      if (ref && ref.current) {
        if (event.target.shadowRoot) {
          if (
            !event.target.shadowRoot.contains(ref.current) &&
            handlerRef.current
          ) {
            handlerRef.current(event);
          }
        } else if (
          ((listenOutside && !ref.current.contains(event.target)) ||
            !listenOutside) &&
          handlerRef.current
        ) {
          handlerRef.current(event);
        }
      }
    };

    document.addEventListener(eventType, listener as EventListener);

    document.addEventListener('touchstart', listener as EventListener);

    return () => {
      document.removeEventListener(eventType, listener as EventListener);

      document.removeEventListener('touchstart', listener as EventListener);
    };
  });
  // endregion
};

export default useListenEvent;
