import { WithReleaseOrDraftProcessId, WithStageId } from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export interface CreateStageParams {
  index: number;
  name: string;
  schema?: string;
  reFetchStages?: boolean;
}

export interface ProcessDefinitionStageGeneral
  extends Pick<BaseEntity, 'name' | 'id'> {
  index: number;
}

export interface ProcessDefinitionStageDetails
  extends ProcessDefinitionStageGeneral {
  schema: string;
}

export interface UpdateStageStageParams extends CreateStageParams {}

export type CreateParams = CreateStageParams & WithReleaseOrDraftProcessId;
export type CreateResult = ProcessDefinitionStageDetails;
export type GetAllParams = WithReleaseOrDraftProcessId;
export type GetAllResult = ProcessDefinitionStageGeneral[];
export type GetDetailsParams = WithStageId & WithReleaseOrDraftProcessId;
export type GetDetailsResult = ProcessDefinitionStageDetails;
export type UpdateParams = UpdateStageStageParams &
  WithStageId &
  WithReleaseOrDraftProcessId;
export type UpdateResult = ProcessDefinitionStageDetails;
export type DeleteParams = WithStageId & WithReleaseOrDraftProcessId;
export type DeleteResult = unknown;

const Stage = {
  create: apiReqSagaCreator<CreateParams, CreateResult>(
    API_REQ_ACTION_TYPES.STAGES.CREATE,
    (args) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.stages._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
      }),
      data: {
        name: args.name,
        index: args.index,
        schema: '',
      },
    })
  ),
  getAll: apiReqSagaCreator<GetAllParams, GetAllResult>(
    API_REQ_ACTION_TYPES.STAGES.GET_LIST,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.stages._(params),
    })
  ),
  getDetails: apiReqSagaCreator<GetDetailsParams, GetDetailsResult>(
    API_REQ_ACTION_TYPES.STAGES.GET_DETAILS,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.stages.byId._(params),
    })
  ),
  update: apiReqSagaCreator<UpdateParams, UpdateResult>(
    API_REQ_ACTION_TYPES.STAGES.UPDATE,
    (args) => ({
      method: HTTP_METHODS.PUT,
      url: ApiPaths.processes.byId.stages.byId._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.stageId]: args[ApiUrlParams.stageId],
      }),
      data: {
        name: args.name,
        index: args.index,
        schema: args.schema,
      },
    })
  ),
  delete: apiReqSagaCreator<DeleteParams, DeleteResult>(
    API_REQ_ACTION_TYPES.STAGES.DELETE,
    (params) => ({
      method: HTTP_METHODS.DELETE,
      url: ApiPaths.processes.byId.stages.byId._(params),
    })
  ),
};

export default Stage;
