import { Auth } from 'types/auth';

import { REGISTRATION_REQ_ACTION_TYPES } from 'store/registration/actions';

import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export interface RegisterParams {
  ott: string;
  phone: string;
  username: string;
  password: string;
  lastName: string;
  firstName: string;
  acceptTerms: boolean;
}

export type ValidateInvitationOttParams = string;
export type ValidateInvitationOttResult = unknown;
export type RegisterResult = Auth;

const Registration = {
  validateInvitationOtt: apiReqSagaCreator<
    ValidateInvitationOttParams,
    ValidateInvitationOttResult
  >(REGISTRATION_REQ_ACTION_TYPES.VALIDATE_INVITATION_OTT, (ott) => ({
    method: HTTP_METHODS.GET,
    url: ApiPaths.auth.validateInvitationOtt(),
    params: {
      ott,
    },
  })),
  register: apiReqSagaCreator<RegisterParams, RegisterResult>(
    REGISTRATION_REQ_ACTION_TYPES.REGISTER,
    (data) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.auth.register(),
      data,
    })
  ),
};

export default Registration;
