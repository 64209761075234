import ROUTES from 'app/routes/paths';
import { UiWithReleaseOrDraftProcessId } from 'app/routes/uiPathParams';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { fork, select } from 'redux-saga/effects';

import ProcessVersions from 'api/endpoints/processVersions';

import ApiUrlParams from 'constants/apiUrlParams';

function* reFetchList() {
  const { pathname } = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId },
  }: match<UiWithReleaseOrDraftProcessId> = yield matchPath(pathname, {
    path: [
      ROUTES.process.definition.details.versions(),
      ROUTES.process.definition.details.forms(),
      ROUTES.process.definition.details.fields(),
      ROUTES.process.definition.details.security(),
      ROUTES.process.definition.details.emailTemplates(),
    ],
  });

  const { query } = yield select(getLocation);

  yield fork(ProcessVersions.getAll, {
    ...query,
    [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
  });

  return {};
}

export default reFetchList;
