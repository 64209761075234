import cn from 'classnames';
import React, { memo } from 'react';

import Button from 'components/molecules/button';

import sm from './styles.module.scss';
import { Props } from './types';

export const ModalButtons = ({
  withTopButtonsMargin,
  rejectConfig,
  submitConfig,
}: Props) => (
  <div
    className={cn(sm.Buttons, {
      [sm.Buttons_WithMargin]: withTopButtonsMargin,
    })}
  >
    {rejectConfig && (
      <div className={sm.Buttons_Button}>
        <Button
          view="Default"
          withFullWidth
          isBig
          isOutlined
          {...rejectConfig}
        />
      </div>
    )}
    <div className={sm.Buttons_Button}>
      <Button
        view="Default"
        isBig
        withFullWidth
        type={submitConfig.onClick ? 'button' : 'submit'}
        {...submitConfig}
      />
    </div>
  </div>
);

export default memo(ModalButtons);
