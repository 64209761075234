import { fork, put } from 'redux-saga/effects';

import createATA from 'utils/createAddToasterAction';

import { reFetchScenariosList } from '.';

const MESSAGE = 'The scenario was successfully set as an automatic';

function* activateScenarioSuccess() {
  yield fork(reFetchScenariosList);
  yield put(createATA({ message: MESSAGE }));
}

export default activateScenarioSuccess;
