import subSelectorCreator from 'utils/subSelectorCreator';

import { SecurityGroupsState } from './reducer';

const createSecurityGroupsSelector = subSelectorCreator<SecurityGroupsState>(
  (state) => state.securityGroups
);

const SecurityGroupsSelectors = {
  getList: createSecurityGroupsSelector(({ list }) => list),
  getTotal: createSecurityGroupsSelector(({ total }) => total),
  getDetails: createSecurityGroupsSelector(({ details }) => details),
  getAvailableUsersList: createSecurityGroupsSelector(
    ({ availableUsersList }) => availableUsersList
  ),
  getAvailableUsersTotal: createSecurityGroupsSelector(
    ({ availableUsersTotal }) => availableUsersTotal
  ),
};

export default SecurityGroupsSelectors;
