import { combineReducers } from 'redux';

import {
  DocumentFormData,
  ScanDocumentContainer,
} from 'api/endpoints/processScan';

import { PROCESS_SCANNING, SetSavedPagesAction } from './actions';

export interface ScanningState {
  isSavedPage: Nullable<boolean>;
  prevFormData: Nullable<DynamicFormParams>;
  lastCreatedDocument: Nullable<ScanDocumentContainer>;
  savedPages: SetSavedPagesAction['payload'];
  barcode: Nullable<string>;
  uploadedFiles: Nullable<KeyValuePairs<File>>;
}

const processScanning = combineReducers<ScanningState>({
  isSavedPage(
    state = false,
    { type, payload }: { type: string; payload: boolean }
  ) {
    switch (type) {
      case PROCESS_SCANNING.SET_IS_PAGE_SAVED:
        return payload || null;
      default:
        return state;
    }
  },
  prevFormData(
    state = null,
    { type, payload }: { type: string; payload: DocumentFormData }
  ) {
    switch (type) {
      case PROCESS_SCANNING.SAVE_FORM_DATA:
        return payload || null;

      default:
        return state;
    }
  },
  savedPages(
    state = { names: [], files: [] },
    { type, payload }: SetSavedPagesAction
  ) {
    switch (type) {
      case PROCESS_SCANNING.SET_SAVED_PAGES:
        return payload;

      default:
        return state;
    }
  },
  barcode(state = null, { type, payload }: { type: string; payload: string }) {
    switch (type) {
      case PROCESS_SCANNING.SAVE_BARCODE:
        return payload || null;

      default:
        return state;
    }
  },
  lastCreatedDocument(
    state = null,
    { type, payload }: { type: string; payload: ScanDocumentContainer }
  ) {
    switch (type) {
      case PROCESS_SCANNING.SAVE_LAST_CREATED_DOCUMENT:
        return payload || null;
      case PROCESS_SCANNING.CLEAR_LAST_CREATED_DOCUMENT:
        return null;
      default:
        return state;
    }
  },
  uploadedFiles(
    state = null,
    { type, payload }: { type: string; payload: Nullable<KeyValuePairs<File>> }
  ) {
    switch (type) {
      case PROCESS_SCANNING.SET_UPLOADED_FILES:
        return payload;
      default:
        return state;
    }
  },
});

export default processScanning;
