import { ConfigurableEntity } from 'components/organisms/processEditor/config';

import createAction from 'utils/createAction';

const BLOCKLY = {
  TOGGLE_CONFIGURE_ENTITY_MODAL: 'BLOCKLY.TOGGLE_CONFIGURE_ENTITY_MODAL',
};

interface ToggleConfigureEntityModal {
  name: ConfigurableEntity;
  id?: string;
  data?: string;
}

const BlocklyActions = {
  toggleConfigureEntityModal: createAction<ToggleConfigureEntityModal>(
    BLOCKLY.TOGGLE_CONFIGURE_ENTITY_MODAL
  ),
};

export type BlocklyToggleConfigureEmailModalAction = ReturnType<
  typeof BlocklyActions.toggleConfigureEntityModal
>;

export { BLOCKLY as BLOCKLY_ACTION_TYPES, BlocklyActions };
