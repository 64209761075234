import PATHS from 'app/routes/paths';
import accessibleByRole from 'hocs/accessibleByRole';

import { string } from 'utils/formatters';

const defaultRedirect = string.withDefaultPagination(
  PATHS.process.execution._()
);

const accessOrDefaultRedirect = accessibleByRole(defaultRedirect);

export { accessOrDefaultRedirect };
