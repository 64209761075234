import { combineReducers } from 'redux';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import {
  GetAvailableUsersResult,
  GetGroupsResult,
  SecurityGroupDetailsWithMembers,
  SecurityGroupGeneral,
  SecurityGroupUserGeneral,
} from 'api/endpoints/securityGroups';

import { SECURITY_GROUPS_ACTION_TYPES } from './actions';

export interface SecurityGroupsState {
  list: Nullable<SecurityGroupGeneral[]>;
  total: Nullable<number>;
  details: Nullable<SecurityGroupDetailsWithMembers>;
  availableUsersList: Nullable<SecurityGroupUserGeneral[]>;
  availableUsersTotal: Nullable<number>;
}

const securityGroups = combineReducers<SecurityGroupsState>({
  list(state = null, { type, payload }: ReduxAction<GetGroupsResult>) {
    switch (type) {
      case API_REQ_ACTION_TYPES.PROCESSES.SECURITY_GROUPS.GET_LIST.SUCCESS:
        return payload.data ?? null;
      default:
        return state;
    }
  },
  total(state = null, { type, payload }: ReduxAction<GetGroupsResult>) {
    switch (type) {
      case API_REQ_ACTION_TYPES.PROCESSES.SECURITY_GROUPS.GET_LIST.SUCCESS:
        return payload.total ?? null;
      default:
        return state;
    }
  },
  details(
    state = null,
    { type, payload }: ReduxAction<SecurityGroupDetailsWithMembers>
  ) {
    switch (type) {
      case SECURITY_GROUPS_ACTION_TYPES.SET_DETAILS:
        return payload ?? null;
      case API_REQ_ACTION_TYPES.PROCESSES.SECURITY_GROUPS.GET_DETAILS.START:
      case API_REQ_ACTION_TYPES.PROCESSES.SECURITY_GROUPS.GET_LIST.START:
        return null;
      default:
        return state;
    }
  },
  availableUsersList(
    state = null,
    { type, payload }: ReduxAction<GetAvailableUsersResult>
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.PROCESSES.SECURITY_GROUPS.GET_AVAILABLE_USERS
        .SUCCESS:
        return payload.data ?? null;
      default:
        return state;
    }
  },
  availableUsersTotal(
    state = null,
    { type, payload }: ReduxAction<GetAvailableUsersResult>
  ) {
    switch (type) {
      case API_REQ_ACTION_TYPES.PROCESSES.SECURITY_GROUPS.GET_AVAILABLE_USERS
        .SUCCESS:
        return payload.total ?? null;
      default:
        return state;
    }
  },
});

export default securityGroups;
