import { getFormValues } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';

import { SearchEntity } from 'components/organisms/form/search/types';

import SecurityGroups, {
  SecurityGroupDetailsWithMembers,
  SecurityGroupUserGeneral,
} from 'api/endpoints/securityGroups';

import { SecurityGroupsActions } from 'store/securityGroups/actions';
import SecurityGroupsSelectors from 'store/securityGroups/selectors';

import ApiUrlParams from 'constants/apiUrlParams';
import { FORM_NAMES } from 'constants/form';

import {
  normalizeAdvancedParams,
  normalizeAdvancedValues,
  normalizeFieldValue,
} from 'utils/normalizers/object';

const usersValuesSelector = getFormValues(FORM_NAMES.CREATE_SECURITY_GROUP);

const configValuesSelector = getFormValues(FORM_NAMES.SECURITY_GROUP_CONFIG);

export type SubmitCreateGroupArgs = [string, string | undefined];

function* submitCreateGroup(releaseOrDraftProcessId: string, groupId?: string) {
  const { name, query } = yield select(configValuesSelector);

  const { ids: users } = yield select(usersValuesSelector);

  const normalizedAdvancedValues = normalizeAdvancedValues(query || {});

  const paramsByFields = (
    Object.values(normalizedAdvancedValues.fields) as unknown as SearchEntity[]
  ).map((e) => ({
    id: e.field.value,
    type: e.function.type,
    operation: e.function.value,
    value: normalizeFieldValue(e.field.type as string, e.value),
  }));

  const normalizedValues = {
    name,
    securityQuery: normalizeAdvancedParams(query?.operator, paramsByFields),
  };

  const normalizedUsers: string[] = users?.map(
    (e: SecurityGroupUserGeneral) => e.id
  );

  let ableToSave = true;

  if (groupId) {
    const initialDetails: SecurityGroupDetailsWithMembers = yield select(
      SecurityGroupsSelectors.getDetails
    );

    const initialUsersIds = initialDetails.users.map((e) => e.id);

    const usersToAdd = normalizedUsers.filter(
      (e) => !initialUsersIds?.includes(e)
    );

    const usersToRemove = initialUsersIds?.filter(
      (e) => e && !normalizedUsers.includes(e)
    );

    if (!initialDetails.isRoot) {
      const { ok } = yield call(SecurityGroups.update, {
        ...normalizedValues,
        [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
        [ApiUrlParams.groupId]: groupId,
      });

      ableToSave = ok;
    }

    if (ableToSave) {
      if (usersToAdd.length) {
        yield call(SecurityGroups.addMembers, {
          [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
          [ApiUrlParams.groupId]: groupId,
          users: usersToAdd,
        });
      }

      if (usersToRemove.length) {
        yield call(SecurityGroups.removeMembers, {
          [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
          [ApiUrlParams.groupId]: groupId,
          users: usersToRemove,
        });
      }
    }
  } else {
    const { result, ok } = yield call(SecurityGroups.create, {
      ...normalizedValues,
      [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
    });

    ableToSave = ok;

    if (ok && normalizedUsers.length) {
      yield call(SecurityGroups.addMembers, {
        [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
        [ApiUrlParams.groupId]: result.id,
        users: normalizedUsers,
      });
    }
  }

  if (ableToSave) {
    yield put(
      SecurityGroupsActions.membersWasUpdatedAndSaved(releaseOrDraftProcessId)
    );
  }
}

export default submitCreateGroup;
