import { ProcessGeneral } from 'api/endpoints/processes';
import { FieldGeneral } from 'api/endpoints/processFields';
import {
  WithFtpPollingScenarioId,
  WithReleaseOrDraftProcessId,
} from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export interface FtpPollingScenarioGeneral extends BaseEntity {
  isActive: boolean;
  process: Nullable<ProcessGeneral>;
  isLaunched: boolean;
}

export interface FtpPollingScenarioDetails
  extends Omit<FtpPollingScenarioGeneral, 'process'> {
  fileNameField: FieldGeneral;
  fileField: FieldGeneral;
  process: ProcessGeneral;
}

export interface CreateScenarioData {
  data: {
    fileNameFieldId: string;
    fileFieldId: string;
  };
}

export interface GetScenarioParams
  extends WithReleaseOrDraftProcessId,
    Pagination {
  isActive?: boolean;
}

export interface UpdateScenarioParams
  extends CreateScenarioData,
    WithReleaseOrDraftProcessId,
    WithFtpPollingScenarioId {}

export type CreateScenarioParams = CreateScenarioData &
  WithReleaseOrDraftProcessId;
export type CreateScenarioResult = FtpPollingScenarioDetails;
export type UpdateScenarioResult = FtpPollingScenarioDetails;
export type GetScenariosResult = DataWithTotal<FtpPollingScenarioGeneral>;
export type ActivateScenarioParams = WithReleaseOrDraftProcessId &
  WithFtpPollingScenarioId;
export type ActivateScenarioResult = unknown;
export type DeactivateScenarioParams = WithReleaseOrDraftProcessId &
  WithFtpPollingScenarioId;
export type DeactivateScenarioResult = unknown;
export type GetScenarioDetailsResult = FtpPollingScenarioDetails;
export type GetScenarioDetailsParams = WithReleaseOrDraftProcessId &
  WithFtpPollingScenarioId;
export type DeleteScenarioParams = WithReleaseOrDraftProcessId &
  WithFtpPollingScenarioId;
export type DeleteScenarioResult = unknown;

const FtpPolling = {
  createScenario: apiReqSagaCreator<CreateScenarioParams, CreateScenarioResult>(
    API_REQ_ACTION_TYPES.FTP_POLLING.SAVE_SCENARIO,
    (args) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.ftpPolling.scenarios._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
      }),
      data: args?.data,
    })
  ),
  updateScenario: apiReqSagaCreator<UpdateScenarioParams, UpdateScenarioResult>(
    API_REQ_ACTION_TYPES.FTP_POLLING.UPDATE_SCENARIO,
    (args) => ({
      method: HTTP_METHODS.PUT,
      url: ApiPaths.processes.byId.ftpPolling.scenarios.byId._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.ftpPollingScenarioId]:
          args[ApiUrlParams.ftpPollingScenarioId],
      }),
      data: args?.data,
    })
  ),
  getScenarios: apiReqSagaCreator<GetScenarioParams, GetScenariosResult>(
    API_REQ_ACTION_TYPES.FTP_POLLING.GET_SCENARIOS,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.processes.byId.ftpPolling.scenarios._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
      }),
      params,
    }),
    {
      withPrevPageOnLessTotal: true,
    }
  ),
  activateScenario: apiReqSagaCreator<
    ActivateScenarioParams,
    ActivateScenarioResult
  >(API_REQ_ACTION_TYPES.FTP_POLLING.ACTIVATE_SCENARIO, (params) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.processes.byId.ftpPolling.scenarios.byId.activate._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        params[ApiUrlParams.releaseOrDraftProcessId],
      [ApiUrlParams.ftpPollingScenarioId]:
        params[ApiUrlParams.ftpPollingScenarioId],
    }),
  })),
  deactivateScenario: apiReqSagaCreator<
    DeactivateScenarioParams,
    DeactivateScenarioResult
  >(API_REQ_ACTION_TYPES.FTP_POLLING.DEACTIVATE_SCENARIO, (params) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.processes.byId.ftpPolling.scenarios.byId.deactivate._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        params[ApiUrlParams.releaseOrDraftProcessId],
      [ApiUrlParams.ftpPollingScenarioId]:
        params[ApiUrlParams.ftpPollingScenarioId],
    }),
  })),
  getScenarioDetails: apiReqSagaCreator<
    GetScenarioDetailsParams,
    GetScenarioDetailsResult
  >(API_REQ_ACTION_TYPES.FTP_POLLING.GET_DETAILS, (params) => ({
    method: HTTP_METHODS.GET,
    url: ApiPaths.processes.byId.ftpPolling.scenarios.byId._({
      [ApiUrlParams.ftpPollingScenarioId]:
        params[ApiUrlParams.ftpPollingScenarioId],
      [ApiUrlParams.releaseOrDraftProcessId]:
        params[ApiUrlParams.releaseOrDraftProcessId],
    }),
  })),
  deleteScenario: apiReqSagaCreator<DeleteScenarioParams, DeleteScenarioResult>(
    API_REQ_ACTION_TYPES.FTP_POLLING.DELETE_SCENARIO,
    (params) => ({
      method: HTTP_METHODS.DELETE,
      url: ApiPaths.processes.byId.ftpPolling.scenarios.byId._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          params[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.ftpPollingScenarioId]:
          params[ApiUrlParams.ftpPollingScenarioId],
      }),
    })
  ),
};

export default FtpPolling;
