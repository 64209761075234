import { TransformPrintRenderScenarioData } from 'pages/processDefinitionPrintRender/config';

import { EmailTemplateGeneral } from 'api/endpoints/emailTemplates';
import { ProcessGeneral } from 'api/endpoints/processes';
import { FieldGeneral } from 'api/endpoints/processFields';
import { TmsConnectionGeneral } from 'api/endpoints/tmsConnections';
import {
  WithReleaseOrDraftProcessId,
  WithPrintRenderScenarioId,
} from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { excludeObjectProperty } from 'utils/normalizers/object';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

const { REACT_APP_API_DOCUMENT_SERVICE: documentServiceUrl } = process.env;

export type BarcodeType = 'code39' | 'code128' | 'codabar' | 'interleaved2of5';
export type AdditionalFieldType = 'barcode' | 'order_number' | 'doc_type';

export interface PrintRenderScenarioTruckmate {
  useLineId?: boolean;
  rebillEnabled?: boolean;
  rebillSkipDocTypes?: string;
  creditEnabled?: boolean;
}

// derived from api:src/models/print-render-scenario.go@PrintRenderScenario
export interface PrintRenderScenarioGeneral extends BaseEntity {
  process: ProcessGeneral;
  barcodeType: BarcodeType;
  isActive: boolean;
  withoutEmail: boolean;
  truckMate?: PrintRenderScenarioTruckmate;
  useSpanOrderNumbers?: boolean;
  tms: Nullable<TmsConnectionGeneral>;
  predefinedFieldValue?: string;
  docTypeValue: string;
  additionalField: AdditionalFieldType;
  emailTemplatesSelectAll: boolean;
  enableDownloadDailyPrintBatch: boolean;
  attachSuccessDocsOnly: boolean;
  summaryNotificationEmailAddrs: string;
  printInvoices: boolean;
}

export type PrintRenderScenarioWithHypermedia = PrintRenderScenarioGeneral &
  WithHypermedia;

export interface PrintRenderScenarioDetails extends PrintRenderScenarioGeneral {
  barcodeField: FieldGeneral;
  orderSearchField: FieldGeneral;
  docTypeSearchField: FieldGeneral;
  sourceFileField: FieldGeneral;
  backupFileField: FieldGeneral;
  docFileSearchField: FieldGeneral;
  emailTemplates: EmailTemplateGeneral[];
  emailToField?: FieldGeneral;
  predefinedField?: FieldGeneral;
}

export interface GetAllPrintRenderScenarios extends Pagination {
  isActive?: boolean;
}

export interface CreatePrintRenderScenario {
  data: TransformPrintRenderScenarioData;
}
export interface PrintRenderScenarioData {
  data: TransformPrintRenderScenarioData;
}

export interface UploadFileData {
  file: FormData;
}

export interface UpdateFileArgs extends UploadFileData {
  fileKey: string;
}
export interface PrintRenderFileResult {
  printFileKey?: string;
  errorFileKey?: string;
  date: string;
}

export type CreateScenarioParams = CreatePrintRenderScenario &
  WithReleaseOrDraftProcessId;
export type CreateScenarioResult = unknown;
export type GetAllScenariosParams = GetAllPrintRenderScenarios &
  WithReleaseOrDraftProcessId;
export type GetAllScenariosResult = DataWithTotal<PrintRenderScenarioGeneral>;
export type GetScenarioDetails = WithReleaseOrDraftProcessId &
  WithPrintRenderScenarioId;
export type GetScenarioDetailsResult = PrintRenderScenarioDetails;
export type ActivateScenarioParams = WithReleaseOrDraftProcessId &
  WithPrintRenderScenarioId;
export type ActivateScenarioResult = unknown;
export type DeActivateScenarioParams = WithReleaseOrDraftProcessId &
  WithPrintRenderScenarioId;
export type DeActivateScenarioResult = unknown;
export type DeleteScenarioParams = WithReleaseOrDraftProcessId &
  WithPrintRenderScenarioId;
export type DeleteScenarioResult = unknown;
export type UpdateScenarioParams = PrintRenderScenarioData &
  WithReleaseOrDraftProcessId &
  WithPrintRenderScenarioId;
export type UpdateScenarioResult = unknown;
export type UpdateFileParams = UpdateFileArgs;
export type UpdateFileResult = unknown;

const PrintRender = {
  createScenario: apiReqSagaCreator<CreateScenarioParams, CreateScenarioResult>(
    API_REQ_ACTION_TYPES.PRINT_RENDER.CREATE,
    (args) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.processes.byId.printRender.scenarios._({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
      }),
      data: args.data,
    })
  ),
  getAllScenarios: apiReqSagaCreator<
    GetAllScenariosParams,
    GetAllScenariosResult
  >(API_REQ_ACTION_TYPES.PRINT_RENDER.GET_ALL, (params) => ({
    method: HTTP_METHODS.GET,
    url: ApiPaths.processes.byId.printRender.scenarios._({
      [ApiUrlParams.releaseOrDraftProcessId]:
        params[ApiUrlParams.releaseOrDraftProcessId],
    }),
    params: excludeObjectProperty(params, ApiUrlParams.releaseOrDraftProcessId),
  })),
  getScenarioDetails: apiReqSagaCreator<
    GetScenarioDetails,
    GetScenarioDetailsResult
  >(API_REQ_ACTION_TYPES.PRINT_RENDER.GET_BY_ID, (params) => ({
    method: HTTP_METHODS.GET,
    url: ApiPaths.processes.byId.printRender.byId(params),
  })),
  activateScenario: apiReqSagaCreator<
    ActivateScenarioParams,
    ActivateScenarioResult
  >(API_REQ_ACTION_TYPES.PRINT_RENDER.ACTIVATE, (params) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.processes.byId.printRender.activate._(params),
  })),
  deActivateScenario: apiReqSagaCreator<
    DeActivateScenarioParams,
    DeActivateScenarioResult
  >(API_REQ_ACTION_TYPES.PRINT_RENDER.DE_ACTIVATE, (params) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.processes.byId.printRender.deActivate._(params),
  })),
  deleteScenario: apiReqSagaCreator<DeleteScenarioParams, DeleteScenarioResult>(
    API_REQ_ACTION_TYPES.PRINT_RENDER.DELETE_BY_ID,
    (params) => ({
      method: HTTP_METHODS.DELETE,
      url: ApiPaths.processes.byId.printRender.byId(params),
    })
  ),
  updateScenario: apiReqSagaCreator<UpdateScenarioParams, UpdateScenarioResult>(
    API_REQ_ACTION_TYPES.PRINT_RENDER.UPDATE_BY_ID,
    (args) => ({
      method: HTTP_METHODS.PUT,
      url: ApiPaths.processes.byId.printRender.byId({
        [ApiUrlParams.releaseOrDraftProcessId]:
          args[ApiUrlParams.releaseOrDraftProcessId],
        [ApiUrlParams.printRenderScenarioId]:
          args[ApiUrlParams.printRenderScenarioId],
      }),
      data: args.data,
    })
  ),
  updateFile: apiReqSagaCreator<UpdateFileParams, UpdateFileResult>(
    API_REQ_ACTION_TYPES.PRINT_RENDER.UPLOAD_FILE,
    (args) => ({
      method: HTTP_METHODS.PUT,
      url: ApiPaths.printRender.file._(),
      data: args.file,
      params: {
        file_key: args.fileKey,
      },
      paramsSerializer: (params) =>
        Object.keys(params).reduce(
          (acc, key, idx) =>
            (acc += `${key}=${encodeURI(params[key])}${
              idx < Object.keys(params).length - 1 ? '&' : ''
            }`),
          ''
        ),
      baseURL: documentServiceUrl,
    })
  ),
};

export default PrintRender;
