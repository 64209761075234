import { put } from 'redux-saga/effects';

import createATA from 'utils/createAddToasterAction';

function* reInviteSuccess() {
  yield put(
    createATA({
      message: 'Invitation was successfully sent',
    })
  );
}

export default reInviteSuccess;
