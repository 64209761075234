import ROUTES, { ScanScenarioInstanceUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { call, put, select } from 'redux-saga/effects';

import ProcessScan, {
  ScanDocumentContainer,
  UpdateDoc,
} from 'api/endpoints/processScan';

import ApiUrlParams from 'constants/apiUrlParams';

import { ProcessScanningActions } from '../actions';
import ScanningSelector from '../selectors';

function* updateDocument(scanInstanceId: string, pages: string[]) {
  const { pathname } = yield select(getLocation);

  const lastCreatedDocument: Nullable<ScanDocumentContainer> = yield select(
    ScanningSelector.getLastCreatedDocument
  );

  const {
    params: { releaseOrDraftProcessId },
  }: match<ScanScenarioInstanceUiParams> = yield matchPath(pathname, {
    path: ROUTES.process.scanningScenarioOld(),
  });

  const { result } = yield call(ProcessScan.updateDocument, {
    [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
    [ApiUrlParams.scanInstanceId]: scanInstanceId,
    [ApiUrlParams.scanDocContainerId]: lastCreatedDocument?.id as string,
    data: {
      ...lastCreatedDocument,
      pages: [...(lastCreatedDocument?.pages || []), ...(pages || [])],
    } as UpdateDoc['data'],
  });

  yield put(ProcessScanningActions.saveLastCreatedDocument(result));
}

export default updateDocument;
