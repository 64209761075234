import { getLocation } from 'connected-react-router';
import { fork, select } from 'redux-saga/effects';

import { Tenants } from 'api/endpoints';

import { RECEIVE_ALL_TENANTS_ACTION } from '../reducer';

export type ReFetchTenantsListArgs = [boolean | {}];

function* reFetchTenantsList(skipAll: boolean | {}) {
  const { query } = yield select(getLocation);

  yield fork(Tenants.getTenants, query);

  if (!skipAll || typeof skipAll === 'object') {
    yield fork(Tenants.getTenants, query, {
      title: RECEIVE_ALL_TENANTS_ACTION,
    });
  }
}

export default reFetchTenantsList;
