import { getLocation } from 'connected-react-router';
import { all, call, put, select } from 'redux-saga/effects';
import { ScanDocumentContainerWithPages } from 'types/scanning';

import { ApiSagaResult } from 'api/apiReqSagaCreator';
import ProcessScan, {
  GetListDocumentsResult,
  ScanDocumentContainer,
} from 'api/endpoints/processScan';
import {
  WithReleaseOrDraftProcessId,
  WithScanInstanceId,
} from 'api/types/pathParams';

import documentBarcodeData from 'store/scanDocumentView/documentBarcodeData';

import crateImageUrlFromBuffer, {
  CrateImageUrlFromBufferResult,
} from 'utils/crateImageUrlFromBuffer';

import { DocumentViewActions } from '../actions';
import ScanDocumentViewSelector from '../selectors';
import getDocumentsPages, {
  GetDocumentsPagesResult,
} from './getDocumentsPages';

export type GetDocumentsArgs = [
  WithReleaseOrDraftProcessId & WithScanInstanceId,
];

function* getDocuments(
  params: WithReleaseOrDraftProcessId & WithScanInstanceId
) {
  const { query } = yield select(getLocation);
  const barcodeValues: Nullable<KeyValuePairs> = yield select(
    ScanDocumentViewSelector.getBarcodeValuesList
  );

  const { limit, offset } = query;

  const { result }: ApiSagaResult<GetListDocumentsResult> = yield call(
    ProcessScan.getListDocuments,
    {
      ...params,
      limit,
      offset,
    }
  );

  const documentsWithPages = (result as GetListDocumentsResult)?.data?.filter(
    (documentItem: ScanDocumentContainer) => documentItem.pages
  );

  const documentsWithBarcode = (result as GetListDocumentsResult)?.data?.filter(
    (documentItem: ScanDocumentContainer) => documentItem.barcode
  );

  const listBarcodeValues = JSON.parse(
    documentBarcodeData.getDocumentsBarcodeData()
  );

  const barcodeKeyValuePairs = documentsWithBarcode?.reduce(
    (prev: KeyValuePairs, next: ScanDocumentContainer) => ({
      ...prev,
      [next?.id]: next?.barcode as string,
    }),
    {}
  );

  yield put(
    DocumentViewActions.setBarcodeValue({
      ...barcodeValues,
      ...barcodeKeyValuePairs,
      ...listBarcodeValues,
    })
  );

  const documentPagesData: GetDocumentsPagesResult[] = yield all(
    [...(documentsWithPages || [])].map((item) =>
      call(
        getDocumentsPages,
        params.scanInstanceId,
        item?.id as string,
        item?.pages as string[]
      )
    )
  );

  const documentWithPages: CrateImageUrlFromBufferResult[] = yield all(
    [...((result as GetListDocumentsResult)?.data || [])]
      ?.map((docItem: ScanDocumentContainer) => {
        const findItem = documentPagesData.find(
          (pageItem) => pageItem.id === docItem.id
        );

        if (findItem && findItem.ok) {
          return call(
            crateImageUrlFromBuffer,
            findItem.buffer,
            docItem.id,
            docItem.pages
          );
        }

        return null;
      })
      .filter(Boolean)
  );

  const updateDocumentsList = (result as GetListDocumentsResult)?.data?.map(
    (doc: ScanDocumentContainer) => {
      const findItem = documentWithPages?.find(
        (documentPageItem) => documentPageItem?.documentId === doc.id
      );

      if (findItem) Object.assign(doc, { pages: findItem.pages });

      if (!('formData' in doc)) Object.assign(doc, { formData: {} });

      return doc;
    }
  ) as ScanDocumentContainerWithPages[];

  // triggered in barcode-per-document mode
  yield put(
    DocumentViewActions.setProcessInstances({
      data: updateDocumentsList ?? null,
      total: (result as GetListDocumentsResult).total,
    })
  );
}

export default getDocuments;
