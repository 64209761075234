import ROUTES from 'app/routes/paths';
import { push } from 'connected-react-router';
import { put, select } from 'redux-saga/effects';

import { RequestActionMeta } from 'api/apiReqSagaCreator';
import { ProcessDetails, ProcessGeneral } from 'api/endpoints/processes';
import {
  CreateProcessInstanceParams,
  CreateProcessInstanceResult,
} from 'api/endpoints/processInstance';

import ProcessSelectors from 'store/process/selectors';

import { INSTANCES_LIST_CONFIG } from 'constants/global';
import UiUrlParams from 'constants/uiUrlParams';

function* redirectToInstance({
  payload,
  meta: { input },
}: ReduxAction<
  CreateProcessInstanceResult,
  RequestActionMeta<CreateProcessInstanceParams>
>) {
  const list: Nullable<ProcessGeneral[]> = yield select(
    ProcessSelectors.getList
  );

  const details: Nullable<ProcessDetails> = yield select(
    ProcessSelectors.getDetails
  );

  const targetProcess = list?.find(
    (e: ProcessGeneral) => e.id === input?.releaseOrDraftProcessId
  );

  const draftProcessId = targetProcess?.version?.draftProcessId ?? details?.id;

  yield sessionStorage.removeItem(INSTANCES_LIST_CONFIG);

  yield put(
    push(
      ROUTES.process.instance._({
        [UiUrlParams.releaseOrDraftProcessId]:
          input?.releaseOrDraftProcessId as string,
        [UiUrlParams.processInstanceId]: payload[0].id,
        [UiUrlParams.draftProcessId]: draftProcessId as string,
      })
    )
  );
}

export default redirectToInstance;
