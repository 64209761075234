import { put } from 'redux-saga/effects';

import * as ERROR_CODES from 'constants/errorCodes';

import createATA from 'utils/createAddToasterAction';

const MESSAGE =
  'Your version is no longer supported. Please re-export this process.';

function* importProcessFailure({
  payload,
}: {
  type: string;
  payload: BackendError[];
}) {
  if (payload.some((e) => e.code === ERROR_CODES.UNSUPPORTED_VERSION)) {
    yield put(createATA({ type: 'error', message: MESSAGE }));
  }
}

export default importProcessFailure;
