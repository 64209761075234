import { put } from 'redux-saga/effects';

import createAddToasterAction from 'utils/createAddToasterAction';

function* deleteTemplateSuccess() {
  yield put(
    createAddToasterAction({
      message: 'Template was successfully deleted',
    })
  );
}

export default deleteTemplateSuccess;
