import { TenantGeneral } from 'api/endpoints/tenants';
import { WithTMSConnectionId } from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { excludeObjectProperty } from 'utils/normalizers/object';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export type ConnectionType = 'tmw' | 'truckmate' | 'innovative';

export interface TmsConnectionGeneral extends BaseEntity {
  valid: boolean;
  type: ConnectionType;
  username: string;
}

export interface TmsConnectionDetails extends TmsConnectionGeneral {
  baseUrl: string;
  authUrl?: string;
  password?: string;
}

export type ReusedConnectionDetailsProps =
  | 'baseUrl'
  | 'username'
  | 'password'
  | 'authUrl'
  | 'type';

export type ValidateTmsCredentials = Pick<
  TmsConnectionDetails,
  ReusedConnectionDetailsProps
>;

export interface ParamsToTest {
  [paramName: string]: string;
}

export type GetTmsConnectionsParams = Pagination | unknown;
export type GetTmsConnectionsResult = DataWithTotal<TmsConnectionGeneral>;
export type AddTmsConnectionParams = ValidateTmsCredentials;
export type AddTmsConnectionResult = TenantGeneral;
export type UpdateTmsConnectionParams = ValidateTmsCredentials &
  WithTMSConnectionId;
export type UpdateTmsConnectionResult = TenantGeneral;
export type DeleteTmsConnectionParams = WithTMSConnectionId;
export type DeleteTmsConnectionResult = unknown;
export type GetTmsConnectionDetailsParams = WithTMSConnectionId;
export type GetTmsConnectionDetailsResult = TmsConnectionDetails;
export type ValidateTmsCredentialsParams = ValidateTmsCredentials;

export interface TestEndpointParams extends GetEndpointsParams {
  params: ParamsToTest;
  tms_id: string;
}

export interface TmsEndpointResponse {
  keys: KeyValuePairs<{
    type: 'text' | 'number';
    value: string | number;
  }>;
}

export interface ValidateTmsCredentialsResult {
  valid: boolean;
}

export interface GetEndpointsParams {
  type: string;
}

export interface EndpointContent {
  endpoint: string;
  params: string[];
}

export interface GetEndpointsResult {
  endpoints: KeyValuePairs<EndpointContent>;
}

const TmsConnections = {
  getConnections: apiReqSagaCreator<
    GetTmsConnectionsParams,
    GetTmsConnectionsResult
  >(
    API_REQ_ACTION_TYPES.TMS_CONNECTIONS.GET_CONNECTIONS,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.tmsConnections._(),
      params,
    }),
    {
      withPrevPageOnLessTotal: true,
    }
  ),
  addConnection: apiReqSagaCreator<
    AddTmsConnectionParams,
    AddTmsConnectionResult
  >(API_REQ_ACTION_TYPES.TMS_CONNECTIONS.ADD_CONNECTION, (data) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.tmsConnections._(),
    data,
  })),
  updateConnection: apiReqSagaCreator<
    UpdateTmsConnectionParams,
    UpdateTmsConnectionResult
  >(API_REQ_ACTION_TYPES.TMS_CONNECTIONS.UPDATE_CONNECTION, (args) => ({
    method: HTTP_METHODS.PUT,
    url: ApiPaths.tmsConnections.byId._({
      [ApiUrlParams.tmsConnectionId]: args[ApiUrlParams.tmsConnectionId],
    }),
    data: excludeObjectProperty(args, ApiUrlParams.tmsConnectionId),
  })),
  deleteConnection: apiReqSagaCreator<
    DeleteTmsConnectionParams,
    DeleteTmsConnectionResult
  >(API_REQ_ACTION_TYPES.TMS_CONNECTIONS.DELETE_CONNECTION, (params) => ({
    method: HTTP_METHODS.DELETE,
    url: ApiPaths.tmsConnections.byId._({
      [ApiUrlParams.tmsConnectionId]: params[ApiUrlParams.tmsConnectionId],
    }),
  })),
  getConnectionDetails: apiReqSagaCreator<
    GetTmsConnectionDetailsParams,
    GetTmsConnectionDetailsResult
  >(API_REQ_ACTION_TYPES.TMS_CONNECTIONS.GET_CONNECTION_DETAILS, (params) => ({
    method: HTTP_METHODS.GET,
    url: ApiPaths.tmsConnections.byId._({
      [ApiUrlParams.tmsConnectionId]: params[ApiUrlParams.tmsConnectionId],
    }),
  })),
  validateCredentials: apiReqSagaCreator<
    ValidateTmsCredentialsParams,
    ValidateTmsCredentialsResult
  >(API_REQ_ACTION_TYPES.TMS_CONNECTIONS.VALIDATE_CREDENTIALS, (data) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.tmsConnections.validateCredentials._(),
    data,
  })),
  getEndpoints: apiReqSagaCreator<GetEndpointsParams, GetEndpointsResult>(
    API_REQ_ACTION_TYPES.TMS_CONNECTIONS.GET_ENDPOINTS,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.tmsConnections.endpoints._(),
      params,
    })
  ),
  testEndpointParams: apiReqSagaCreator<
    TestEndpointParams,
    TmsEndpointResponse
  >(API_REQ_ACTION_TYPES.TMS_CONNECTIONS.TEST_ENDPOINT_PARAMS, (data) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.tmsConnections.endpoints.keys._(),
    data,
    transformRequest: (d) => JSON.stringify(d),
  })),
};

export default TmsConnections;
