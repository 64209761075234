import ROUTES, { ProcessInstanceUiParams } from 'app/routes/paths';
import { getLocation } from 'connected-react-router';
import { match } from 'react-router';
import { matchPath } from 'react-router-dom';
import { fork, select } from 'redux-saga/effects';

import ProcessInstance from 'api/endpoints/processInstance';

import ApiUrlParams from 'constants/apiUrlParams';

function* reFetchInstanceDetails() {
  const { pathname } = yield select(getLocation);

  const {
    params: { releaseOrDraftProcessId, processInstanceId },
  }: match<ProcessInstanceUiParams> = yield matchPath(pathname, {
    path: [ROUTES.process.instance._()],
  });

  yield fork(ProcessInstance.getProcessInstanceById, {
    [ApiUrlParams.releaseOrDraftProcessId]: releaseOrDraftProcessId,
    [ApiUrlParams.processInstanceId]: processInstanceId,
  });
}

export default reFetchInstanceDetails;
