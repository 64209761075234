import ROUTES from 'app/routes/paths';
import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

const HOME_SUB_ROUTES = [ROUTES.process.home._()];

const DEFINITION_SUB_ROUTES = [
  ROUTES.process.definition._(),
  ROUTES.process.editProcess._(),
  ROUTES.process.editProcess.preview(),
  ROUTES.process.createProcessForm(),
  ROUTES.process.editProcessForm(),
  ROUTES.process.formDetails._(),
  ROUTES.process.formDetails.preview(),
  ROUTES.process.scanningDetails._(),
  ROUTES.process.scanningDetails.preview(),
  ROUTES.process.definition.services.printRender._(),
  ROUTES.process.definition.services.printRender.preview(),
  ROUTES.process.definition.services.scanning._(),
  ROUTES.process.definition.services.scanning.preview(),
  ROUTES.process.printRenderDetails._(),
  ROUTES.process.printRenderDetails.preview(),
  ROUTES.process.definition.services.ftpPolling._(),
  ROUTES.process.definition.services.ftpPolling.preview(),
  ROUTES.process.definition.services.emailPolling._(),
  ROUTES.process.definition.services.emailPolling.preview(),
  ROUTES.process.definition.services.ocr._(),
  ROUTES.process.definition.services.ocr.preview(),
];

const EXECUTION_SUB_ROUTES = [
  ROUTES.process.execution._(),
  ROUTES.process.instance._(),
  // ROUTES.process.scanningDocumentView(),
  ROUTES.process.scanningScenarioOld(),
  ROUTES.process.scanningScenario(),
];

const USER_SUB_ROUTES = [
  ROUTES.management._(),
  ROUTES.management.users._(),
  ROUTES.management.tenants._(),
  ROUTES.management.emailSettings._(),
  ROUTES.management.connections._(),
  ROUTES.management.connections.ftp._(),
  ROUTES.management.connections.tms._(),
  ROUTES.management.connections.tms.details._(),
  ROUTES.management.connections.tms.details._(),
];

export interface UseCheckRoute {
  isDefinitionRoute: boolean;
  isExecutionRoute: boolean;
  isUserRoute: boolean;
  isHomeRoute: boolean;
}

const useCheckRoute = () => {
  const { pathname } = useLocation();

  const isDefinitionRoute: boolean = DEFINITION_SUB_ROUTES.some((path) =>
    matchPath(pathname, { path })
  );

  const isExecutionRoute: boolean = EXECUTION_SUB_ROUTES.some((path) =>
    matchPath(pathname, { path })
  );

  const isUserRoute: boolean = USER_SUB_ROUTES.some((path) =>
    matchPath(pathname, { path })
  );

  const isHomeRoute: boolean = HOME_SUB_ROUTES.some((path) =>
    matchPath(pathname, { path })
  );

  return useMemo(
    () => ({
      isDefinitionRoute,
      isExecutionRoute,
      isUserRoute,
      isHomeRoute,
    }),
    [isDefinitionRoute, isExecutionRoute, isUserRoute, isHomeRoute]
  );
};

export default useCheckRoute;
