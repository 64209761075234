import { conformToMask } from 'react-text-mask';

import {
  BLOCK_TYPE,
  ConstantVariable,
} from 'components/organisms/blocklyWrapper/config';

import { EmailTemplateGeneral } from 'api/endpoints/emailTemplates';
import { ReusableFunctionGeneral } from 'api/endpoints/function';
import { FieldGeneral } from 'api/endpoints/processFields';
import { FormGeneral } from 'api/endpoints/processForm';

import { FORM_NAMES } from 'constants/form';
import { DataModelFieldSubType, DataModelFieldType } from 'constants/global';
import { MASKS } from 'constants/masks';

const getUrlWithParams = (base: string, params: QueryParams) =>
  `${base}?`.concat(
    Object.entries(params)
      .map(([key, value]) => {
        if (Array.isArray(value))
          return value.map((v) => `${key}=${v}`).join('&');
        else return `${key}=${value}`;
      })
      .join('&')
  );

const withDefaultPagination = (uri: string, perPage = 16) =>
  `${uri}?page=0&offset=0&limit=${perPage}`;

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const getDynamicFieldType = (field: FieldGeneral) =>
  `${BLOCK_TYPE.DATA_MODEL_ATTRIBUTE}___${field.type}___${field.id}`;

const getDynamicFormType = (field: FormGeneral) =>
  `${BLOCK_TYPE.DATA_MODEL_FORM}___${field.id}`;

const getDynamicFunctionType = (item: ReusableFunctionGeneral) =>
  `${BLOCK_TYPE.FUNCTION}___${item.id}`;

const getDynamicEmailTemplateType = (item: EmailTemplateGeneral) =>
  `${BLOCK_TYPE.EMAIL_TEMPLATE}___${item.id}`;

const getDynamicConstantVariableType = (item: ConstantVariable) =>
  `${BLOCK_TYPE.CONSTANT_VARIABLE}___${item.id}`;

const getDynamicFormName = (
  taskId: string,
  formId: string,
  formType = FORM_NAMES.FORM_FACTORY
) => `${formType}___${taskId}___${formId}`;

const formatField = (
  value: unknown,
  type: DataModelFieldType | DataModelFieldSubType
) => {
  if (value === null || typeof value === 'undefined') return value;
  if (type === DataModelFieldSubType.TextEmail) return value;
  if (!type || !MASKS[type]) return value;
  const maskedValue = conformToMask(value, MASKS[type], {
    guide: false,
  });
  return maskedValue.conformedValue;
};

const fixedEncodeURIComponent = (str: string) =>
  encodeURIComponent(str).replace(
    /[!'()*]/g,
    (c) => `%${c.charCodeAt(0).toString(16)}`
  );

export {
  fixedEncodeURIComponent,
  withDefaultPagination,
  getUrlWithParams,
  capitalize,
  getDynamicFieldType,
  getDynamicFormType,
  getDynamicFormName,
  getDynamicFunctionType,
  getDynamicEmailTemplateType,
  getDynamicConstantVariableType,
  formatField,
};
