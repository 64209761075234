import createAction from 'utils/createAction';

const PROCESS_SCANNING = {
  SET_IS_PAGE_SAVED: 'PROCESS_SCANNING.SET_IS_PAGE_SAVED',
  SAVE_FORM_DATA: 'PROCESS_SCANNING.SAVE_FORM_DATA',
  SET_SAVED_PAGES: 'PROCESS_SCANNING.SET_SAVED_PAGES',
  SAVE_BARCODE: 'PROCESS_SCANNING.SAVE_BARCODE',
  SAVE_LAST_CREATED_DOCUMENT: 'PROCESS_SCANNING.SAVE_LAST_CREATED_DOCUMENT',
  CLEAR_LAST_CREATED_DOCUMENT: 'PROCESS_SCANNING.CLEAR_LAST_CREATED_DOCUMENT',
  SET_UPLOADED_FILES: 'PROCESS_SCANNING.SET_UPLOADED_FILES',
};

const ProcessScanningActions = {
  setIsPageSaved: createAction<boolean>(PROCESS_SCANNING.SET_IS_PAGE_SAVED),
  setSavedPages: createAction<{ names: string[] | []; files: File[] | [] }>(
    PROCESS_SCANNING.SET_SAVED_PAGES
  ),
  saveFormData: createAction<DynamicFormParams>(
    PROCESS_SCANNING.SAVE_FORM_DATA
  ),
  saveBarcode: createAction<string>(PROCESS_SCANNING.SAVE_BARCODE),
  saveLastCreatedDocument: createAction(
    PROCESS_SCANNING.SAVE_LAST_CREATED_DOCUMENT
  ),
  clearLastCreatedDocument: createAction(
    PROCESS_SCANNING.CLEAR_LAST_CREATED_DOCUMENT
  ),
  setUploadedFiles: createAction<Nullable<KeyValuePairs>>(
    PROCESS_SCANNING.SET_UPLOADED_FILES
  ),
};

export type SetSavedPagesAction = ReturnType<
  typeof ProcessScanningActions.setSavedPages
>;

export { ProcessScanningActions, PROCESS_SCANNING };
