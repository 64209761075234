import React, { memo } from 'react';

import { ReactComponent as OrganizationBuilding } from 'assets/icon/organization-building.svg';

import Text from 'components/atoms/text';

import { TEXTS } from './config';
import sm from './styles.module.scss';
import useLogic from './useLogic';

const Footer = () => {
  // region *******************************DATA*********************************
  const content = useLogic();
  // endregion

  // region ********************************JSX*********************************
  return (
    <footer className={sm.Container}>
      <div className={sm.Container_Name}>
        {!!content && (
          <>
            <div className={sm.Container_Name_Icon}>
              <OrganizationBuilding />
            </div>
            <div className={sm.Container_Name_Label}>
              <Text content={content} withSemiBoldWeight with12Font />
            </div>
          </>
        )}
      </div>
      <div className={sm.Container_Powered}>
        <Text content={TEXTS.POWERED} with12Font />
        <div className={sm.Container_Powered_Name}>
          <Text
            content={TEXTS.NAME.FIRST}
            with12Font
            withBlueColor
            withSemiBoldWeight
          />
          <Text content={TEXTS.NAME.SECOND} with12Font />
        </div>
      </div>
    </footer>
  );
  // endregion
};

export default memo(Footer);
