import React, { memo } from 'react';

import ErrorImage from 'assets/img/error-page.svg';

import Text from 'components/atoms/text';

import { TEXTS } from './config';
import sm from './styles.module.scss';

const ErrorPage = () => (
  <div className={sm.Container}>
    <div className={sm.Container_Img}>
      <img src={ErrorImage} alt={TEXTS.ALT} />
    </div>
    <div className={sm.Container_TextWrapper}>
      <Text
        content={TEXTS.DESCRIPTION}
        with20Font
        withSemiBoldWeight
        withPreLine
      />
    </div>
  </div>
);

export default memo(ErrorPage);
