import {
  ApiError,
  ApiResponseAsync,
  translateAxiosErrorToApiError,
} from 'container/models';

import { authHeaderName } from 'api/apiReqSagaCreator/config';
import { handleExpiredTokens } from 'api/apiReqSagaCreator/httpRequest';
import { ProcessInstanceDetails } from 'api/endpoints/processInstance';
import { OdataURLBuilder } from 'api/types/OdataURLBuilder';

import {
  AbstractIndexingDocumentsRepo,
  FinishIndexDocumentResponse,
  IndexDocumentWithHyperMedia,
  LockDocumentResponse,
} from './abstractIndexingDocumentsRepo';

export class IndexingDocumentRepo extends AbstractIndexingDocumentsRepo {
  getProcessInstance = (link: Link): ApiResponseAsync<ProcessInstanceDetails> =>
    handleExpiredTokens(() =>
      this.client.get<ProcessInstanceDetails>(link.href, {
        headers: {
          [authHeaderName]: this.tokenAccessor(),
        },
      })
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);

  upsert = (
    link: Link,
    name: string,
    draftProcessId: string,
    processInstanceId: string,
    indexingSourceId: string,
    fileFieldId: string
  ): ApiResponseAsync<IndexDocumentWithHyperMedia> =>
    handleExpiredTokens(() =>
      this.client.post<IndexDocumentWithHyperMedia>(
        new OdataURLBuilder(link, { process_id: draftProcessId }).toString(),
        {
          name,
          processInstanceId,
          indexingSourceId,
          fileFieldId,
        },
        {
          headers: {
            [authHeaderName]: this.tokenAccessor(),
          },
        }
      )
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);

  lock = (
    link: Link,
    scanConfigurationId: string,
    indexingDocumentId: string
  ): ApiResponseAsync<LockDocumentResponse> =>
    handleExpiredTokens(() =>
      this.client.post<LockDocumentResponse>(
        new OdataURLBuilder(link, {
          index_document_id: indexingDocumentId,
        }).toString(),
        {
          scanConfigurationId,
        },
        {
          headers: {
            [authHeaderName]: this.tokenAccessor(),
          },
        }
      )
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);

  lockNext = (link: Link): ApiResponseAsync<Nullable<LockDocumentResponse>> =>
    handleExpiredTokens(() =>
      this.client.post<Nullable<LockDocumentResponse>>(
        link.href,
        {},
        {
          headers: {
            [authHeaderName]: this.tokenAccessor(),
          },
        }
      )
    )
      .then((response) => (response.status === 204 ? null : response.data))
      .catch(translateAxiosErrorToApiError);

  unLock = (link: Link): ApiResponseAsync<IndexDocumentWithHyperMedia> =>
    handleExpiredTokens(() =>
      this.client.post<IndexDocumentWithHyperMedia>(
        link.href,
        {},
        {
          headers: {
            [authHeaderName]: this.tokenAccessor(),
          },
        }
      )
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);

  finish = (link: Link): ApiResponseAsync<FinishIndexDocumentResponse> =>
    handleExpiredTokens(() =>
      this.client.post<FinishIndexDocumentResponse>(
        link.href,
        {},
        {
          headers: {
            [authHeaderName]: this.tokenAccessor(),
          },
        }
      )
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);

  deleteDoc = (
    document: IndexDocumentWithHyperMedia
  ): ApiResponseAsync<null> => {
    const link = document.links?.delete;

    if (!link)
      return Promise.resolve<ApiError>({
        status: 400,
        statusText: 'Bad Request',
        data: [
          {
            code: -1,
            description: 'No delete link found',
          },
        ],
      });

    return handleExpiredTokens(() =>
      this.client.delete(new OdataURLBuilder(link).toString(), {
        headers: { [authHeaderName]: this.tokenAccessor() },
      })
    )
      .then(() => null)
      .catch(translateAxiosErrorToApiError);
  };

  getDocumentCount = (
    link: Link,
    indexingSourceId: string
  ): ApiResponseAsync<number> => {
    let query = new OdataURLBuilder(link);
    query.setFilter(
      `indexing_source_id eq '${indexingSourceId}' AND lock_user eq null`
    );
    query.setCount(true);

    return handleExpiredTokens(() =>
      this.client.get<number>(query.toString(), {
        headers: {
          [authHeaderName]: this.tokenAccessor(),
        },
      })
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);
  };
}
