import { Params } from 'store/process/sagas/normalizedFormFiles';

import createAction from 'utils/createAction';

const EXTERNAL = {
  FORM: {
    START_SUBMIT: 'EXTERNAL.FORM.START_SUBMIT',
  },
};
const ExternalActions = {
  form: {
    startSubmit: createAction<Pick<Params, 'ott' | 'fields' | 'form'>>(
      EXTERNAL.FORM.START_SUBMIT
    ),
  },
};

export type StartSubmitFormAction = ReturnType<
  typeof ExternalActions.form.startSubmit
>;

export { EXTERNAL as EXTERNAL_ACTION_TYPES, ExternalActions };
