import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { HTTP_METHODS, RESPONSE_TYPE } from '../constants';

export type GetBlobParams = string;
export type GetBlobResult = Blob;
export type CheckFileParams = string;
export type CheckFileResult = unknown;

const Files = {
  getBlob: apiReqSagaCreator<GetBlobParams, GetBlobResult>(
    API_REQ_ACTION_TYPES.FILES.GET_BY_URL,
    (url) => ({
      method: HTTP_METHODS.GET,
      url,
      responseType: RESPONSE_TYPE.BLOB,
      transformResponse: (data) => data,
    })
  ),
  checkFile: apiReqSagaCreator<CheckFileParams, CheckFileResult>(
    API_REQ_ACTION_TYPES.FILES.CHECK_FILE,
    (url) => ({
      method: HTTP_METHODS.OPTIONS,
      url,
    })
  ),
};

export default Files;
