const MAX_HISTORY_LENGTH = 50;

enum DataModelFieldType {
  Text = 'text',
  Date = 'date',
  Number = 'number',
  File = 'file',
  Collection = 'collection',
  Select = 'select',
}

enum ExternalTypes {
  options = 'options',
}

enum DataModelFieldSubType {
  TextEmail = 'textEmail',
  TextUrl = 'textURL',
  TextPhone = 'textPhone',
  TextRichText = 'textRichText',
  NumberPhone = 'numberPhone',
  DateReverse = 'dateReverse',
  DateDefault = 'dateDefault',
  DateDots = 'dateDots',
}

const DATA_MODEL_FIELD_LABELS = {
  [DataModelFieldType.Text]: 'Text Field',
  [DataModelFieldType.Select]: 'Select one',
  [DataModelFieldType.Number]: 'Number',
  [DataModelFieldType.File]: 'File',
  [DataModelFieldType.Date]: 'Date',
  [DataModelFieldType.Collection]: 'Collection',
};

const DATA_MODEL_FIELD_SUB_LABELS = {
  [DataModelFieldSubType.TextEmail]: 'Text Field: Email',
  [DataModelFieldSubType.TextUrl]: 'Text Field: URL',
  [DataModelFieldSubType.TextPhone]: 'Text Field: Phone',
  [DataModelFieldSubType.TextRichText]: 'Text Field: Rich text',
  [DataModelFieldSubType.NumberPhone]: 'Number: Phone',
  [DataModelFieldSubType.DateDefault]: 'Date: MM / DD / YYYY',
  [DataModelFieldSubType.DateReverse]: 'Date: DD / MM / YYYY',
  [DataModelFieldSubType.DateDots]: 'Date: MM . DD . YYYY',
};

enum SystemStatus {
  Active = 'active',
  Inactive = 'inactive',
  Invited = 'invited',
  Blocked = 'blocked',
  Success = 'success',
  Running = 'running',
  Failed = 'failed',
  Pending = 'pending',
  Warning = 'warning',
  Error = 'error',
  Normal = 'normal',
  Draft = 'draft',
  Released = 'released',
  Valid = 'valid',
  InValid = 'inValid',
  Skipped = 'skipped',
}

enum TaskType {
  ShowForm = 'form-show',
  GetForm = 'form-get',
  Indexing = 'indexing',
  ShowDocument = 'document-show',
  SendEmail = 'email-send',
  SendEmailWithVars = 'email-send-with-vars',
  SendSms = 'sms-send',
  SendSmsWithVars = 'sms-send-with-vars',
  SendFormViaEmail = 'form-send-via-email',
  SendFormViaSms = 'form-send-via-sms',
  OcrFile = 'ocr-file',
  SearchProcessInstances = 'search-process-instances',
  UpdateProcessInstances = 'update-by-search',
  SendEmailWithTemplate = 'email-send-with-template',
  SendFormWithEmailTemplate = 'send-form-with-email-template',
  FinishInstance = 'finish-instance',
  SendFileToFtp = 'send-file-to-ftp',
}

enum BarcodeTypeValue {
  Code39 = 'code39',
  Code128 = 'code128',
  CodaBar = 'codabar',
  Interleaved2of5 = 'interleaved2of5',
}

const BARCODE_TYPE_LABELS = {
  [BarcodeTypeValue.Code39]: 'Code 39',
  [BarcodeTypeValue.Code128]: 'Code 128',
  [BarcodeTypeValue.CodaBar]: 'Codabar',
  [BarcodeTypeValue.Interleaved2of5]: 'Interleaved 2 of 5',
};

const ACTION_WORDS = {
  DELETE_PROCESS: 'delete',
  DELETE_TENANT: 'delete tenant',
};

const PORTAL_ROOT_IDS = {
  SUB_MENU: 'submenu-root',
  MODAL: 'modal-root',
  MENU: 'menu-root',
  ADVANCED_SEARCH: 'advanced-search-root',
  SELECT: 'select-dropdown-root',
};

const HIDE_FROM_FOCUS = -1;

const INSTANCES_LIST_CONFIG = 'INSTANCES_LIST_CONFIG';

enum SearchTypes {
  quick = 'quickSearch',
  advanced = 'advancedSearch',
  view = 'view',
}

const UPLOAD_FILE_FIELD_NAME = 'file';

const KEYS_CODE = {
  ARROW_UP: 38,
  ARROW_DOWN: 40,
};

export {
  HIDE_FROM_FOCUS,
  MAX_HISTORY_LENGTH,
  DataModelFieldType,
  DATA_MODEL_FIELD_LABELS,
  TaskType,
  SystemStatus,
  BarcodeTypeValue,
  BARCODE_TYPE_LABELS,
  ACTION_WORDS,
  PORTAL_ROOT_IDS,
  SearchTypes,
  INSTANCES_LIST_CONFIG,
  DataModelFieldSubType,
  DATA_MODEL_FIELD_SUB_LABELS,
  ExternalTypes,
  UPLOAD_FILE_FIELD_NAME,
  KEYS_CODE,
};
