import { put } from 'redux-saga/effects';

import createATA from 'utils/createAddToasterAction';

const MESSAGE = 'Key setting for OCR was successfully deleted';

function* removeKeysSuccess() {
  yield put(
    createATA({
      message: MESSAGE,
    })
  );
}

export default removeKeysSuccess;
