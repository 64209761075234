import { transformRequest } from 'api/apiReqSagaCreator/httpRequest';
import { WithTemplateId } from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

const normalizeProcessIdKey = (object: ByProcessId) => {
  const { processId, ...rest } = object;
  return {
    ...rest,
    process_id: processId,
  };
};

export type TemplateStatus = 'inactive' | 'active';

export interface TemplateLayout {
  field: string;
  headerText: string;
  customId: string;
  visible: boolean;
  positionIndex: number;
  variables?: string[];
  width?: string | number;
  sortOrder?: string;
  cellType?: string;
}

export interface GridTemplateGeneral extends Pick<BaseEntity, 'name' | 'id'> {
  layout: TemplateLayout[];
  status: TemplateStatus;
  ownerId: string;
  processId: string;
  type: string;
  links: Hypermedia;
}

export interface ByProcessId {
  processId?: string;
}

export interface ByGridType {
  type: 'instance_list' | 'log_list' | 'default_instance_list';
}

export interface SaveTemplateParams extends ByGridType, ByProcessId {
  name: string;
  layout: unknown;
}

export interface SaveDefaultTemplateParams extends ByGridType, ByProcessId {
  links: Link;
  layout: unknown;
}

export interface GetTemplates extends ByGridType, Pagination {}

export type SaveTemplateResult = GridTemplateGeneral;
export type SaveDefaultTemplateResult = GridTemplateGeneral;
export type GetListParams = GetTemplates & ByProcessId;
export type GetListResult = DataWithTotal<GridTemplateGeneral>;
export type ActivateTemplateParams = ByGridType & WithTemplateId & ByProcessId;
export type ActivateTemplateResult = GridTemplateGeneral;
export type RemoveTemplateParams = ByGridType & WithTemplateId & ByProcessId;
export type RemoveTemplateResult = unknown;

const Grid = {
  saveTemplate: apiReqSagaCreator<SaveTemplateParams, SaveTemplateResult>(
    API_REQ_ACTION_TYPES.GRID.SAVE_TEMPLATE,
    (args) => ({
      url: ApiPaths.gridTemplates._(),
      method: HTTP_METHODS.POST,
      data: {
        name: args.name,
        layout: args.layout,
        processId: args.processId,
        type: args.type,
      },
      transformRequest: (data) => transformRequest(data, ['layout']),
    })
  ),
  saveDefaultTemplate: apiReqSagaCreator<
    SaveDefaultTemplateParams,
    SaveDefaultTemplateResult
  >(API_REQ_ACTION_TYPES.GRID.SAVE_TEMPLATE, (args) => ({
    url: args.links.href,
    method: HTTP_METHODS.PUT,
    data: { layout: args.layout },
    transformRequest: (data) => transformRequest(data, ['layout']),
  })),
  getList: apiReqSagaCreator<GetListParams, GetListResult>(
    API_REQ_ACTION_TYPES.GRID.GET_ALL_TEMPLATES,
    (params) => ({
      url: ApiPaths.gridTemplates._(),
      method: HTTP_METHODS.GET,
      params: normalizeProcessIdKey(params),
    })
  ),
  activateTemplate: apiReqSagaCreator<
    ActivateTemplateParams,
    ActivateTemplateResult
  >(API_REQ_ACTION_TYPES.GRID.ACTIVATE_TEMPLATE, (params) => ({
    url: ApiPaths.gridTemplates.byId.activate._({
      [ApiUrlParams.templateId]: params[ApiUrlParams.templateId],
    }),
    method: HTTP_METHODS.POST,
    params: normalizeProcessIdKey(params),
  })),
  removeTemplate: apiReqSagaCreator<RemoveTemplateParams, RemoveTemplateResult>(
    API_REQ_ACTION_TYPES.GRID.REMOVE_TEMPLATE,
    (params) => ({
      url: ApiPaths.gridTemplates.byId._({
        [ApiUrlParams.templateId]: params[ApiUrlParams.templateId],
      }),
      method: HTTP_METHODS.DELETE,
      params: normalizeProcessIdKey(params),
    })
  ),
};

export default Grid;
