import { takeLatest } from 'redux-saga/effects';

import { API_REQ_ACTION_TYPES } from 'api/actions';

import {
  activateScenarioSuccess,
  deleteScenarioSuccess,
  reFetchScenariosList,
  saveScenarioError,
  saveScenarioSuccess,
} from './sagas';

function* watcherSaga() {
  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.FTP_POLLING.SAVE_SCENARIO.SUCCESS,
      API_REQ_ACTION_TYPES.FTP_POLLING.UPDATE_SCENARIO.SUCCESS,
    ],
    saveScenarioSuccess
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.FTP_POLLING.ACTIVATE_SCENARIO.SUCCESS,
    activateScenarioSuccess
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.FTP_POLLING.DEACTIVATE_SCENARIO.SUCCESS,
    reFetchScenariosList
  );

  yield takeLatest(
    API_REQ_ACTION_TYPES.FTP_POLLING.DELETE_SCENARIO.SUCCESS,
    deleteScenarioSuccess
  );

  yield takeLatest(
    [
      API_REQ_ACTION_TYPES.FTP_POLLING.SAVE_SCENARIO.ERROR,
      API_REQ_ACTION_TYPES.FTP_POLLING.UPDATE_SCENARIO.ERROR,
    ],
    saveScenarioError
  );
}

export default watcherSaga;
