import { call } from 'redux-saga/effects';

import ProcessInstanceEndpoint, {
  GetInstancesListParams,
} from 'api/endpoints/processInstance';

interface getInstanceParams {
  payload: GetInstancesListParams;
  type: string;
}

function* getInstancesById(params: getInstanceParams) {
  yield call(ProcessInstanceEndpoint.getInstancesList, {
    ...params.payload,
  });
}

export default getInstancesById;
